import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import withRouter from "../../../../withRouter";
import { alpha } from "@mui/material/styles";
import Store from "../../../../core/models/Store";
import connect from "react-redux/es/components/connect";
import { CartActionType } from "../../../common/cart/CartActionTypes";
import { Cart } from "../../../common/cart/CartReducer";
import { withMediaQuery } from "../../../../withMediaQuery";
import TickIcon from "../../../../statics/svgs/TickIcon";

interface ILocationHeaderMenuProps {
  isMobile: boolean;
  onStoreSelected(store: Store): void;
  cartCreateOrUpdate: (cart: Cart | undefined) => void;
  closeEvent: any;
}

class LocationHeaderMenu extends React.Component<
  ILocationHeaderMenuProps,
  any
> {
  render() {
    const columnSpacing =
      (window.innerWidth * 50) /
      PixelsToViewPortUnitConverter.getBaseWidth() /
      6;
    const rowSpacing = columnSpacing;
    return this.props.isMobile ? (
      <Box
        sx={{
          display: "flex",
          backgroundColor: (theme) => theme.palette.primary.main,
          // paddingRight: PixelsToViewPortUnitConverter.getvw(250),
          paddingLeft: PixelsToViewPortUnitConverter.getvw(144),
          paddingTop: PixelsToViewPortUnitConverter.getvw(40),
          paddingBottom: PixelsToViewPortUnitConverter.getvw(70),
        }}
      >
        <Grid
          columnSpacing={columnSpacing}
          rowSpacing={rowSpacing}
          container
          columns={{ xs: 12, sm: 12 }}
        >
          {PartnerManager.shared().stores.map((store) => {
            return (
              <Grid
                item
                sx={{ display: "inline-flex" }}
                onClick={() => {
                  if (PartnerManager.shared().currentSelectedStore != store) {
                    PartnerManager.shared().setCurrentSelectedStore(store);
                    this.props.cartCreateOrUpdate({
                      storeId: store.id,
                      products: [],
                    });
                  } else {
                    this.props.closeEvent();
                  }

                  this.props.onStoreSelected(store);
                }}
              >
                <Grid
                  sx={{ position: "relative" }}
                  item
                  xs={10}
                  sm={4}
                  key={store.id}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Stack
                      sx={{
                        height: "100%",

                        position: "relative",
                        paddingY: PixelsToViewPortUnitConverter.getvw(10),
                        paddingLeft: PixelsToViewPortUnitConverter.getvw(20),
                        paddingRight: PixelsToViewPortUnitConverter.getvw(10),
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Barlow",
                          fontWeight: 700,
                          letterSpacing: "0.16em",
                          fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
                          color: (theme) => theme.palette.primary.contrastText,
                        }}
                      >
                        {store.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Barlow",
                          fontWeight: 600,
                          letterSpacing: "0.16em",
                          fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
                          color: (theme) =>
                            alpha(theme.palette.primary.contrastText, 0.8),
                        }}
                      >
                        {store.fullAddress}
                      </Typography>
                    </Stack>
                    <Button
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        padding: "0px",
                        minWidth: "0px",
                        minHeight: "0px",
                        top: 0,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={2}
                  sx={{ paddingTop: PixelsToViewPortUnitConverter.getvw(100) }}
                >
                  {PartnerManager.shared().currentSelectedStore.name ===
                    store.name && <TickIcon />}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          backgroundColor: (theme) => theme.palette.primary.main,
          paddingRight: PixelsToViewPortUnitConverter.getvw(500),
          paddingLeft: PixelsToViewPortUnitConverter.getvw(144),
          paddingTop: PixelsToViewPortUnitConverter.getvw(40),
          paddingBottom: PixelsToViewPortUnitConverter.getvw(70),
        }}
      >
        <Grid
          columnSpacing={columnSpacing}
          rowSpacing={rowSpacing}
          container
          columns={{ xs: 12, sm: 12 }}
          sx={{}}
        >
          {PartnerManager.shared().stores.map((store) => {
            return (
              <Grid
                sx={{ position: "relative" }}
                item
                xs={12}
                sm={4}
                key={store.id}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Stack
                    sx={{
                      height: "100%",

                      position: "relative",
                      paddingY: PixelsToViewPortUnitConverter.getvw(10),
                      paddingLeft: PixelsToViewPortUnitConverter.getvw(20),
                      paddingRight: PixelsToViewPortUnitConverter.getvw(10),
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Barlow",
                        fontWeight: 700,
                        letterSpacing: "0.16em",
                        fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
                        color: (theme) => theme.palette.primary.contrastText,
                      }}
                    >
                      {store.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Barlow",
                        fontWeight: 600,
                        letterSpacing: "0.16em",
                        fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
                        color: (theme) =>
                          alpha(theme.palette.primary.contrastText, 0.8),
                      }}
                    >
                      {store.fullAddress}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      padding: "0px",
                      minWidth: "0px",
                      minHeight: "0px",
                      top: 0,
                    }}
                    onClick={() => {
                      if (
                        PartnerManager.shared().currentSelectedStore != store
                      ) {
                        PartnerManager.shared().setCurrentSelectedStore(store);
                        this.props.cartCreateOrUpdate({
                          storeId: store.id,
                          products: [],
                        });
                      }

                      this.props.onStoreSelected(store);
                    }}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  cartCreateOrUpdate: (cart: Cart | undefined) =>
    dispatch({
      type: CartActionType.CART_CREATE_OR_UPDATE,
      payload: cart,
    }),
});
export default withRouter(
  connect(null, mapDispatchToProps)(withMediaQuery(LocationHeaderMenu))
);
