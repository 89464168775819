import { alpha, Box, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import "./VerifyOTPComponent.scss";
import ErrorLabel from "../other/ErrorLabel";
import CustomButton from "../other/CustomButton";
import TextUtils from "../../../../core/utils/TextUtils";
import OtpInput from "react-otp-input";
import { useElementSize } from "usehooks-ts";
import DimensionUtils from "../../../../core/utils/DimensionUtils";
import UserAuthService from "../../../../services/UserAuthService";
import { ApiError } from "../../../../core/webservice/ApiError";
import AmplifyManager from "../../../../core/utils/AmplifyManager";
import { withMediaQuery } from "../../../../withMediaQuery";

type VerifyOTPComponentProps = {
  userName: string;
  password: string;
  signUpandSignIn?: boolean;
  resetPassword?: boolean;
  onVerificationSuccessful(): void;
  isMobile: boolean;
};

const VerifyOTPComponent: React.FC<VerifyOTPComponentProps> = (
  props: VerifyOTPComponentProps
) => {
  const otpRef = useRef(null);
  const [otp, setOtp] = useState("");
  const [isLoadingA, setIsLoadingA] = useState(false);
  const [isLoadingB, setIsLoadingB] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [otpBoxRef, { width, height }] = useElementSize();
  const [otpBoxContainerWidth, setOtpBoxContainerWidth] = useState(width);
  const {
    userName,
    password,
    signUpandSignIn = false,
    resetPassword = false,
  } = props;

  useEffect(() => {
    setOtpBoxContainerWidth(width);
  }, [width]);

  useEffect(() => {
    if (!TextUtils.isEmpty(otp) && otp.length === 6) {
      // CALL API
      handleEnterClicked();
    }
  }, [otp]);

  const isFormValid = () => {
    if (TextUtils.isEmpty(otp) || otp.length !== 6) {
      return false;
    }
    return true;
  };

  const handleResendClicked = () => {
    if (isLoadingA) return;
    setErrorMessage(undefined);
    setIsLoadingA(true);
    UserAuthService.resendSignUpCode(userName)
      .then((response: any) => {
        setIsLoadingA(false);
      })
      .catch((error: ApiError) => {
        setIsLoadingA(false);
        setErrorMessage(error.message);
      });
  };

  const handleEnterClicked = () => {
    if (!isFormValid()) {
      return;
    }
    if (signUpandSignIn) {
      // sign up flow
      if (isLoadingB) return;
      setErrorMessage(undefined);
      setIsLoadingB(true);
      UserAuthService.confirmSignUpCode(userName, otp)
        .then((response: any) => {
          signInUser();
          // setIsLoadingB(false)
          // props.onVerificationSuccessful()
        })
        .catch((error: ApiError) => {
          setOtp("");
          setIsLoadingB(false);
          setErrorMessage(error.message);
          if (otpRef.current) {
            otpRef.current.focusInput(0);
          }
        });
    } else if (resetPassword) {
      // reset password flow
    }
  };

  const signInUser = () => {
    UserAuthService.signInUser(userName, password)
      .then((response: any) => {
        setIsLoadingB(false);
        AmplifyManager.shared().setJwtAccessToken(response);
        props.onVerificationSuccessful();
      })
      .catch((error: ApiError) => {
        setIsLoadingB(false);
        setErrorMessage(error.message);
      });
  };

  const spacingItem = DimensionUtils.getVwNumber(17);
  const marginLR =
    DimensionUtils.getVwNumber(92) + DimensionUtils.getVwNumber(92);
  const remainingWidth = otpBoxContainerWidth - (spacingItem * 5 + marginLR);
  const otpBoxWidth = remainingWidth / 6;

  const renderSeparator = () => {
    return <Box sx={{ width: `${spacingItem}px` }}></Box>;
  };

  return (
    <Box
      ref={otpBoxRef}
      sx={{
        alignSelf: "center",
        width: {
          sm: "40vw",
          xs: "100vw",
        },
        height: {
          sm: "27vw",
          xs: "auto",
        },
        maxWidth: "100%",
        mt: { sm: PixelsToViewPortUnitConverter.getvw(25), xs: PixelsToViewPortUnitConverter.getvw(100) },
        mb: PixelsToViewPortUnitConverter.getvw(25),
        borderWidth: { sm: "1px", xs: "0px" },
        borderStyle: { sm: "solid", xs: "unset" },
        borderRadius: "0px",
        borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.2),
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
      }}
    >
      <Box
        className="verify-otp-container"
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
        }}
      >
        <Typography variant="h2" className="header-title">
          {`ENTER CODE`}
        </Typography>
        <Typography variant="h2" className="sub-header-title">
          {`Check your email for a 6 digit code`}
        </Typography>
        <Box className="otpBox" sx={
          {
            marginTop: {
              sm: "0px", xs: "70px !important"
            },
            marginBottom: {
              sm: "0px", xs: "20px !important"
            }
          }
        }>
          <OtpInput
            ref={otpRef}
            value={otp}
            onChange={(otp: any) => {
              setOtp(otp);
              setErrorMessage(undefined);
            }}
            separator={renderSeparator()}
            inputStyle={{
              lineHeight: 2,
              backgroundColor: "transparent",
              color: "#000",
              border: "none",
              width: `${otpBoxWidth}px`,
              height: props.isMobile ? PixelsToViewPortUnitConverter.getvw(170) : PixelsToViewPortUnitConverter.getvw(44),
              padding: 0,
              margin: 0,
              borderRadius: 0,
              borderBottom: "1px solid #000",
              outline: "none",
              fontFamily: "Barlow",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: props.isMobile ? PixelsToViewPortUnitConverter.getFontVw(20) : PixelsToViewPortUnitConverter.getFontVw(25),
            }}
            isInputNum={true}
            numInputs={6}
            shouldAutoFocus={true}
          />
        </Box>
        <ErrorLabel errorMessage={errorMessage} mt={17} />
        <Box
          className="button-container"
          sx={{
            mt: {
              sm: PixelsToViewPortUnitConverter.getvw(60),
              xs: PixelsToViewPortUnitConverter.getvw(120),
            }
          }}
        >
          <CustomButton
            variant="v1"
            title="RESEND"
            loading={isLoadingA}
            onClick={() => {
              handleResendClicked();
            }}
          />
          <CustomButton
            variant="v2"
            title="ENTER"
            disabled={!isFormValid()}
            loading={isLoadingB}
            onClick={() => {
              handleEnterClicked();
            }}
          />
        </Box>
      </Box>
    </Box >
  );
};
export default withMediaQuery(VerifyOTPComponent);
