import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box/Box";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import withRouter from "../../../../withRouter";
import BannerComponent from "./BannerComponent";
import CustomerReviewsComponent from "./CustomerReviewsComponent";
import StoreLocationMapComponent from "./StoreLocationMapComponent";
import EffectsComponent from "./EffectsComponent";
import PopularProducts from "../menu_products/PopularProducts";
import { CartProduct } from "../../../../core/models/Product";
import { connect } from "react-redux";
import { CartActionType } from "../../../common/cart/CartActionTypes";
import { Cart } from "../../../common/cart/CartReducer";
import { RootState } from "../../../../redux/Reducer";
import { NavigationProps } from "../../../../Navigation.types";
import LocationBtn from "../../../common/locationBtn/LocationBtn";

interface IEffectsV2HomeComponentProps {
  router: NavigationProps;
  cart: Cart | undefined;
  pStoreId: number;
  addToCartOrUpdate: (product: CartProduct) => void;
}

interface IEffectsV2HomeComponentState {
  cart: Cart | undefined;
  forceRefresh: boolean;
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cartProducts.cart,
  pStoreId: state.cartProducts.cart.storeId,
});

class EffectsV2HomeComponent extends React.Component<
  IEffectsV2HomeComponentProps,
  IEffectsV2HomeComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      cart: props.cart,
      forceRefresh: false,
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.cart !== prevState.cart) {
      return { cart: nextProps.cart };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.pStoreId != prevProps.pStoreId) {
      this.setState({ forceRefresh: true });
      setTimeout(() => {
        this.setState({ forceRefresh: false });
      }, 100);
    }
  }

  render() {
    return (
      !this.state.forceRefresh && (
        <Stack
          sx={{
            position: "relative",
            width: "100%",
            marginTop: PixelsToViewPortUnitConverter.getvw(116),
          }}
        >
          <LocationBtn />
          <BannerComponent />
          <Box
            sx={{
              height: "1px",
              opacity: "0.35",
              backgroundColor: "#000000",
              marginTop: PixelsToViewPortUnitConverter.getvw(54),
              marginBottom: {
            sm:PixelsToViewPortUnitConverter.getvw(32),
            xs:PixelsToViewPortUnitConverter.getvw(75),
              },
               marginRight: {
                sm: PixelsToViewPortUnitConverter.getvw(206),
                xs: PixelsToViewPortUnitConverter.getvw(100),
              },
              marginLeft: {
                sm: PixelsToViewPortUnitConverter.getvw(214),
                xs: PixelsToViewPortUnitConverter.getvw(100),
              },



            }}
          />
          <PopularProducts
            router={this.props.router}
            marginLeft={118}
            marginRight={118}
            pCart={this.state.cart}
            pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
          />
          <EffectsComponent />
          <Box
            sx={{
              marginTop: PixelsToViewPortUnitConverter.getvw(160),
            }}
          />
          <StoreLocationMapComponent />
          <Box
            sx={{
              marginTop: PixelsToViewPortUnitConverter.getvw(134),
            }}
          />
          <CustomerReviewsComponent />
        </Stack>
      )
    );
  }

  private onAddOrRemoveProductFromCart = (product: CartProduct) => {
    this.props.addToCartOrUpdate(product);
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  addToCartOrUpdate: (product: CartProduct) =>
    dispatch({
      type: CartActionType.ADD_TO_CART_OR_UPDATE,
      payload: product,
    }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EffectsV2HomeComponent)
);
