import { CartProduct } from "../../../core/models/Product";
import PartnerManager from "../../../core/utils/PartnerManager";
import { CartAction, CartActionType } from "./CartActionTypes";

export interface Cart {
  storeId: number;
  products: CartProduct[];
}

interface State {
  cart: Cart | undefined;
}

const initialState = {
  cart: undefined,
};

const cartProductReducer = (
  state: State = initialState,
  action: CartAction
): State => {
  switch (action.type) {
    case CartActionType.ADD_TO_CART_OR_UPDATE:
      if (state.cart) {
        let products = state.cart.products.filter(
          (product) => action.payload.productId != product.productId
        );

        if (action.payload.count !== 0) {
          products = products.concat(action.payload);
        } else {
          const toBeDeleteProductIndex = products.findIndex(
            (product) => action.payload.productId == product.productId
          );
          if (toBeDeleteProductIndex !== -1) {
            products.splice(toBeDeleteProductIndex, 1);
          }
        }

        return {
          cart: {
            storeId: PartnerManager.shared().currentSelectedStore.id,
            products: products,
          },
        };
      } else {
        return {
          cart: {
            storeId: PartnerManager.shared().currentSelectedStore.id,
            products: [action.payload],
          },
        };
      }
    case CartActionType.CART_CREATE_OR_UPDATE:
      return {
        cart: action.payload as Cart,
      };
    default:
      return state;
  }
};

export default cartProductReducer;
