import { Box, Stack, Typography, List } from "@mui/material";
import Reservation from "../../../core/models/Reservation";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import Loader from "../loader/Loader";
import ReservationListItem from "./ReservationListItem";

interface IReservationComponentProps {
  pTitle: string;
  pReservations: Reservation[] | undefined;
  pIsLoading: boolean;
}

export default function ReservationComponent(
  props: IReservationComponentProps
) {
  return (
    <Stack
      sx={{
        position: "relative",
        marginTop: PixelsToViewPortUnitConverter.getvw(90),
      }}
    >
      <Typography
        sx={{
          fontSize: PixelsToViewPortUnitConverter.getFontVw(36),
          fontFamily: "Bebas",
          fontWeight: "400",
          color: (theme) => theme.palette.primary.contrastText,
          letterSpacing: "0.1em",
          textAlign: "start",
          lineHeight: "unset",
        }}
      >
        {props.pTitle}
      </Typography>
      <Box
        sx={{
          position: "relative",
          minHeight: PixelsToViewPortUnitConverter.getvw(300),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.pIsLoading ? (
          <Loader pshow={true} />
        ) : props.pReservations && props.pReservations.length > 0 ? (
          <Box width={{ sm: "100%", xs: "auto" }}>
            <List sx={{ 
              padding:{sm:'15px',xs:'0px'}
             }}>
              {props.pReservations.map((reservation) => {
                return (
                  <ReservationListItem
                    key={reservation.id}
                    pReservation={reservation}
                    pShowInExpandMode={false}
                  />
                );
              })}
            </List>
          </Box>
        ) : (
          <Typography
            sx={{
              fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
              fontFamily: "Barlow",
              fontWeight: "400",
              color: (theme) => theme.palette.primary.contrastText,
              letterSpacing: "0.1em",
            }}
          >
            {props.pReservations ? "No orders found" : "Somthing went wrong"}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
