import { createTheme } from "@mui/material/styles";
import { light } from "@mui/material/styles/createPalette";
import { PartnerAppThemeType } from "../core/models/PartnerTheme";
import PartnerManager from "../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../core/utils/PixelsToViewPortUnitConverter";

export function getEffectsV2Theme() {
  return createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: PartnerManager.shared().partner.theme.backgroundColor,
        contrastText: PartnerManager.shared().partner.theme.pageHighlightColor,
      },
      secondary: {
        main: PartnerManager.shared().partner.theme.buttonColor,
        contrastText: PartnerManager.shared().partner.theme.buttonTextColor,
      },
      background: {
        default: PartnerManager.shared().partner.theme.backgroundColor,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          fullWidth: false,
          color: "secondary",
        },
        styleOverrides: {
          root: {
            fontFamily: "Bebas",
            fontWeight: "400",
            fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
            borderRadius: "0px",
            minHeight: "0px",
            padding: "0px",
            letterSpacing: "0.1em",
          },
          contained: {},
          text: {
            color: PartnerManager.shared().partner.theme.pageHighlightColor,
          },
          startIcon: {
            marginRight: PixelsToViewPortUnitConverter.getvw(16),
            marginLeft: "0px",
          },
          endIcon: {
            maring: "0px",
            marginRight: "0px",
            marginLeft: PixelsToViewPortUnitConverter.getvw(8),
          },
          iconSizeMedium: {
            "& > *:first-child": {
              fontSize: "inherit",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            height: "20px",
            boxSizing: "border-box",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "0px",
          },
        },
      },
    },
  });
}
