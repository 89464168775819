import Box from "@mui/material/Box/Box";
import Card from "@mui/material/Card/Card";
import CardActionArea from "@mui/material/CardActionArea/CardActionArea";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import React from "react";
import Carousel from "../../../../core/customcomponents/gridcarousel/components/Carousel";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Navigation from "../../../../Navigation";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import { withMediaQuery } from "../../../../withMediaQuery";
import withRouter from "../../../../withRouter";
import Loader from "../../../common/loader/Loader";

interface ICategoriesComponentState {
  sShowLoader: boolean;
}

class CategoriesComponent extends React.Component<
  any,
  ICategoriesComponentState
> {
  categoriesToBeFiltered = [
    "flower",
    "gear",
    "topical",
    "tincture",
    "pre-roll",
    "edible",
    "extract",
    "merch",
    "grow",
    "vape",
  ];
  constructor(props: any) {
    super(props);
    this.state = {
      sShowLoader:
        PartnerManager.shared().categories == undefined ||
        PartnerManager.shared().categories.length == 0,
    };
  }

  componentDidMount() {
    if (
      PartnerManager.shared().categories == undefined ||
      PartnerManager.shared().categories.length == 0
    ) {
      StoreOperationsService.getCategories(
        PartnerManager.shared().currentSelectedStore.id
      ).then((_) => {
        this.setState({
          sShowLoader: false,
        });
      });
    }
  }

  render() {
    const filterdCategories = PartnerManager.shared()
      .categories.filter((cat) =>
        this.categoriesToBeFiltered.includes(
          cat.name.toLocaleLowerCase()
        )
      );
    const columnSpacing =
      (window.innerWidth * 30) /
      PixelsToViewPortUnitConverter.getBaseWidth() /
      2;
    const rowSpacing =
      (window.innerWidth * 40) /
      PixelsToViewPortUnitConverter.getBaseWidth() /
      8;
    return (
      <Stack sx={{
        overflowX: {
          sm: "unset",
          xs: "hidden"
        }
      }}>
        <Typography
          sx={{
            fontFamily: "Bebas",
            fontWeight: 400,
            fontSize: PixelsToViewPortUnitConverter.getFontVw(28),
            paddingRight: {
              sm: PixelsToViewPortUnitConverter.getvw(200),
              xs: PixelsToViewPortUnitConverter.getvw(130)
            },
            paddingLeft: {
              sm: PixelsToViewPortUnitConverter.getvw(200),
              xs: PixelsToViewPortUnitConverter.getvw(130)
            },
            color: (theme) => theme.palette.primary.contrastText,
            letterSpacing: "0.1em",
            textAlign: "start",
          }}
        >
          CATEGORIES
        </Typography>
        {this.state.sShowLoader ? (
          <Box
            sx={{
              position: "relative",
              minHeight: "180px",
            }}
          >
            <Loader pshow={true} />
          </Box>
        ) : (
          <Box
            sx={{
              marginLeft:
                { sm: "0", xs: PixelsToViewPortUnitConverter.getvw(-110) },
              marginRight:
                { sm: "0", xs: PixelsToViewPortUnitConverter.getvw(-220) },
              textAlign: "center"
            }}>
            <Carousel
              containerStyle={{
                position: "relative",
                paddingLeft: PixelsToViewPortUnitConverter.getvw(200),
                paddingRight: PixelsToViewPortUnitConverter.getvw(200),
              }}
              cols={!this.props.isMobile ? 4 : 2}
              rows={!this.props.isMobile ? 2 : Math.round(filterdCategories.length / 2)}
              gap={columnSpacing}
            >
              {
                filterdCategories.map((category) => {
                  return (
                    <Carousel.Item key={category.name}>
                      <Stack sx={{ padding: "5px" }}>
                        <Card
                          elevation={0}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center",
                            borderWidth: PixelsToViewPortUnitConverter.getvw(1),
                            marginTop: PixelsToViewPortUnitConverter.getvw(20),
                            borderStyle: "solid",
                            backgroundColor: "#ffffff",
                            borderColor: "rgba(0, 0, 0, 0.25)",
                            boxShadow: "0px 5px 25px 0px rgba(0,0,0,0.07)",
                          }}
                          onClick={() => {
                            Navigation.toShopAllByCategory(
                              this.props.router,
                              category
                            );
                          }}
                        >
                          <CardActionArea
                            sx={{
                              width: "100%",
                              position: "relative",
                              paddingTop: "100%",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                borderWidth: PixelsToViewPortUnitConverter.getvw(
                                  1
                                ),
                                objectFit: "contain",
                                aspectRatio: "1/1",
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: "100%",
                              }}
                              src={category.getImageUrl()}
                            />
                          </CardActionArea>
                        </Card>
                        <Typography
                          sx={{
                            fontFamily: "Barlow",
                            fontWeight: 600,
                            fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                            paddingTop: PixelsToViewPortUnitConverter.getvw(10),
                            color: (theme) => theme.palette.primary.contrastText,
                            letterSpacing: "0.2em",
                            textTransform: "capitalize",
                          }}
                        >
                          {category.name}
                        </Typography>
                      </Stack>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          </Box>
        )}
      </Stack>
    );
  }
}

export default withRouter(withMediaQuery(CategoriesComponent));