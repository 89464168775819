import DateUtils from "../utils/DateUtils";
import TextUtils from '../utils/TextUtils';

export default class ProductReview {
    private mProductId: number;
    public get productId() {
      return this.mProductId;
    }
  
    private mRating: number;
    public get rating() {
      return this.mRating;
    }
  
    private mReview: string;
    public get review() {
      return this.mReview;
    }
  
    private mDate: string;
    public get reviewDate() {
      return this.mDate;
    }

    public displayReviewDate() {
        if(this.mDate && !TextUtils.isEmpty(this.mDate)) {
            return DateUtils.dateToDisplayDateFormat(new Date(this.mDate))
        }
        return undefined;
    }
  
    public constructor(productReviewJson: any) {
      this.mProductId = productReviewJson.product_id;
      this.mRating = productReviewJson.rating;
      this.mReview = productReviewJson.comment;
      this.mDate = productReviewJson.created_at
    }

    public static list(items: any): ProductReview[] {
        let finalArray: ProductReview[] = []
        const reviewsArray = items
        const length = reviewsArray.length
        for (var i = 0; i < length ; i++) { 
            const pReview = reviewsArray[i]
            let pObject = new ProductReview(pReview)
            finalArray.push(pObject)
        }
        return finalArray
    }


  }