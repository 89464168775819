import React, { useEffect, useState } from "react";
import "./SignUpCompleted.scss";
import { alpha, Box, Typography } from "@mui/material";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import SignUpDoneIcon from '../../../../statics/svgs/SignUpDoneIcon';

type SignUpCompletedProps = {
    onSignUpCompleted(): void;
};

const SignUpCompleted: React.FC<SignUpCompletedProps> = (props: SignUpCompletedProps) => {

    useEffect(() => {
        setTimeout(() => {
            props.onSignUpCompleted()
        }, 2000)
    }, [])

    return (
        <Box sx={{
            alignSelf: 'center',
            width: {
                sm: "40vw",
                xs: "100vw",
            },
            height: {
                sm: "27vw",
                xs: "auto",
            },
            maxWidth: "100%",
            mt: PixelsToViewPortUnitConverter.getvw(25),
            mb: PixelsToViewPortUnitConverter.getvw(25),
            borderWidth: { sm: "1px", xs: "0px" },
            borderStyle: { sm: "solid", xs: "unset" },
            borderRadius: '0px',
            borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.20),
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0)
        }}>
            <Box className="signup-completed-container"
                sx={{
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0)
                }}>
                <Typography variant="h1" className="header-title">
                    {`USER REGISTRATION COMPLETE`}
                </Typography>
                <Box className="done-icon-box" sx={{
                    mt:
                    {
                        sm: PixelsToViewPortUnitConverter.getvw(70),
                        xs: PixelsToViewPortUnitConverter.getvw(300)
                    }
                }}>
                    <SignUpDoneIcon
                        sx={{
                            fill: 'none',
                            stroke: "#55FFB8",
                            width: {
                                sm: PixelsToViewPortUnitConverter.getvw(182),
                                xs: PixelsToViewPortUnitConverter.getvw(700),
                            },
                            height: {
                                sm: PixelsToViewPortUnitConverter.getvw(132),
                                xs: PixelsToViewPortUnitConverter.getvw(500),
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export default SignUpCompleted