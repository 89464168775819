import TextUtils from "./TextUtils";
// import compareVersions from "compare-versions";
import Store from '../models/Store';
import LocalStorageUtils from './LocalStorageUtils';
import Strings from './Strings';

export default class GeneralUtils {
  private constructor() { }

  public static makeACall(phoneNum: string | undefined) {
    if (!TextUtils.isEmpty(phoneNum)) {
      window.location.href = "tel:" + phoneNum;
    }
  }

  public static isAndroidOS(): boolean {
    let userAgent = navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  public static isSafariBrowser(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public static isPreviewSite(location: Location): boolean {
    if(location.host && location.host.length > 0 && location.host.startsWith('preview-')) {
      return true
    }
    return false
  }

  public static getHostName(location: Location): string {
    let host = undefined;
    if(process.env.REACT_APP_ENVIRONMENT === "dev") {
      // host = "catshop.warpspd.ai"
      // host = "3bros-7677914.iheartjane.online"
      // host = "janeshop01.firsthandle.net" // Category theme
      host = "caseys-4645.firsthandle.net"
    } else {
      if(this.isPreviewSite(location)) {
        host = location.host.replace('preview-','');
      } else {
        let sHost = location.host
        if(sHost && sHost.startsWith('www.')) {
          sHost = sHost.replace('www.','')
        }
        host = sHost;
      }
    }
    return host
  }

  /*public static forceAppUpdateRequired(latestVersion: string, latestBuildVersion: string): boolean {
    var storeAppVersion = latestVersion;
    var currentAppV = process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : "";
    var storeAppBuildVersion = latestBuildVersion;
    var currentAppBuildV = process.env.REACT_APP_BUILD_VERSION
      ? process.env.REACT_APP_BUILD_VERSION
      : "";

    if (
      !TextUtils.isEmpty(storeAppVersion) &&
      !TextUtils.isEmpty(currentAppV)
    ) {
      let versionComparator = compareVersions(currentAppV, storeAppVersion);
      if (versionComparator == -1) {
        return true;
      }
    }

    if (
      !TextUtils.isEmpty(storeAppBuildVersion) &&
      !TextUtils.isEmpty(currentAppBuildV)
    ) {
      let buildComparator = compareVersions(
        currentAppBuildV,
        storeAppBuildVersion
      );
      if (buildComparator == -1) {
        return true;
      }
    }

    return false;
  }*/

  public static distance(lat1: number, lon1: number, lat2: number, lon2: number, unit: string) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  }

  public static getNearestStore(storeList: Store[]) {
    let storeFound = undefined
    let latNumber = undefined;
    let longNumber = undefined;
    if(LocalStorageUtils.getItem(Strings.LOCATION_INFO_KEY)) {
      const locDetails = JSON.parse(LocalStorageUtils.getItem(Strings.LOCATION_INFO_KEY))
      latNumber = locDetails.latitude
      longNumber = locDetails.longitude
    }
    if(latNumber && longNumber && storeList && storeList.length > 0) {
      storeFound = storeList[0]
      let minDistance = undefined
      storeList.forEach((store, i) => {
        const d = this.distance(latNumber,longNumber,store.latitude,store.longitude,"K")
        if(minDistance) {
          if(d < minDistance) {
            minDistance = d
            storeFound = store
          }
        } else {
          minDistance = d
          storeFound = store
        }
      });
    }
    return storeFound
  }

}