import React from "react";
import ErrorIcon from "../../../statics/svgs/Error";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { alpha } from "@mui/material/styles";
import TextUtils from "../../../core/utils/TextUtils";
import withRouter from "../../../withRouter";
import Navigation from "../../../Navigation";

interface IMessageComponentProps {
  pShowGoToHomeBtn: boolean;
  pTitle: string;
  pMessage: string;
  router: any;
}
class MessageComponent extends React.Component<IMessageComponentProps, any> {
  public constructor(props: IMessageComponentProps) {
    super(props);
  }

  public render() {
    return (
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: {
            xs: PixelsToViewPortUnitConverter.getvw(0),
            sm: PixelsToViewPortUnitConverter.getvw(40),
          },
          marginBottom: {
            xs: PixelsToViewPortUnitConverter.getvw(150),
            sm: PixelsToViewPortUnitConverter.getvw(40),
          },
        }}
      >
        <IconButton
          sx={{
            padding: "0px",
            minWidth: "0px",
            marginBottom: PixelsToViewPortUnitConverter.getvw(50),
          }}
        >
          <ErrorIcon
            viewBox="0 0 100 100"
            sx={{
              width: PixelsToViewPortUnitConverter.getvw(100),
              height: PixelsToViewPortUnitConverter.getvw(100),
              fill: (theme) => alpha(theme.palette.primary.contrastText, 0.42),
            }}
          />
        </IconButton>
        {!TextUtils.isEmpty(this.props.pTitle) && (
          <Typography
            sx={{
              fontSize: PixelsToViewPortUnitConverter.getFontVw(36),
              fontFamily: "Bebas",
              fontWeight: "400",
              color: (theme) => theme.palette.primary.contrastText,
              letterSpacing: "0.1em",
              marginBottom: PixelsToViewPortUnitConverter.getvw(4),
            }}
          >
            {this.props.pTitle}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
            fontFamily: "Barlow",
            fontWeight: "400",
            color: (theme) => theme.palette.primary.contrastText,
            marginX: PixelsToViewPortUnitConverter.getvw(200),
          }}
        >
          {this.props.pMessage}
        </Typography>
        {this.props.pShowGoToHomeBtn && (
          <Button
            variant="contained"
            disableElevation={true}
            onClick={() => {
              Navigation.toHome(this.props.router);
            }}
            sx={{
              borderRadius: "0px",
              width: {
                xs: PixelsToViewPortUnitConverter.getvw(600),
                sm: PixelsToViewPortUnitConverter.getvw(182),
              },
              height: {
                xs: PixelsToViewPortUnitConverter.getvw(170),
                sm: PixelsToViewPortUnitConverter.getvw(48),
              },
              fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
              minHeight: "0px",
              minWidth: "0px",
              padding: "0px",
              fontFamily: "Barlow",
              fontWeight: "400",
              marginTop: PixelsToViewPortUnitConverter.getvw(50),
              color: (theme) => theme.palette.secondary.contrastText,
              backgroundColor: (theme) => theme.palette.secondary.main,
            }}
          >
            Go to Home
          </Button>
        )}
      </Stack>
    );
    // return <Alert></Alert>;
  }
}
export default withRouter(MessageComponent);
