import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PixelsToViewPortUnitConverter from "../../../utils/PixelsToViewPortUnitConverter";
import PartnerManager from "../../../utils/PartnerManager";
import { Card, CardActionArea } from "@mui/material";

const ButtonWrapper = styled(Card)`
  position: absolute;
  top: calc(50% - ${PixelsToViewPortUnitConverter.getvw(20)});
  height: ${PixelsToViewPortUnitConverter.getvw(40)};
  width: ${PixelsToViewPortUnitConverter.getvw(40)};
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  @media screen and (max-width: ${({ mobileBreakpoint }) =>
      mobileBreakpoint}px) {
    display: none;
  }
`;

const Button = styled.span`
  position: relative;

  &::before {
    content: "";
    height: ${PixelsToViewPortUnitConverter.getvw(12)};
    width: ${PixelsToViewPortUnitConverter.getvw(12)};
    background: transparent;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ type }) =>
      type === "prev"
        ? "translate(-25%, -50%) rotate(-135deg)"
        : "translate(-75%, -50%) rotate(45deg)"};
  }
`;

const ArrowButton = ({
  type,
  mobileBreakpoint = 1,
  hidden = false,
  CustomBtn,
  onClick,
}) => (
  <ButtonWrapper
    elevation={0}
    hidden={hidden}
    onClick={onClick}
    sx={{
      backgroundColor: "unset",
      borderRadius: "50%",
      left: type === "prev" ? 0 : "unset",
      right: type === "next" ? 0 : "unset",
      marginLeft:
        type === "prev" ? PixelsToViewPortUnitConverter.getvw(114) : 0,
      marginRight:
        type === "next" ? PixelsToViewPortUnitConverter.getvw(114) : 0,
      borderColor: PartnerManager.shared().partner
        ? PartnerManager.shared().partner.theme.pageHighlightColor
        : "#fff",
    }}
  >
    <CardActionArea sx={{ height: "100%" }}>
      {CustomBtn ? (
        typeof CustomBtn === "function" ? (
          <CustomBtn />
        ) : (
          CustomBtn
        )
      ) : (
        <Button
          type={type}
          sx={{
            borderColor: PartnerManager.shared.partner
              ? PartnerManager.shared.partner.theme.pageHighlightColor
              : "#000",
          }}
        />
      )}
    </CardActionArea>
  </ButtonWrapper>
);

ArrowButton.propTypes = {
  type: PropTypes.oneOf(["prev", "next"]).isRequired,
  mobileBreakpoint: PropTypes.number,
  hidden: PropTypes.bool,
  CustomBtn: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  onClick: PropTypes.func.isRequired,
};

export default ArrowButton;
