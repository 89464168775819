import Reservation from "../core/models/Reservation";
import User from "../core/models/User";
import WebServiceUtils from "../core/webservice/WebServiceUtils";

export default class UserService {
  public static EXTERNAL_USER_END_POINT = `${process.env.REACT_APP_JANE_ROOTS_BASE_URL}/user_api`;

  public static async getUser() {
    const url = UserService.EXTERNAL_USER_END_POINT + `/user`;
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try {
        if (response.data && response.data.data) {
          return Promise.resolve(new User().parseResponse(response.data.data));
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  public static async updateUser(
    firstName: String,
    lastName: String,
    phone: String
  ) {
    const url = UserService.EXTERNAL_USER_END_POINT + `/user`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    const response = await WebServiceUtils.patch(
      {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
      },
      config,
      url
    );
    if (response.success) {
      try {
        if (response.data && response.data.data) {
          return Promise.resolve(new User().parseResponse(response.data.data));
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  public static async getReservationHistory() {
    const url = UserService.EXTERNAL_USER_END_POINT + `/carts?page_size=50`;
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try {
        if (
          response.data &&
          response.data.data &&
          response.data.data.reservations &&
          Array.isArray(response.data.data.reservations)
        ) {
          const reservations: Reservation[] = [];
          let reservationJson;
          for (reservationJson of response.data.data.reservations) {
            reservations.push(new Reservation(reservationJson));
          }

          return Promise.resolve(reservations);
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  public static async getReserversionDetails(uuid: string) {
    const url = UserService.EXTERNAL_USER_END_POINT + `/carts/` + uuid;
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try {
        if (
          response.data &&
          response.data.data &&
          response.data.data.reservation
        ) {
          return Promise.resolve(
            new Reservation(response.data.data.reservation)
          );
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }
}
