import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import PartnerManager from '../../core/utils/PartnerManager';

export default function AddToCartIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 80 89"
    const whiteColor = (PartnerManager.shared().partner) ? PartnerManager.shared().partner.theme.buttonTextColor : '#fff'
    return (
        <SvgIcon {...uProps}>
            <path d="M0 0H60C71.0457 0 80 8.95431 80 20V89H0V0Z"/>
            <line x1="36.5" y1="28.5" x2="50.5" y2="28.5" strokeWidth="3" strokeLinecap="round"/>
            <line x1="43.5" y1="35.5" x2="43.5" y2="21.5" strokeWidth="3" strokeLinecap="round"/>
            <path d="M59.913 40.8697L55.2609 55.5218C54.913 56.5653 54 57.261 52.913 57.261H34.9565C33.913 57.261 32.913 56.6088 32.5652 55.6523L25.6957 37.261H21.7391C20.7826 37.261 20 36.4784 20 35.5218C20 34.5653 20.7826 33.7827 21.7391 33.7827H26.913C27.6522 33.7827 28.3043 34.261 28.5652 34.9566L35.6522 53.7827H52.1739L55.8696 42.0436H35.3913C34.4348 42.0436 33.6522 41.261 33.6522 40.3045C33.6522 39.3479 34.4348 38.5653 35.3913 38.5653H58.2609C58.8261 38.5653 59.3478 38.8697 59.6522 39.3045C60 39.7392 60.087 40.3479 59.913 40.8697ZM35.8261 59.5653C35.087 59.5653 34.3478 59.8697 33.8261 60.3914C33.3043 60.9131 33 61.6523 33 62.3914C33 63.1305 33.3043 63.8697 33.8261 64.3914C34.3478 64.9131 35.087 65.2175 35.8261 65.2175C36.5652 65.2175 37.3043 64.9131 37.8261 64.3914C38.3478 63.8697 38.6522 63.1305 38.6522 62.3914C38.6522 61.6523 38.3478 60.9131 37.8261 60.3914C37.3043 59.8697 36.5652 59.5653 35.8261 59.5653ZM51.4348 59.5653C50.6957 59.5653 49.9565 59.8697 49.4348 60.3914C48.913 60.9131 48.6087 61.6523 48.6087 62.3914C48.6087 63.1305 48.913 63.8697 49.4348 64.3914C49.9565 64.9131 50.6957 65.2175 51.4348 65.2175C52.1739 65.2175 52.913 64.9131 53.4348 64.3914C53.9565 63.8697 54.2609 63.1305 54.2609 62.3914C54.2609 61.6523 53.9565 60.9131 53.4348 60.3914C52.913 59.8697 52.1739 59.5653 51.4348 59.5653Z" fill={whiteColor}/>
        </SvgIcon>
    );
}