import FilterAttribute from './FilterAttribute';
import Filter from './Filter';
import RangeAttribute from './RangeAttribute';
import { isNaN } from 'lodash';
import FilterUtils from '../utils/FilterUtils';
export default class AppliedFilter {

    private static allowedSlugs = [
                    "category",
                    "percent_thc",
                    "percent_cbd",
                    "applicable_special_ids",
                    "root_types",
                    "aggregate_rating",
                    "activities",
                    "feelings",
                    "bucket_price",
                    "available_weights",
                    "brand",
                    "query",
                    "sortBy"
                ];

    // index of applied_filter
    private mIndex: number;
    public get index() {
      return this.mIndex;
    }

    // feelings/percent_cbd/activities..etc
    private mSlug: string;
    public get slug() {
      return this.mSlug;
    }

    // values like [root_types]=flower/[aggregate_rating]=3:/[bucket_price]=20:39.99
    private mValue: string;
    public get value() {
      return this.mValue;
    }

    // percent_thc/percent_cbd min
    private mMinValue: number;
    public get minValue() {
      return this.mMinValue;
    }

    // percent_thc/percent_cbd max
    private mMaxValue: number;
    public get maxValue() {
      return this.mMaxValue;
    }

    // display value on UI
    private mDisplayValue: string;
    public get displayValue() {
      return this.mDisplayValue;
    }

    public setDisplayValue(str: string) {
        this.mDisplayValue = str
    }
  
    public constructor() {}

    public init(slugName: string, value: string, min: number, max: number) {
      this.mSlug = slugName
      this.mValue = value
      this.mMinValue = min
      this.mMaxValue = max
    }

    public setIndex(indx: number){
        this.mIndex = indx
    }

    public setMaxValue(val: number){
        this.mMaxValue = val
    }

    public setMinValue(val: number){
        this.mMinValue = val
    }

    public getDisplayName() {
        if((this.mSlug === 'percent_thc' || this.mSlug === 'percent_cbd')) {
            if(this.mSlug === 'percent_thc') {
                return `THC: ${this.mMinValue}-${this.mMaxValue}%`
            } else {
                return `CBD: ${this.mMinValue}-${this.mMaxValue}%`
            }
        } else {
            return this.displayValue
        }        
    }

    public getTempDisplayName() {
        if((this.mSlug === 'percent_thc' || this.mSlug === 'percent_cbd')) {
            if(this.mSlug === 'percent_thc') {
                return `THC: ${this.mMinValue}-${this.mMaxValue}%`
            } else {
                return `CBD: ${this.mMinValue}-${this.mMaxValue}%`
            }
        } else {
            return this.displayValue
        }
    }

    public getQueryStringParam(index: number): {key: string, value: any}[] {
        let keyName = ''
        let value: any = ''
        let finalArray = []
        if(this.mSlug === 'percent_thc' ||
            this.mSlug === 'percent_cbd') {
            if(this.mMinValue) {
                keyName = `facets.${this.mSlug}[min]`
                value = this.mMinValue
                finalArray.push({ key: keyName, value: value })
            }
            if(this.mMaxValue) {
                keyName = `facets.${this.mSlug}[max]`
                value = this.mMaxValue
                finalArray.push({ key: keyName, value: value })
            }
        } else if(this.mSlug === 'bucket_price' ||
            this.mSlug === 'aggregate_rating') {
            keyName = `facets.${this.mSlug}`
            value = this.mValue
            finalArray.push({ key: keyName, value: value })
        } else if(this.mSlug === 'query') {
            keyName = `query`
            value = this.mValue
            finalArray.push({ key: keyName, value: value })
        } else {
            keyName = `facets.${this.mSlug}[${index}]`
            value = this.mValue
            finalArray.push({ key: keyName, value: value })
        }
        return finalArray
    }

    public static list(filterQueryParams: any): AppliedFilter[] {
        if(!filterQueryParams) return []
        let finalArray: AppliedFilter[] = []
        // console.log('list :'+JSON.stringify(filterQueryParams))
        Object.entries(filterQueryParams).forEach(([key, value]) =>  {
            const separator = '.'
            let slug = undefined
            let itemIndexOrName = undefined
            let minVal = undefined
            let maxVal = undefined
            let val = value as any
            if(key.toLowerCase() === 'query') {
                // search query param
                let searchVal = ''
                if(Array.isArray(val)) {
                    searchVal = val[0] as string // pick first object
                } else {
                    searchVal = value as string
                }
                let searchAppliedFilter = new AppliedFilter()
                searchAppliedFilter.init('query',searchVal,undefined,undefined)
                finalArray.push(searchAppliedFilter)
            } else if(key.includes(separator)) {
                const arr = key.split(separator)
                if(arr && arr.length > 1 && arr[0] === 'facets') {
                    const slugDetails = arr[1]
                    const numRegPattern = /\[[0-9]+\]/g
                    const regPattern = /\[[a-z]+\]/g
                    if(numRegPattern.test(slugDetails)) {
                        // category/brand/applicable_special_ids/root_types/activities/feelings/available_weights
                        slug = slugDetails.replace(numRegPattern, '');
                        slug = slug.replaceAll('[','').replaceAll(']','')
                        var restultArr = slugDetails.match(numRegPattern);
                        itemIndexOrName = restultArr[0].toString().replaceAll('[','').replaceAll(']','')
                    } else if(regPattern.test(slugDetails)) {
                        // percent_cbd or percent_thc
                        slug = slugDetails.replace(regPattern, '');
                        slug = slug.replaceAll('[','').replaceAll(']','')
                        var restultArr = slugDetails.match(regPattern);
                        itemIndexOrName = restultArr[0].replaceAll('[','').replaceAll(']','')
                        if(itemIndexOrName !== 'min' && itemIndexOrName !== 'max') {
                            itemIndexOrName = undefined
                        }
                        if(itemIndexOrName === 'min') {
                            minVal = Number(val)
                            val = undefined
                        }

                        if(itemIndexOrName === 'max') {
                            maxVal = Number(val)
                            val = undefined
                        } 
                    } else {
                        // aggregate_rating / bucket_price
                        slug = arr[1]
                    }
                }
            }

            if(this.allowedSlugs.includes(slug)) {
                let appliedFilter = new AppliedFilter()
                appliedFilter.init(slug,val,minVal,maxVal)
                finalArray.push(appliedFilter)
            }
        })
        
        let index = 0;
        finalArray.forEach((item) => (item.setIndex(index++)));
        return finalArray
    }

    public static getAppliedFilter(filter: Filter, filterAttribute: FilterAttribute): AppliedFilter {
        let sAppliedFilter: AppliedFilter = undefined
        sAppliedFilter = new AppliedFilter()
        sAppliedFilter.init(filter.slug,filterAttribute.appliedFilterValue,undefined,undefined)
        return sAppliedFilter
    }

    public static getAppliedRangeFilterList(filter: Filter, rangeAttribute: RangeAttribute): AppliedFilter[] {
        let sMinAppliedFilter: AppliedFilter = undefined
        let sMaxAppliedFilter: AppliedFilter = undefined
        let finalArray: AppliedFilter[] = []
        if(rangeAttribute.isMinRangeApplied()) {
            sMinAppliedFilter = new AppliedFilter()
            const minVal = rangeAttribute.isMinRangeApplied() ? rangeAttribute.minSelected: undefined
            sMinAppliedFilter.init(filter.slug,undefined,minVal,undefined)
            finalArray.push(sMinAppliedFilter)
        }
        if(rangeAttribute.isMaxRangeApplied()) {
            sMaxAppliedFilter = new AppliedFilter()
            const maxVal = rangeAttribute.isMaxRangeApplied() ? rangeAttribute.maxSelected: undefined
            sMaxAppliedFilter.init(filter.slug,undefined,undefined,maxVal)
            finalArray.push(sMaxAppliedFilter)
        }
        return finalArray
    }

    public static isFilterApplied(slug: string, arr: AppliedFilter[]): boolean {
        if(this.findAppliedFilter(slug,arr).length > 0) {
            return true
        }
        return false
    }

    public static isChildFilterApplied(fAttribute: FilterAttribute, arr: AppliedFilter[]): boolean {
        let appliedFilter: AppliedFilter = this.findAppliedFilterWithValue(fAttribute.filterSlug, fAttribute.appliedFilterValue, arr)
        if(appliedFilter) {
            return true
        }
        return false
    }

    public static isFilterAttibuteSlugApplied(fAttribute: FilterAttribute, arr: AppliedFilter[]): boolean {
        let appliedFilter: AppliedFilter = this.findAppliedFilterWithValue(fAttribute.filterSlug, fAttribute.appliedFilterValue, arr)
        if(appliedFilter) {
            // console.log('filterSlug filterSlug=>'+fAttribute.filterSlug)
            // console.log('filterSlug slug=>'+fAttribute.slug)
            return true
        }
        return false
    }

    public static findAppliedFilterWithValue(slug: string, value: string, arr: AppliedFilter[]): AppliedFilter {
        let appliedFilter: AppliedFilter = arr.find((item: AppliedFilter)=> item.slug === slug && item.value === value);
        if(appliedFilter) {
            return appliedFilter
        }
        return undefined
    }

    public static findAppliedFilter(slug: string, arr: AppliedFilter[]): AppliedFilter[] {
        let appliedFiltersArr = arr.filter((item: AppliedFilter)=> item.slug === slug);
        if(appliedFiltersArr.length > 0) {
            return appliedFiltersArr
        }
        return []
    }

    public static findAppliedFilterListWithArray(appliedFilter: AppliedFilter, arr: AppliedFilter[]): AppliedFilter[] {
        let appdFilterList: AppliedFilter[] = []
        if(appliedFilter.slug === 'root_types' && appliedFilter.value === '') {
            appdFilterList = arr.filter((item: AppliedFilter)=> item.slug == appliedFilter.slug);
        } else if(appliedFilter.slug === 'root_types' && !appliedFilter.value.includes(':')) {
            // is parent category
            const term = `${appliedFilter.value}:`
            appdFilterList = arr.filter((item: AppliedFilter)=> item.slug == appliedFilter.slug && item.value.indexOf(term) > -1);
        } else {
            appdFilterList = arr.filter((item: AppliedFilter)=> item.slug == appliedFilter.slug);
        }
        return appdFilterList
    
    }

    public static findParentAppliedFilterWithArray(filterAttribute: FilterAttribute, arr: AppliedFilter[]): AppliedFilter {
        let appdFilter: AppliedFilter = undefined
        appdFilter = arr.find((item: AppliedFilter)=> item.slug == filterAttribute.filterSlug && item.value === filterAttribute.parentSlug);
        return appdFilter
    }

    public static findAppliedFilterWithArray(appliedFilter: AppliedFilter, arr: AppliedFilter[]): AppliedFilter {
        let appdFilter: AppliedFilter = undefined
        if(appliedFilter.slug === 'percent_thc' || appliedFilter.slug === 'percent_cbd') {
            if(appliedFilter.minValue) {
                appdFilter = arr.find((item: AppliedFilter)=> item.slug == appliedFilter.slug && item.minValue == appliedFilter.minValue);
            } else if(appliedFilter.maxValue) {
                appdFilter = arr.find((item: AppliedFilter)=> item.slug == appliedFilter.slug && item.maxValue == appliedFilter.maxValue);
            } else {
                 appdFilter = arr.find((item: AppliedFilter)=> item.slug == appliedFilter.slug);
            }
        } else {
            appdFilter = arr.find((item: AppliedFilter)=> item.slug == appliedFilter.slug && item.value == appliedFilter.value);
        }
        return appdFilter
    }

    public static getToBeDeletedAppliedFilters(appliedFilter: AppliedFilter, arr: AppliedFilter[]): AppliedFilter[] {
        let toBeDeletedList: AppliedFilter[] = []
        if(appliedFilter.slug === 'percent_thc' || appliedFilter.slug === 'percent_cbd') {
            toBeDeletedList = arr.filter((item: AppliedFilter)=> item.slug == appliedFilter.slug);
        } else {
            toBeDeletedList = arr.filter((item: AppliedFilter)=> item.slug == appliedFilter.slug && item.value == appliedFilter.value);
        }
        return toBeDeletedList
    }


    public static removePreviousAppliedRangeFilters(rangeAttribute: RangeAttribute, arr: AppliedFilter[]): AppliedFilter[] {
        const aFilterList: AppliedFilter[] = [...arr]
        const selectedFilters = this.findAppliedFilter(rangeAttribute.filterSlug,aFilterList)
        const length = selectedFilters.length
        for (var i = 0; i < length ; i++) {
            const mAppliedFilter = selectedFilters[i]
            const indexTobeDeleted = aFilterList.indexOf(mAppliedFilter)
            if(indexTobeDeleted !== -1) {
                aFilterList.splice(indexTobeDeleted,1)
            }
        }
        return aFilterList
    }

    public static findMinMaxFromRangeAppliedFilters(appliedFiltersList: AppliedFilter[], slug: string) {
        let minVal = 0
        let maxVal = 0
        const appldFilterListFound = appliedFiltersList.filter((item: AppliedFilter)=> item.slug === slug)
        if(appldFilterListFound && appldFilterListFound.length > 0) {                        
            const lengthOfItems = appldFilterListFound.length
            for (var i = 0; i < lengthOfItems ; i++) {
                let mAppliedFilter = appldFilterListFound[i]                
                if(mAppliedFilter.minValue) {
                    minVal = mAppliedFilter.minValue
                } else if(mAppliedFilter.maxValue) {
                    maxVal = mAppliedFilter.maxValue
                }
            }
        }
        return {min: minVal,max: maxVal}
    }

    public getFacetFilterQuery(): string[] {
        let finalArray: string[] = []
        let isNumericFacets = false
        if(Filter.numericSlugs.includes(this.slug)) {
            isNumericFacets = true
        }

        if(isNumericFacets) {
            if(this.value) {
                const minMax = FilterUtils.getMinMaxFromRange(this.value)
                if(minMax.min) {
                    finalArray.push(`${this.slug} >= ${minMax.min}`)
                }
                if(minMax.max) {
                    finalArray.push(`${this.slug} <= ${minMax.max}`)
                }
            } else if(this.minValue) {
                if(!isNaN(this.minValue)){
                    finalArray.push(`${this.slug} >= ${Number(this.minValue)}`)
                }
            } else if(this.maxValue) {
                if(!isNaN(this.maxValue)){
                    finalArray.push(`${this.slug} <= ${Number(this.maxValue)}`)
                }
            }
        } else {
            finalArray.push(`${this.slug}:${this.value}`)
        }

        /*
        [{"mIndex":0,"mSlug":"activities","mValue":"Get some sleep"},
        {"mIndex":1,"mSlug":"activities","mValue":"I don't know"},
        {"mIndex":2,"mSlug":"aggregate_rating","mValue":"3:"},
        {"mIndex":3,"mSlug":"available_weights","mValue":"eighth ounce"},
        {"mIndex":4,"mSlug":"brand","mValue":"Dr. Solomon's"},
        {"mIndex":5,"mSlug":"bucket_price","mValue":"20:39.99"},
        {"mIndex":6,"mSlug":"category","mValue":"hybrid"},
        {"mIndex":7,"mSlug":"feelings","mValue":"Not high"},
        {"mIndex":8,"mSlug":"feelings","mValue":"Pain free"},
        {"mIndex":9,"mSlug":"percent_cbd","mMaxValue":9},
        {"mIndex":10,"mSlug":"percent_cbd","mMinValue":3},
        {"mIndex":11,"mSlug":"percent_thc","mMaxValue":24},
        {"mIndex":12,"mSlug":"percent_thc","mMinValue":3},
        {"mIndex":13,"mSlug":"root_types","mValue":"sale"},
        {"mIndex":14,"mSlug":"root_types","mValue":"edible:Candies"},
        {"mIndex":15,"mSlug":"root_types","mValue":"extract"}]
        */

        // console.log('getFacetFilterQuery :'+JSON.stringify(finalArray))
        return finalArray
    }
}