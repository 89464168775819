import SvgIcon from "@mui/material/SvgIcon";
export default function RightArrow(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M0.999999 17L5 9L0.999999 1"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
