import SvgIcon from "@mui/material/SvgIcon";
export default function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M30.505 27.15L24.8383 21.5C26.6666 19.1708 27.6586 16.2944 27.655 13.3333C27.655 10.6963 26.873 8.11839 25.4079 5.92574C23.9428 3.73308 21.8604 2.02411 19.4241 1.01495C16.9877 0.00577708 14.3068 -0.258267 11.7204 0.256202C9.134 0.770672 6.75823 2.04055 4.89353 3.90525C3.02883 5.76995 1.75895 8.14572 1.24448 10.7321C0.730014 13.3185 0.994058 15.9994 2.00323 18.4358C3.0124 20.8721 4.72136 22.9545 6.91402 24.4196C9.10667 25.8847 11.6845 26.6667 14.3216 26.6667C17.2827 26.6704 20.159 25.6783 22.4883 23.85L28.1383 29.5167C28.2932 29.6729 28.4776 29.7969 28.6807 29.8815C28.8838 29.9661 29.1016 30.0097 29.3216 30.0097C29.5416 30.0097 29.7595 29.9661 29.9626 29.8815C30.1657 29.7969 30.35 29.6729 30.505 29.5167C30.6612 29.3617 30.7852 29.1774 30.8698 28.9743C30.9544 28.7712 30.998 28.5534 30.998 28.3333C30.998 28.1133 30.9544 27.8955 30.8698 27.6924C30.7852 27.4893 30.6612 27.3049 30.505 27.15ZM4.32162 13.3333C4.32162 11.3555 4.90811 9.42213 6.00692 7.77764C7.10574 6.13315 8.66753 4.85142 10.4948 4.09454C12.322 3.33767 14.3327 3.13963 16.2725 3.52549C18.2123 3.91134 19.9942 4.86375 21.3927 6.26227C22.7912 7.6608 23.7436 9.44263 24.1295 11.3824C24.5153 13.3222 24.3173 15.3329 23.5604 17.1602C22.8035 18.9874 21.5218 20.5492 19.8773 21.648C18.2328 22.7468 16.2994 23.3333 14.3216 23.3333C11.6695 23.3333 9.12592 22.2798 7.25055 20.4044C5.37519 18.529 4.32162 15.9855 4.32162 13.3333Z"
      />
    </SvgIcon>
  );
}
