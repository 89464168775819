import {
  alpha,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { SnackbarWithIcon } from "../../../core/customcomponents/SnackbarWithIcon";
import User from "../../../core/models/User";
import DimensionUtils from "../../../core/utils/DimensionUtils";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import TextUtils from "../../../core/utils/TextUtils";
import { ApiError } from "../../../core/webservice/ApiError";
import UserService from "../../../services/UserService";
import EditPin from "../../../statics/svgs/EditPin";
import PlainRightTick from "../../../statics/svgs/PlainRightTick";
import ErrorLabel from "../../themes/effectsv2/other/ErrorLabel";
import Loader from "../loader/Loader";
import ChangePasswordComponent from "./ChangePasswordComponent";
import Close from "../../../statics/svgs/Close";
import { SeoDisallowPageConfig } from "../../../seo/SeoDisallowPageConfig";

interface StyledTextFieldProps extends StandardTextFieldProps {
  isProfileEditEnabled: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: "auto",
  borderRadius: PixelsToViewPortUnitConverter.getvw(3),
  height: PixelsToViewPortUnitConverter.getvw(41),
  width: PixelsToViewPortUnitConverter.getvw(144),
}));

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isProfileEditEnabled",
})<StyledTextFieldProps>(({ isProfileEditEnabled, theme }) => ({
  width: PixelsToViewPortUnitConverter.getvw(364),
  marginTop: PixelsToViewPortUnitConverter.getvw(36),

  "& .MuiInput-underline:before": {
    borderColor: theme.palette.primary.contrastText,
    borderBottomWidth: isProfileEditEnabled
      ? PixelsToViewPortUnitConverter.getvw(1)
      : "0px",
  },
  "& .MuiInput-underline:after": {
    borderColor: theme.palette.primary.contrastText,
    borderBottomWidth: isProfileEditEnabled
      ? PixelsToViewPortUnitConverter.getvw(2)
      : "0px",
  },
  "& .MuiInput-underline:hover:before": {
    borderColor: theme.palette.primary.contrastText,
    borderBottomWidth: isProfileEditEnabled
      ? PixelsToViewPortUnitConverter.getvw(1)
      : "0px",
  },
  "& .MuiFormLabel-root": {
    fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
    fontFamily: "Barlow",
    fontWeight: "600",
    color: alpha(theme.palette.primary.contrastText, 0.4),
    letterSpacing: "0.1em",
    transform: "translate(0, -1.5px) scale(0.80)",
  },
  "& .MuiInputBase-input.MuiInput-input": {
    fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
    fontFamily: "Barlow",
    fontWeight: "500",
    color: theme.palette.primary.contrastText,
    letterSpacing: "0.1em",
  },
  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
  },
}));

export default function AccountDetailsComponent(props) {
  const [isProfileEditEnabled, setProfileEditEnabled] = useState(false);
  const [isPasswordChangeEnabled, setPasswordChangeEnabled] = useState(false);
  const [isLodingProfile, setLoadingProfile] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [editUser, setEditUser] = useState<User | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  useEffect(() => {
    if (!user && !errorMessage) {
      window.scrollTo(0, 0);
      setLoadingProfile(true);
      UserService.getUser()
        .then((user) => {
          setLoadingProfile(false);
          setUser(user);
        })
        .catch((error: ApiError) => {
          setLoadingProfile(false);
          setErrorMessage(error.message);
        });
    }
  }, [isLodingProfile]);

  function handleUpdateProfile(event) {
    event.preventDefault();
    if (isUpdating) return;

    let phone = !TextUtils.isEmpty(editUser.phone)
      ? "+1" + TextUtils.stripNonNumeric(editUser.phone)
      : "";

    if (TextUtils.isEmpty(phone) || phone.length < 12) {
      setErrorMessage("Invalid Phone Number");
      return;
    }

    if (TextUtils.isEmpty(editUser.firstName)) {
      setErrorMessage("First Name must be filled");
      return;
    }

    if (TextUtils.isEmpty(editUser.lastName)) {
      setErrorMessage("Last Name must be filled");
      return;
    }

    setIsUpdating(true);
    setErrorMessage(undefined);

    UserService.updateUser(editUser.firstName, editUser.lastName, phone)
      .then((updatedUser) => {
        setIsUpdating(false);
        setProfileEditEnabled(false);
        setUser(updatedUser);
        setToastMsg("Profile updated successfully");
        setShowToast(true);
      })
      .catch((error: ApiError) => {
        setIsUpdating(false);
        setErrorMessage(error.message);
      });
  }

  function handleChangePasswordCanceled() {
    setPasswordChangeEnabled(false);
  }

  function handleChangePasswordSucceed() {
    setPasswordChangeEnabled(false);
    setToastMsg("Password updated successfully");
    setShowToast(true);
  }

  return (
    <Stack
      sx={{
        position: "relative",
        marginTop: PixelsToViewPortUnitConverter.getvw(80),
        minHeight: PixelsToViewPortUnitConverter.getvw(100),
        marginRight: {
          sm:PixelsToViewPortUnitConverter.getvw(250),
          xs:PixelsToViewPortUnitConverter.getvw(0),
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          borderWidth: "0px",
          borderBottomWidth: PixelsToViewPortUnitConverter.getvw(1),
           borderStyle:{
            xs:"none",
            sm:'solid'
          },
          
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.2),
          paddingBottom: PixelsToViewPortUnitConverter.getvw(30),
       }}
      >
        <Typography
          sx={{
             fontSize:{
              sm: PixelsToViewPortUnitConverter.getFontVw(36),
              xs: PixelsToViewPortUnitConverter.getFontVw(36),
            },

            marginTop: {
            sm:PixelsToViewPortUnitConverter.getvw(0),
            xs:PixelsToViewPortUnitConverter.getvw(140),
            },  
            position:"relative",          
            fontFamily: "Bebas",
            fontWeight: "400",
            color: (theme) => theme.palette.primary.contrastText,
            letterSpacing: "0.1em",
          }}
        >
          ACCOUNT DETAILS
        </Typography>
        {isProfileEditEnabled ? (
          <Stack
            direction="row"
            sx={{
              marginLeft: "auto",             
              position:{
                sm:"relative",
                xs:"absolute"
              },
              marginTop:{
                sm:PixelsToViewPortUnitConverter.getvw(0),
                xs:PixelsToViewPortUnitConverter.getvw(325)
              },
             
            }}
          >
            <StyledButton
              variant="outlined"
              disabled={isUpdating}
              sx={{
        marginRight:{
        sm:PixelsToViewPortUnitConverter.getvw(9),
        xs:PixelsToViewPortUnitConverter.getvw(30)
        },
        width: {
        sm:PixelsToViewPortUnitConverter.getvw(144),
        xs:PixelsToViewPortUnitConverter.getvw(400),
        },
        height: {
        sm:PixelsToViewPortUnitConverter.getvw(41),
        xs:PixelsToViewPortUnitConverter.getvw(100),
        },
        fontSize:{
        sm:PixelsToViewPortUnitConverter.getFontVw(18),
        xs:PixelsToViewPortUnitConverter.getFontVw(16),          
        },
}}
              onClick={(event) => {
                setErrorMessage(undefined);
                setProfileEditEnabled(false);
              }}
            >
              cancel
            </StyledButton>
            <StyledButton sx={{
                marginRight: PixelsToViewPortUnitConverter.getvw(9),
                width: {
                  sm:PixelsToViewPortUnitConverter.getvw(144),
                  xs:PixelsToViewPortUnitConverter.getvw(400),
                  },
                  height: {
                  sm:PixelsToViewPortUnitConverter.getvw(41),
                  xs:PixelsToViewPortUnitConverter.getvw(100),
                  },
                  fontSize:{
                  sm:PixelsToViewPortUnitConverter.getFontVw(18),
                  xs:PixelsToViewPortUnitConverter.getFontVw(16),          
                  },               
              }}
            
              variant="contained"
              onClick={(event) => {
                handleUpdateProfile(event);
              }}
            >
              {!isUpdating && "SAVE CHANGES"}
              {isUpdating && (
                <CircularProgress
                  size={DimensionUtils.getVwNumber(20)}
                  sx={{
                    color: (theme) => theme.palette.secondary.contrastText,
                  }}
                />
              )}
            </StyledButton>
          </Stack>
        ) : (
          <IconButton
            sx={{
              padding: "0px",
              marginLeft: "auto",
              paddingX: PixelsToViewPortUnitConverter.getvw(10),
              marginTop: {
                sm: PixelsToViewPortUnitConverter.getvw(0),
                xs: PixelsToViewPortUnitConverter.getvw(110),
                },

            }}
            onClick={() => {
              setEditUser(Object.assign(new User(), user));
              !errorMessage && setProfileEditEnabled(true);
            }}
          >
            <EditPin
              sx={{
                width: {
                  sm: PixelsToViewPortUnitConverter.getvw(38),
                  xs: PixelsToViewPortUnitConverter.getvw(80),
                  },

                height: {
                  sm: PixelsToViewPortUnitConverter.getvw(32),
                  xs: PixelsToViewPortUnitConverter.getvw(80),
                },             
                fill: "none",
                color: (theme) => theme.palette.primary.contrastText,
              }}
              viewBox="0 0 38 32"
            />
          </IconButton>
        )}
      </Stack>
      {isLodingProfile ? (
        <Box
          sx={{
            position: "relative",
            marginTop: PixelsToViewPortUnitConverter.getvw(36),
            minHeight: PixelsToViewPortUnitConverter.getvw(400),
          }}
        >
          <Loader pshow={true} />
        </Box>
      ) : user ? (
        <Stack>
          <Stack sx={{ alignItems: "flex-start" }}>
            <Stack
           direction="row"
           sx={{
             display: "flex",
             flexDirection:{
              sm:'row',
              xs: 'column' 
             }, 
           
            marginTop:{
              sm:'0',
              xs:PixelsToViewPortUnitConverter.getvw(60),
            }
            
           }}           
           >
  <StyledTextField sx={{              
   width:{ xs:"85vw",sm:"24.0741vw"}, 
   marginBottom:{
    sm:'0',
    xs:PixelsToViewPortUnitConverter.getvw(60),
  }
}}          
                isProfileEditEnabled={isProfileEditEnabled}
                variant="standard"
                label="First Name"
                onChange={(event) => {
                  if (isUpdating) return;
                  const newUserInfo = Object.assign(new User(), editUser);
                  newUserInfo.firstName = event.currentTarget.value;
                  setEditUser(newUserInfo);
                }}
                value={ 
                  isProfileEditEnabled ? editUser.firstName : user.firstName
                }
                disabled={!isProfileEditEnabled}
              />
              <StyledTextField 
                isProfileEditEnabled={isProfileEditEnabled}
                variant="standard"
                label="Last Name"
                value={isProfileEditEnabled ? editUser.lastName : user.lastName}
                disabled={!isProfileEditEnabled}
                onChange={(event) => {
                  if (isUpdating) return;
                  const newUserInfo = Object.assign(new User(), editUser);
                  newUserInfo.lastName = event.currentTarget.value;
                  setEditUser(newUserInfo);
                }}
                sx={{                  
                  marginLeft:{
                    sm:PixelsToViewPortUnitConverter.getvw(108),
                    xs: '0' 
                   },
                   width:{ xs:"85vw",sm:"24.0741vw"},
                   marginBottom:{
                    sm:'0',
                    xs:PixelsToViewPortUnitConverter.getvw(60),
                  } 
                }}
                
              />
            </Stack>
            <Stack 
             direction="row"
             sx={{
               display: "flex",
               flexDirection:{
                sm:'row',
                xs: 'column' 
               }                         
             }}  
            >
  <StyledTextField sx={{                  
   width:{ xs:"85vw",sm:"24.0741vw"},
   marginBottom:{
    sm:'0',
    xs:PixelsToViewPortUnitConverter.getvw(60),
  } 
}}             
                isProfileEditEnabled={isProfileEditEnabled}
                label="Phone"
                variant="standard"
                onChange={(event) => {
                  if (!isUpdating) {
                    const newUserInfo = Object.assign(new User(), editUser);
                    newUserInfo.phone = TextUtils.normalizeInputToUSPhoneNum(
                      event.currentTarget.value,
                      editUser.phone
                    );
                    setEditUser(newUserInfo);
                  }
                }}
                value={isProfileEditEnabled ? editUser.phone : user.phone}
                disabled={!isProfileEditEnabled}
              />
              <StyledTextField


                isProfileEditEnabled={false}
                label="Email"
                variant="standard"
                value={isProfileEditEnabled ? editUser.email : user.email}
                disabled={true}
                sx={{                  
                  marginLeft:{
                    sm:PixelsToViewPortUnitConverter.getvw(108),
                    xs: '0' 
                   },
                   width:{ xs:"85vw",sm:"24.0741vw"
                  },
                  marginBottom:{
                    sm:'0',
                    xs:PixelsToViewPortUnitConverter.getvw(60),
                  } 
                }}
              />
            </Stack>
            <ErrorLabel errorMessage={errorMessage} mt={17} />
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
                fontFamily: "Barlow",
                fontWeight: "600",
                color: (theme) =>
                  alpha(theme.palette.primary.contrastText, 0.4),
                letterSpacing: "0.1em",
                textAlign: "left",
                // marginBottom: PixelsToViewPortUnitConverter.getvw(14),
                // marginTop: PixelsToViewPortUnitConverter.getvw(50),
                marginBottom:{
                  sm:PixelsToViewPortUnitConverter.getvw(14),         
                  xs:PixelsToViewPortUnitConverter.getvw(30)          
                },
                marginTop:{
                  sm:PixelsToViewPortUnitConverter.getvw(50),         
                  xs:PixelsToViewPortUnitConverter.getvw(50)          
                },
                
              }}
            >
              Password
            </Typography>
            {isPasswordChangeEnabled ? (
              <ChangePasswordComponent
                onChangePasswordCanceled={handleChangePasswordCanceled}
                onChangePasswordSucceed={handleChangePasswordSucceed}
              />
            ) : (
              <Button
                variant="contained"
                sx={{
                  borderRadius: PixelsToViewPortUnitConverter.getvw(3),
                  // height: PixelsToViewPortUnitConverter.getvw(41),
                  // width: PixelsToViewPortUnitConverter.getvw(179),
                 
                  width:{
                    sm:PixelsToViewPortUnitConverter.getvw(179),         
                    xs:PixelsToViewPortUnitConverter.getvw(700)          
                  },
                  height:{
                    sm:PixelsToViewPortUnitConverter.getvw(41),         
                    xs:PixelsToViewPortUnitConverter.getvw(160)          
                  },
                }}
                onClick={() => {
                  setPasswordChangeEnabled(true);
                }}
              >
                change password
              </Button>
            )}
          </Stack>
        </Stack>
      ) : (
        <Typography
          sx={{
            marginTop: PixelsToViewPortUnitConverter.getvw(36),
            minHeight: PixelsToViewPortUnitConverter.getvw(100),
            fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
            fontFamily: "Barlow",
            fontWeight: "400",
            color: (theme) => theme.palette.primary.contrastText,
            letterSpacing: "0.1em",
          }}
        >
          Somthing went wrong
        </Typography>
      )}
      <SnackbarWithIcon
        icon={
          <PlainRightTick
            viewBox="0 0 31 24"
            sx={{
              width: PixelsToViewPortUnitConverter.getvw(27),
              height: PixelsToViewPortUnitConverter.getvw(19),
              color: "#000000",
            }}
          />
        }
        action={
          <IconButton
            sx={{
              padding: "0px",
            }}
            onClick={() => {
              setShowToast(false);
            }}
          >
            <Close
              sx={{
                width: PixelsToViewPortUnitConverter.getvw(12),
                height: PixelsToViewPortUnitConverter.getvw(12),
                color: "#000000",
                marginRight: "8px",
                paddingBottom: "4px",
              }}
              viewBox="0 0 12 12"
            />
          </IconButton>
        }
        onClose={() => {
          setShowToast(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showToast}
        autoHideDuration={5000}
        message={toastMsg}
      />
      <SeoDisallowPageConfig pTitle="Account" />
    </Stack>
  );
}
