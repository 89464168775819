import AppRoutes from "../../routes";

export enum ReservationStatus {
  PENDING = "pending",
  VERIFICATION = "verification",
  STAFF_MEMBER_REVIEW = "staff_member_review",
  PREPARING = "preparing",
  DELIVERY_STARTED = "delivery_started",
  DELIVERY_FINISHED = "delivery_finished",
  READY_FOR_PICKUP = "ready_for_pickup",
  FINISHED = "finished",
  WITH_REVIEW = "with_review",
  CANCELLED = "cancelled",
  DISMISSED = "dismissed",
  FINISHED_WITHOUT_REVIEW = "finished_without_review",
  UNKNOWN = "unknown",
}

export default class Reservation {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mStatus: ReservationStatus;
  public get status() {
    return this.mStatus;
  }

  private mAmount: number;
  public get amount() {
    return this.mAmount;
  }

  private mSalesTax: number;
  public get salesTax() {
    return this.mSalesTax;
  }

  private mServiceFee: number;
  public get serviceFee() {
    return this.mServiceFee;
  }

  private mDiscount: number;
  public get discount() {
    return this.mDiscount;
  }

  private mDeliveryFee: number;
  public get deliveryFee() {
    return this.mDeliveryFee;
  }

  private mStoreName: string;
  public get storeName() {
    return this.mStoreName;
  }

  private mStoreId: number;
  public get storeId() {
    return this.mStoreId;
  }

  private mReservationMode: string;
  public get reservationMode() {
    return this.mReservationMode;
  }

  private mCheckedOutTime: Date;
  public get checkedOutTime() {
    return this.mCheckedOutTime;
  }

  private mReservationProducts: ReservationProduct[];
  public get reservationProducts() {
    return this.mReservationProducts;
  }

  public isOrderCompleted(): boolean {
    return (
      [
        ReservationStatus.DELIVERY_FINISHED,
        ReservationStatus.FINISHED,
        ReservationStatus.CANCELLED,
        ReservationStatus.DISMISSED,
        ReservationStatus.FINISHED_WITHOUT_REVIEW,
      ].find((status) => status == this.mStatus) != undefined
    );
  }

  public isServiceFeeFree(): boolean {
    return this.mServiceFee <= 0;
  }

  public subtotal() {
    return (
      this.mAmount -
      this.mSalesTax -
      this.mServiceFee -
      this.mDeliveryFee +
      this.mDiscount
    );
  }

  public constructor(reservationJson: any) {
    this.mId = reservationJson.id;
    this.mStatus = Object.values(ReservationStatus).includes(
      reservationJson.status
    )
      ? reservationJson.status
      : ReservationStatus.UNKNOWN;
    this.mAmount = parseFloat(reservationJson.amount);
    this.mSalesTax = parseFloat(reservationJson.sales_tax_amount);
    this.mServiceFee = parseFloat(reservationJson.service_fee_amount);
    this.mStoreId = reservationJson.store_id;
    this.mStoreName = reservationJson.store_name;
    this.mReservationMode = reservationJson.reservation_mode;
    this.mCheckedOutTime = new Date(
      reservationJson.checked_out_time.replace(/-/g, AppRoutes.ROOT) + " UTC"
    );
    this.mDeliveryFee = parseFloat(reservationJson.delivery_fee_amount);

    try {
      if (reservationJson.products && Array.isArray(reservationJson.products)) {
        const reservationProducts: ReservationProduct[] = [];
        let reservationProductJson;
        for (reservationProductJson of reservationJson.products) {
          reservationProducts.push(
            new ReservationProduct(reservationProductJson)
          );
        }

        this.mReservationProducts = reservationProducts;

        this.mDiscount = reservationProducts.reduce(
          (sum, current) =>
            sum +
            (current.regularPrice - current.discountedPrice) * current.quntity,
          0
        );
      }
    } catch (error) {}
  }
}

export class ReservationProduct {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mName: string;
  public get name() {
    return this.mName;
  }

  private mPriceId: string;
  public get priceId() {
    return this.mPriceId;
  }

  private mRegularPrice: number;
  public get regularPrice() {
    return this.mRegularPrice;
  }

  private mDiscountedPrice: number;
  public get discountedPrice() {
    return this.mDiscountedPrice;
  }

  private mQuntity: number;
  public get quntity() {
    return this.mQuntity;
  }

  private mBrand: string;
  public get brand() {
    return this.mBrand;
  }

  private mCategory: string;
  public get category() {
    return this.mCategory;
  }

  public constructor(reservationProductJson: any) {
    this.mId = reservationProductJson.id;
    this.mName = reservationProductJson.name;
    this.mPriceId = reservationProductJson.price_id;
    this.mRegularPrice = parseFloat(reservationProductJson.checkout_price);
    this.mDiscountedPrice = parseFloat(
      reservationProductJson.discounted_checkout_price
    );
    this.mBrand = reservationProductJson.brand;
    this.mCategory = reservationProductJson.kind;
    this.mQuntity = reservationProductJson.count;
  }
}
