import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function FilterIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 40 40"
    return (
        <SvgIcon {...uProps}>
            <path d="M22.6667 14.6667H5.33333C4.97971 14.6667 4.64057 14.5262 4.39052 14.2761C4.14048 14.0261 4 13.687 4 13.3333C4 12.9797 4.14048 12.6406 4.39052 12.3905C4.64057 12.1405 4.97971 12 5.33333 12H22.6667C23.0203 12 23.3594 12.1405 23.6095 12.3905C23.8595 12.6406 24 12.9797 24 13.3333C24 13.687 23.8595 14.0261 23.6095 14.2761C23.3594 14.5262 23.0203 14.6667 22.6667 14.6667ZM34.6667 14.6667H29.3333C28.9797 14.6667 28.6406 14.5262 28.3905 14.2761C28.1405 14.0261 28 13.687 28 13.3333C28 12.9797 28.1405 12.6406 28.3905 12.3905C28.6406 12.1405 28.9797 12 29.3333 12H34.6667C35.0203 12 35.3594 12.1405 35.6095 12.3905C35.8595 12.6406 36 12.9797 36 13.3333C36 13.687 35.8595 14.0261 35.6095 14.2761C35.3594 14.5262 35.0203 14.6667 34.6667 14.6667Z"/>
            <path d="M26 18.0003C25.077 18.0003 24.1748 17.7266 23.4073 17.2139C22.6399 16.7011 22.0418 15.9722 21.6886 15.1195C21.3354 14.2668 21.2429 13.3285 21.423 12.4232C21.6031 11.518 22.0475 10.6865 22.7002 10.0338C23.3528 9.38118 24.1843 8.93673 25.0896 8.75666C25.9948 8.5766 26.9331 8.66901 27.7859 9.02222C28.6386 9.37543 29.3674 9.97357 29.8802 10.741C30.393 11.5084 30.6667 12.4107 30.6667 13.3337C30.6667 14.5713 30.175 15.7583 29.2998 16.6335C28.4247 17.5087 27.2377 18.0003 26 18.0003ZM26 11.3337C25.6044 11.3337 25.2178 11.451 24.8889 11.6707C24.56 11.8905 24.3036 12.2028 24.1522 12.5683C24.0009 12.9337 23.9613 13.3359 24.0384 13.7238C24.1156 14.1118 24.3061 14.4682 24.5858 14.7479C24.8655 15.0276 25.2219 15.2181 25.6098 15.2952C25.9978 15.3724 26.3999 15.3328 26.7654 15.1814C27.1308 15.03 27.4432 14.7737 27.6629 14.4448C27.8827 14.1159 28 13.7292 28 13.3337C28 12.8032 27.7893 12.2945 27.4142 11.9194C27.0391 11.5444 26.5304 11.3337 26 11.3337ZM34.6667 28.0003H17.3333C16.9797 28.0003 16.6406 27.8598 16.3905 27.6098C16.1405 27.3598 16 27.0206 16 26.667C16 26.3134 16.1405 25.9742 16.3905 25.7242C16.6406 25.4741 16.9797 25.3337 17.3333 25.3337H34.6667C35.0203 25.3337 35.3594 25.4741 35.6095 25.7242C35.8595 25.9742 36 26.3134 36 26.667C36 27.0206 35.8595 27.3598 35.6095 27.6098C35.3594 27.8598 35.0203 28.0003 34.6667 28.0003ZM10.6667 28.0003H5.33333C4.97971 28.0003 4.64057 27.8598 4.39052 27.6098C4.14048 27.3598 4 27.0206 4 26.667C4 26.3134 4.14048 25.9742 4.39052 25.7242C4.64057 25.4741 4.97971 25.3337 5.33333 25.3337H10.6667C11.0203 25.3337 11.3594 25.4741 11.6095 25.7242C11.8595 25.9742 12 26.3134 12 26.667C12 27.0206 11.8595 27.3598 11.6095 27.6098C11.3594 27.8598 11.0203 28.0003 10.6667 28.0003Z"/>
            <path d="M13.9997 31.3333C13.0767 31.3333 12.1744 31.0596 11.407 30.5469C10.6396 30.0341 10.0414 29.3052 9.68824 28.4525C9.33503 27.5998 9.24261 26.6615 9.42268 25.7562C9.60274 24.851 10.0472 24.0195 10.6998 23.3668C11.3525 22.7142 12.184 22.2697 13.0893 22.0897C13.9945 21.9096 14.9328 22.002 15.7855 22.3552C16.6383 22.7084 17.3671 23.3066 17.8799 24.074C18.3926 24.8414 18.6663 25.7437 18.6663 26.6667C18.6663 27.9043 18.1747 29.0913 17.2995 29.9665C16.4243 30.8417 15.2374 31.3333 13.9997 31.3333ZM13.9997 24.6667C13.6041 24.6667 13.2174 24.784 12.8885 25.0037C12.5596 25.2235 12.3033 25.5359 12.1519 25.9013C12.0005 26.2668 11.9609 26.6689 12.0381 27.0568C12.1153 27.4448 12.3058 27.8012 12.5855 28.0809C12.8652 28.3606 13.2215 28.5511 13.6095 28.6282C13.9975 28.7054 14.3996 28.6658 14.765 28.5144C15.1305 28.3631 15.4429 28.1067 15.6626 27.7778C15.8824 27.4489 15.9997 27.0622 15.9997 26.6667C15.9997 26.1362 15.789 25.6275 15.4139 25.2525C15.0388 24.8774 14.5301 24.6667 13.9997 24.6667Z"/>
        </SvgIcon>
    );
}