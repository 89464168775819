import DateUtils from "../utils/DateUtils";

export default class DailyWorkingHours {
  private mDay: string;
  public get day() {
    return this.mDay;
  }

  private mFrom: string | null;
  public get from() {
    return this.mFrom;
  }

  private mTo: string | null;
  public get to() {
    return this.mTo;
  }

  public isClosed(): boolean {
    return this.mFrom == null || this.mTo == null;
  }

  public constructor(responseJson: any) {
    this.mDay = responseJson.day;

    if (responseJson.period) {
      this.mFrom = responseJson.period.from;
      this.mTo = responseJson.period.to;
    }
  }

  public period(): string {
    return this.isClosed()
      ? " Closed"
      : DateUtils.convert24To12Hrs(this.mFrom) +
          " - " +
          DateUtils.convert24To12Hrs(this.mTo);
  }
}
