import TextUtils from '../utils/TextUtils';
import AlgoliaManager from '../webservice/AlgoliaManager';
import SortingItem from './SortingItem';
export default class SortingList {

    public static aToZ: string = AlgoliaManager.shared().menuProductsIndexName()
    public static priceAsc = AlgoliaManager.shared().menuProductsByPriceLowToHighIndexName()
    public static priceDesc = AlgoliaManager.shared().menuProductsByPriceHighToLowIndexName()
    public static thcAsc = AlgoliaManager.shared().menuProductsByTHCLowToHighIndexName()
    public static thcDesc = AlgoliaManager.shared().menuProductsByTHCHighToLowIndexName()
    public static rating = AlgoliaManager.shared().menuProductsByRatingIndexName()

    private static sortingItemsObjectList: {name: string, value: string}[] = [
        {name: 'A-Z', value: SortingList.aToZ},
        {name:'Price Low to High', value: SortingList.priceAsc},
        {name:'Price High to Low', value: SortingList.priceDesc},
        {name:'THC% Low to High', value: SortingList.thcAsc},
        {name:'THC% High to Low', value: SortingList.thcDesc},
        {name:'Rating', value: SortingList.rating}
    ]
    public static sortByKey = 'sortBy'

    private mSortingList: SortingItem[];
    public get sortingList() {
        return this.mSortingList;
    }

    public constructor() {
        const sortingList = SortingItem.list(SortingList.sortingItemsObjectList)
        this.mSortingList = sortingList
    }

    public init(filterQueryParams: any) {
        let itemToBeSelected = this.mSortingList[0]
        if(filterQueryParams && Object.keys(filterQueryParams).length > 0) {
            Object.entries(filterQueryParams).forEach(
                ([key, value]) =>  {
                const objFound = this.getSortingItemByKeyValue(key,value)
                if(objFound) {
                    itemToBeSelected = objFound
                }
            })
        }
        itemToBeSelected.setSelected(true)
    }

    public selectSortingItem(newSortingItem: SortingItem) {
        let currentSortingItem = this.getSelectedSortingItem()
        currentSortingItem.setSelected(false);
        newSortingItem.setSelected(true);
    }

    public getSelectedSortingItem(): SortingItem {
        let sortingItemObj = this.mSortingList.find((item: SortingItem)=> item.selected === true);
        if(sortingItemObj) {
            return sortingItemObj
        }
        return undefined
    }

    public getSortingItemByValue(value: any): SortingItem {
        let sortingItemObj = this.mSortingList.find((item: SortingItem)=> item.value === value);
        if(sortingItemObj) {
            return sortingItemObj
        }
        return undefined
    }

    public getSortingItemByKeyValue(key:any, value: any): SortingItem {
        let sortingItem: SortingItem = undefined
        if(!TextUtils.isEmpty(key) && 
            key.toLowerCase() === SortingList.sortByKey.toLowerCase() &&
            !TextUtils.isEmpty(value)) {
            let sortingItemObj = this.mSortingList.find((item: SortingItem)=> item.value === value);
            if(sortingItemObj) {
                sortingItem = sortingItemObj
            }
        }
        return sortingItem
    }

    public static list(filterQueryParams: any): SortingList {
        let sList = new SortingList()
        sList.init(filterQueryParams)
        return sList
    }    
}