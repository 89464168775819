import React from "react";
import "./AppliedFiltersComponent.scss";
import { AppliedFilterButton } from "./AppliedFilterButton";
import AppliedFilter from '../../../../core/models/AppliedFilter';
import { AppliedFilterButtonV2 } from './AppliedFilterButtonV2';

type AppliedFiltersComponentProps = {
    appliedFiltersList: AppliedFilter[],
    onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
};

export const AppliedFiltersComponent: React.FC<AppliedFiltersComponentProps> = (props: AppliedFiltersComponentProps) => {

    const renderAppliedFiltersList = (appliedFiltersList: AppliedFilter[]) => {
        return appliedFiltersList.map((appliedFilter: AppliedFilter, index: number) => renderAppliedFilterItem(appliedFilter,index));
    }

    const renderAppliedFilterItem = (appliedFilter: AppliedFilter, index: number) => {
        if(appliedFilter.slug !== 'query') return null
        return(
            <AppliedFilterButtonV2
                key={index.toString()} 
                appliedFilter={appliedFilter}
                onRemoveAppliedFilter={props.onRemoveAppliedFilter}
            />
            // <AppliedFilterButton
            //     key={index.toString()} 
            //     appliedFilter={appliedFilter}
            //     onRemoveAppliedFilter={props.onRemoveAppliedFilter}
            // />
        )
    }

    return (
        <div className="cmenu-applied-filters-container">
            {renderAppliedFiltersList(props.appliedFiltersList)}
        </div>
    )
}