import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Reservation, {
  ReservationStatus,
} from "../../../core/models/Reservation";
import { ApiError } from "../../../core/webservice/ApiError";
import UserService from "../../../services/UserService";
import ReservationComponent from "./ReservationComponent";
import { NavigationProps } from "../../../Navigation.types";

interface IProps {
  router: NavigationProps;
}

export default function ReservationsComponent(props: IProps) {
  const [currentReservations, setCurrentReservations] = useState(undefined);
  const [pastReservations, setPastReservations] = useState(undefined);
  const [loadingReservations, setLoadingReservations] = useState(true);

  useEffect(() => {
    if (!currentReservations && !pastReservations) {
      setLoadingReservations(true);
      UserService.getReservationHistory()
        .then((reservations) => {
          setLoadingReservations(false);

          const curReservations = [];
          const pstReservations = [];
          let reservation: Reservation;
          for (reservation of reservations) {
            if (reservation.status != ReservationStatus.PENDING) {
              if (reservation.isOrderCompleted())
                pstReservations.push(reservation);
              else curReservations.push(reservation);
            }
          }
          setCurrentReservations(curReservations);
          setPastReservations(pstReservations);
        })
        .catch((apiError: ApiError) => {
          setLoadingReservations(false);
        });
    }
  }, [currentReservations, pastReservations]);

  return (
    <Stack>
      <ReservationComponent
        pTitle="current orders"
        pReservations={currentReservations}
        pIsLoading={loadingReservations}
      />
      <ReservationComponent
        pTitle="past orders"
        pReservations={pastReservations}
        pIsLoading={loadingReservations}
      />
    </Stack>
  );
}
