import PartnerManager from "../core/utils/PartnerManager";
import { Helmet } from "react-helmet";
export function DefaultSeoConfig(props: any) {
  const description=PartnerManager.shared().partner?`Welcome to ${PartnerManager.shared().partner.appName}, We offer legal and best quality marjuana products. Available in ${PartnerManager.shared().stores.length} locations: ${PartnerManager.shared().stores.map(_=>_.name).join(",")}`:"";
  const defaultLdJson = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    url: `https://${window.location.host}/`,
    name: PartnerManager.shared().partner
      ? PartnerManager.shared().partner.appName
      : "",
    potentialAction: {
      "@type": "SearchAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: `https://${window.location.host}/products?query={search_term}`,
      },
      "query-input": "required name=search_term",
    },
  };

  return (
    <Helmet>
      <title>
        {PartnerManager.shared().partner
          ? PartnerManager.shared().partner.appName
          : ""}
      </title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      ‍<link rel="canonical" href={window.location.href.split("?")[0]} />
      <script type="application/ld+json">
        {JSON.stringify(defaultLdJson)}
      </script>
    </Helmet>
  );
}
