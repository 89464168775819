
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ExpandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <line x1="2" y1="11.333" x2="22" y2="11.333" strokeWidth="4" strokeLinecap="round"/>
            <line x1="11.333" y1="22" x2="11.333" y2="2" strokeWidth="4" strokeLinecap="round"/>
        </SvgIcon>
    );
}