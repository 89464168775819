import PixelsToViewPortUnitConverter from "./PixelsToViewPortUnitConverter";
export default class DimensionUtils {
  private constructor() {}

  public static isMobile() {
      let sIsMobile = false
      const mobileBrkpoint = 600 // mobile breakpoint
      let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
      let currentScreenWidth = (safariAgent) ? document.body.clientWidth : window.screen.width
      if(currentScreenWidth <= mobileBrkpoint) {
        sIsMobile = true
      } 
      return sIsMobile
  }
    
  public static getVwNumber(target: number) {
    const baseDesignWidth = 1512; // as per the style guide
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    if (safariAgent) {
      // document.body.clientWidth or window.innerWidth
      const result = (document.body.clientWidth * target) / baseDesignWidth;
      return result;
    }
    const result = (window.screen.width * target) / baseDesignWidth;
    return result;
  }

  public static getWeightCardDimension(
    parentWidth: number,
    productCount: number
  ) {
    if (productCount <= 0) {
      const result = {
        parentWidth: parentWidth,
        width: 0,
        height: 0,
        gap: 0,
        itemsPerPage: 0,
        parentLeftPadding: 0,
        parentRightPadding: 0,
      };
    }
    const baseDesignWidth = 1512; // as per the style guide
    let noOfItems = productCount < 3 ? productCount : 3; // single page items
    let itemGap = noOfItems < 3 ? noOfItems * 5 : 5; // single page items
    const leftArrowPadding = noOfItems < 3 ? 10 : 5; //(window.screen.width * 26) / baseDesignWidth; //43
    const leftArrowSize = 0; //(window.screen.width * 39) / baseDesignWidth; //39
    const leftArrowWidth = leftArrowPadding + leftArrowSize;
    const rightArrowPadding = noOfItems < 3 ? 10 : 5; //(window.screen.width * 26) / baseDesignWidth; //43
    const rightArrowSize = 0; //(window.screen.width * 39) / baseDesignWidth; //39
    const rightArrowWidth = rightArrowPadding + rightArrowSize;
    const totalLeftRightArrowWidth = leftArrowWidth + rightArrowWidth;
    const parentDivLeftRightPadding = 0;
    const parentDivWidth = parentWidth - parentDivLeftRightPadding;
    let gap = this.getVwNumber(itemGap);
    const baseItemWidth = 87; //87
    const baseItemHeight = 61; //61
    const itemRatio = baseItemWidth / baseItemHeight; // w-251, h-337 | ratio = (251/337) = 0.7448071217

    const totalGap = gap * (noOfItems - 1);
    let itemWidth =
      (parentDivWidth - (totalGap + totalLeftRightArrowWidth)) / noOfItems;
    itemWidth = itemWidth - (this.getVwNumber(20) + 2);
    let itemHeight = itemWidth / itemRatio;

    /*let minItemWidth = 80;
      if(itemWidth < minItemWidth) {
        console.log('itemWidth :'+itemWidth)
        console.log('minItemWidth :'+minItemWidth)
        itemWidth = minItemWidth
        noOfItems = (parentDivWidth/itemWidth) | 0
        const newTotalGap = (parentDivWidth - (itemWidth * noOfItems))
        gap = newTotalGap/((noOfItems == 1) ? 1 : (noOfItems - 1))
        itemHeight = itemWidth / itemRatio
      }*/

    const result = {
      parentWidth: parentWidth,
      width: itemWidth,
      height: itemHeight,
      gap: gap,
      itemsPerPage: noOfItems,
      parentLeftPadding: leftArrowWidth,
      parentRightPadding: rightArrowWidth,
    };
    // console.log(result)
    return result;
  }

  public static getWeightCarouselCardDimension(
    parentWidth: number,
    productCount: number
  ) {
    if (productCount <= 0) {
      const result = {
        parentWidth: parentWidth,
        width: 0,
        height: 0,
        gap: 0,
        itemsPerPage: 0,
        parentLeftPadding: 0,
        parentRightPadding: 0,
        nextPreviousIconSize: 0,
        leftM: 0,
        rightM: 0,
      };
      return result;
    }

    let noOfItems = productCount === 1 ? 1 : 2;
    let itemGap = 10;

    const leftM = this.getVwNumber(30);
    const rightM = this.getVwNumber(30);

    const leftArrowPadding =
      noOfItems === 1 ? this.getVwNumber(40) : this.getVwNumber(0);
    const leftArrowSize = this.getVwNumber(8);
    const leftArrowWidth = leftArrowPadding + leftArrowSize + leftM;
    const rightArrowPadding =
      noOfItems === 1 ? this.getVwNumber(40) : this.getVwNumber(0);
    const rightArrowSize = this.getVwNumber(8);
    const rightArrowWidth = rightArrowPadding + rightArrowSize + rightM;
    const totalLeftRightArrowWidth = leftArrowWidth + rightArrowWidth;
    const parentDivLeftRightPadding = 0;
    const parentDivWidth = parentWidth - parentDivLeftRightPadding;
    let gap = this.getVwNumber(itemGap);
    const baseItemWidth = 49;
    const baseItemHeight = 69;
    const itemRatio = baseItemWidth / baseItemHeight;

    const boxBorder = 2;
    const boxPaddingLR = this.getVwNumber(10) + this.getVwNumber(10);

    const totalGap = gap * (noOfItems - 1);
    let itemWidth =
      (parentDivWidth - (totalGap + totalLeftRightArrowWidth)) / noOfItems;
    itemWidth = itemWidth - (boxBorder + boxPaddingLR);
    let itemHeight = itemWidth / itemRatio;

    const result = {
      parentWidth: parentWidth,
      width: itemWidth,
      height: itemHeight,
      gap: gap,
      itemsPerPage: noOfItems,
      parentLeftPadding: leftArrowWidth,
      parentRightPadding: rightArrowWidth,
      leftM: leftM,
      rightM: rightM,
      nextPreviousIconSize: rightArrowSize,
    };
    // console.log(result)
    return result;
  }

  public static getProductCardDimension(
    parentWidth: number,
    productCount: number,
    noOfItems: number,
    isShopAll: boolean
  ) {
    if (parentWidth <= 0) {
      return {
        parentWidth: parentWidth,
        width: 0,
        height: 0,
        imageHeight: 0,
        gap: 0,
        itemsPerPage: noOfItems,
        parentLeftPadding: 0,
        parentRightPadding: 0,
        nextPreviousIconSize: 0,
        leftM: 0,
        rightM: 0,
      };
    }
    const isMobile = this.isMobile();
    // let noOfItems = 4 // single page items

    const leftM = isShopAll ? this.getVwNumber(20) : 0;
    const rightM = isShopAll ? this.getVwNumber(20) : 0;

    const leftArrowPadding = this.getVwNumber(43); //43
    const leftArrowSize = this.getVwNumber(39); //39
    const leftArrowWidth = leftArrowPadding + leftArrowSize + leftM;
    const rightArrowPadding = this.getVwNumber(43); //43
    const rightArrowSize = this.getVwNumber(39); //39
    const rightArrowWidth = rightArrowPadding + rightArrowSize + rightM;
    const totalLeftRightArrowWidth = leftArrowWidth + rightArrowWidth;
    const parentDivLeftRightPadding = 0;
    const parentDivWidth = parentWidth - parentDivLeftRightPadding;
    let gap = this.getVwNumber(28);
    const baseItemWidth = (isMobile) ? 180 : 251;
    const baseItemHeight = (isMobile) ? 300 : 352; //370
    const baseImageViewHeight = (isMobile) ? 158 : 260; //260, 211
    const itemRatio = baseItemWidth / baseItemHeight; // w-251, h-337 | ratio = (251/337) = 0.7448071217
    const imageRatio = baseItemHeight / baseImageViewHeight; // h-354, h-260 | ratio = (354/260) = 1.3615384615

    const totalGap = gap * (noOfItems - 1);
    let itemWidth =
      (parentDivWidth - (totalGap + totalLeftRightArrowWidth)) / noOfItems;
    let itemHeight = itemWidth / itemRatio;
    let minItemWidth = (isMobile) ? 0 : 170;
    if (itemWidth < minItemWidth) {
      const newNoOfItems = noOfItems - 1;
      return this.getProductCardDimension(
        parentWidth,
        productCount,
        newNoOfItems,
        isShopAll
      );
    }

    const extImageHeight = itemHeight / imageRatio;

    const result = {
      parentWidth: parentWidth,
      width: itemWidth,
      height: itemHeight,
      imageHeight: extImageHeight,
      gap: gap,
      itemsPerPage: noOfItems,
      parentLeftPadding: leftArrowWidth,
      parentRightPadding: rightArrowWidth,
      nextPreviousIconSize: rightArrowSize,
      leftM: leftM,
      rightM: rightM,
    };
    // console.log('screenWidth :'+window.screen.width)
    // console.log(result)
    return result;
  }

  public static getProductCardGridDimension(
    parentWidth: number,
    productCount: number
  ) {
    const isMobile = this.isMobile();
    let noOfItems = 4; // single page items
    const leftArrowPadding = 0; //43
    const leftArrowSize = 0; //39
    const leftArrowWidth = leftArrowPadding + leftArrowSize;
    const rightArrowPadding = 0; //43
    const rightArrowSize = 0; //39
    const rightArrowWidth = rightArrowPadding + rightArrowSize;
    const totalLeftRightArrowWidth = leftArrowWidth + rightArrowWidth;
    const parentDivLeftRightPadding = 0;
    const parentDivWidth = parentWidth - parentDivLeftRightPadding;
    let gap = this.getVwNumber(28);
    const baseItemWidth = (isMobile) ? 180 : 251;
    const baseItemHeight = (isMobile) ? 300 : 370; // 337, 354
    const baseImageViewHeight = (isMobile) ? 158 : 260; //260, 211
    const itemRatio = baseItemWidth / baseItemHeight; // w-251, h-337 | ratio = (251/337) = 0.7448071217
    const imageRatio = baseItemHeight / baseImageViewHeight; // h-354, h-260 | ratio = (354/260) = 1.3615384615

    const totalGap = gap * (noOfItems - 1);
    let itemWidth =
      (parentDivWidth - (totalGap + totalLeftRightArrowWidth)) / noOfItems;
    let itemHeight = itemWidth / itemRatio;

    let minItemWidth = (isMobile) ? 150 : 170;
    if (itemWidth < minItemWidth) {
      itemWidth = minItemWidth;
      noOfItems = (parentDivWidth / itemWidth) | 0;
      const newTotalGap = parentDivWidth - itemWidth * noOfItems;
      gap = newTotalGap / (noOfItems == 1 ? 1 : noOfItems - 1);
      itemHeight = itemWidth / itemRatio;
    }

    const extImageHeight = itemHeight / imageRatio;

    const result = {
      parentWidth: parentWidth,
      width: itemWidth,
      height: itemHeight,
      imageHeight: extImageHeight,
      gap: gap,
      itemsPerPage: noOfItems,
      parentLeftPadding: leftArrowWidth,
      parentRightPadding: rightArrowWidth,
    };
    // console.log(result)
    return result;
  }
}
