import Filter, { FilterType, FilterQuery } from '../models/Filter';
import TextUtils from './TextUtils';
import FilterAttribute from '../models/FilterAttribute';
import ProductSectionModel from '../models/ProductSectionModel';
import AppliedFilter from '../models/AppliedFilter';
import RangeAttribute from '../models/RangeAttribute';
import { isNumber } from 'lodash';
import AlgoliaManager from '../webservice/AlgoliaManager';
import SortingList from '../models/SortingList';
import SortingItem from '../models/SortingItem';
import PartnerManager from './PartnerManager';
import { PartnerAppThemeType } from '../models/PartnerTheme';
import Effect from '../models/Effect';
const queryString = require('query-string');

export default class FilterUtils {

    public static readonly filterSlugsEnumMapping = {
        "kind":"ROOT_CATEGORIES",
        "brand":"BRANDS",
        "category":"LINEAGE",
        "feelings":"FEELINGS",
        "activities":"ACTIVITIES",
        "root_types":"CATEGORIES",
        "percent_cbd":"CBD_POTENCY",
        "percent_thc":"THC_POTENCY",
        "bucket_price":"PRICE",
        "aggregate_rating":"CUSTOMER_REVIEWS",
        "available_weights":"AVAILABLE_WEIGHTS",
    }

    public static readonly filterSlugsHeaderTitleEnumMapping = {
        "kind":"KIND",
        "brand":"BRANDS",
        "category":"LINEAGE",
        "feelings":"FEELINGS",
        "activities":"ACTIVITIES",
        "root_types":"CATEGORIES",
        "percent_cbd":"CBD POTENCY",
        "percent_thc":"THC POTENCY",
        "bucket_price":"PRICE",
        "aggregate_rating":"CUSTOMER REVIEWS",
        "available_weights":"AVAILABLE WEIGHTS",
    }

    public static readonly weightSlugEnumMapping = {
        "half gram":"0.5g",
        "gram":"1g",
        "two gram":"2g",
        "eighth ounce":"3.5g",
        "quarter ounce":"7g",
        "half ounce":"14g",
        "ounce":"28g",
    }

    public static priceRange = [":19.99","20:39.99","40:59.99","60:79.99","80:"]
    public static customerReviewRange = ["4:","3:","2:","1:"]

    public static getWeightTitle(weightName: string) {
        let weightType = undefined
        if(weightName && !TextUtils.isEmpty(weightName)) {
            const sSlugEnum = this.weightSlugEnumMapping[weightName]
            if(sSlugEnum) {
                weightType = sSlugEnum
            }
        }
        return weightType
    }

    public static getFilterRank(filterType: FilterType|undefined): number {
        if(filterType === FilterType.LINEAGE) return 0
        if(filterType === FilterType.THC_POTENCY) return 1
        if(filterType === FilterType.CBD_POTENCY) return 2
        if(filterType === FilterType.CATEGORIES) return 3
        if(filterType === FilterType.CUSTOMER_REVIEWS) return 4
        if(filterType === FilterType.ACTIVITIES) return 5
        if(filterType === FilterType.FEELINGS) return 6
        if(filterType === FilterType.PRICE) return 7
        if(filterType === FilterType.AVAILABLE_WEIGHTS) return 8
        if(filterType === FilterType.BRANDS) return 9
        return 10
    }

    public static getFilterEnums(filterName: string): { filterType: FilterType|undefined; filterHeaderTitle: string|undefined } {
        let fType = undefined
        let fHeadertTitle = undefined
        if(filterName && !TextUtils.isEmpty(filterName)) {
            const sSlugEnum = this.filterSlugsEnumMapping[filterName]
            if(sSlugEnum) {
                fType = <FilterType>sSlugEnum
            }
            const sSlugHeaderEnum = this.filterSlugsHeaderTitleEnumMapping[filterName]
            if(sSlugHeaderEnum) {
                fHeadertTitle = sSlugHeaderEnum
            }
        }
        return {filterType : fType, filterHeaderTitle: fHeadertTitle}
    }

    public static createPriceFilters(attributeItems: any, filter: Filter, appliedFiltersList: AppliedFilter[]): FilterAttribute[] {
        let priceFiltersArr: FilterAttribute[] = [];
        
        // convert objects to array
        var arrayOfObjects = Object.keys(attributeItems).map((key) => ({ "bucket_price": Number(key), "count": Number(attributeItems[key]) }));

        // iterate over the defined ranges
        this.priceRange.forEach((rangeValue: string) => {
            const range = this.getMinMaxFromPriceRange(rangeValue)
            let result = []
            let totalProductCount = 0
            if((range.min && range.max && range.max !== 0 && range.min !== 0)) {
                result = arrayOfObjects.filter((item: any)=> item.bucket_price >= range.min && item.bucket_price <= range.max);
            } else if((range.min && range.min !== 0)) {
                result = arrayOfObjects.filter((item: any)=> item.bucket_price >= range.min);
            } else if((range.max && range.max !== 0)) {
                result = arrayOfObjects.filter((item: any)=> item.bucket_price <= range.max);
            }

            if(result.length > 0) {
                totalProductCount = result.reduce((n, {count}) => n + count, 0)
            }

            const filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith(range.name, range.slug, range.rangeValue, totalProductCount, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            priceFiltersArr.push(filterAttributeObj)

        })

        return priceFiltersArr
    }


    public static createCustomerReviewsFilters(attributeItems: any, filter: Filter, appliedFiltersList: AppliedFilter[]): FilterAttribute[] {
        let customerRatingFiltersArr: FilterAttribute[] = [];
        
        // convert objects to array
        var arrayOfObjects = Object.keys(attributeItems).map((key) => ({ "aggregate_rating": Number(key), "count": Number(attributeItems[key]) }));
        
        // iterate over the defined ranges
        let totalCountOfAllReviews = 0
        this.customerReviewRange.forEach((rangeValue: string) => {
            const range = this.getMinMaxFromCustomerReviewRange(rangeValue)
            let result = []
            let totalProductCount = 0
            if((range.min && range.min !== 0)) {
                result = arrayOfObjects.filter((item: any)=> item.aggregate_rating >= range.min);
            }

            if(result.length > 0) {
                totalProductCount = result.reduce((n, {count}) => n + count, 0)
                totalCountOfAllReviews += totalProductCount
            }

            let filterAttributeObj1: FilterAttribute = new FilterAttribute()
            filterAttributeObj1.initWith(range.name, range.slug, range.rangeValue, totalProductCount, filter)
            filterAttributeObj1.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj1,appliedFiltersList))
            customerRatingFiltersArr.push(filterAttributeObj1)
        })

        if(totalCountOfAllReviews === 0) {
            return []
        }
        
        return customerRatingFiltersArr
    }

    public static createAvailableWeightsFilters(attributeItems: any, filter: Filter, appliedFiltersList: AppliedFilter[]): FilterAttribute[] {
        let weightFilters: FilterAttribute[] = [];
        const isOtherFilterApplied = AppliedFilter.isFilterApplied(filter.slug, appliedFiltersList)
        // weightFilters.push(FilterAttribute.getAllFilterAttribute(filter,isOtherFilterApplied))
        let pCountHalfGram = 0, pCountGram = 0, pCountTwoGram = 0, pCountEightOunce = 0, pCountQuarterOunce = 0, pCountHalfOunce = 0, pCountOunce = 0         
        
        if(attributeItems.hasOwnProperty("half gram")) {
            pCountHalfGram = attributeItems["half gram"]
        }

        if(attributeItems.hasOwnProperty("gram")) {
            pCountGram = attributeItems["gram"]
        }

        if(attributeItems.hasOwnProperty("two gram")) {
            pCountTwoGram = attributeItems["two gram"]
        }

        if(attributeItems.hasOwnProperty("eighth ounce")) {
            pCountEightOunce = attributeItems["eighth ounce"]
        }

        if(attributeItems.hasOwnProperty("quarter ounce")) {
            pCountQuarterOunce = attributeItems["quarter ounce"]
        }

        if(attributeItems.hasOwnProperty("half ounce")) {
            pCountHalfOunce = attributeItems["half ounce"]
        }

        if(attributeItems.hasOwnProperty("ounce")) {
            pCountOunce = attributeItems["ounce"]
        }
        
        if(pCountHalfGram > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('0.5g', 'half gram','half gram', pCountHalfGram, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }
        
        if(pCountGram > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('1g', 'gram', 'gram', pCountGram, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }

        if(pCountTwoGram > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('2g', 'two gram', 'two gram', pCountTwoGram, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }

        if(pCountEightOunce > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('3.5g', 'eighth ounce', 'eighth ounce', pCountEightOunce, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }

        if(pCountQuarterOunce > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('7g', 'quarter ounce', 'quarter ounce', pCountQuarterOunce, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }

        if(pCountHalfOunce > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('14g', 'half ounce', 'half ounce', pCountHalfOunce, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }

        if(pCountOunce > 0) {
            let filterAttributeObj: FilterAttribute = new FilterAttribute()
            filterAttributeObj.initWith('28g', 'ounce', 'ounce', pCountOunce, filter)
            filterAttributeObj.selected(AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList))
            weightFilters.push(filterAttributeObj)
        }
        
        return weightFilters
    }

    public static isAnyProductSectionOpen(arr: ProductSectionModel[]): boolean {
        const productSectionModel = arr.find((item: ProductSectionModel)=> item.isSelected === true)
        if(productSectionModel) {
            return true
        }
        return false
    }

    public static getLineageFilter(filters: Filter[]): Filter {
        let lineageFilter: Filter = undefined
        if(filters) {
            lineageFilter = filters.find((item: Filter)=> item.type === FilterType.LINEAGE)
        }
        return lineageFilter
    }

    public static getProductSections(filters: Filter[]): ProductSectionModel[] { 
        let finalArray: ProductSectionModel[] = []
        // return finalArray
        const shouldApplySort = true
        let isCategoryTheme = false

        switch (PartnerManager.shared().partner.theme.name) {
            case PartnerAppThemeType.CATEGORIES:
                isCategoryTheme = true
                break;
            default:
                break;
        }

        let isAnyCategoryOrEffectSelected = false
        if(isCategoryTheme) {
            const categoryFilter= filters.find((item: Filter)=> item.type === FilterType.CATEGORIES)
            if(categoryFilter) {
                for(let fAttr of categoryFilter.filterAttributes) {
                    const toBeRemoved = ["all", "sale"];
                    const allowedSlugs = ["flower"];
                    // !toBeRemoved
                    // allowedSlugs
                    if(!toBeRemoved.includes(fAttr.slug)) {
                        if(fAttr.isSelected === true) {
                            isAnyCategoryOrEffectSelected = true
                        }
                        let pSection = new ProductSectionModel()
                        pSection.initWith(fAttr)
                        finalArray.push(pSection)
                        // break;
                    }
                }
            }
        } else {
            const effectFilter= filters.find((item: Filter)=> item.type === FilterType.FEELINGS)
            if(effectFilter) {
                for(let fAttr of effectFilter.filterAttributes) {
                    const toBeRemoved = ["all"];
                    if(!toBeRemoved.includes(fAttr.slug)) {
                        if(fAttr.isSelected === true) {
                            isAnyCategoryOrEffectSelected = true
                        }
                        let pSection = new ProductSectionModel()
                        pSection.initWith(fAttr)
                        finalArray.push(pSection)
                    }
                }
            }
        }        

        // see all button true/false settings
        if(isAnyCategoryOrEffectSelected) {
            finalArray.forEach((item: ProductSectionModel) => {
                item.selected(true)
            })
        }
        
        // sorting categories by rank on right panel
        if(shouldApplySort && finalArray.length > 0) {
            finalArray.sort((a, b) => (a.rank > b.rank ? 1 : -1))
        }
        return finalArray
    }

    public static getQueryFilterValue(items: any[], keyName: string, operator: string) {
        let queryFilters = ''
        if(items && Array.isArray(items) && items.length > 0) {
            let itemIdFilters = [];
            for(let itemId of items){
                itemIdFilters.push(`${keyName} = ${itemId}`)
            }
            if(itemIdFilters.length > 1) {
                queryFilters = itemIdFilters.join(` ${operator} `);
            } else {
                queryFilters = itemIdFilters[0]
            }
        }
        return queryFilters
    }

    public static getQueryFilter(storeIds: number[], filters: Filter[], productSectionModel: ProductSectionModel, pageSize: number ,page: number): FilterQuery { 
        let queryFilters = ''
        let arrayOfFilters = []
        let operator = 'AND'

        // Add filter wise filter attributes
        for (let filter of filters) {
            const fQuery = filter.getQuery()
            if(fQuery) {
                arrayOfFilters.push(fQuery)
            }
        }

        // Add store filter
        if(storeIds.length > 0) {
            const storeQueryFilters = this.getQueryFilterValue(storeIds,'store_id','AND')
            arrayOfFilters.push(storeQueryFilters)
        }

        // Add category filter based on product section model
        if(productSectionModel) {
            arrayOfFilters.push(productSectionModel.getQuery())
        }

        if(arrayOfFilters.length > 0) { 
            queryFilters = (arrayOfFilters.length > 1) ? arrayOfFilters.join(` ${operator} `) : arrayOfFilters[0];
        }
        // console.log('finalQuery :'+queryFilters);
        return { hitsPerPage: pageSize, filters: queryFilters, page: page}
    }

    public static createFiltersQueryString(previousQueryString: string|undefined|null,filter: Filter, filterAttribute: FilterAttribute, rangeAttribute: RangeAttribute, isSelected: boolean, sortingItem: SortingItem, toBeRemovedAppdFilter: AppliedFilter): string {
        // return ''
        let finalQs: string = ''
        let masterAppliedFilterList: AppliedFilter[] = []
    
        // get previous applied filters
        let parsedObjects = undefined
        let previousSortBy = undefined
        let isQueryFilterTobeRemoved = (toBeRemovedAppdFilter && toBeRemovedAppdFilter.slug === 'query') ? toBeRemovedAppdFilter : undefined
        let isOtherFilterTobeRemoved = (toBeRemovedAppdFilter && toBeRemovedAppdFilter.slug !== 'query') ? toBeRemovedAppdFilter : undefined
        if(previousQueryString && !TextUtils.isEmpty(previousQueryString)) {
            parsedObjects = queryString.parse(previousQueryString);
            let appliedFilterList = AppliedFilter.list(parsedObjects)
            masterAppliedFilterList = masterAppliedFilterList.concat(appliedFilterList)

            if(parsedObjects['sortBy']) {
                previousSortBy = parsedObjects['sortBy']
            }
        }

        // console.log('toBeRemovedAppdFilter=> '+JSON.stringify(toBeRemovedAppdFilter))
        // console.log('previous parsedObjects=> '+JSON.stringify(parsedObjects))
        // console.log('previous AppliedFilters=> '+JSON.stringify(masterAppliedFilterList))
        // console.log('filterAttribute=> '+JSON.stringify(filterAttribute))
        // console.log('rangeAttribute=> '+JSON.stringify(rangeAttribute))

        if(filterAttribute) {
            // FilterAttribute
            // get new applied filters
            const appliedFilter = AppliedFilter.getAppliedFilter(filter,filterAttribute)
            // console.log('new appliedFilter=> '+JSON.stringify(appliedFilter))
            if(appliedFilter) {
                const isAllFilter = (appliedFilter.value === '') ? true:false
                if(isAllFilter) {
                    // remove all applied filters for particular filter slug for e.g feelings, activities
                    const appdFilterList = AppliedFilter.findAppliedFilterListWithArray(appliedFilter,masterAppliedFilterList)
                    if(appdFilterList && appdFilterList.length > 0) {
                        const length = appdFilterList.length
                        for (var i = 0; i < length ; i++) {
                            const mAppliedFilter = appdFilterList[i]
                            const index = masterAppliedFilterList.indexOf(mAppliedFilter)
                            if(index !== -1) {
                                masterAppliedFilterList.splice(index,1)
                            }                            
                        }
                    }
                } else {

                    if(Filter.singleSelectedFilters.includes(appliedFilter.slug)) {
                        // remove all applied filters for particular filter slug for e.g feelings, activities
                        const appdFilterList = AppliedFilter.findAppliedFilterListWithArray(appliedFilter,masterAppliedFilterList)
                        if(appdFilterList && appdFilterList.length > 0) {
                            const length = appdFilterList.length
                            for (var i = 0; i < length ; i++) {
                                const mAppliedFilter = appdFilterList[i]
                                const index = masterAppliedFilterList.indexOf(mAppliedFilter)
                                if(index !== -1) {
                                    masterAppliedFilterList.splice(index,1)
                                }                            
                            }
                        }
                    }

                    if(filterAttribute.isAll && filterAttribute.isChild) {
                        // 'ALL' tapped at child level - remove existing filters
                        // remove all applied filters for particular filter slug for e.g feelings, activities
                        const appdFilterList = AppliedFilter.findAppliedFilterListWithArray(appliedFilter,masterAppliedFilterList)
                        if(appdFilterList && appdFilterList.length > 0) {
                            const length = appdFilterList.length
                            for (var i = 0; i < length ; i++) {
                                const mAppliedFilter = appdFilterList[i]
                                const index = masterAppliedFilterList.indexOf(mAppliedFilter)
                                if(index !== -1) {
                                    masterAppliedFilterList.splice(index,1)
                                }                            
                            }
                        }
                    } else if(filterAttribute.isChild) {
                        // 'Other' tapped at child level - remove parent filter
                        const appdFilter = AppliedFilter.findParentAppliedFilterWithArray(filterAttribute,masterAppliedFilterList)
                        if(appdFilter) {
                            const index = masterAppliedFilterList.indexOf(appdFilter)
                            if(index !== -1) {
                                masterAppliedFilterList.splice(index,1)
                            }
                        }
                    }

                    if(isSelected) {
                        // add filter
                        masterAppliedFilterList.push(appliedFilter)
                    } else {
                        // remove filter
                        const appdFilter = AppliedFilter.findAppliedFilterWithArray(appliedFilter,masterAppliedFilterList)
                        const index = masterAppliedFilterList.indexOf(appdFilter)
                        if(appdFilter && index !== -1) {
                            masterAppliedFilterList.splice(index,1)
                        }
                    }
                }
            }
        } else if(rangeAttribute) {
            // RangeAttribute
            
            // remove previous applied filters if any
            masterAppliedFilterList = AppliedFilter.removePreviousAppliedRangeFilters(rangeAttribute,masterAppliedFilterList)

            // get new applied filters
            const appliedFilterList = AppliedFilter.getAppliedRangeFilterList(filter,rangeAttribute)
            masterAppliedFilterList = masterAppliedFilterList.concat(appliedFilterList)
        } else if(sortingItem) {
            //
        } else if(isOtherFilterTobeRemoved) {
            // Other filter to be removed
            const toBeDeletedList = AppliedFilter.getToBeDeletedAppliedFilters(isOtherFilterTobeRemoved,masterAppliedFilterList)
            if(toBeDeletedList && toBeDeletedList.length > 0) {
                toBeDeletedList.forEach((appdFilter: AppliedFilter) => {
                    const index = masterAppliedFilterList.indexOf(appdFilter)
                    if(index !== -1) {
                        masterAppliedFilterList.splice(index,1)
                    }
                })
            }
            
        } else if(isQueryFilterTobeRemoved){
            // Remove query filter
            let removeAppliedFilterObj = AppliedFilter.findAppliedFilterWithValue(toBeRemovedAppdFilter.slug, toBeRemovedAppdFilter.value, masterAppliedFilterList)
            const indexTobeDeleted = masterAppliedFilterList.indexOf(removeAppliedFilterObj)
            if(indexTobeDeleted !== -1) {
                masterAppliedFilterList.splice(indexTobeDeleted,1)
            }
        }else {
            masterAppliedFilterList = []
        }

        // console.log('masterAppliedFilterList =>'+JSON.stringify(masterAppliedFilterList))

        // find unique slug list as per the order
        var slugsList = masterAppliedFilterList.map(appliedFilter => (appliedFilter.slug));
        let uniqueSlugsList: string[] = [];
        slugsList.forEach((c) => {
            if (!uniqueSlugsList.includes(c)) {
                uniqueSlugsList.push(c);
            }
        });
        
        // create slug wise filters objects by key and value
        let masterObj = {}
        for(let slug of uniqueSlugsList) {            
            const afilterList = AppliedFilter.findAppliedFilter(slug,masterAppliedFilterList)
            const length = afilterList.length
            for (var i = 0; i < length ; i++) {
                const mAppliedFilter = afilterList[i]
                const sObjArr = mAppliedFilter.getQueryStringParam(i)
                for(let sAppdFilter of sObjArr) {
                    masterObj[sAppdFilter.key] = sAppdFilter.value
                }
            }
        }

        if(sortingItem || previousSortBy) {
            if(sortingItem) {
                masterObj['sortBy'] = sortingItem.value
            } else {
                masterObj['sortBy'] = previousSortBy
            }
        }

        console.log('query string => '+queryString.stringify(masterObj))
        finalQs = '?'+queryString.stringify(masterObj)
        return finalQs
    }

    // Uncheck All attributes - Parent & Child level
    public static uncheckAllElements(filter: Filter): Filter {
        // parent attributes
        let filterAttributeList = filter.filterAttributes
        let sFilterAttributesList = filterAttributeList.filter((item: FilterAttribute)=> item.isSelected === true);
        for (let obj of sFilterAttributesList) {
            obj.selected(false)
            if(obj.subAttributes && obj.subAttributes.length > 0) {
                // child attributes
                let childFilterAttributeList = obj.subAttributes.filter((item: FilterAttribute)=> item.isSelected === true);
                for (let childObj of childFilterAttributeList) {
                    childObj.selected(false)
                }                
            }
        }
        return filter
    }

    // Check or Uncheck 'ALL' - Parent level
    public static checkOrUncheckParentAllAttributeItem(filter: Filter, selected: boolean): Filter {
        // parent attributes
        let filterAttributeList = filter.filterAttributes
        filterAttributeList = this.selectOrDeselectAllAttribute(filterAttributeList,selected) // deselect all item
        return filter
    }

    // Check or Uncheck 'ALL' - Child level for particular FilterAttribute
    public static checkOrUncheckChildAllAttributeItem(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): Filter {
        let parentAttribute = FilterAttribute.findParentFilterAttribute(filter.filterAttributes,filterAttribute.parentSlug)
        if(parentAttribute) {
            if(parentAttribute.subAttributes && parentAttribute.subAttributes.length > 0) {
                // child attributes
                let childFilterAttributeList = parentAttribute.subAttributes
                childFilterAttributeList = this.selectOrDeselectAllAttribute(childFilterAttributeList,selected)
                
                // update parent attibute also
                const filterAttributeList = filter.filterAttributes
                parentAttribute.selected(selected)
                filterAttributeList[parentAttribute.index] = parentAttribute;
            }
        }
        return filter
    }

    // Check or Uncheck All Attributes - Parent level
    public static checkOrUncheckParentAttributeItems(filter: Filter, selected: boolean): Filter {
        // parent attributes
        let filterAttributeList = filter.filterAttributes
        filterAttributeList = this.selectOrDeselectAttributes(filterAttributeList,selected)
        return filter
    }

    // Check or Uncheck All Attributes - Child level
    public static checkOrUncheckChildAttributeItems(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): Filter {
        let parentAttribute = FilterAttribute.findParentFilterAttribute(filter.filterAttributes,filterAttribute.parentSlug)
        if(parentAttribute) {
            if(parentAttribute.subAttributes && parentAttribute.subAttributes.length > 0) {
                // child attributes
                let childFilterAttributeList = parentAttribute.subAttributes
                childFilterAttributeList = this.selectOrDeselectAttributes(childFilterAttributeList,selected)
                
                // update parent attibute also
                const filterAttributeList = filter.filterAttributes
                parentAttribute.selected(selected)
                filterAttributeList[parentAttribute.index] = parentAttribute;
            }
        }
        return filter
    }

    // Check or Uncheck All Attributes including All + Others
    public static selectOrDeselectAttributes(filterAttributeList: FilterAttribute[], selected: boolean): FilterAttribute[] {
        const attributesList = filterAttributeList.filter((item: FilterAttribute)=> item.isSelected === !selected);
        if(attributesList && attributesList.length > 0) {
            for (let obj of attributesList) {
                obj.selected(selected)
            }
        }
        return filterAttributeList
    }

    // Check or Uncheck 'ALL' Attribute only
    public static selectOrDeselectAllAttribute(filterAttributeList: FilterAttribute[], selected: boolean): FilterAttribute[] {
        let allFilterAttribute = filterAttributeList.find((item: FilterAttribute)=> item.isAll === true && item.isSelected === !selected);
        if(allFilterAttribute) {
            allFilterAttribute.selected(selected)
            filterAttributeList[0] = allFilterAttribute;
        }
        return filterAttributeList
    }

    public static isAnyItemsSelected(filter: Filter): boolean {
        // parent attributes
        let isSelectedItemFound = false
        let filterAttributeList = filter.filterAttributes
        let sFilterAttributesList = filterAttributeList.filter((item: FilterAttribute) => item.isAll === false && item.isSelected === true);
        if(sFilterAttributesList && sFilterAttributesList.length > 0) {
            isSelectedItemFound = true
        }

        if(!isSelectedItemFound) {
            for (let obj of filterAttributeList) {
                if(obj.subAttributes && obj.subAttributes.length > 0) {
                    // child attributes
                    let childFilterAttributeList = obj.subAttributes.filter((item: FilterAttribute) => item.isSelected === true);
                    if(childFilterAttributeList && childFilterAttributeList.length > 0) {
                        isSelectedItemFound = true
                        break;
                    }                
                }
            }
        }
        return isSelectedItemFound
    }

    public static isAnyItemsSelectedAtChildLevel(filter: Filter, filterAttribute: FilterAttribute): boolean {
        // parent attributes
        let isSelectedItemFound = false
        let filterAttributeList = filter.filterAttributes
        let parentAttribute = FilterAttribute.findParentFilterAttribute(filterAttributeList,filterAttribute.parentSlug)
        if(parentAttribute) {
            let childFilterAttributeList = parentAttribute.subAttributes
            if(childFilterAttributeList && childFilterAttributeList.length > 0) {
                // child attributes
                let selectedFilterAttributes = childFilterAttributeList.filter((item: FilterAttribute) => item.isSelected === true);
                if(selectedFilterAttributes && selectedFilterAttributes.length > 0) {
                    isSelectedItemFound = true
                }                
            }
        }
        return isSelectedItemFound
    }

    

    public static uncheckAll(filterAttributeList: FilterAttribute[]): FilterAttribute[] {
        let sFilterAttributesList = filterAttributeList.filter((item: FilterAttribute)=> item.isSelected === true);
        for (let obj of sFilterAttributesList) {
            obj.selected(false)
        }
        return filterAttributeList
    }
    

    public static uncheckAllForSubAttribtes(filterAttributeList: FilterAttribute[]): FilterAttribute[] {
        let filterAttribForSubCat = filterAttributeList.filter((item: FilterAttribute)=> item.subAttributes.length > 0);
        const length = filterAttribForSubCat.length
        for (var i = 0; i < length ; i++) {
            let mFilterAttrib = filterAttribForSubCat[i]
            const subAttribLength = mFilterAttrib.subAttributes.length
            for (var j = 0; j < subAttribLength ; j++) {
                let mSubFilterAttrib = mFilterAttrib.subAttributes[j]
                mSubFilterAttrib.selected(false)
            }
        }
        return filterAttributeList
    }

    // New Method
    public static onSelectOrDeselectFilterAttribute1(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): Filter {
        if(filterAttribute.isAll) {
            // 'ALL' checked or unchecked - Parent/Child Level

            if(selected) {
                // checked
                if(filterAttribute.isChild) {
                    // checked - child level

                    // unchecked 'ALL' item at parent level
                    filter = this.checkOrUncheckParentAllAttributeItem(filter,false)

                    // uncheck 'ALL' + Other items at child level
                    filter = this.checkOrUncheckChildAttributeItems(filter,filterAttribute,false)

                    // checked 'ALL' item at child level
                    filter = this.checkOrUncheckChildAllAttributeItem(filter,filterAttribute,selected)
                    
                } else {
                    // checked - parent level

                    // uncheck all items including parent and child items
                    filter = this.uncheckAllElements(filter)

                    // checked 'ALL' item at parent level
                    filter = this.checkOrUncheckParentAllAttributeItem(filter,selected)
                }
            } else {
                if(filterAttribute.isChild) {
                    // unchecked at child level

                    // unchecked 'ALL' item at child level
                    filter = this.checkOrUncheckChildAllAttributeItem(filter,filterAttribute,selected)

                    // If any items not selected select parent 'ALL'
                    if(!this.isAnyItemsSelected(filter)) {
                        // checked 'ALL' item at parent level
                        filter = this.checkOrUncheckParentAllAttributeItem(filter,selected)
                    }

                } else {
                    // unchecked at parent level - NOT allowed
                }
            }


        } else {
            // 'OTHER' checked or unchecked

            if(selected) {
                // checked
                if(filterAttribute.isChild) {
                    // checked - child level

                    // multiple selection only option for now

                    // unchecked 'ALL' item at parent level
                    filter = this.checkOrUncheckParentAllAttributeItem(filter,false)

                    // unchecked 'ALL' item at child level
                    filter = this.checkOrUncheckChildAllAttributeItem(filter,filterAttribute,false)

                    // checked the 'Other' item - parent level
                    const filterAttributeList = filter.filterAttributes
                    filterAttribute.selected(selected)
                    filterAttributeList[filterAttribute.index] = filterAttribute;
                
                } else {
                    // checked - parent level

                    if(filter.isMultiSelectAllowed) {
                        // Multiple selection

                        // unchecked 'ALL' item at parent level
                        filter = this.checkOrUncheckParentAllAttributeItem(filter,false)

                    } else {
                        // Single selection

                        // unchecked 'ALL + Others' at parent level
                        filter = this.checkOrUncheckChildAttributeItems(filter,filterAttribute,false)

                    }

                    // checked the 'Other' item - parent level
                    const filterAttributeList = filter.filterAttributes
                    filterAttribute.selected(selected)
                    filterAttributeList[filterAttribute.index] = filterAttribute;

                }
            } else {
                // unchecked
                if(filterAttribute.isChild) {
                    // unchecked - child level

                    // unchecked the 'Other' item - parent level
                    const filterAttributeList = filter.filterAttributes
                    filterAttribute.selected(selected)
                    filterAttributeList[filterAttribute.index] = filterAttribute;

                    // If any items not selected at child level select child 'ALL'
                    if(!this.isAnyItemsSelectedAtChildLevel(filter,filterAttribute)) {
                        // checked 'ALL' item at parent level
                        filter = this.checkOrUncheckChildAllAttributeItem(filter,filterAttribute,true)
                    }

                    // If any items not selected select parent 'ALL'
                    if(!this.isAnyItemsSelected(filter)) {
                        // checked 'ALL' item at parent level
                        filter = this.checkOrUncheckParentAllAttributeItem(filter,true)
                    }

                } else {
                    // unchecked - parent level

                    // unchecked the 'Other' item - parent level
                    const filterAttributeList = filter.filterAttributes
                    filterAttribute.selected(selected)
                    filterAttributeList[filterAttribute.index] = filterAttribute;

                    // If any items not selected select parent 'ALL'
                    if(!this.isAnyItemsSelected(filter)) {
                        // checked 'ALL' item at parent level
                        filter = this.checkOrUncheckParentAllAttributeItem(filter,selected)
                    }
                }
            }



        }
        return filter
    }
    
    public static onSelectOrDeselectFilterAttribute(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): Filter {
        let filterAttributeList = filter.filterAttributes

        if(!filterAttribute.isAll) {
            // other selected/deselected
            if(selected) {
                // other selected

                // 1. find all attribute (if selected to true)
                filterAttributeList = this.selectOrDeselectAllAttribute(filterAttributeList,false) // deselect all

                // deselect child
                if(filterAttribute.isChild) {
                    let parentAttribute = FilterAttribute.findParentFilterAttribute(filterAttributeList,filterAttribute.parentSlug)
                    let childFilterAttributeList = parentAttribute.subAttributes
                    childFilterAttributeList = this.uncheckAll(childFilterAttributeList)

                    // 2. Select All attribute
                    childFilterAttributeList = this.selectOrDeselectAllAttribute(childFilterAttributeList,false) // deselect all
                }

                // Find other attribute if selected
                let otherSelectedAttribute = undefined
                if(!filter.isMultiSelectAllowed) {
                    otherSelectedAttribute = filterAttributeList.find((item: FilterAttribute)=> item.isAll === false && item.isSelected === true);
                }

                // 2. select the attribute
                filterAttribute.selected(selected)
                filterAttributeList[filterAttribute.index] = filterAttribute;

                // 3. If other attribute selected, deselect it (In case of SINGLE Selection)
                if(otherSelectedAttribute) {
                    otherSelectedAttribute.selected(false) // deselct all
                    const indexTobeUpdated =  filterAttributeList.indexOf(otherSelectedAttribute)
                    if(indexTobeUpdated != -1) {
                        filterAttributeList[indexTobeUpdated] = otherSelectedAttribute;
                    }
                }
            } else {
                // other deselected

                // 1. Deselect filterAttribute to false
                filterAttribute.selected(selected)
                filterAttributeList[filterAttribute.index] = filterAttribute;

                // 2. If All not selected, select it if others not selected
                let otherSelectedAttribute = filterAttributeList.find((item: FilterAttribute)=> item.isAll === false && item.isSelected === true);
                
                if(!otherSelectedAttribute) {
                    // look in child attributes
                    let filterAttribForSubCat = filterAttributeList.filter((item: FilterAttribute)=> item.subAttributes.length > 0);
                    const length = filterAttribForSubCat.length
                    for (var i = 0; i < length ; i++) {
                        let mFilterAttrib = filterAttribForSubCat[i]
                        let childFilterAttributeList = mFilterAttrib.subAttributes
                        otherSelectedAttribute = childFilterAttributeList.find((item: FilterAttribute)=> item.isSelected === true);
                        if(otherSelectedAttribute) break;
                    }
                }

                if(!otherSelectedAttribute) {
                    filterAttributeList = this.selectOrDeselectAllAttribute(filterAttributeList,true) // select all
                }
            }
                        
        } else {
            // all selected/deselected

            if(selected) {
                // 1. Uncheck All
                filterAttributeList = this.uncheckAll(filterAttributeList)

                // Uncheck All child attributes

                // 1. Uncheck All child
                if(filterAttribute.isChild) {
                    let parentAttribute = FilterAttribute.findParentFilterAttribute(filterAttributeList,filterAttribute.parentSlug)
                    let childFilterAttributeList = parentAttribute.subAttributes
                    childFilterAttributeList = this.uncheckAll(childFilterAttributeList)

                    // 2. Select All attribute
                    childFilterAttributeList = this.selectOrDeselectAllAttribute(childFilterAttributeList,true) // select all
                } else {    
                    // 2. Select All attribute
                    filterAttributeList = this.selectOrDeselectAllAttribute(filterAttributeList,true) // select all
                }

                
            } else {
                if(filterAttribute.isChild) {

                    // find parent attribute
                    let parentAttribute = FilterAttribute.findParentFilterAttribute(filterAttributeList,filterAttribute.parentSlug)
                    let childFilterAttributeList = parentAttribute.subAttributes

                    // 2. Uncheck all attribute (in case of child)
                    filterAttribute.selected(selected)
                    childFilterAttributeList[filterAttribute.index] = filterAttribute;

                    // 3. If All not selected, select it if others not selected
                    let otherSelectedAttribute = filterAttributeList.find((item: FilterAttribute)=> item.isAll === false && item.isSelected === true);
                    
                    if(!otherSelectedAttribute) {
                        // look in child attributes
                        let filterAttribForSubCat = filterAttributeList.filter((item: FilterAttribute)=> item.subAttributes.length > 0);
                        const length = filterAttribForSubCat.length
                        for (var i = 0; i < length ; i++) {
                            let mFilterAttrib = filterAttribForSubCat[i]
                            let childFilterAttributeList = mFilterAttrib.subAttributes
                            otherSelectedAttribute = childFilterAttributeList.find((item: FilterAttribute)=> item.isSelected === true);
                            if(otherSelectedAttribute) break;
                        }
                    }
                    
                    if(!otherSelectedAttribute) {
                        filterAttributeList = this.selectOrDeselectAllAttribute(filterAttributeList,true) // select all
                    }

                }
            }
        }
        return filter
    }

    public static getSelectedRange(filters: Filter[],appliedFiltersList: AppliedFilter[],slug: string) {
        const filterObjFound = filters.find((item: Filter)=> item.slug === slug)
        let minVal = filterObjFound.rangeAttribute.min
        let maxVal = filterObjFound.rangeAttribute.max
        const appldFilterListFound = appliedFiltersList.filter((item: AppliedFilter)=> item.slug === filterObjFound.slug)
        if(appldFilterListFound && appldFilterListFound.length > 0) {                        
            const lengthOfItems = appldFilterListFound.length
            for (var i = 0; i < lengthOfItems ; i++) {
                let mAppliedFilter = appldFilterListFound[i]                
                if(mAppliedFilter.minValue) {
                    minVal = mAppliedFilter.minValue
                } else if(mAppliedFilter.maxValue) {
                    maxVal = mAppliedFilter.maxValue
                }
            }
        }
        return {min: minVal,max: maxVal}
    }

    public static isChildCategory(childSlug: string): boolean {
        const separator = ':'
        if(!TextUtils.isEmpty(childSlug) && childSlug.includes(separator)) {
            const arr = childSlug.split(separator)
            if(arr && arr.length > 1) {
                return true
            }
        }
        return false
    }

    public static getParentSlugName(childSlug: string): string {
        let parentSlug = undefined
        const separator = ':'
        if(!TextUtils.isEmpty(childSlug) && childSlug.includes(separator)) {
            const arr = childSlug.split(separator)
            if(arr && arr.length > 1) {
                parentSlug = arr[0]
            }
        }
        return parentSlug
    }

    public static getChildSlugName(childSlug: string): string {
        let childSlugTitle = undefined
        const separator = ':'
        if(!TextUtils.isEmpty(childSlug) && childSlug.includes(separator)) {
            const arr = childSlug.split(separator)
            if(arr && arr.length > 1) {
                childSlugTitle = arr[1]
            }
        }
        return childSlugTitle
    }

    public static getMinMaxFromPriceRange(rangeValue: string): 
        { min: number, max: number, name: string, slug: string, rangeValue: string} {
        const range = this.getMinMaxFromRange(rangeValue)
        const min = range.min
        const max = range.max
        let name = ''
        let slug = ''
        if((min && max && max !== 0 && min !== 0)) {
            name = `$${Math.round(min)}-${Math.round(max)}`
            slug = `bucket_price>=${min} AND bucket_price<=${max}`
        } else if((min && min !== 0)) {
            name = `$${Math.round(min)} & above`
            slug = `bucket_price>=${min}`
        } else if((max && max !== 0)) {
            name = `Under $${Math.round(max)}`
            slug = `bucket_price<=${max}`
        }
        return {min, max, name, slug, rangeValue}
    }

    public static getMinMaxFromCustomerReviewRange(rangeValue: string): 
        { min: number, max: number, name: string, slug: string, rangeValue: string} {
        const range = this.getMinMaxFromRange(rangeValue)
        const min = range.min
        const max = range.max
        let name = ''
        let slug = ''
        if((min && min !== 0)) {
            name = `${min}+`
            slug = `aggregate_rating >= ${min} AND review_count >= 5`
        }
        return {min, max, name, slug, rangeValue}
    }

    public static getMinMaxFromRange(rangeValue: string): { min: number, max: number } {
        let min: number = undefined
        let max: number = undefined
        const separator = ':'
        if(!TextUtils.isEmpty(rangeValue) && rangeValue.includes(separator)) {
            // 3: or 20:59.99
            const arr = rangeValue.split(separator)
            if(arr && arr.length > 0) {
                if(!isNaN(Number(arr[0]))){
                    min = Number(arr[0])
                }
                if(arr.length > 1 && !isNaN(Number(arr[1]))){
                    max = Number(arr[1])
                }
            }
        } else {
            if(!isNaN(Number(rangeValue))){
                min = Number(rangeValue)
            }
        }
        return {min, max}
    }

    public static getAppliedFiltersForUI(filters: Filter[], appliedFiltersList: AppliedFilter[]): { appliedFilters: AppliedFilter[], dummyAppliedFilters: AppliedFilter[] }  {
        let finalArray: AppliedFilter[] = []
        let dummyFinalArray: AppliedFilter[] = []

        // console.log('PRE appliedFiltersList :'+JSON.stringify(appliedFiltersList))
        // console.log('PRE filters :'+JSON.stringify(filters))

        // iterate over appliedFiltersList
        appliedFiltersList.forEach((mAppliedFilter: AppliedFilter) => {
            if(mAppliedFilter.slug !== 'query') {
                // find filter based on applied filter slug
                const filterObjFound = filters.find((item: Filter)=> item.slug === mAppliedFilter.slug)
                if(filterObjFound) { 
                    if(filterObjFound.slug === 'root_types') {
                        if(mAppliedFilter.value) {
                            const parentSlug = this.getParentSlugName(mAppliedFilter.value)
                            let filterAttribObjFound = undefined
                            if(parentSlug) {
                                let parentAttribute = FilterAttribute.findParentFilterAttribute(filterObjFound.filterAttributes,parentSlug)
                                if(parentAttribute) { 
                                    filterAttribObjFound = parentAttribute.subAttributes.find((item: FilterAttribute)=> item.appliedFilterValue === mAppliedFilter.value)
                                }
                            } else {
                                filterAttribObjFound = filterObjFound.filterAttributes.find((item: FilterAttribute)=> item.appliedFilterValue === mAppliedFilter.value)
                            }

                            if(filterAttribObjFound) {
                                mAppliedFilter.setDisplayValue(filterAttribObjFound.displayLabel())
                                finalArray.push(mAppliedFilter)
                            } else {
                                // dummy added
                                const dummyAttribute = FilterAttribute.dummyFilterAttribute(mAppliedFilter)
                                mAppliedFilter.setDisplayValue(dummyAttribute.displayLabel())
                                finalArray.push(mAppliedFilter)
                                dummyFinalArray.push(mAppliedFilter)
                            }
                        }
                    } else if(filterObjFound.slug !== 'percent_thc' && filterObjFound.slug !== 'percent_cbd') {
                        if(mAppliedFilter.value) {
                            const filterAttribObjFound = filterObjFound.filterAttributes.find((item: FilterAttribute)=> item.appliedFilterValue === mAppliedFilter.value)
                            if(filterAttribObjFound) {
                                mAppliedFilter.setDisplayValue(filterAttribObjFound.displayLabel())
                                finalArray.push(mAppliedFilter)
                            } else {
                                // dummy added
                                const dummyAttribute = FilterAttribute.dummyFilterAttribute(mAppliedFilter)
                                mAppliedFilter.setDisplayValue(dummyAttribute.displayLabel())
                                finalArray.push(mAppliedFilter)
                                dummyFinalArray.push(mAppliedFilter)
                            }
                        }
                    } else {
                        const rangeForTHC = this.getSelectedRange(filters,appliedFiltersList,filterObjFound.slug)
                        const isFilterProcessed = finalArray.find((item: AppliedFilter)=> item.slug === filterObjFound.slug)
                        if(!isFilterProcessed) {
                            let tempAppliedFilterObj = new AppliedFilter()
                            tempAppliedFilterObj.init(filterObjFound.slug,undefined,rangeForTHC.min,rangeForTHC.max)
                            finalArray.push(tempAppliedFilterObj)
                        } else {
                            isFilterProcessed.setMinValue(rangeForTHC.min)
                            isFilterProcessed.setMaxValue(rangeForTHC.max)
                        }
                    }
                } else {
                    console.log('filter not found for :'+mAppliedFilter.slug)
                    // dummy filter
                    // dummy added
                    if(mAppliedFilter.slug === 'percent_thc' || mAppliedFilter.slug === 'percent_cbd') {
                        const isFilterProcessed = finalArray.find((item: AppliedFilter)=> item.slug === mAppliedFilter.slug)
                        if(!isFilterProcessed) {
                            const dummyRangeAttribute = RangeAttribute.dummyRangeAttribute(appliedFiltersList,mAppliedFilter)
                            let tempAppliedFilterObj = new AppliedFilter()
                            tempAppliedFilterObj.init(mAppliedFilter.slug,undefined,dummyRangeAttribute.minSelected,dummyRangeAttribute.maxSelected)
                            finalArray.push(tempAppliedFilterObj)
                        }
                    } else {
                        // dummy added
                        const dummyAttribute = FilterAttribute.dummyFilterAttribute(mAppliedFilter)
                        mAppliedFilter.setDisplayValue(dummyAttribute.displayLabel())
                        finalArray.push(mAppliedFilter)
                        dummyFinalArray.push(mAppliedFilter)
                    }
                }
            } else {
            //query
            mAppliedFilter.setDisplayValue(mAppliedFilter.value)
            finalArray.push(mAppliedFilter)
            }
        })  


        // Assigning Index
        const length = finalArray.length
        for (var i = 0; i < length ; i++) {
          let mFilter = finalArray[i]
          mFilter.setIndex(i)
        }
        // console.log('POST appliedFiltersList UI appliedFilters:'+JSON.stringify(finalArray))
        // console.log('POST appliedFiltersList UI dummyFinalArray:'+JSON.stringify(dummyFinalArray))
        return { appliedFilters: finalArray, dummyAppliedFilters: dummyFinalArray }
    }

    public static removeAppliedFilter(filters: Filter[], appliedFiltersList: AppliedFilter[], toBeDeletedFilter:AppliedFilter): {filter: Filter,filterAttribute: FilterAttribute } {
        let selectedFilter: Filter = undefined
        let selectedFilterAttribute: FilterAttribute = undefined
        selectedFilter = filters.find((item: Filter)=> item.slug === toBeDeletedFilter.slug)
        if(selectedFilter) {
            if(Filter.rangeFiltersSlug.includes(selectedFilter.slug)) {
                // range filter
                selectedFilter.rangeAttribute.resetRange()
            } else {
                // checkbox filter
                if(toBeDeletedFilter.value) {
                    selectedFilterAttribute = selectedFilter.filterAttributes.find((item: FilterAttribute)=> item.appliedFilterValue === toBeDeletedFilter.value)
                    if(selectedFilterAttribute) {
                        selectedFilterAttribute.selected(false)
                        selectedFilter = this.onSelectOrDeselectFilterAttribute(selectedFilter,selectedFilterAttribute,false)
                    } else {
                        // child filter
                        const parentSlug = this.getParentSlugName(toBeDeletedFilter.value)
                        if(parentSlug) {
                            let parentAttribute = FilterAttribute.findParentFilterAttribute(selectedFilter.filterAttributes,parentSlug)
                            if(parentAttribute) {
                                if(parentAttribute.subAttributes && parentAttribute.subAttributes.length > 0) {
                                    selectedFilterAttribute = parentAttribute.subAttributes.find((item: FilterAttribute)=> item.appliedFilterValue === toBeDeletedFilter.value)
                                    if(selectedFilterAttribute) {
                                        selectedFilterAttribute.selected(false)
                                        selectedFilter = this.onSelectOrDeselectFilterAttribute(selectedFilter,selectedFilterAttribute,false)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return {filter: selectedFilter, filterAttribute: selectedFilterAttribute}
    }

    public static getMultipleQueries(storeId: number, appliedFiltersList: AppliedFilter[], sortingList: SortingList) {
        const facets = ["category","percent_thc","percent_cbd","aggregate_rating","activities","feelings","bucket_price","available_weights","root_types","brand"];
        const sFilters = `store_id = ${storeId}`
        let queriesList = []

        const currentSortItem = sortingList.getSelectedSortingItem()
        const searchIndex = currentSortItem.value // AlgoliaManager.shared().menuProductsIndexName();
        const sFacetFilters = FilterUtils.getFacetFilterQueries(appliedFiltersList,undefined)
        const searchQueryParam = FilterUtils.getSearchQueryParameterFilter(appliedFiltersList)

        const masterFilterQuery = {
            indexName: searchIndex,
            query: searchQueryParam,
            params: {
                hitsPerPage: 0,
                filters: sFilters, 
                facets: facets,
                facetFilters: sFacetFilters.facetFilters,
                numericFilters: sFacetFilters.numericFilters
            }
        }
        queriesList.push(masterFilterQuery)

        const uniqueSlugsList = this.uniqueAppliedFilterSlugs(appliedFiltersList);
        uniqueSlugsList.forEach((slug) => {
            const sFacetFiltersForSlug = FilterUtils.getFacetFilterQueries(appliedFiltersList,slug)
            const subfilterQuery = {
                indexName: searchIndex,
                query: searchQueryParam,
                params: {
                    hitsPerPage: 0,
                    filters: sFilters, 
                    facets: [slug],
                    facetFilters: sFacetFiltersForSlug.facetFilters,
                    numericFilters: sFacetFiltersForSlug.numericFilters
                }
            }
            queriesList.push(subfilterQuery)
        });
        return queriesList
    }

    public static uniqueAppliedFilterSlugs(appliedFiltersList: AppliedFilter[]): string[] {
        let uniqueSlugsList: string[] = [];
        const slugsList = appliedFiltersList.map(appliedFilter => (appliedFilter.slug));
        slugsList.forEach((c) => {
            if (!uniqueSlugsList.includes(c)) {
                uniqueSlugsList.push(c);
            }
        });
        return uniqueSlugsList
    }

    public static getFacetFilterQueries(appliedFiltersList: AppliedFilter[], toBeSkipSlug: string) {
        let facetFilters: Array<string>[] = []
        let numericFilters: string[] = []
        const uniqueSlugsList = this.uniqueAppliedFilterSlugs(appliedFiltersList);
        const skipSlug = (toBeSkipSlug) ? toBeSkipSlug : ''
        let skipSlugArr = ['query']
        if(skipSlug && skipSlug.length > 0) skipSlugArr.push(skipSlug)

        uniqueSlugsList.forEach((slug) => {
            if(!skipSlugArr.includes(slug)) {
                const afilterList = AppliedFilter.findAppliedFilter(slug,appliedFiltersList)
                let arr: string[] = []
                let arrN: string[] = []
                let isNumericFacets = false
                if(Filter.numericSlugs.includes(slug)) {
                    isNumericFacets = true
                }
                
                afilterList.forEach((appdFilter) => {
                    const fQueriesList = appdFilter.getFacetFilterQuery()
                    fQueriesList.forEach((f) => {
                        if(isNumericFacets) {
                            numericFilters.push(f)
                        } else {
                            arr.push(f)
                        }
                    })
                });
    
                if(isNumericFacets) {
                    // numericFilters = arrN
                } else {
                    facetFilters.push(arr)
                }
            }
        })        
        // console.log('facetFilters =>'+JSON.stringify(facetFilters))
        // console.log('numericFilters =>'+JSON.stringify(numericFilters))
        return { facetFilters: facetFilters, numericFilters: numericFilters }
    }

    public static findCategoryAttribute(filters: Filter[],attributeSlug: string) {
        let selectedFilter: Filter = undefined
        let selectedFilterAttribute: FilterAttribute = undefined
        selectedFilter = filters.find((item: Filter)=> item.type === FilterType.CATEGORIES)
        if(selectedFilter) {
            selectedFilterAttribute = selectedFilter.filterAttributes.find((item: FilterAttribute)=> item.slug === attributeSlug)
        }
        return { filter: selectedFilter, filterAttribute: selectedFilterAttribute}
    }

    public static findFilterByProductSectionModel(filters: Filter[],productSectionModel: ProductSectionModel) {
        let selectedFilter: Filter = undefined
        let selectedFilterAttribute: FilterAttribute = undefined
        selectedFilter = filters.find((item: Filter)=> item.slug === productSectionModel.filterSlug)
        if(selectedFilter) {
            selectedFilterAttribute = selectedFilter.filterAttributes.find((item: FilterAttribute)=> item.slug === productSectionModel.slug)
        }
        return { filter: selectedFilter, filterAttribute: selectedFilterAttribute}
    }

    public static getSearchQueryParameterFilter(appliedFiltersList: AppliedFilter[]): string {
        let searchQueryAppldFilter: AppliedFilter = undefined
        let searchParamString = ''
        const results = AppliedFilter.findAppliedFilter('query',appliedFiltersList)
        if(results.length > 0) {
            searchQueryAppldFilter = results[0]
            searchParamString = searchQueryAppldFilter.value
        }
        return searchParamString
    }

    public static isPriceSortingApplied(): boolean {
        const lTohKey = `${SortingList.sortByKey}=${SortingList.priceAsc}`
        const hTolKey = `${SortingList.sortByKey}=${SortingList.priceDesc}`
        if(window.location.search.includes(lTohKey) || window.location.search.includes(hTolKey)) {
            return true;
        }
        return false;
    }

    public static isPriceLowToHigh(): boolean {
        const lTohKey = `${SortingList.sortByKey}=${SortingList.priceAsc}`
        if(window.location.search.includes(lTohKey)) {
            return true;
        }
        return false;
    }

    public static isPriceHighToLow(): boolean {
        const hTolKey = `${SortingList.sortByKey}=${SortingList.priceDesc}`
        if(window.location.search.includes(hTolKey)) {
            return true;
        }
        return false;
    }

}