import { Box } from "@mui/system";
import arrowDownIcon from "./arrowDownIcon.svg";
import locationIcon from "./locationIcon.svg";
import PartnerManager from "../../../core/utils/PartnerManager";
import { Button } from "@mui/material";
import { useState } from "react";
import ResponsiveLocationSlider from "../../themes/effectsv2/menu_products/ResponsiveLocationSlider";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";

const LocationBtn = (): JSX.Element => {
  const [openLocationDrawer, setOpenLocationDrawer] = useState<boolean>(false);
  return (
    <>
      <Box
        sx={{
          marginRight: PixelsToViewPortUnitConverter.getvw(90),
          marginLeft: PixelsToViewPortUnitConverter.getvw(90),
          paddingBottom: "10px",
          display: { sm: "none", xs: "block" },
          marginTop: "35px",
          borderBottom: "1px solid #E6E6E6",
        }}
      >
        <Button
          onClick={() => {
            setOpenLocationDrawer(!openLocationDrawer);
          }}
        >
          <img src={locationIcon} />
          <Box
            sx={{
              padding: "0 10px",
              fontSize: "14px",
              fontFamily: "Barlow",
              fontWeight: "600",
            }}
          >
            {PartnerManager.shared().currentSelectedStore.name}
          </Box>
          <img src={arrowDownIcon} />
        </Button>
      </Box>
      <ResponsiveLocationSlider
        openLocationDrawer={openLocationDrawer}
        setOpenLocationDrawer={setOpenLocationDrawer}
      />
    </>
  );
};
export default LocationBtn;
