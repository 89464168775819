import React, { useEffect, useRef, useState } from "react";
import "./ProductGridFooter.scss";
import { LoadMoreComponent } from "./LoadMoreComponent";
import { Box, IconButton} from "@mui/material";
import ProductList from '../../../../core/models/ProductList';
import MoveToTopIcon from "../../../../statics/svgs/MoveToTopIcon";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';

type ProductGridFooterProps = {
    productList: ProductList
    pOnLoadMoreTapped(productList: ProductList): void;
    pOnScrollToTopTapped(): void;
};

export const ProductGridFooter: React.FC<ProductGridFooterProps> = (props: ProductGridFooterProps) => {
    
    const handleScrollToTopClickEvent = () => {
        props.pOnScrollToTopTapped()
    };

    return(
        <Box
            className="products-grid-footer"
            sx={{
                display: 'grid',
                WebkitBoxPack: 'end',
                placeItems: 'end center',
                gridTemplateColumns: '1fr 50px',
            }}>
                <IconButton color="primary" sx={{
                        height: '50px',
                        width: '50px',
                        gridArea: '1 / 2 / auto / auto',
                        mb: '0px',
                        border: '1px solid rgb(233, 233, 233)',
                        backgroundColor: 'rgb(251, 251, 251)',
                        borderRadius: '100%',
                        boxShadow: 'rgb(220 220 220 / 50%) 0px 0px'
                    }} onClick={handleScrollToTopClickEvent}>
                    <MoveToTopIcon
                        sx={{
                            width: '24px',
                            height: '24px',
                            fill: (theme) => theme.palette.primary.main,
                        }}
                    />
                </IconButton>
                {(props.productList.products.length < props.productList.productsCount) && 
                    <LoadMoreComponent 
                        productList={props.productList}
                        pOnLoadMoreTapped={props.pOnLoadMoreTapped}/>}
        </Box>      
    )
}





