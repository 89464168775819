import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PixelsToViewPortUnitConverter from "../utils/PixelsToViewPortUnitConverter";

export const SnackbarWithIcon = ({ icon, action, message, ...props }) => {
  return (
    <Snackbar {...props}>
      <Alert
        icon={icon ?? false}
        action={action ?? false}
        sx={{
          fontFamily: "Barlow",
          fontWeight: "600",
          fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
          color: "#000000",
          letterSpacing: "0.1em",
          backgroundColor: "#55FFB8",
          alignItems: "center",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
