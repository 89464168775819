import React from "react";
import "./FiltersContainer.scss";
import { FilterMenuItem } from "./FilterMenuItem";
import Filter, { FilterType } from "../../../../core/models/Filter";
import FilterAttribute from "../../../../core/models/FilterAttribute";
import RangeAttribute from "../../../../core/models/RangeAttribute";
import { ButtonBase, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import AppliedFilter from "../../../../core/models/AppliedFilter";

type FiltersContainerProps = {
  pFilters: Filter[];
  appliedFilterList: AppliedFilter[];
  pOnFilterHeaderTapped(filter: Filter): void;
  pOnSelectFilterAttribute(
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ): void;
  pOnSelectRangeAttribute(filter: Filter, rangeAttribute: RangeAttribute): void;
  pOnResetClicked(): void;
};

export const FiltersContainer: React.FC<FiltersContainerProps> = (
  props: FiltersContainerProps
) => {
  const handleResetClicked = (event: React.MouseEvent<HTMLElement>) => {
    props.pOnResetClicked();
  };

  const renderFilterItem = (filter: Filter) => {
    return (
      <FilterMenuItem
        pFilter={filter}
        pOnFilterHeaderTapped={props.pOnFilterHeaderTapped}
        pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
        key={filter.slug}
        pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
      />
    );
  };

  const renderFilters = (filters: Filter[]) => {
    return filters.map((filter: Filter) => renderFilterItem(filter));
  };

  const renderResetButton = () => {
    const shouldVisible =
      !props.appliedFilterList ||
      (props.appliedFilterList && props.appliedFilterList.length == 0)
        ? false
        : true;
    // if(!props.appliedFilterList || (props.appliedFilterList && props.appliedFilterList.length == 0)) return null
    return (
      <Box
        className="reset-btn-main-container"
        sx={{
          visibility: shouldVisible ? "visible" : "hidden",
          justifyContent: "flex-start",
          display: {
            sm: "block !important",
            xs: "none !important",
          },
        }}
      >
        <ButtonBase className="reset-btn-base" onClick={handleResetClicked}>
          <Box className="reset-container">
            <Typography
              variant="h1"
              className="reset-title"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
            >
              {`Reset Filters`}
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
    );
  };

  return (
    <div className="product-filters-inner-container">
      {renderResetButton()}
      {renderFilters(props.pFilters)}
    </div>
  );
};
