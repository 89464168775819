import FilterAttribute from './FilterAttribute';
import FilterUtils from '../utils/FilterUtils';
import RangeAttribute from './RangeAttribute';
import AppliedFilter from './AppliedFilter';

export enum FilterType {
    ROOT_CATEGORIES = "ROOT_CATEGORIES",
    BRANDS = "BRANDS",
    LINEAGE = "LINEAGE",
    FEELINGS = "FEELINGS",
    ACTIVITIES = "ACTIVITIES",
    CATEGORIES = "CATEGORIES",
    CBD_POTENCY = "CBD_POTENCY",
    THC_POTENCY = "THC_POTENCY",
    PRICE = "PRICE",
    CUSTOMER_REVIEWS = "CUSTOMER_REVIEWS",
    AVAILABLE_WEIGHTS = "AVAILABLE_WEIGHTS"
}

export type FilterQuery = {
  hitsPerPage?: number,
  filters?: string,
  facets?: Array<string>,
  page?: number
}

export default class Filter {
  
    private mIndex: number;
    public get index() {
      return this.mIndex;
    }

    private mName: string;
    public get name() {
      return this.mName;
    }

    private mSlug: string;
    public get slug() {
      return this.mSlug;
    }

    private mRank: number;
    public get rank() {
      return this.mRank;
    }

    private mType: FilterType;
    public get type() {
      return this.mType;
    }

    private mFilterAttributes: FilterAttribute[];
    public get filterAttributes() {
      return this.mFilterAttributes;
    }

    private mRangeAttribute: RangeAttribute;
    public get rangeAttribute() {
      return this.mRangeAttribute;
    }

    private mSelected: boolean;
    public get isSelected() {
      return this.mSelected;
    }

    private mLimit: number;
    public get limit() {
      return this.mLimit;
    }

    private mShowMore: boolean;
    public get showMore() {
      return this.mShowMore;
    }

    private mShowMoreLimit: number;
    public get showMoreLimit() {
      return this.mShowMoreLimit;
    }

    private mMultiSelectAllowed: boolean;
    public get isMultiSelectAllowed() {
      return this.mMultiSelectAllowed;
    }

    public static filtersSlugMaster = [
      "category",
      "percent_thc",
      "percent_cbd",
      "root_types",
      "aggregate_rating",
      "activities",
      "feelings",
      "bucket_price",
      "available_weights",
      "brand",
      "applicable_special_ids"
    ]
    public static singleSelectedFilters = ["aggregate_rating","available_weights","bucket_price","percent_cbd","percent_thc","applicable_special_ids"]
    public static rangeFiltersSlug = ['percent_thc','percent_cbd']
    public static numericSlugs = ['bucket_price','aggregate_rating','percent_thc','percent_cbd']
    
    public constructor() {}

    public init(filterItem: any) {
      this.mSlug = filterItem.filterSlug
      this.mType = filterItem.filterType
      this.mName = filterItem.filterHeaderTitle
      this.mSelected = true //default true
      this.mLimit = -1
      this.mShowMore = false
      this.mShowMoreLimit = 1000
      this.mMultiSelectAllowed = true

      // if(this.mType === FilterType.BRANDS) {
      //   this.mLimit = 5 // items to be shown including all
      //   this.mShowMore = true
      //   this.mShowMoreLimit = 1000
      // }

      if(Filter.singleSelectedFilters.includes(this.mSlug)) {
        this.mMultiSelectAllowed = false
      }
    }

    public selected(isSelected: boolean) {
        this.mSelected = isSelected
    }

    public setFilterAttributes(filterAttributes: FilterAttribute[]) {
      this.mFilterAttributes = filterAttributes
    }

    public setRangeAttribute(rangeAttribute: RangeAttribute) {
      this.mRangeAttribute = rangeAttribute
    }

    public static list(response: any, appliedFiltersList: AppliedFilter[]): Filter[] {
        let finalArray: Filter[] = [];
        if(response && 
            response.facets &&
            Object.keys(response.facets).length > 0) {
              const facetItems = response.facets
              Object.entries(facetItems).forEach(
                ([filterSlug, filterValues]) =>  {
                    let filterType = FilterUtils.getFilterEnums(filterSlug).filterType
                    let filterHeaderTitle = FilterUtils.getFilterEnums(filterSlug).filterHeaderTitle
                    if(filterType) {
                        let rangeAttribute: RangeAttribute = undefined
                        let filterAttributesList: FilterAttribute[] = undefined
                        const filterRank = FilterUtils.getFilterRank(filterType)
                        

                        let filterObj: Filter = new Filter()
                        filterObj.mRank = filterRank
                        filterObj.init({filterSlug,filterType,filterHeaderTitle})
                        let items = filterValues
                        if(filterObj.type == FilterType.THC_POTENCY ||
                          filterObj.type == FilterType.CBD_POTENCY) {
                            rangeAttribute = RangeAttribute.initWithRangeProperties(items,filterObj,appliedFiltersList)
                        } else {
                            filterAttributesList = FilterAttribute.list(items,filterObj,appliedFiltersList)
                        }

                        if(rangeAttribute && rangeAttribute.max > 0) {
                          // to display valid range on UI
                          filterObj.mRangeAttribute = rangeAttribute
                          finalArray.push(filterObj)
                        } else if(filterAttributesList && filterAttributesList.length > 0) {
                          filterObj.mFilterAttributes = filterAttributesList
                          finalArray.push(filterObj)
                        }                        
                    }
                }
              );
        }
        if(finalArray.length > 0) {
          finalArray.sort((a, b) => (a.rank > b.rank ? 1 : -1))
        }

        // Assigning Index
        const length = finalArray.length
        for (var i = 0; i < length ; i++) {
          let mFilter = finalArray[i]
          mFilter.mIndex = i
        }
        return finalArray;
    }

    // Find all selected FilterAttribute
    private getSelectedFilterAttributes(): FilterAttribute[] {
      let filterAttributesArr: FilterAttribute[] = this.mFilterAttributes.filter((item: FilterAttribute)=> item.isAll === false && item.isSelected == true);
      if(filterAttributesArr && filterAttributesArr.length > 0) {
        return filterAttributesArr
      }
      return []
    }

    // Find all selected child FilterAttribute
    private getSelectedSubFilterAttributes(): FilterAttribute[] {
      let subFilterAttributesArr: FilterAttribute[] = []
      this.filterAttributes.forEach((parentFilterAttribute,i) => {
        if(parentFilterAttribute.subAttributes.length > 0) {
          const subAttributes = parentFilterAttribute.subAttributes
          let filterAttributesArr: FilterAttribute[] = subAttributes.filter((item: FilterAttribute)=> item.isAll === false && item.isSelected == true);
          if(filterAttributesArr && filterAttributesArr.length > 0) {
            subFilterAttributesArr = subFilterAttributesArr.concat(filterAttributesArr)
          }
        }
      })
      return subFilterAttributesArr
    }

    public getQuery() {
      let queryFilters = undefined
      if(this.mType !== FilterType.CBD_POTENCY &&
        this.mType !== FilterType.THC_POTENCY) {
        let filterAttributesArr = this.getSelectedFilterAttributes();
        let arr = []
        const operator = 'OR'
        for(let fAttrib of filterAttributesArr) {
          let query = fAttrib.getQuery()
          if(query) {
            arr.push(query)
          }
        }

        if(this.mType === FilterType.CATEGORIES) {
          // child filters
          let filterSubAttributesArr = this.getSelectedSubFilterAttributes();
          for(let fSubAttrib of filterSubAttributesArr) {
            let query = fSubAttrib.getQuery()
            if(query) {
              arr.push(query)
            }
          }
        }
        if(arr.length > 0) {
            queryFilters = (arr.length > 1) ? arr.join(` ${operator} `) : arr[0];
        }
      } else {
        const rangeFilterAttrib = this.rangeAttribute
        if(rangeFilterAttrib) {
          queryFilters = rangeFilterAttrib.getQuery(this)
        }
      }
      return queryFilters
    }
}