import React, { useEffect, useState } from "react";
import "./ProductCardOverlay.scss";
import { alpha, ButtonBase, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { Box } from "@mui/system";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import RoundMinus from "../../../../statics/svgs/RoundMinus";
import RoundPlus from "../../../../statics/svgs/RoundPlus";
import Button from '@mui/material/Button';
import Product, { CartProduct } from "../../../../core/models/Product";
import Weight from '../../../../core/models/Weight';
import { useElementSize } from 'usehooks-ts'
import { Visibility } from "@mui/icons-material";
import { WeightListComponent } from "./WeightListComponent";
import DimensionUtils from '../../../../core/utils/DimensionUtils';
import { Cart } from '../../../common/cart/CartReducer';
import { WeightCarousel } from "./WeightCarousel";

type ProductCardOverlayProps = {
    product: Product;
    overlayHeight: number;
    overlayWidth: number;
    display: boolean;
    pCart: Cart | undefined;
    pOnProductOverlayWidthUpdate(width: number, height: number): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

export const ProductCardOverlay: React.FC<ProductCardOverlayProps> = (props: ProductCardOverlayProps) => {

    const minimumQuantity = 1
    let defSelectedWeight = props.product.getSelectedWeight()
    let defQuantity = minimumQuantity
    let sIsAddedToCart = false
    let sAddedPriceId = undefined
    if(props.pCart && props.pCart.products.length > 0) {
        const productId = props.product.id
        const itemFound = props.pCart.products.find((item: CartProduct)=> item.productId == productId);
        if(itemFound) {
            sAddedPriceId = itemFound.priceId
            const sWeight = props.product.getSelectedWeightByPriceId(itemFound.priceId)
            if(sWeight) {
                defQuantity = itemFound.count
                defSelectedWeight = sWeight
                sIsAddedToCart = true
            }
        }
    }

    const [boxRef, { width, height }] = useElementSize()
    const [quantity, setQuantity] = useState(minimumQuantity)
    const [weightDimension, setWeightDimension] = useState(DimensionUtils.getWeightCarouselCardDimension(props.overlayWidth,props.product.getAllWeightsForProductCard().length))
    const [selectedWeight, setSelectedWeight] = useState(defSelectedWeight)
    const [isAddedToCart, setIsAddedToCart] = useState(sIsAddedToCart)
    const [addedPriceId, setAddedPriceId] = useState(sAddedPriceId)
    const className = `${(props.display) ? 'cmenu-product-card-overlay -expanded' : 'cmenu-product-card-overlay'}`

    useEffect(() => {
        // console.log('4.    ProductCardOverlay =>'+JSON.stringify(props.pCart))
        updateProductCartInfo(props.pCart)
    },[props.pCart])

    const updateProductCartInfo = (pCart: Cart|undefined) => {
        const productId = props.product.id
        let oQauntity = minimumQuantity
        let oWeight = props.product.getSelectedWeight()
        let oIsAddedTocart = false
        if(pCart) {
            const itemFound = pCart.products.find((item: CartProduct)=> item.productId == productId);
            if(itemFound) {
                const sWeight = props.product.getSelectedWeightByPriceId(itemFound.priceId)
                if(sWeight) {
                    oQauntity = itemFound.count
                    oWeight = sWeight
                    oIsAddedTocart = true
                }
            }
        }
        setQuantity(oQauntity)
        setSelectedWeight(oWeight)
        setIsAddedToCart(oIsAddedTocart)
    }

    useEffect(() => {
        const parentDivWidth = props.overlayWidth - 4
        setWeightDimension(DimensionUtils.getWeightCarouselCardDimension(parentDivWidth,props.product.getAllWeightsForProductCard().length))
        if(width > 0 && height > 0) {
            props.pOnProductOverlayWidthUpdate(parentDivWidth,height)
        } else {
            props.pOnProductOverlayWidthUpdate(parentDivWidth,height)
        }
    },[boxRef,width,height])

    

    const handleAddToCartClick = (event: React.MouseEvent<HTMLElement>) => {
        const cartProduct: CartProduct = {
            productId: props.product.id,
            priceId: selectedWeight.priceId,
            count: quantity
        }
        props.pOnAddOrRemoveProductFromCart(cartProduct)
    }

    const increaseQuantity = () => {
        if(quantity < props.product.maxCartQuantity) {
            /*const elements = Array.from(document.getElementsByClassName('quantity-title') as HTMLCollectionOf<HTMLElement>)
            const qLabel = elements[0]
            qLabel.className += " -increase";
            qLabel.style.transform = 'scaleY(0)';
            qLabel.addEventListener('transitionend', function() {
                qLabel.className = 'quantity-title'
            });*/
            setQuantity(quantity+1)
        }
    }

    const decreaseQuantity = () => {
        if(quantity > minimumQuantity) {
            /*const elements = Array.from(document.getElementsByClassName('quantity-title') as HTMLCollectionOf<HTMLElement>)
            const qLabel = elements[0]
            qLabel.style.transform = 'scaleY(0)';
            qLabel.className += " -decrease";
            qLabel.addEventListener('transitionend', function() {
                qLabel.className = 'quantity-title'
            });*/
            setQuantity(quantity-1)
        } else if(isAddedToCart && quantity == 1) {
            setQuantity(0)
        }
    }

    const isDecreaseDisabled = () => {
        let isDisabled = (quantity > minimumQuantity) ? false : true
        if(isDisabled && isAddedToCart) {
            isDisabled = (quantity == minimumQuantity) ? false : true
          }
        return isDisabled
    }

    const isIncreaseDisabled = () => {
        const isDisabled = (quantity < props.product.maxCartQuantity) ? false : true
        return isDisabled
    }

    const renderQuantityCounter = () => {
        return(
            <Box className="quantity-container">
                <IconButton onClick={decreaseQuantity} disabled={isDecreaseDisabled()}>
                    <RoundMinus
                        sx={{
                            fill: 'none'
                        }}
                    />
                </IconButton>
                <Typography
                    variant="h1"
                    className="quantity-title"
                    sx={{
                        color: (theme) => theme.palette.primary.contrastText
                    }}>
                        {`${quantity}`}
                </Typography>
                <IconButton onClick={increaseQuantity} disabled={isIncreaseDisabled()}>
                    <RoundPlus
                        sx={{
                            fill: 'none'
                        }}
                    />
                </IconButton>  
            </Box>
        );
    }

    const renderAddToCartButton = () => {
        return(
            <Button
                sx={{
                    border: '0.6875px solid rgba(0, 0, 0, 0.98)',
                    borderColor: (theme) => theme.palette.secondary.main,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    borderRadius: PixelsToViewPortUnitConverter.getFontVw(4),
                    textTransform: 'none',
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    letterSpacing: '0.2em',
                    height: PixelsToViewPortUnitConverter.getvw(37),
                    minWidth: PixelsToViewPortUnitConverter.getvw(175),
                    marginTop: PixelsToViewPortUnitConverter.getvw(10),
                    paddingTop: PixelsToViewPortUnitConverter.getvw(6),
                    paddingBottom: PixelsToViewPortUnitConverter.getvw(6),
                    paddingRight: PixelsToViewPortUnitConverter.getvw(17),
                    paddingLeft: PixelsToViewPortUnitConverter.getvw(17),
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(12),
                    lineHeight: PixelsToViewPortUnitConverter.getFontVw(14),
                    color: (theme) => alpha(theme.palette.secondary.contrastText,1.0),
                    ':hover': {
                        // color: (theme) => theme.palette.primary.main,
                        backgroundColor: (theme) => alpha(theme.palette.secondary.main,0.6),
                    }
                }}
                variant="outlined" 
                onClick={handleAddToCartClick}>
                {!isAddedToCart ? `ADD TO CART` : (quantity !== 0) ? `UPDATE` : `REMOVE`}
            </Button>
        )
    }

    const renderWeightListComp = () => {
        return(
            <WeightListComponent 
                product={props.product} 
                weight={selectedWeight}
                weightDimension={weightDimension} 
                display={props.display}
                pOnWeightSelected={(w) => {
                    setSelectedWeight(w)
                }}/>
        )
    }

    const renderWeightCarouselComp = () => {
        return(
            <WeightCarousel 
                product={props.product} 
                pSelectedPriceId={addedPriceId}
                weight={selectedWeight}
                weightDimension={weightDimension} 
                display={props.display}
                pOnWeightSelected={(w) => {
                    setSelectedWeight(w)
                }}
                pOnAddOrRemoveProductFromCart={(p) => {
                    setAddedPriceId(p.priceId)
                    props.pOnAddOrRemoveProductFromCart(p)
                }}/>
        )
    }

    return(
          <Box  
                ref={boxRef}
                className={className}
                sx={{
                    display:`${(props.display) ? 'flex' : 'flex'}`,
                    visibility:`${(props.display) ? 'visible' : 'visible'}`,
                }}>
                {/* {renderQuantityCounter()}
                {renderAddToCartButton()} */}
                {/* {renderWeightListComp()} */}
                {renderWeightCarouselComp()}
          </Box>     
    )
}





