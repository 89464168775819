import React, { useState } from "react";
import "./FilterMenuItem.scss";
import { RangeSlider } from "./RangeSlider";
import { FilterHeader } from "./FilterHeader";
import { CheckboxFilter } from "./CheckboxFilter";
import Accordion from "@mui/material/Accordion";
import Filter, { FilterType } from "../../../../core/models/Filter";
import FilterAttribute from "../../../../core/models/FilterAttribute";
import RangeAttribute from "../../../../core/models/RangeAttribute";
import { Box } from "@mui/material";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";

type FilterMenuItemProps = {
  pFilter: Filter;
  pOnFilterHeaderTapped(filter: Filter): void;
  pOnSelectFilterAttribute(
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ): void;
  pOnSelectRangeAttribute(filter: Filter, rangeAttribute: RangeAttribute): void;
};

export const FilterMenuItem: React.FC<FilterMenuItemProps> = (
  props: FilterMenuItemProps
) => {
  const renderFilterDetails = (filter: Filter) => {
    if (
      filter.type !== FilterType.THC_POTENCY &&
      filter.type !== FilterType.CBD_POTENCY
    ) {
      return (
        <CheckboxFilter
          pFilter={filter}
          pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
        />
      );
    } else {
      return (
        <RangeSlider
          pFilter={filter}
          pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
        />
      );
    }
  };

  const renderFilterItem = (filter: Filter) => {
    return (
      <Accordion
        key={filter.slug}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
        expanded={filter.isSelected}
        disableGutters
      >
        <FilterHeader
          filter={filter}
          onFilterHeaderTapped={props.pOnFilterHeaderTapped}
        />
        {renderFilterDetails(filter)}
      </Accordion>
    );
  };

  return (
    <Box
      className="cmenu-product-filter-item-container"
      sx={{
        marginBottom: {
          sm: PixelsToViewPortUnitConverter.getvw(11),
          xs: PixelsToViewPortUnitConverter.getvw(70),
        },
      }}
      key={props.pFilter.slug}
    >
      {renderFilterItem(props.pFilter)}
    </Box>
  );
};
