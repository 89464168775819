import Filter from './Filter';
import AppliedFilter from './AppliedFilter';
import FilterUtils from '../utils/FilterUtils';

export default class RangeAttribute {
    private mMin: number;
    public get min() {
      return this.mMin;
    }

    private mMax: number;
    public get max() {
      return this.mMax;
    }

    private mSteps: number;
    public get steps() {
      return this.mSteps;
    }

    private mMaxSelected: number;
    public get maxSelected() {
      return this.mMaxSelected;
    }

    private mMinSelected: number;
    public get minSelected() {
      return this.mMinSelected;
    }

    private mFilterSlug: string;
    public get filterSlug() {
      return this.mFilterSlug;
    }

    public constructor() {
        this.mMin = 0
        this.mMax = 0
        this.mMaxSelected = 0
        this.mMinSelected = 0
    }

    public init() {
        
    }

    public selected(min: number,max: number) {
      this.mMinSelected = min
      this.mMaxSelected = max
    }

    public isAnyRangeApplied(): boolean {
      let isRangeApplied = false
      if(this.mMin !== this.mMinSelected || this.mMax !== this.mMaxSelected) {
        isRangeApplied = true 
      }
      return isRangeApplied
    }

    public isBothRangeApplied(): boolean {
      let isRangeApplied = false
      if(this.mMin !== this.mMinSelected && this.mMax !== this.mMaxSelected) {
        isRangeApplied = true 
      }
      return isRangeApplied
    }

    public isMinRangeApplied(): boolean {
      let isRangeApplied = false
      if(this.mMin !== this.mMinSelected) {
        isRangeApplied = true 
      }
      return isRangeApplied
    }

    public isMaxRangeApplied(): boolean {
      let isRangeApplied = false
      if(this.mMax !== this.mMaxSelected) {
        isRangeApplied = true 
      }
      return isRangeApplied
    }

    public resetRange() {
      this.mMaxSelected = 0
      this.mMinSelected = 0
    }

    public static initWithRangeProperties(attributeItems: any,filter: Filter, appliedFiltersList: AppliedFilter[]): RangeAttribute  {
        let rangeAttribute: RangeAttribute = new RangeAttribute()
        const percentValues = Object.keys(attributeItems) as unknown as number[];
        const maxPercent = Math.round(Math.max(...percentValues));
        const minPercent = Math.round(Math.min(...percentValues));
        const interval = 100/maxPercent;
        rangeAttribute.mMax = maxPercent
        rangeAttribute.mMin = minPercent
        rangeAttribute.mSteps = interval
        rangeAttribute.mFilterSlug = filter.slug
        const minMaxRange = AppliedFilter.findMinMaxFromRangeAppliedFilters(appliedFiltersList, filter.slug)
        rangeAttribute.selected(minMaxRange.min,minMaxRange.max)
        return rangeAttribute
    }

    public getQuery(filter: Filter): string {
        if(this.minSelected === 0 && this.maxSelected === 0) return undefined
        let array = []
        if(this.minSelected > 0) {
            array.push(`${filter.slug} <= ${this.minSelected}`)
        }
        if(this.maxSelected > 0) {
          array.push(`${filter.slug} <= ${this.maxSelected}`)
        }
        if(array.length > 1) {
          return array.join(` ${"AND"} `);
        } else {
          return array[0]
        }
    }

    public static dummyRangeAttribute(appliedFiltersList: AppliedFilter[],appliedFilter: AppliedFilter) {
      const minMaxRange = AppliedFilter.findMinMaxFromRangeAppliedFilters(appliedFiltersList, appliedFilter.slug)
      let dummyRangeAttribute: RangeAttribute = new RangeAttribute()
      const maxPercent = minMaxRange.max;
      const minPercent = minMaxRange.min;
      const interval = 100/maxPercent;
      dummyRangeAttribute.mMax = maxPercent
      dummyRangeAttribute.mMin = minPercent
      dummyRangeAttribute.mSteps = interval
      dummyRangeAttribute.mFilterSlug = appliedFilter.slug
      dummyRangeAttribute.selected(minMaxRange.min,minMaxRange.max)
      return dummyRangeAttribute
    }
}