import React from "react";
import "./ProductListHeader.scss";
import {
  alpha,
  ButtonBase,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import { Box } from "@mui/system";
import SortingList from "../../../../core/models/SortingList";
import SortingItem from "../../../../core/models/SortingItem";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import DownArrowIcon from "../../../../statics/svgs/DownArrowIcon";

type SortingOptionProps = {
  pSortingList: SortingList;
  pOnSelectSortingOption(sortingItem: SortingItem): void;
};

export const SortingOption: React.FC<SortingOptionProps> = (
  props: SortingOptionProps
) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedItem = props.pSortingList.getSortingItemByValue(
      event.target.value
    );
    props.pOnSelectSortingOption(selectedItem);
  };

  const renderSortingList = () => {
    const items = props.pSortingList.sortingList;
    return items.map((item: SortingItem, index: number) =>
      renderSortingItem(item, index)
    );
  };

  const renderSortingItem = (item: SortingItem, index: number) => {
    // console.log('renderSortingItem start:'+index)
    // console.log('name :'+item.name)
    // console.log('value :'+item.value)
    // console.log('selected :'+item.selected)
    return (
      <MenuItem
        key={index.toString()}
        sx={{
          fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
          lineHeight: PixelsToViewPortUnitConverter.getFontVw(17),
          fontWeight: "500",
          fontFamily: "Barlow",
          color: (theme) => theme.palette.secondary.main,
        }}
        value={item.value}
      >
        {item.name}
      </MenuItem>
    );
  };

  const renderSelectedValue = (item: SortingItem) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: PixelsToViewPortUnitConverter.getFontVw(19),
            lineHeight: PixelsToViewPortUnitConverter.getFontVw(23),
            fontWeight: "400",
            fontFamily: "Bebas",
            fontStyle: "normal",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          {"Sort by"}
        </Typography>
        <Typography
          sx={{
            pl: PixelsToViewPortUnitConverter.getFontVw(10),
            fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
            lineHeight: PixelsToViewPortUnitConverter.getFontVw(17),
            fontWeight: "500",
            fontFamily: "Barlow",
            fontStyle: "normal",
            letterSpacing: "0.1em",
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          {item.name}
        </Typography>
      </Box>
    );
  };

  return (
    <ButtonBase
      sx={{
        "&:hover": {
          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.04),
        },
      }}
    >
      <FormControl
        sx={{
          m: 1,
          mt: 0,
          mb: 0,
          mr: 0,
          minWidth: PixelsToViewPortUnitConverter.getFontVw(150),
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: 0,
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: 0,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
              borderRadius: 0,
            },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: 0,
          },
        }}
      >
        <Select
          value={`${props.pSortingList.getSelectedSortingItem().value}`}
          onChange={handleChange}
          displayEmpty
          sx={{
            height: 42,
          }}
          SelectDisplayProps={{
            style: {
              minHeight: 0,
              padding: PixelsToViewPortUnitConverter.getvw(0),
              paddingRight: PixelsToViewPortUnitConverter.getFontVw(0),
            },
          }}
          renderValue={(selected) => {
            return renderSelectedValue(
              props.pSortingList.getSelectedSortingItem()
            );
          }}
          IconComponent={() => (
            <DownArrowIcon
              sx={{
                fill: "none",
                width: PixelsToViewPortUnitConverter.getFontVw(12),
                height: PixelsToViewPortUnitConverter.getFontVw(12),
                stroke: (theme) => theme.palette.secondary.main,
              }}
              style={{
                color: "#fff",
                paddingRight: PixelsToViewPortUnitConverter.getFontVw(0),
              }}
            />
          )}
        >
          {renderSortingList()}
        </Select>
      </FormControl>
    </ButtonBase>
  );
};
