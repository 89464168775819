import { Helmet } from "react-helmet";
interface ISeoDisallowPageConfig {
  pTitle: string;
}
export function SeoDisallowPageConfig(props: ISeoDisallowPageConfig) {
  return (
    <Helmet>
      <title>{props.pTitle}</title>
      <meta name="robots" content="noindex,follow" data-rh="true"></meta>
    </Helmet>
  );
}
