import React from "react";
import GoogleMapReact, { Coords } from "google-map-react";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PinIcon from "../../../../statics/svgs/PinIcon";
import IconButton from "@mui/material/IconButton/IconButton";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Typography from "@mui/material/Typography/Typography";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";

const MarkerTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFCFC",
    color: "#000000",
    fontSize: PixelsToViewPortUnitConverter.getFontVw(30),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFCFC",
  },
}));

export default class StoreLocationMapComponent extends React.Component<
  any,
  any
> {
  static defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 17,
    zoomControl: false,
    fullscreenControl: false,
  };

  render() {
    return (
      <div
        style={{
          marginTop: PixelsToViewPortUnitConverter.getvw(118),
          width: "100%",
          height: PixelsToViewPortUnitConverter.getvh(685),
          maxHeight: PixelsToViewPortUnitConverter.getvw(685),
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBRyoewfQ9snnnx59ZsLeJletbzt9bqVDk" }}
          defaultCenter={{
            lat: PartnerManager.shared().currentSelectedStore.latitude,
            lng: PartnerManager.shared().currentSelectedStore.longitude,
          }}
          defaultZoom={StoreLocationMapComponent.defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          draggable={false}
          options={{ zoomControl: false, fullscreenControl: false }}
        >
          <Marker
            lat={PartnerManager.shared().currentSelectedStore.latitude}
            lng={PartnerManager.shared().currentSelectedStore.longitude}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

class Marker extends React.Component<any, any> {
  render() {
    return (
      <div style={{ height: "500px", width: "100px" }}>
        <MarkerTooltip
          placement="top"
          arrow
          title={
            <React.Fragment>
              <Typography
                textAlign="center"
                color="inherit"
                fontFamily="Overpass"
                fontWeight="700"
              >
                {PartnerManager.shared().currentSelectedStore.name}
              </Typography>
              <Typography
                textAlign="center"
                color="inherit"
                fontFamily="Overpass"
                fontWeight="400"
              >
                {PartnerManager.shared().currentSelectedStore.fullAddress}
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton>
            <PinIcon
              sx={{
                width: PixelsToViewPortUnitConverter.getvw(52),
                height: PixelsToViewPortUnitConverter.getvw(39),
              }}
              viewBox="0 0 52 39"
            />
          </IconButton>
        </MarkerTooltip>
      </div>
    );
  }
}
