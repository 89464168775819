import React from "react";
import "./CustomerVerifyCode.scss";
import withRouter from "../../../withRouter";
import { NavigationProps, NavigationState } from "../../../Navigation.types";
import Navigation from "../../../Navigation";
import { Box, IconButton } from "@mui/material";
import VerifyOTPComponent from "../../themes/effectsv2/verify_otp/VerifyOTPComponent";
import BackIconV2 from "../../../statics/svgs/BackIconV2";
import BreadcrumbsComponent from "../../themes/effectsv2/breadcrumbs/BreadcrumbsComponent";

interface CustomerVerifyCodeProps {
  router: NavigationProps;
}

interface CustomerVerifyCodeState {
  username: string;
  password: string;
}

class CustomerVerifyCode extends React.Component<
  CustomerVerifyCodeProps,
  CustomerVerifyCodeState
> {
  constructor(props: CustomerVerifyCodeProps) {
    super(props);
    const navState = props.router.location.state as NavigationState;
    this.state = {
      username: navState.username || "",
      password: navState.password || "",
    };
  }

  componentDidMount() {}

  private handleSignUpCompleted() {
    Navigation.toProfile(this.props.router);
  }

  private handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    Navigation.back(this.props.router);
  };

  private renderVerifyCodeEffectV2ThemeContainer() {
    return (
      <VerifyOTPComponent
        userName={this.state.username}
        password={this.state.password}
        signUpandSignIn={true}
        onVerificationSuccessful={() => {
          this.handleSignUpCompleted();
        }}
      />
    );
  }

  render() {
    return (
      <Box
        className="customer-verify-code-container"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Box
          className="signup-inner-container"
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Box className="left-container">
            <IconButton
              onClick={(e) => {
                this.handleBackClick(e);
              }}
            >
              <BackIconV2
                sx={{
                  fill: "none",
                  stroke: (theme) => theme.palette.primary.contrastText,
                }}
              />
            </IconButton>
          </Box>
          <Box className="center-container">
            <BreadcrumbsComponent
              router={this.props.router}
              isSectionExpanded={false}
            />
            {this.renderVerifyCodeEffectV2ThemeContainer()}
          </Box>
          <Box className="right-container"></Box>
        </Box>
      </Box>
    );
    return null;
  }
}

export default withRouter(CustomerVerifyCode);
