import Button from "@mui/material/Button/Button";
import IconButton from "@mui/material/IconButton/IconButton";
import Stack from "@mui/material/Stack/Stack";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../core/webservice/ApiError";
import Navigation from "../../../Navigation";
import UserAuthService from "../../../services/UserAuthService";
import BackIconV2 from "../../../statics/svgs/BackIconV2";
import withRouter from "../../../withRouter";
import BreadcrumbsComponent from "../../themes/effectsv2/breadcrumbs/BreadcrumbsComponent";
import ReservationsComponent from "./ReservationsComponent";
import { alpha } from "@mui/material/styles";
import AccountDetailsComponent from "./AccountDetailsComponent";
import Box from "@mui/material/Box/Box";

function ProfileComponent(props) {
  return (
    <Stack
      sx={{
        width: "100%",
        position: "relative",
        paddingY: PixelsToViewPortUnitConverter.getvw(116),
      }}
    >
      <Stack
        direction="row"
        sx={{
          marginLeft: PixelsToViewPortUnitConverter.getvw(80),
          marginTop: PixelsToViewPortUnitConverter.getvw(24),
        }}
      >
         <Box display={{xs:"none", lg: "block"}}>
          <IconButton
          onClick={() => {
            Navigation.back(props.router);
          }}
        >
          <BackIconV2
            sx={{
              fill: "none",
              stroke: (theme) => theme.palette.primary.contrastText,
            }}
          />
        </IconButton>
        </Box>

        <Box
          sx={{
            position: "absolute",
            marginLeft: PixelsToViewPortUnitConverter.getvw(110),
            display: "flex",
            alignSelf: "center",
          }}
        >
          <BreadcrumbsComponent
            router={props.router}
            isSectionExpanded={false}
          />
        </Box>
        <Button
          variant="outlined"
          sx={{
            width: PixelsToViewPortUnitConverter.getvw(144),
            height: PixelsToViewPortUnitConverter.getvw(40),
            borderWidth: PixelsToViewPortUnitConverter.getvw(1),
            borderColor: (theme) => alpha(theme.palette.secondary.main, 0.22),
            marginLeft: "auto",
            marginRight: PixelsToViewPortUnitConverter.getvw(73),
            marginTop: {
              sm: PixelsToViewPortUnitConverter.getvw(1),
              xs:PixelsToViewPortUnitConverter.getvw(103),
            },
            paddingTop: {
              sm: PixelsToViewPortUnitConverter.getvw(0),
              xs:PixelsToViewPortUnitConverter.getvw(40),
            },
            paddingBottom: {
              sm: PixelsToViewPortUnitConverter.getvw(0),
              xs:PixelsToViewPortUnitConverter.getvw(40),
            },
            position:{
              xs:"absolute", sm:"unset"
            }, right:{xs:'0', sm:"unset",},
            zIndex:{
              xs:"999",
              sm:"unset",
            }
            
          }}
          onClick={() => {
            UserAuthService.signOut()
              .then(() => {
                Navigation.logOut(props.router);
              })
              .catch((error: ApiError) => {});
          }}
        >
          LOG OUT
        </Button>
      </Stack>
      <Stack
        sx={{
          position: "relative",
          paddingX: {
          sm: PixelsToViewPortUnitConverter.getvw(200),
          xs: PixelsToViewPortUnitConverter.getvw(100),
            },

        }}
      >
        <AccountDetailsComponent />
        <ReservationsComponent router={props.router} />
      </Stack>
    </Stack>
  );
}
export default withRouter(ProfileComponent);
