import { CartProduct } from "../../../core/models/Product";
import { Cart } from "./CartReducer";

export enum CartActionType {
  ADD_TO_CART_OR_UPDATE = "ADD_TO_CART_OR_UPDATE",
  CART_CREATE_OR_UPDATE = "CART_CREATE_OR_UPDATE",
}

interface actionAddToCartOrUpdate {
  type: CartActionType.ADD_TO_CART_OR_UPDATE;
  payload: CartProduct;
}

interface actionCartCreateOrUpdate {
  type: CartActionType.CART_CREATE_OR_UPDATE;
  payload: Cart | undefined;
}

export type CartAction = actionAddToCartOrUpdate | actionCartCreateOrUpdate;
