import React, { useState } from "react";
import "./CheckboxFilter.scss";
import { CheckboxChildrenFilter } from "./CheckboxChildrenFilter";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Rating from "@mui/material/Rating";
import { Button, CardActionArea, Link, Stack } from "@mui/material";
import { alpha } from "@mui/system";
import FilterAttribute from "../../../../core/models/FilterAttribute";
import Filter, { FilterType } from "../../../../core/models/Filter";
import TickIcon from "../../../../statics/svgs/TickIcon";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

type CheckboxFilterProps = {
  pFilter: Filter;
  pOnSelectFilterAttribute(
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ): void;
};

export const CheckboxFilter: React.FC<CheckboxFilterProps> = (
  props: CheckboxFilterProps
) => {
  const [showMoreRecords, setShowMoreRecords] = useState(false);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    filterAttribute: FilterAttribute
  ) => {
    if (filterAttribute.isAll && filterAttribute.isSelected) {
      event.preventDefault();
    }
    const selected = !filterAttribute.isSelected;
    props.pOnSelectFilterAttribute(props.pFilter, filterAttribute, selected);
  };

  const isShowMoreOrFewerTapped = () => {
    setShowMoreRecords(!showMoreRecords);
  };

  const renderLabel = (filterAttribute: FilterAttribute) => {
    let labelTitle = filterAttribute.name;
    if (
      !filterAttribute.isAll &&
      (props.pFilter.type == FilterType.BRANDS ||
        props.pFilter.type == FilterType.ACTIVITIES ||
        props.pFilter.type == FilterType.FEELINGS ||
        props.pFilter.type == FilterType.CATEGORIES ||
        props.pFilter.type == FilterType.PRICE)
    ) {
      if (
        props.pFilter.type == FilterType.CATEGORIES &&
        filterAttribute.subAttributes.length > 0
      ) {
        labelTitle = `> ${filterAttribute.displayName()}`;
      } else {
        labelTitle = filterAttribute.displayName();
      }
    }

    const className = `${
      filterAttribute.isSelected
        ? "checkbox-form-control-label-selected"
        : "checkbox-form-control-label"
    }`;
    return (
      <Typography
        className={className}
        variant="h1"
        sx={{
          fontSize: {
            sm: `${PixelsToViewPortUnitConverter.getFontVw(13)} !important`,
            xs: `${PixelsToViewPortUnitConverter.getFontVw(16)} !important`,
          },
          color: (theme) =>
            filterAttribute.isSelected
              ? alpha(theme.palette.secondary.main, 1.0)
              : alpha(theme.palette.secondary.main, 1.0),
          [`&, & .MuiTypography-root`]: {
            color: (theme) =>
              filterAttribute.isSelected
                ? alpha(theme.palette.secondary.main, 1.0)
                : alpha(theme.palette.secondary.main, 1.0),
          },
        }}
      >
        {labelTitle}
      </Typography>
    );
  };

  const renderRatingLabel = (filterAttribute: FilterAttribute) => {
    if (props.pFilter.type == FilterType.CUSTOMER_REVIEWS) {
      const ratingValue = Number(filterAttribute.name.charAt(0));
      if (isNaN(ratingValue)) {
        return renderLabel(filterAttribute);
      }
      return (
        <div className="rating-label-element">
          <Rating
            name="read-only"
            value={ratingValue}
            readOnly
            sx={{
              color: (theme) => theme.palette.secondary.main,
              mr: PixelsToViewPortUnitConverter.getvw(8),
            }}
          />
          {renderLabel(filterAttribute)}
        </div>
      );
    }
    return null;
  };

  const renderFilterAttribute = (filterAttribute: FilterAttribute) => {
    if (
      props.pFilter.type == FilterType.CATEGORIES &&
      filterAttribute.subAttributes.length > 0
    ) {
      return (
        <CheckboxChildrenFilter
          pFilter={props.pFilter}
          pFilterAttribute={filterAttribute}
          pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
          key={filterAttribute.slug}
        />
      );
    }

    return (
      <Card
        key={filterAttribute.slug}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          marginBottom: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(45),
          },
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(45),
          },
        }}
      >
        <CardActionArea
          onClick={(e) => {
            handleChange(e, filterAttribute);
          }}
        >
          <CardContent
            sx={{
              padding: "0",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={PixelsToViewPortUnitConverter.getvw(21)}
            >
              <TickIcon
                sx={{
                  width: {
                    sm: PixelsToViewPortUnitConverter.getvw(17),
                    xs: PixelsToViewPortUnitConverter.getvw(52),
                  },
                  height: {
                    sm: PixelsToViewPortUnitConverter.getvw(12),
                    xs: PixelsToViewPortUnitConverter.getvw(47),
                  },
                  visibility: filterAttribute.isSelected ? "visible" : "hidden",
                }}
              />
              {renderRatingLabel(filterAttribute)}
              {props.pFilter.type !== FilterType.CUSTOMER_REVIEWS &&
                renderLabel(filterAttribute)}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const renderShowMoreSection = () => {
    if (!props.pFilter.showMore) return null;
    return (
      <div className="show-more-container">
        <Link
          component="button"
          variant="body2"
          onClick={isShowMoreOrFewerTapped}
        >
          {`Show ${showMoreRecords ? `Fewer` : `All`} ${props.pFilter.name}`}
        </Link>
      </div>
    );
  };

  const renderFilterAttributes = (filters: Filter) => {
    let filterAttributesArr = filters.filterAttributes;
    if (filters.showMore) {
      if (!showMoreRecords) {
        filterAttributesArr = filters.filterAttributes.slice(
          0,
          filters.limit + 1
        );
      }
    }
    return filterAttributesArr.map((filterAttribute: FilterAttribute) =>
      renderFilterAttribute(filterAttribute)
    );
  };

  return (
    <AccordionDetails
      className="cmenu-product-filter-attributes-wrapper"
      sx={{
        paddingTop: PixelsToViewPortUnitConverter.getvw(10),
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "inherit",
      }}
    >
      {renderFilterAttributes(props.pFilter)}
      {renderShowMoreSection()}
    </AccordionDetails>
  );
};
