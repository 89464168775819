import React, { useState } from "react";
import "./BreadcrumbsComponent.scss";
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  IconButton,
} from "@mui/material";
import HomeIcon from "../../../../statics/svgs/HomeIcon";
import { matchRoutes } from "react-router-dom";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { NavigationProps } from "../../../../Navigation.types";
import Navigation from "../../../../Navigation";
import AppRoutes from "../../../../routes";
const queryString = require("query-string");

type BreadcrumbsComponentProps = {
  router: NavigationProps;
};

const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = (
  props: BreadcrumbsComponentProps
) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };

  const myRoutes = [
    {
      path: AppRoutes.ROOT,
      strict: true,
      exact: true,
      breadcrumName: "Home",
    },
    {
      path: AppRoutes.PRODUCTS,
      strict: true,
      exact: true,
      breadcrumName: "All Products",
    },
    {
      path: "products/:productId",
      strict: true,
      exact: true,
      breadcrumName: "Product Details",
    },
  ];

  const matchRoute = (routes, location) => {
    const matchedRoutes = matchRoutes(routes, location.pathname);
    if (!Array.isArray(matchedRoutes) || matchedRoutes.length === 0) {
      // throw new Error('No route for ' + location.pathname);
    }
    return matchedRoutes;
  };

  const homeHandleClick = (
    event: React.MouseEvent<HTMLElement>,
    previousState: any
  ) => {
    // console.info('homeHandleClick.'+JSON.stringify(previousState));
    props.router.navigate({
      pathname: AppRoutes.ROOT,
    });
  };

  const homeAllProductsClick = (
    event: React.MouseEvent<HTMLElement>,
    previousState: any
  ) => {
    // console.info('homeAllProductsClick.'+JSON.stringify(previousState));
    if (previousState && previousState.isFromShopAll) {
      props.router.navigate(-1);
      return;
    }
    Navigation.toShopAll(props.router);
  };

  const renderHomePath = (isActive: boolean, previousState: any) => {
    return (
      <Link
        underline="hover"
        onClick={(e: any) => homeHandleClick(e, previousState)}
      >
        <IconButton>
          <HomeIcon
            sx={{
              width: PixelsToViewPortUnitConverter.getvw(24),
              height: PixelsToViewPortUnitConverter.getvw(24),
            }}
          />
        </IconButton>
      </Link>
    );
  };

  const renderAllProductsPath = (isActive: boolean, previousState: any) => {
    if (isActive) {
      return (
        <Typography className="breadcrum-title-active" color="text.primary">
          {`All Products`}
        </Typography>
      );
    }
    return (
      <Link
        className="breadcrum-title-inactive"
        underline="hover"
        color="inherit"
        onClick={(e: any) => homeAllProductsClick(e, previousState)}
      >
        {`All Products`}
      </Link>
    );
  };

  const renderPDPPath = (isActive: boolean, previousState: any) => {
    if (isActive) {
      return (
        <Typography className="breadcrum-title-active" color="text.primary">
          {`Product Details`}
        </Typography>
      );
    }
    return null;
  };

  const breadCrumItems = () => {
    let finalItems = [];
    // console.log(props.router)
    const match = matchRoute(myRoutes, props.router.location);
    if (match) {
      let previousState = props.router.location.state;
      let route = match[0];
      const pathName = route.route.path;
      // console.log(match)
      // console.log(route.route.path)
      if (pathName === AppRoutes.PRODUCTS) {
        finalItems.push(renderHomePath(false, previousState));
        finalItems.push(renderAllProductsPath(true, previousState));
      } else if (pathName === "products/:productId") {
        finalItems.push(renderHomePath(false, previousState));
        finalItems.push(renderAllProductsPath(false, previousState));
        finalItems.push(renderPDPPath(true, previousState));
      } else {
      }
    }
    return finalItems;
  };

  const renderBreadCrumList = () => {
    const items = breadCrumItems();
    if (items.length === 0) return null;
    return <Breadcrumbs aria-label="breadcrumb">{items}</Breadcrumbs>;
  };

  return (
    <div
      className="breadcrumb-container"
      role="presentation"
      onClick={handleClick}
    >
      {renderBreadCrumList()}
    </div>
  );
};
export default BreadcrumbsComponent;
