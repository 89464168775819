import React from "react";
import "./AppliedFilterButtonV2.scss";
import { alpha, Box, Button } from '@mui/material';
import AppliedFilter from '../../../../core/models/AppliedFilter';
import CrossIcon from "../../../../statics/svgs/CrossIcon";
import StarIcon from "../../../../statics/svgs/StarIcon";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import CrossIconV2 from "../../../../statics/svgs/CrossIconV2";

type AppliedFilterButtonV2Props = {
    appliedFilter: AppliedFilter;
    onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
};

export const AppliedFilterButtonV2: React.FC<AppliedFilterButtonV2Props> = (props: AppliedFilterButtonV2Props) => {

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        props.onRemoveAppliedFilter(props.appliedFilter)
    };

    let displayName = props.appliedFilter.getDisplayName()
    if(props.appliedFilter.slug === 'query') {
        displayName = '"' + props.appliedFilter.getDisplayName() + '"'
    }
    return (
        <Button 
            type="submit"
            color="primary"
            className="cmenu-applied-filter-btn-v2"
            sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                color: (theme) => theme.palette.primary.contrastText,
                borderColor: (theme) => alpha(theme.palette.primary.contrastText,0.14),
            }}
            endIcon={<CrossIconV2 
                sx={{
                    width: 15,
                    height: 15,
                    marginTop: PixelsToViewPortUnitConverter.getvw(8),
                    fill: (theme) => theme.palette.primary.contrastText,
                    stroke: (theme) => theme.palette.primary.contrastText
                }}/>}
            onClick={handleClick}
        >
                {(props.appliedFilter.slug === 'aggregate_rating') && <StarIcon style={{ fontSize: 14, paddingRight: '5px' }} />}
                {displayName}
        </Button>
    )
}