import {
  alpha,
  Box,
  ClickAwayListener,
  Grid,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import TextUtils from "../../../../core/utils/TextUtils";
import CustomButton from "../other/CustomButton";
import CustomTextInput from "../other/CustomTextInput";
import "./LoginComponent.scss";
import UserAuthService from "../../../../services/UserAuthService";
import { ApiError } from "../../../../core/webservice/ApiError";
import AmplifyManager from "../../../../core/utils/AmplifyManager";
import ErrorLabel from "../other/ErrorLabel";

type LoginComponentProps = {
  pShowProfileContent: boolean;
  anchorEl: Element;
  pIsPopover?: boolean;
  onOpen(): void;
  onClose(): void;
  onNavigateToVerifyCode(userName: string, password: string): void;
  onLoginSuccess(): void;
  onSignUpClicked(): void;
};

const LoginComponent: React.FC<LoginComponentProps> = (
  props: LoginComponentProps
) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const { pIsPopover = true } = props;

  useEffect(() => {
    // componentDidMount events
    return () => {
      // componentWillUnmount events
      setErrorMessage(undefined);
    };
  }, []);

  const handleForgetPasswordClicked = () => {};

  const handleSignUpClicked = () => {
    props.onSignUpClicked();
  };

  const handleLogInClicked = () => {
    if (!isFormValid()) {
      return;
    }
    // CALL Sign In API
    if (isLoading) return;
    setErrorMessage(undefined);
    setIsLoading(true);
    UserAuthService.signInUser(userName, password)
      .then((response: any) => {
        setIsLoading(false);
        AmplifyManager.shared().setJwtAccessToken(response);
        props.onLoginSuccess();
      })
      .catch((error: ApiError) => {
        if (error.errorCode === 601) {
          sendVerificationCode();
        } else {
          setIsLoading(false);
          setErrorMessage(error.message);
        }
      });
  };

  const sendVerificationCode = () => {
    UserAuthService.resendSignUpCode(userName)
      .then((response: any) => {
        setIsLoading(false);
        props.onNavigateToVerifyCode(userName, password);
      })
      .catch((error: ApiError) => {
        setIsLoading(false);
        setErrorMessage(error.message);
      });
  };

  const isFormValid = () => {
    if (
      TextUtils.isEmpty(userName) ||
      TextUtils.isEmpty(password) ||
      !TextUtils.isValidEmailId(userName)
    ) {
      return false;
    }
    return true;
  };

  const renderLoginContainer = () => {
    return (
      <Box
        className="login-main-container"
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
        }}
      >
        <Typography variant="h2" className="header-title">
          {`LOG IN`}
        </Typography>
        <CustomTextInput
          placeholder="your email address"
          description="Enter your email"
          value={userName}
          defaultValue=""
          mt={60}
          onChangeText={(value) => {
            setUserName(value);
          }}
        />
        <CustomTextInput
          type="password"
          placeholder="your password"
          description="Enter your password"
          value={password}
          defaultValue=""
          mt={30}
          // rightActionTitle={"Forget Password"}
          onChangeText={(value) => {
            setPassword(value);
          }}
          onRightActionClicked={handleForgetPasswordClicked}
        />
        <ErrorLabel errorMessage={errorMessage} mt={17} />
        <Box
          className="button-container"
          sx={{
            mt: {
              sm: PixelsToViewPortUnitConverter.getvw(60),
              xs: PixelsToViewPortUnitConverter.getvw(200),
            },
          }}
        >
          <CustomButton
            variant="v1"
            title="SIGN UP"
            onClick={() => {
              handleSignUpClicked();
            }}
          />
          <CustomButton
            variant="v2"
            title="LOG IN"
            disabled={!isFormValid()}
            loading={isLoading}
            onClick={() => {
              handleLogInClicked();
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderCustomerLoginComp = () => {
    return (
      <Box
        sx={{
          alignSelf: "center",
          width: {
            sm: "40vw",
            xs: "100vw",
          },
          height: {
            sm: "35vw",
            xs: "auto",
          },
          zIndex: {
            sm: "0",
            xs: "999",
          },
          position: {
            sm: "unset",
            xs: "relative",
          },
          overflowY: "auto",
          paddingTop: {
            sm: PixelsToViewPortUnitConverter.getFontVh(0),
            xs: PixelsToViewPortUnitConverter.getFontVh(115),
          },
          paddingBottom: {
            sm: PixelsToViewPortUnitConverter.getFontVh(0),
            xs: PixelsToViewPortUnitConverter.getFontVh(100),
          },
          maxWidth: "100%",
          mt: PixelsToViewPortUnitConverter.getvw(25),
          mb: PixelsToViewPortUnitConverter.getvw(25),
          borderWidth: {
            sm: "1px",
            xs: "0",
          },
          borderStyle: "solid",
          borderRadius: "0px",
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.2),
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
        }}
      >
        {renderLoginContainer()}
      </Box>
    );
  };

  if (!pIsPopover) return renderCustomerLoginComp();

  return (
    <Popover
      disableRestoreFocus={true}
      open={props.pShowProfileContent}
      onClose={() => {
        props.onClose();
      }}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      elevation={0}
      marginThreshold={0}
      PaperProps={{
        sx: {
          position: "absolute",
          right: "0",
          zIndex: {
            sm: "0",
            xs: "99",
          },
          top: {
            sm: "0",
            xs: "64px",
          },
          width: {
            sm: "40vw",
            xs: "100vw",
          },
          height: {
            sm: "77vh",
            xs: "calc(100% - 189px)",
          },
          paddingTop: {
            sm: "0",
            xs: "60px",
          },
          justifyContent: {
            sm: "unset",
            xs: "start",
          },
          maxWidth: "100%",
          // marginTop: PixelsToViewPortUnitConverter.getvw(6),
          borderBottomWidth: "1px",
          borderTopWidth: "0px",
          borderStyle: "solid",
          borderRadius: "0px",
          pointerEvents: "auto",
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.2),
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
        },
        // onPointerEnter: () => {
        // props.onOpen()
        // },
        // onPointerLeave: () => {
        // props.onClose()
        // },
      }}
      sx={{ pointerEvents: "none" }}
    >
      <ClickAwayListener
        onClickAway={() => {
          props.onClose();
        }}
      >
        {renderLoginContainer()}
      </ClickAwayListener>
    </Popover>
  );
};
export default LoginComponent;
