import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CrossIconV2(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 16 15"
    return (
        <SvgIcon {...uProps}>
           <rect x="4.9375" y="3.0625" width="9.90334" height="1.23792" transform="rotate(45 4.9375 3.0625)"/>
           <rect x="11.9375" y="3.9375" width="9.90334" height="1.23792" transform="rotate(135 11.9375 3.9375)"/>
        </SvgIcon>
    );
}


