import React, { useState } from "react";
import "./CheckboxFilter.scss";
import { CheckboxChildrenFilter } from './CheckboxChildrenFilter';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Rating from '@mui/material/Rating';
import { Link } from "@mui/material";
import { alpha } from "@mui/system";
import FilterAttribute from '../../../../core/models/FilterAttribute';
import Filter, { FilterType } from "../../../../core/models/Filter";

type CheckboxFilterProps = {
    pFilter: Filter;
    pOnSelectFilterAttribute(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
};

export const CheckboxFilter: React.FC<CheckboxFilterProps> = (props: CheckboxFilterProps) => {

    const [showMoreRecords, setShowMoreRecords] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>,filterAttribute: FilterAttribute) => {
        // console.log('name '+filterAttribute.name)
        // console.log('checked '+event.target.checked)
        if(filterAttribute.isAll && filterAttribute.isSelected && !event.target.checked) {
            event.target.checked = true
            event.preventDefault()
        }
        props.pOnSelectFilterAttribute(props.pFilter,filterAttribute,event.target.checked)
    };

    const isShowMoreOrFewerTapped = () => {
        setShowMoreRecords(!showMoreRecords)
    }

    const renderLabelElement = (filterAttribute: FilterAttribute) => {
        if(props.pFilter.type == FilterType.CUSTOMER_REVIEWS) {
            const ratingValue = Number(filterAttribute.name.charAt(0))
            if(isNaN(ratingValue)) {
                return filterAttribute.name
            }
            return(<div className="rating-label-element">
                <Rating name="read-only" value={ratingValue} readOnly />
                <Typography sx={{
                            color: (theme) => alpha(theme.palette.primary.main,0.5)
                        }}>
                            {filterAttribute.name}
                </Typography>
            </div>);
        }
        if(!filterAttribute.isAll && (props.pFilter.type == FilterType.BRANDS ||
            props.pFilter.type == FilterType.ACTIVITIES ||
            props.pFilter.type == FilterType.FEELINGS ||
            props.pFilter.type == FilterType.CATEGORIES ||
            props.pFilter.type == FilterType.PRICE)) {
            
            if(props.pFilter.type == FilterType.CATEGORIES && filterAttribute.subAttributes.length > 0) {
                return `> ${filterAttribute.displayName()}`
            }

            return filterAttribute.displayName()
        }
        return filterAttribute.name
    }

    const renderFilterAttribute = (filterAttribute: FilterAttribute) => {

        if(props.pFilter.type == FilterType.CATEGORIES && filterAttribute.subAttributes.length > 0) {
            return(
                <CheckboxChildrenFilter
                    pFilter={props.pFilter}
                    pFilterAttribute={filterAttribute}
                    pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
                    key={filterAttribute.slug}
                />
            )
        }

        const className = `${(filterAttribute.isSelected) ? 'checkbox-form-control-label-selected': 'checkbox-form-control-label'}`
        return(
            <FormControlLabel
                sx={{
                    color: (theme) => (filterAttribute.isSelected) ? alpha(theme.palette.primary.main,1.0) : alpha(theme.palette.primary.main,0.5),
                    [`&, & .MuiTypography-root`]: {
                        color: (theme) => (filterAttribute.isSelected) ? alpha(theme.palette.primary.main,1.0) : alpha(theme.palette.primary.main,0.5)
                    }
                }}
                className={className}
                value={filterAttribute.slug}
                control={<Checkbox
                    checked={filterAttribute.isSelected}
                    onChange={(e) => handleChange(e,filterAttribute)}
                    sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: (theme) => theme.palette.secondary.main,
                        },
                      }}
                />}
                label={renderLabelElement(filterAttribute)}
                labelPlacement="start"
                key={filterAttribute.slug}
            />
        )
    }

    const renderShowMoreSection = () => {
        if(!props.pFilter.showMore) return null
        return(
            <div className="show-more-container">
                <Link component="button" variant="body2" onClick={isShowMoreOrFewerTapped}>
                {`Show ${showMoreRecords?`Fewer`:`All`} ${props.pFilter.name}`}</Link>
            </div>   
        )
    }

    const renderFilterAttributes = (filters: Filter) => {
        let filterAttributesArr = filters.filterAttributes
        if(filters.showMore) {
            if(!showMoreRecords) {
                filterAttributesArr = filters.filterAttributes.slice(0, filters.limit+1);
            }
        }
        return filterAttributesArr.map((filterAttribute: FilterAttribute) => renderFilterAttribute(filterAttribute));
    }

    return (
        <AccordionDetails className="product-filter-attributes-wrapper"
            sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                width: 'inherit'
            }}>
            {renderFilterAttributes(props.pFilter)}
            {renderShowMoreSection()}
        </AccordionDetails>
    );
};