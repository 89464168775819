import {
  InputBase,
  IconButton,
  ClickAwayListener,
  List,
  ListItemButton,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import React from "react";
import ProductList from "../../../../core/models/ProductList";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import TextUtils from "../../../../core/utils/TextUtils";
import { ApiError } from "../../../../core/webservice/ApiError";
import MenuService from "../../../../services/MenuService";
import SearchIcon from "../../../../statics/svgs/SearchIcon";
import SearchSuggestionItem from "../../effectsv2/header/SearchSuggestionItem";
import { alpha } from "@mui/material/styles";
import withRouter from "../../../../withRouter";
import Navigation from "../../../../Navigation";

interface IBannerComponentState {
  sSearchResults: ProductList | undefined;
  sSearchValue: string | undefined;
  sSearchSuggestions: boolean;
}

const queryString = require("query-string");

class BannerComponent extends React.Component<any, IBannerComponentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sSearchResults: undefined,
      sSearchValue: undefined,
      sSearchSuggestions: true,
    };
  }

  render() {
    return (
      <Box
        sx={{
          height: {
            sm: "60vh",
            xs: "25vh",
          },
          position: "relative",
          display: "flex",
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(10),
            xs: PixelsToViewPortUnitConverter.getvw(0),
          },

          marginLeft: {
            sm: PixelsToViewPortUnitConverter.getvw(62),
            xs: PixelsToViewPortUnitConverter.getvw(0),
          },
          marginRight: {
            sm: PixelsToViewPortUnitConverter.getvw(62),
            xs: PixelsToViewPortUnitConverter.getvw(0),
          },
          marginBottom: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(80),
          },
        }}
      >
        <img
          style={{
            height: "100%",
            objectFit: "cover",
            width: "100%",
          }}
          src="https://d2o1iceed9pvab.cloudfront.net/categories_banner.png"
        />

        <Stack
          sx={{
            position: "absolute",
            display: "flex",
            backgroundColor: (theme) => theme.palette.background.default,
            width: {
              sm: "40%",
              xs: "89vw",
            },
            right: 0,
            left: 0,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: {
              sm: "26vh",
              xs: "10vh",
            },
            zIndex: 1000,
            boxShadow: "0px 4px 15px 0px rgba(0,0,0,0.15)",
          }}
        >
          <Box
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InputBase
              sx={{
                fontFamily: "Barlow",
                fontWeight: "500",
                flex: 1,
                fontSize: {
                  sm: PixelsToViewPortUnitConverter.getFontVw(16),
                  xs: PixelsToViewPortUnitConverter.getFontVw(15),
                },
                padding: {
                  sm: `${PixelsToViewPortUnitConverter.getFontVw(
                    8
                  )} ${PixelsToViewPortUnitConverter.getFontVw(0)}`,
                  xs: `${PixelsToViewPortUnitConverter.getFontVw(
                    2
                  )} ${PixelsToViewPortUnitConverter.getFontVw(0)}`,
                },
                marginLeft: {
                  sm: PixelsToViewPortUnitConverter.getFontVw(30),
                  xs: PixelsToViewPortUnitConverter.getFontVw(15),
                },
              }}
              type="search"
              value={this.state.sSearchValue}
              placeholder="Strain, brand, effect, location, etc."
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                this.setState({
                  sSearchResults: undefined,
                  sSearchValue: e.currentTarget.value,
                  sSearchSuggestions: true,
                });
                if (!TextUtils.isEmpty(e.currentTarget.value)) {
                  MenuService.getProducts("", e.currentTarget.value)
                    .then((results) => {
                      if (results.query == this.state.sSearchValue) {
                        this.setState({ sSearchResults: results });
                      }
                    })
                    .catch((apiError: ApiError) => {});
                } else {
                  this.setState({ sSearchResults: undefined });
                }
              }}
              onClick={() => this.setState({ sSearchSuggestions: true })}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const parsed = queryString.parse(
                    this.props.router.location.search
                  );
                  parsed["query"] = this.state.sSearchValue;
                  Navigation.toShopAllWithSearchQuery(
                    this.props.router,
                    `${queryString.stringify(parsed)}`
                  );
                }
              }}
            />
            <Button
              variant="text"
              sx={{
                opacity: !TextUtils.isEmpty(this.state.sSearchValue) ? 1 : 0,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                fontFamily: "Barlow",
                fontWeight: 500,
                minWidth: "0px",
                minHeight: "0px",
                padding: "0px",
                marginBottom: PixelsToViewPortUnitConverter.getvw(4),
                paddingRight: {
                  sm: PixelsToViewPortUnitConverter.getvw(8),
                  xs: PixelsToViewPortUnitConverter.getvw(50),
                },
                paddingLeft: PixelsToViewPortUnitConverter.getvw(4),
                color: (theme) => theme.palette.primary.contrastText,
              }}
              onClick={() => {
                this.setState({
                  sSearchResults: undefined,
                  sSearchSuggestions: false,
                  sSearchValue: "",
                });
              }}
            >
              Clear
            </Button>
            <Box
              sx={{
                height: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: PixelsToViewPortUnitConverter.getvw(30),
                marginRight: PixelsToViewPortUnitConverter.getFontVw(20),
              }}
            >
              <IconButton
                focusRipple={true}
                sx={{
                  color: (theme) =>
                    alpha(theme.palette.primary.contrastText, 0.17),
                }}
                onClick={() => {
                  const parsed = queryString.parse(
                    this.props.router.location.search
                  );
                  parsed["query"] = this.state.sSearchValue;
                  Navigation.toShopAllWithSearchQuery(
                    this.props.router,
                    `${queryString.stringify(parsed)}`
                  );
                }}
              >
                <SearchIcon
                  sx={{
                    height: {
                      sm: PixelsToViewPortUnitConverter.getvw(30),
                      xs: PixelsToViewPortUnitConverter.getvw(50),
                    },
                  }}
                  viewBox="0 0 31 31"
                />
              </IconButton>
            </Box>
          </Box>
          {this.state.sSearchSuggestions &&
            this.state.sSearchResults &&
            this.state.sSearchResults.products.length > 0 && (
              <ClickAwayListener
                onClickAway={() => {
                  this.setState({ sSearchSuggestions: false });
                }}
              >
                <Box
                  sx={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  }}
                >
                  <List>
                    {this.state.sSearchResults.products.map((product) => (
                      <ListItemButton
                        sx={{
                          overflowX: "hidden",
                          // paddingRight: "0px",
                          // paddingY: PixelsToViewPortUnitConverter.getvw(12),
                        }}
                        onClick={() => {
                          this.setState({
                            sSearchSuggestions: false,
                          });
                          Navigation.toProductDetails(
                            this.props.router,
                            product
                          );
                        }}
                      >
                        <SearchSuggestionItem pProduct={product} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </ClickAwayListener>
            )}
        </Stack>
      </Box>
    );
  }
}
export default withRouter(BannerComponent);
