import { Google, WidgetsSharp } from "@mui/icons-material";
import { Button, Stack, Typography, IconButton, Input } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import LocationImg from "../../../statics/svgs/LocationImg";
import CustomButton from "../../themes/effectsv2/other/CustomButton";
import LocalStorageUtils from "../../../core/utils/LocalStorageUtils";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import PartnerManager from "../../../core/utils/PartnerManager";
import "./Geolocation.scss";
import GeolocationResults from "./GeolocationResults";
import crossImage from "../../../statics/svgs/crossImage.svg";
import poweredGoogle from "../../../statics/svgs/poweredGoogle.svg";
import Strings from "../../../core/utils/Strings";

function GeoLocation() {
  const [returnNull, setReturnNull] = useState(
    LocalStorageUtils.getItem(Strings.LOCATION_INFO_KEY) ? null : true
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [locationdetail, setLocationDetail] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [statusCheck, setStatusCheck] = useState(null);

  useEffect(() => {
    if (locationdetail.latitude) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationdetail.latitude},${locationdetail.longitude}&key=${process.env.REACT_APP_LOCATION_API_KEY}`
      )
        .then((res) => res.json())
        .then((json) => {
          setValue(json.results[0].formatted_address);
          setIsButtonDisabled(false);
        });
    }
  }, [statusCheck]);

  useEffect(() => {
    LocalStorageUtils.getItem("1s_user_21_yrs_old") == "true" && !returnNull
      ? setReturnNull(true)
      : setReturnNull(true); //put false instead of true, if you want to unhide GeoLocation from 2nd setReturnNull
  }, []);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const handleInput = (e) => {
    !e.target.value && setIsButtonDisabled(true);
    setValue(e.target.value);
  };

  const submitLatLong = () => {
    LocalStorageUtils.storeItem(
      Strings.LOCATION_INFO_KEY,
      JSON.stringify(locationdetail)
    );
    setReturnNull(true);
  };
  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      clearSuggestions();
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setLocationDetail({
          latitude: lat,
          longitude: lng,
        });
      });
      value ? setIsButtonDisabled(false) : setIsButtonDisabled(true);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      return (
        <GeolocationResults
          suggestion={suggestion}
          handleSelect={handleSelect}
        />
      );
    });

  const getLocation = async () => {
    if (!navigator.geolocation) {
      setStatusCheck("Geolocation is not supported by your browser");
    } else {
      setStatusCheck("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationDetail({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setStatusCheck("done");
        },
        () => {
          setStatusCheck("Unable to retrieve your location");
        }
      );
    }
    statusCheck === "Unable to retrieve your location" &&
      alert("Location Services are disabled!");
  };

  return (
    <>
      {!returnNull ? (
        <Stack
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            display: "flex",
            zIndex: 1500,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#242424",
          }}
        >
          <Box
            sx={{
              width: { xs: "100vw", sm: "47vw" },
              height: { xs: "100vh", sm: "auto" },
              display: "block",
              backgroundColor: (theme) => theme.palette.primary.main,
              boxSizing: "border-box",
            }}
          >
            <Box className="crossImage">
              <img
                src={crossImage}
                style={{ cursor: "pointer" }}
                width={PixelsToViewPortUnitConverter.getvw(350)}
                height={PixelsToViewPortUnitConverter.getvw(350)}
                onClick={() => setReturnNull(true)}
              />
            </Box>
            <Box
              sx={{
                paddingTop: PixelsToViewPortUnitConverter.getvw(20),
                paddingBottom: PixelsToViewPortUnitConverter.getvw(50),
                paddingLeft: PixelsToViewPortUnitConverter.getvw(150),
                paddingRight: PixelsToViewPortUnitConverter.getvw(150),
              }}
            >
              <LocationImg
                sx={{
                  width: {
                    xs: PixelsToViewPortUnitConverter.getvw(700),
                    sm: PixelsToViewPortUnitConverter.getvw(291),
                  },
                  height: {
                    xs: PixelsToViewPortUnitConverter.getvw(700),
                    sm: PixelsToViewPortUnitConverter.getvw(315),
                  },
                  marginTop: PixelsToViewPortUnitConverter.getvw(-70),
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Bebas",
                  fontWeight: 400,
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(26),
                  color: "#000",
                  letterSpacing: "0.1em",
                  marginTop: PixelsToViewPortUnitConverter.getvw(-70),
                  textTransform: "uppercase",
                }}
              >
                find products at stores near you
              </Typography>

              <Box
                sx={{
                  position: "relative",
                  marginTop: {
                    xs: PixelsToViewPortUnitConverter.getvw(100),
                    sm: PixelsToViewPortUnitConverter.getvw(0),
                  },
                }}
              >
                <input
                  value={value}
                  onChange={handleInput}
                  placeholder="Address, city, zip code"
                  className="locationInput"
                  style={{
                    paddingBottom: PixelsToViewPortUnitConverter.getvw(10),
                    backgroundColor:
                      PartnerManager.shared().partner.theme.backgroundColor,
                    borderWidth: "1px",
                    borderBottomColor: "#000",
                    borderRadius: "0",
                  }}
                />

                {status === "OK" && statusCheck !== "done" && (
                  <Box
                    className="opendlocationbox"
                    sx={{
                      backgroundColor:
                        PartnerManager.shared().partner.theme.backgroundColor,
                      height: { sm: PixelsToViewPortUnitConverter.getvw(210) },
                      maxHeight: {
                        xs: PixelsToViewPortUnitConverter.getvw(700),
                        sm: PixelsToViewPortUnitConverter.getvw(250),
                      },
                      minHeight: {
                        xs: PixelsToViewPortUnitConverter.getvw(700),
                        sm: PixelsToViewPortUnitConverter.getvw(250),
                      },
                    }}
                  >
                    {renderSuggestions()}
                    <Box
                      className="poweredGoogleParent"
                      sx={{
                        width: "100%",
                        textAlign: "right",
                        position: "sticky",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        backgroundColor:
                          PartnerManager.shared().partner.theme.backgroundColor,
                      }}
                    >
                      <img src={poweredGoogle} className="poweredGoogle" />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  position: "relative",
                  marginLeft: PixelsToViewPortUnitConverter.getvw(30),
                  marginRight: PixelsToViewPortUnitConverter.getvw(30),
                  marginTop: {
                    xs: PixelsToViewPortUnitConverter.getvw(700),
                    sm: PixelsToViewPortUnitConverter.getvw(0),
                  },
                }}
              >
                <Box
                  sx={{
                    mb: {
                      xs: PixelsToViewPortUnitConverter.getvw(10),
                      sm: PixelsToViewPortUnitConverter.getvw(0),
                    },
                  }}
                >
                  <CustomButton
                    mt={PixelsToViewPortUnitConverter.getvw(40)}
                    mb={PixelsToViewPortUnitConverter.getvw(20)}
                    variant="v2"
                    title="confirm"
                    disabled={isButtonDisabled}
                    onClick={() => submitLatLong()}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="v1"
                    title="use current location"
                    onClick={() => getLocation()}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}

export default GeoLocation;
