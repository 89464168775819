import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../../core/webservice/ApiError";
import Navigation from "../../../../Navigation";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import withRouter from "../../../../withRouter";
import Loader from "../../../common/loader/Loader";
import Effect from '../../../../core/models/Effect';

interface IEffectsHeaderMenuState {
  sShowLoader: boolean;
}

class EffectsHeaderMenu extends React.Component<any, IEffectsHeaderMenuState> {
  constructor(props: any) {
    super(props);
    this.state = { sShowLoader: false };
  }

  componentDidMount() {
    if (PartnerManager.shared().effects == undefined) {
      this.setState({ sShowLoader: true });
      StoreOperationsService.getEffects(
        PartnerManager.shared().currentSelectedStore.id
      )
        .then((_) => {
          this.setState({
            sShowLoader: false,
          });
        })
        .catch((apiError: ApiError) => {
          this.setState({
            sShowLoader: false,
          });
        });
    }
  }

  render() {
    return this.state.sShowLoader ? (
      <Box
        sx={{
          width: "40vw",
          minHeight: "100px",
          padding: PixelsToViewPortUnitConverter.getvw(20),
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <Loader pshow={true} />
      </Box>
    ) : (
      <Stack
        sx={{
          width: "40vw",
          padding: PixelsToViewPortUnitConverter.getvw(20),
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          sx={{
            color: "primary.contrastText",
            fontFamily: "Overpass",
            fontWeight: 700,
            fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
            textAlign: "start",
          }}
        >
          How would you like to feel today?
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "repeat(2, min-content)",
            gridAutoFlow: "column",
            marginTop: PixelsToViewPortUnitConverter.getvw(10),
          }}
        >
          {this.effectMenuRowItem(undefined)}
          {PartnerManager.shared().effects
            ? PartnerManager.shared().effects.map((effect) => {
                return this.effectMenuRowItem(effect);
              })
            : ""}
        </Box>
      </Stack>
    );
  }

  private effectMenuRowItem(effect: Effect|undefined) {
    return (
      <Button
        onClick={() => {
          Navigation.toShopAllByEffect(this.props.router, effect)
        }}
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
          width: "100%",
          padding: PixelsToViewPortUnitConverter.getvw(10),
          fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
          fontWeight: "400",
        }}
      >
        {effect.name}
      </Button>
    );
  }
}

export default withRouter(EffectsHeaderMenu);
