export default class SortingItem {

    private mName: string;
    public get name() {
        return this.mName;
    }

    private mValue: string;
    public get value() {
        return this.mValue;
    }

    private mSelected: boolean;
    public get selected() {
        return this.mSelected;
    }

    public setSelected(isSelected: boolean) {
        this.mSelected = isSelected
    }

    public constructor() {
        this.mSelected = false
    }

    public init(item: any) {
        this.mName = item.name
        this.mValue = item.value
    }

    public static list(items: any): SortingItem[] {
        const finalArray: SortingItem[] = []
        items.forEach((item: any) => {
            const sortingItem = new SortingItem()
            sortingItem.init(item)
            finalArray.push(sortingItem)
        })        
        return finalArray
    }

    public queryString(): string {
        return `sortBy=${this.value}`
    }
}