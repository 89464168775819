import React, { useEffect, useRef, useState } from "react";
import "./ProductGridList.scss";
import { ProductCard } from './ProductCard';
import { Box } from '@mui/material';
import Product, { CartProduct } from "../../../../core/models/Product";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import ProductList from '../../../../core/models/ProductList';
import Loader from '../../../common/loader/Loader';

type ProductGridListProps = {
    itemDimension: any;
    productList: ProductList;
    isLoading: boolean;
    pOnProductClicked(product: Product): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

export const ProductGridList: React.FC<ProductGridListProps> = (props: ProductGridListProps) => {
    
    const [items, setItems] = React.useState([]);
    const [width, setParentWidth] = React.useState(props.itemDimension.parentWidth);

    // on parent width or product changes reload the UI
    useEffect(() => {
        setParentWidth(props.itemDimension.parentWidth);
        setUpUI()
    }, [props.itemDimension.parentWidth, props.productList.products]);

    const renderProductCard = (item: Product, index: number ,itemWidth: number, itemHeight: number) => {    
        return(
            <ProductCard
                key={index.toString()} 
                product={item} 
                width={itemWidth} 
                height={itemHeight}
                pOnProductClicked={props.pOnProductClicked}
                pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}/>
        )
    }

    const renderProducts = (products: Product[]) => {
        return products.map((p: Product,index: number) => renderProductCard(p,index,props.itemDimension.width,props.itemDimension.height));   
    }

    const setUpUI = () => {
        let childItems = []
        childItems.push(renderProducts(props.productList.products))
        setItems(childItems)
    }

    if(!props.productList.dataLoaded || (props.productList.page === 0 && props.isLoading)) {
        return(
            <Box sx={{ position: "relative", minHeight: "100px", width: "100%" }}>
                <Loader pshow={true} />
            </Box>
        )
    }

    return(
        <Box
            className="products-grid-container"
            sx={{
                display: `${(props.isLoading || !props.productList.dataLoaded)?'none':'grid'}`,
                gap: PixelsToViewPortUnitConverter.getvw(25), 
                WebkitBoxPack: 'center', 
                justifyContent: 'center',
                gridTemplateColumns: `repeat(auto-fill, minmax(${props.itemDimension.width}px, 1fr))`
            }}>
            {items}
        </Box>       
    )
}
