import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import account from "./accounticon.svg";
import accountuncheck from "./accounticonuncheck.svg";
import home from "./homeicon.svg";
import homeuncheck from "./homeiconuncheck.svg";
import search from "./searchicon.svg";
import searchuncheck from "./searchiconuncheck.svg";
import shop from "./shopicon.svg";
import shopuncheck from "./shopiconuncheck.svg";
import AmplifyManager from "../../../core/utils/AmplifyManager";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import AppRoutes from "../../../routes";
import { Button, ButtonBase } from "@mui/material";
import Navigation from '../../../Navigation';
import { NavigationProps } from "../../../Navigation.types";

type BottomBarProps = {
  router: NavigationProps;
};

const BottomBar: React.FC<BottomBarProps> = (props: BottomBarProps) => {
  const location = useLocation();
  
  return (
    <>
      <Box
        sx={{
          display: {
            sm: "none",
            xs: "flex",
          },
          justifyContent: "space-between",
          height: PixelsToViewPortUnitConverter.getvw(291),
          padding: `${PixelsToViewPortUnitConverter.getvw(0)} ${PixelsToViewPortUnitConverter.getvw(200)}`,
          position: "fixed",
          left: "0",
          right: "0",
          bottom: "0",
          background: "black",
          zIndex: "1200",
          alignItems: "center",
        }}
      >
        <ButtonBase onClick={() => {Navigation.toHome(props.router)}}>
          <img src={(location.pathname === AppRoutes.ROOT) ? home : homeuncheck} />
        </ButtonBase>
        <ButtonBase onClick={() => {Navigation.toShopAllTab(props.router)}}>
          <img src={(location.pathname.includes(AppRoutes.PRODUCTS)) ? shop : shopuncheck} />
        </ButtonBase>
        <ButtonBase onClick={() => {Navigation.toSearch(props.router)}}>
          <img src={(location.pathname === AppRoutes.SEARCH) ? search : searchuncheck} />
        </ButtonBase>
        <ButtonBase onClick={() => {AmplifyManager.shared().isUserLoggedIn() ? Navigation.toProfile(props.router) : Navigation.toLogin(props.router)}}>
          <img src={(location.pathname === AppRoutes.ACCOUNT || location.pathname === AppRoutes.LOGIN) ? account : accountuncheck} />
        </ButtonBase>
      </Box>
    </>
  );
};
export default BottomBar;
