import React from "react";
import "./ProductCard.scss";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Rating } from "@mui/material";
import AddToCartIcon from "../../../../statics/svgs/AddToCartIcon";
import Product, { CartProduct } from '../../../../core/models/Product';

type ProductCardProps = {
    product: Product;
    width: number;
    height: number;
    pOnProductClicked(product: Product): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

export const ProductCard: React.FC<ProductCardProps> = (props: ProductCardProps) => {

    const { product } = props
    const productName = product.name
    const productRating = product.aggregateRating
    const imgUrl = product.getProductCardImageUrl()
    const priceInfo = product.getPriceInfo()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        props.pOnProductClicked(props.product)
    };

    const renderProductCard = () => {
        return (
            <Card className="product-card" 
                sx={{
                    height: `${props.width}`,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    boxShadow: 'none'
                }
            } onClick={handleClick}>
                <div style={{ position: "relative" }}>
                <CardMedia
                    component="img"
                    style={{
                        width: "auto",
                        minHeight: "100px",
                        maxHeight: "100px",
                        padding: "0px 20px",
                        objectFit: 'cover'
                    }}
                    image={imgUrl}
                    alt={productName}
                    loading="lazy"
                />
                <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    top:0,
                    width: props.width,
                }}>
                    <AddToCartIcon
                        sx={{
                            width: '55px',
                            height: '55px',
                            fill: (theme) => theme.palette.secondary.main,
                            stroke: (theme) => theme.palette.secondary.contrastText,
                        }}/>
                </Box>
                <CardContent sx={
                        {
                            "&:last-child": {
                                paddingBottom: '8px'
                            }
                        }
                    }>
                    <Rating name="read-only" value={productRating} max={5} precision={0.1} readOnly />
                    <Typography 
                        className="product-title"
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            color: (theme) => theme.palette.primary.main
                        }}
                    >
                    {productName}
                    </Typography>
                    <Typography
                        sx={{color: (theme) => theme.palette.secondary.main }}
                        className="product-price-info">
                        {priceInfo}
                    </Typography>
                </CardContent>
                </div>  
            </Card>
        );
    }

    return (
        <Box
            sx={{
                boxSizing: 'border-box;'
            }}
        >
            {renderProductCard()}
        </Box> 
    );
};