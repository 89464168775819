import React, { useState } from "react";
import "./CheckboxChildrenFilter.scss";
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';
import { Accordion } from "@mui/material";
import { alpha } from "@mui/system";
import AccordionSummary from '@mui/material/AccordionSummary';
import Filter, { FilterType } from "../../../../core/models/Filter";
import FilterAttribute from '../../../../core/models/FilterAttribute';
import ExpandCollapseIcon from '../../../../statics/svgs/ExpandCollapseIcon';

type CheckboxChildrenFilterProps = {
    pFilter: Filter
    pFilterAttribute: FilterAttribute;
    pOnSelectFilterAttribute(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
};

export const CheckboxChildrenFilter: React.FC<CheckboxChildrenFilterProps> = (props: CheckboxChildrenFilterProps) => {

    const [isOpen, setIsOpen] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>,filterAttribute: FilterAttribute) => {
        // console.log('name '+filterAttribute.name)
        // console.log('checked '+event.target.checked)
        /*if(filterAttribute.isAll && filterAttribute.isSelected && !event.target.checked) {
            event.target.checked = true
            event.preventDefault()
        }*/
        props.pOnSelectFilterAttribute(props.pFilter,filterAttribute,event.target.checked)
    };

    const renderLabelElement = (filterAttribute: FilterAttribute) => {
        if(props.pFilter.type == FilterType.CUSTOMER_REVIEWS) {
            const ratingValue = Number(filterAttribute.name.charAt(0))
            if(isNaN(ratingValue)) {
                return filterAttribute.name
            }
            return(<div className="rating-label-element">
                <Rating name="read-only" value={ratingValue} readOnly />
                <Typography
                    sx={{
                        color: (theme) => alpha(theme.palette.primary.main,0.5)
                    }}
                    >{filterAttribute.name}</Typography>
            </div>);
        }
        if(!filterAttribute.isAll && (props.pFilter.type == FilterType.BRANDS ||
            props.pFilter.type == FilterType.ACTIVITIES ||
            props.pFilter.type == FilterType.FEELINGS ||
            props.pFilter.type == FilterType.CATEGORIES ||
            props.pFilter.type == FilterType.PRICE)) {
            
            if(props.pFilter.type == FilterType.CATEGORIES && filterAttribute.subAttributes.length > 0) {
                return `> ${filterAttribute.displayName()}`
            }

            return filterAttribute.displayName()
        }
        return filterAttribute.name
    }

    const renderFilterAttribute = (filterAttribute: FilterAttribute) => {
        const className = `${(filterAttribute.isSelected) ? 'checkbox-form-control-label-selected': 'checkbox-form-control-label'}`
        return(
            <FormControlLabel
                sx={{
                    color: (theme) => (filterAttribute.isSelected) ? alpha(theme.palette.primary.main,1.0) : alpha(theme.palette.primary.main,0.5),
                    [`&, & .MuiTypography-root`]: {
                        color: (theme) => (filterAttribute.isSelected) ? alpha(theme.palette.primary.main,1.0) : alpha(theme.palette.primary.main,0.5)
                    }
                }}
                className={className}
                value={filterAttribute.slug}
                control={<Checkbox
                    checked={filterAttribute.isSelected}
                    onChange={(e) => handleChange(e,filterAttribute)}
                    sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                            color: (theme) => theme.palette.secondary.main,
                        },
                      }}
                />}
                label={renderLabelElement(filterAttribute)}
                labelPlacement="start"
                key={filterAttribute.slug}
            />
        )
    }

    const renderChildrens = (filterAttribute: FilterAttribute) => {
        return filterAttribute.subAttributes.map((filterAttribute: FilterAttribute) => renderFilterAttribute(filterAttribute));
    }

    const expandMoreIcon = () => {
        const className = `expandCollapseIcon ${(isOpen && '-selected')}`
        return(<ExpandCollapseIcon
            className={className}
            style={{ fontSize: 20 }} 
        />);
    }

    return(
        <div className="chidren-filter-attribute-wrapper">
            <Accordion onChange = {(e,expanded) => {
                        setIsOpen(expanded)
                }}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        "&.MuiAccordionSummary-root": {
                            minHeight: 0,
                            height: '22px',
                            padding: '0px 0px',
                            marginBottom: '6px'
                        },
                        "& .MuiAccordionSummary-content":{
                            margin: '0px 0px',
                            height: 'inherit'
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          // margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
                          margin: "12px 0",
                        }
                    }}
                >
                    {expandMoreIcon()}
                    <Typography
                        sx={{
                            color: (theme) => alpha(theme.palette.primary.main,0.5)
                        }}
                        className="parent-item-name">
                            {props.pFilterAttribute.displayName()}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {renderChildrens(props.pFilterAttribute)}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};