import TextUtils from "../utils/TextUtils";

export enum PartnerAppThemeType {
  CATEGORIES = "CATEGORIES",
  EFFECTS = "EFFECTS",
  EFFECTS1 = "EFFECTS_V2",
  EFFECTS2 = "EFFECTS2",
  UNKNOWN = "UNKNOWN",
}

export type PartnerAppTheme = {
  id?: number | null | undefined;
  themeId?: number | null | undefined;
  name: PartnerAppThemeType;
  logoUrl: string | null;
  faviconUrl: string | null;
  logoFileId: string | null;
  faviconFileId: string | null;
  backgroundColor: string | null;
  pageHighlightColor: string | null;
  buttonColor: string | null;
  buttonTextColor: string | null;
};

export default class PartnerTheme {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mName: PartnerAppThemeType;
  public get name() {
    return this.mName;
  }

  private mLogoUrl: string;
  public get logoUrl() {
    return this.mLogoUrl;
  }

  private mFavIconUrl: string;
  public get favIconUrl() {
    return this.mFavIconUrl;
  }

  private mBackgroundColor: string;
  public get backgroundColor() {
    // return "#4a4a4a";
    // return "#f6f0e4";
    //return "#ffffff";
    return this.mBackgroundColor;
  }

  private mPageHighlightColor: string;
  public get pageHighlightColor() {
    // return "#ffffff";
    // return "#9b9b9b";
    //return "#000000";
    return this.mPageHighlightColor;
  }

  private mButtonColor: string;
  public get buttonColor() {
    // return '#2C5F2DFF'
    // return '#990011FF'
    // return "#ff6100";

    return this.mButtonColor;
  }

  private mButtonTextColor: string;
  public get buttonTextColor() {
    // return '#FFE77AFF'
    // return '#FCF6F5FF'
    // return "#ffffff";

    return this.mButtonTextColor;
  }

  public isThemeConfigured(): boolean {
    if (
      TextUtils.isEmpty(this.mName) ||
      TextUtils.isEmpty(this.mLogoUrl) ||
      TextUtils.isEmpty(this.mFavIconUrl) ||
      TextUtils.isEmpty(this.mBackgroundColor) ||
      TextUtils.isEmpty(this.mPageHighlightColor) ||
      TextUtils.isEmpty(this.mButtonColor) ||
      TextUtils.isEmpty(this.mButtonTextColor)
    ) {
      return false;
    }
    return true;
  }

  public constructor(theme: PartnerAppTheme) {
    this.mId = theme.id;
    this.mName = theme.name;
    this.mLogoUrl = theme.logoUrl;
    this.mFavIconUrl = theme.faviconUrl;
    this.mBackgroundColor = theme.backgroundColor;
    this.mPageHighlightColor = theme.pageHighlightColor;
    this.mButtonColor = theme.buttonColor;
    this.mButtonTextColor = theme.buttonTextColor;
  }
}
