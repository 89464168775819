import React, { useState } from "react";
import "./BreadcrumbsComponent.scss";
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  IconButton,
} from "@mui/material";
import HomeIconV2 from "../../../../statics/svgs/HomeIconV2";
import { matchRoutes } from "react-router-dom";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { NavigationProps, NavigationState } from "../../../../Navigation.types";
import Navigation from "../../../../Navigation";
import Breadcrumb, {
  BreadcrumbType,
  IBreadcrumb,
} from "../../../../core/models/Breadcrumb";
import AppRoutes from "../../../../routes";
const queryString = require("query-string");

type BreadcrumbsComponentProps = {
  router: NavigationProps;
  isSectionExpanded: boolean;
};

const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = (
  props: BreadcrumbsComponentProps
) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };

  const myRoutes = [
    {
      path: AppRoutes.ROOT,
      strict: true,
      exact: true,
    },
    {
      path: AppRoutes.PRODUCTS,
      strict: true,
      exact: true,
    },
    {
      path: "products/:productId",
      strict: true,
      exact: true,
    },
    {
      path: AppRoutes.ACCOUNT,
      strict: true,
      exact: true,
    },
    {
      path: AppRoutes.SIGNUP,
      strict: true,
      exact: true,
    },
    {
      path: AppRoutes.VERIFY_CODE,
      strict: true,
      exact: true,
    },
  ];

  const matchRoute = (routes, location) => {
    const matchedRoutes = matchRoutes(routes, location.pathname);
    if (!Array.isArray(matchedRoutes) || matchedRoutes.length === 0) {
      // throw new Error('No route for ' + location.pathname);
    }
    return matchedRoutes;
  };

  const handleBreadCrumbClick = (
    event: React.MouseEvent<HTMLElement>,
    breadCrumb: Breadcrumb,
    previousState: NavigationState
  ) => {
    Navigation.toBreadcrumb(props.router, breadCrumb);
  };

  const renderBreadCrumb = (
    breadCrumb: Breadcrumb,
    previousState: NavigationState
  ) => {
    if (breadCrumb.type === BreadcrumbType.HOME) {
      return (
        <Link
          key={breadCrumb.type}
          underline="hover"
          onClick={(e: any) =>
            handleBreadCrumbClick(e, breadCrumb, previousState)
          }
        >
          <IconButton>
            <HomeIconV2
              sx={{
                fill: (theme) => theme.palette.primary.contrastText,
                width: PixelsToViewPortUnitConverter.getvw(13),
                height: PixelsToViewPortUnitConverter.getvw(15),
              }}
            />
          </IconButton>
        </Link>
      );
    }
    if (breadCrumb.isActive) {
      return (
        <Typography
          key={breadCrumb.type}
          className="breadcrum-title-active"
          sx={{
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {`${breadCrumb.name}`}
        </Typography>
      );
    }
    return (
      <Link
        key={breadCrumb.type}
        className="breadcrum-title-inactive"
        underline="hover"
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
        }}
        onClick={(e: any) =>
          handleBreadCrumbClick(e, breadCrumb, previousState)
        }
      >
        {`${breadCrumb.name}`}
      </Link>
    );
  };

  const renderBreadCrumbItem = (
    bredCrumbItem: Breadcrumb,
    previousState: NavigationState
  ) => {
    switch (bredCrumbItem.type) {
      case BreadcrumbType.HOME:
        return renderBreadCrumb(bredCrumbItem, previousState);
      case BreadcrumbType.ALL_PRODUCTS:
        return renderBreadCrumb(bredCrumbItem, previousState);
      case BreadcrumbType.EFFECT:
      case BreadcrumbType.CATEGORY:
      case BreadcrumbType.SEARCH_RESULTS:
      case BreadcrumbType.PRODUCT_DETAILS:
      case BreadcrumbType.LOGIN:
      case BreadcrumbType.SIGN_UP:
      case BreadcrumbType.VERIFY_CODE:
      case BreadcrumbType.PROFILE:
        return renderBreadCrumb(bredCrumbItem, previousState);
      default:
        return null;
        break;
    }
  };

  const renderBreadCrumbItems = (
    previousState: NavigationState,
    url: string
  ) => {
    let finalItems = [];
    const finalUrl = decodeURIComponent(url);
    const breadCrumbTypeArr: IBreadcrumb[] =
      previousState && previousState.breadCrumbsArray
        ? previousState.breadCrumbsArray
        : [];
    if (breadCrumbTypeArr && breadCrumbTypeArr.length > 0) {
      const breadCrumbsList = Breadcrumb.listForHome(breadCrumbTypeArr);
      breadCrumbsList.forEach((breadCrumbItem, i) => {
        const result = breadCrumbItem.isPresentInURL(finalUrl);
        if (result) {
          finalItems.push(renderBreadCrumbItem(breadCrumbItem, previousState));
        }
      });
    }
    return finalItems;
  };

  const breadCrumItems = () => {
    let finalItems = [];
    const match = matchRoute(myRoutes, props.router.location);
    if (match) {
      const { router } = props;
      const previousState = router.location.state as NavigationState;
      let route = match[0];
      const pathName = route.route.path;
      if (
        pathName === AppRoutes.PRODUCTS ||
        pathName === "products/:productId" ||
        pathName === AppRoutes.ACCOUNT ||
        pathName === AppRoutes.LOGIN ||
        pathName === AppRoutes.SIGNUP ||
        pathName === AppRoutes.VERIFY_CODE
      ) {
        const url = router.location.pathname + router.location.search;
        finalItems = finalItems.concat(
          Array.from(renderBreadCrumbItems(previousState, url))
        );
      }
    }
    return finalItems;
  };

  const renderBreadCrumList = () => {
    const items = breadCrumItems();
    if (items.length === 0) return null;
    return <Breadcrumbs aria-label="breadcrumb">{items}</Breadcrumbs>;
  };

  return (
    <Box
      sx={{
        display: {
          sm: "block",
          xs: "none",
        },
      }}
      className="cmenu-breadcrumb-container"
      role="presentation"
      onClick={handleClick}
    >
      {renderBreadCrumList()}
    </Box>
  );
};
export default BreadcrumbsComponent;
