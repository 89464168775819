import SvgIcon from "@mui/material/SvgIcon";
export default function Close(props) {
  return (
    <SvgIcon {...props}>
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="1.26172"
        width="14.2609"
        height="1.78261"
        transform="rotate(45 1.26172 0)"
        fill="currentColor"
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="11.3477"
        y="1.25781"
        width="14.2609"
        height="1.78261"
        transform="rotate(135 11.3477 1.25781)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
