import TextUtils from "./TextUtils";
import LocalStorageUtils from "./LocalStorageUtils";

export default class SessionManager {

  private ACCESS_TOKEN = "pAccessToken";
  private REFRESH_TOKEN = "pRefreshToken";
  private JANE_ACCESS_TOKEN = "pJAccessToken";
  
  public static shared(): SessionManager {
    if (!SessionManager.sSessionManager) {
        SessionManager.sSessionManager = new SessionManager();
    }
    return SessionManager.sSessionManager;
  }

  private static sSessionManager: SessionManager | null;


  // Backend access token
  private mAccessToken: string | undefined;
  public get accessToken(): string | undefined {
    if (this.mAccessToken) {
      return this.mAccessToken;
    } else {
      const sessionAuthToken = LocalStorageUtils.getItem(this.ACCESS_TOKEN);
      if (sessionAuthToken) {
        return (this.mAccessToken = sessionAuthToken);
      }
      return undefined;
    }
  }

  // Backend refresh token
  private mRefreshToken: string | undefined;
  public get refreshToken(): string | undefined {
    if (this.mRefreshToken) {
      return this.mRefreshToken;
    } else {
      const sessionAuthToken = LocalStorageUtils.getItem(this.REFRESH_TOKEN);
      if (sessionAuthToken) {
        return (this.mRefreshToken = sessionAuthToken);
      }
      return undefined;
    }
  }

  // Jane access token
  private mJaneAccessToken: string | undefined;
  public get janeAccessToken(): string | undefined {
    if (this.mJaneAccessToken) {
      return this.mJaneAccessToken;
    } else {
      const sessionAuthToken = LocalStorageUtils.getItem(this.JANE_ACCESS_TOKEN);
      if (sessionAuthToken) {
        return (this.mJaneAccessToken = sessionAuthToken);
      }
      return undefined;
    }
  }

  private constructor() { }

  public init() {
    this.mJaneAccessToken = undefined;
    this.mAccessToken = undefined;
    this.mRefreshToken = undefined;
  }

  public saveJaneAccessToken(accessToken: string) {
    if (accessToken && !TextUtils.isEmpty(accessToken)) {
      this.mJaneAccessToken = accessToken
      LocalStorageUtils.storeItem(this.JANE_ACCESS_TOKEN, accessToken);
    } else {
      localStorage.removeItem(this.JANE_ACCESS_TOKEN);
    }
  }

  public saveOnboardingTokenDetails(loginResponse: any) {
    this.clearSession();
    if(!loginResponse) return;
    let accessToken = loginResponse.accessToken;
    let refreshToken = loginResponse.refreshToken;
    if (accessToken && !TextUtils.isEmpty(accessToken) && refreshToken && !TextUtils.isEmpty(refreshToken)) {
      this.mAccessToken = accessToken;
      this.mRefreshToken = refreshToken;
      LocalStorageUtils.storeItem(this.ACCESS_TOKEN, accessToken);
      LocalStorageUtils.storeItem(this.REFRESH_TOKEN, refreshToken);
    } else {
      this.mAccessToken = undefined;
      this.mRefreshToken = undefined;
      localStorage.removeItem(this.ACCESS_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
    }
  }

  public saveRefreshTokenDetails(loginResponse: any) {
    if(!loginResponse) return;
    let accessToken = loginResponse.accessToken;
    if (accessToken && !TextUtils.isEmpty(accessToken)) {
      this.mAccessToken = accessToken;
      LocalStorageUtils.storeItem(this.ACCESS_TOKEN, accessToken);
    } else {
      this.mAccessToken = undefined;
      localStorage.removeItem(this.ACCESS_TOKEN);
    }
  }

  public isTokenAvailable(): boolean {
    const authToken = this.accessToken;
    return authToken && !TextUtils.isEmpty(authToken) ? true : false;
  }

  public isJaneTokenAvailable(): boolean {
    const authToken = this.janeAccessToken;
    return authToken && !TextUtils.isEmpty(authToken) ? true : false;
  }

  /* Clear Session */
  public clearSession() {
    if (LocalStorageUtils.localStorageAvailable()) {
      localStorage.removeItem(this.ACCESS_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
      localStorage.removeItem(this.JANE_ACCESS_TOKEN);
    }
    this.mAccessToken = undefined;
    this.mRefreshToken = undefined;
    this.mJaneAccessToken = undefined;
    SessionManager.sSessionManager = null;
  }
}
