import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import Product from "../../../../core/models/Product";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { NavigationProps } from "../../../../Navigation.types";
import withRouter from "../../../../withRouter";
import Navigation from '../../../../Navigation';

interface IBestSellingProductComponentProps {
  router: NavigationProps;
  pProduct: Product;
}
class BestSellingProductComponent extends React.Component<
  IBestSellingProductComponentProps,
  any
> {
  render() {
    return (
      <Card
        sx={{
          width: "19vw",
          height: "100%",
          backgroundColor: "background",
          borderRadius: "14px",
          ":hover": {
            boxShadow: 20,
          },
        }}
      >
        <CardActionArea
          sx={{
            padding: PixelsToViewPortUnitConverter.getvw(20),
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          onClick={() => {
            Navigation.toProductDetails(this.props.router,this.props.pProduct)
          }}
        >
          <CardMedia
            component="img"
            height="50%"
            image={this.props.pProduct.getProductCardImageUrl()}
          />
          <CardContent
            sx={{
              padding: "0px",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              flex: "1 0 auto",
            }}
          >
            <Typography
              sx={{
                marginTop: PixelsToViewPortUnitConverter.getvw(20),
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                lineClamp: "2",
                maxLines: "2",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                fontWeight: "700",
                fontFamily: "Overpass",
                textAlign: "start",
                flex: "1 0 auto",
              }}
            >
              {this.props.pProduct.name}
            </Typography>
            <Typography
              sx={{
                textAlign: "start",
                textTransform: "capitalize",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                fontFamily: "Overpass",
                fontWeight: "700",
                color: "#A39E9E",
              }}
            >
              {this.props.pProduct.kind}
            </Typography>
            <Typography
              sx={{
                textAlign: "start",
                textTransform: "capitalize",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                fontFamily: "Overpass",
                fontWeight: "700",
                color: "secondary.main",
                marginTop: PixelsToViewPortUnitConverter.getvw(16),
              }}
            >
              {this.props.pProduct.getPriceInfo()}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default withRouter(BestSellingProductComponent);
