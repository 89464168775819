import { Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import * as React from "react";
import Effect from "../../../../core/models/Effect";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Navigation from "../../../../Navigation";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import withRouter from "../../../../withRouter";
import Loader from "../../../common/loader/Loader";

interface IEffectsComponentState {
  sShowLoader: boolean;
}

const EffectNameTypo = styled(Typography)(({ theme }) => ({
  position: "relative",

  borderBottomRightRadius: "14px",
  color: theme.palette.primary.contrastText,
  fontFamily: "Beloved",
  fontWeight: 400,
  lineHeight: 1,

  fontSize: PixelsToViewPortUnitConverter.getFontVw(64),
  paddingRight: PixelsToViewPortUnitConverter.getvw(40),
  paddingLeft: PixelsToViewPortUnitConverter.getvw(40),
  textAlign: "center",
  height: "75px",
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    paddingRight: "0px",
    paddingLeft: "0px",
    width: "100%",
  },
}));

const svg = require("external-svg-loader");
class EffectsComponent extends React.Component<any, IEffectsComponentState> {
  EFFECTS_TILES_FONT_FAMILY = [
    "Playfair",
    "Petit",
    "PlusJakarta",
    "Poly",
    "Playfair",
    "Piazzolla",
    "Poly",
    "PortLligat",
  ];
  constructor(props: any) {
    super(props);
    this.state = {
      sShowLoader:
        PartnerManager.shared().effects == undefined ||
        PartnerManager.shared().effects.length == 0,
    };
  }

  componentDidMount() {
    if (
      PartnerManager.shared().effects == undefined ||
      PartnerManager.shared().effects.length == 0
    ) {
      StoreOperationsService.getEffects(
        PartnerManager.shared().currentSelectedStore.id
      ).then((_) => {
        this.setState({
          sShowLoader: false,
        });
      });
    }
  }

  render() {
    const columnSpacing =
      (window.innerWidth * 30) /
      PixelsToViewPortUnitConverter.getBaseWidth() /
      8;
    const rowSpacing =
      (window.innerWidth * 46) /
      PixelsToViewPortUnitConverter.getBaseWidth() /
      8;
    return (
      <Stack
        sx={{
          position: "relative",
          marginTop:{
            sm:PixelsToViewPortUnitConverter.getvw(138),
            xs:PixelsToViewPortUnitConverter.getvw(0),
          }, 
          backgroundColor: "rgba(0,0,0,0.05)",
          paddingTop: {
            sm:PixelsToViewPortUnitConverter.getvw(103),
            xs:PixelsToViewPortUnitConverter.getvw(60),
          },
          paddingBottom: {
            sm:PixelsToViewPortUnitConverter.getvw(103),
            xs:PixelsToViewPortUnitConverter.getvw(70),
          },
          minHeight: "180px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Bebas",
            fontWeight: 400,
           fontSize:{
            sm: PixelsToViewPortUnitConverter.getFontVw(35),
            xs:PixelsToViewPortUnitConverter.getFontVw(20),
           },
            marginBottom: PixelsToViewPortUnitConverter.getvw(60),
            color: (theme) => theme.palette.primary.contrastText,
            textAlign: "center",
            letterSpacing: "0.1em",
          }}
        >
          HOW WOULD YOU LIKE TO FEEL TODAY?
        </Typography>
        {this.state.sShowLoader ? (
          <Loader pshow={true} />
        ) : (
          <Box
            sx={{
              marginX: PixelsToViewPortUnitConverter.getvw(200),
            }}
          >
            <Grid
              columnSpacing={columnSpacing}
              rowSpacing={rowSpacing}
              container
              columns={{ xs: 12, sm: 12 }}
            >
              {PartnerManager.shared()
                .effects.slice(
                  0,
                  Math.min(
                    PartnerManager.shared().effects.length,
                    this.EFFECTS_TILES_FONT_FAMILY.length
                  )
                )
                .map((effect, index) => {
                  return (
                    <Grid
                      sx={{ position: "relative" }}
                      item
                      xs={6}
                      sm={3}
                      key={effect.name}
                    >
                      <Box
                        sx={{
                          borderWidth: PixelsToViewPortUnitConverter.getvw(2),
                          borderColor: "#ffffff",
                          borderStyle: "solid",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {/* <img
                        style={{
                          margin: "auto",
                          paddingTop: PixelsToViewPortUnitConverter.getvw(24),
                          paddingBottom: PixelsToViewPortUnitConverter.getvw(
                            24
                          ),
                        }}
                        src={Effect.getEffectImageUrl("effectv2", effect.name)}
                      ></img> */}
                        <svg
                          data-src={Effect.getEffectImageUrl(
                            "effects_v2",
                            effect.name
                          )}
                          style={{
                            margin: "auto",
                            paddingTop: PixelsToViewPortUnitConverter.getvw(24),
                            paddingBottom: PixelsToViewPortUnitConverter.getvw(
                              24
                            ),
                            color: PartnerManager.shared().partner.theme
                              .pageHighlightColor,
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: this.EFFECTS_TILES_FONT_FAMILY[index],
                            fontWeight: 400,
                            letterSpacing: "0.1em",
                            fontSize: PixelsToViewPortUnitConverter.getFontVw(
                              20
                            ),
                            color: (theme) =>
                              theme.palette.primary.contrastText,
                            lineHeight: "unset",
                            marginBottom: PixelsToViewPortUnitConverter.getvw(
                              23
                            ),
                          }}
                        >
                          {effect.name}
                        </Typography>
                        <Button
                          sx={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            padding: "0px",
                            minWidth: "0px",
                            minHeight: "0px",
                          }}
                          onClick={() => {
                            Navigation.toShopAllByEffect(
                              this.props.router,
                              effect
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        )}
      </Stack>
    );
  }
}

export default withRouter(EffectsComponent);
