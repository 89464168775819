import dateFormat from "dateformat";

export default class DateUtils {
  public static readonly MIN_DOB = new Date(1930, 0, 1);
  public static readonly DEFAULT_DISPLAY_DATE_FORMATE = "mm-dd-yyyy hh:MM TT";
  public static readonly DEFAULT_DISPLAY_DATE_ONLY_FORMATE = "mm-dd-yyyy";
  public static readonly DEFAULT_DISPLAY_TIME_ONLY_FORMATE = "hh:MM TT";

  /*public static toDisplayFormat(date: Date): string {
    return dateFormat(date, DateUtils.DEFAULT_DISPLAY_DATE_FORMATE);
  }*/

  public static getDayName(day: number): string {
    const daylist = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday ",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return daylist[day];
  }

  public static convert24To12Hrs(in24HrsTime: string): string {
    return new Date("1970-01-01T" + in24HrsTime + "Z")
      .toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
      .replace("AM", "am")
      .replace("PM", "pm");
  }

  public static getCurrentTimeUnixTimestamp(): number {
    return Math.trunc(Date.now() / 1000);
  }

  public static timeStampToDisplayDateFormat(timestamp: number): string {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
  }

  public static dateToDisplayDateFormat(dt: Date): string {
    return new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(dt);
  }

  public static dateToDisplayDateFormatV2(dt: Date): string {
    return dateFormat(dt, "d mmmm yyyy h:MM TT");
  }
}
