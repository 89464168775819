import SvgIcon from "@mui/material/SvgIcon";
export default function EditPin(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.1465 32L15.8798 28.3706H37.3333V32H12.1465ZM30.1822 6.7476L25.923 2.60703L28.184 0.408946C28.4995 0.136316 28.8851 0 29.3408 0C29.7966 0 30.1822 0.153354 30.4977 0.460064L32.4432 2.35144C32.7587 2.65815 32.9164 3.03301 32.9164 3.47604C32.9164 3.91906 32.7412 4.29393 32.3906 4.60064L30.1822 6.7476ZM29.0253 7.8722L4.20657 32H0V27.9105L24.8188 3.73163L29.0253 7.8722Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
