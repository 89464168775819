
export default class AppRoutes {
    
    public static readonly ROOT = "/";
    public static readonly PRODUCTS = "/products";
    public static readonly PRODUCT_DETAILS = "/products/:productId";
    public static readonly CART = "/cart";
    public static readonly ACCOUNT = "/account";
    public static readonly LOGIN = "/login";
    public static readonly SIGNUP = "/signup";
    public static readonly VERIFY_CODE = "/verify_code";
    public static readonly SEARCH = "/search";
    public static readonly API = "/api";
    
    private constructor() { }
}