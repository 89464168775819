import SvgIcon from "@mui/material/SvgIcon";
export default function PinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M26 39L14.4222 21.6333C8.25744 12.3862 14.8863 5.70117e-07 26 1.5417e-06C37.1137 2.51329e-06 43.7426 12.3862 37.5778 21.6333L26 39Z"
        fill="#1FBC7A"
      />
      <path
        d="M26.2545 4C25.1416 8.1375 22.9901 15.157 26.2545 19.9508C29.3743 15.585 27.2057 8.2231 26.2545 4Z"
        fill="white"
      />
      <path
        d="M16.1014 10.1032C17.873 13.4027 20.711 19.1583 25.661 20.2521C25.0295 15.6045 19.2376 12.2201 16.1014 10.1032Z"
        fill="white"
      />
      <path
        d="M17.5048 19.8446C19.4772 20.6177 22.8092 22.0587 25.3435 20.7165C23.3685 18.9447 19.6321 19.608 17.5048 19.8446Z"
        fill="white"
      />
      <path
        d="M22.6486 22.6934C23.488 22.4942 24.9373 22.2028 25.4736 21.1654C24.4015 21.0311 23.3054 22.1204 22.6486 22.6934Z"
        fill="white"
      />
      <path
        d="M36.383 10.1032C34.6114 13.4027 31.7734 19.1583 26.8234 20.2521C27.4548 15.6045 33.2468 12.2201 36.383 10.1032Z"
        fill="white"
      />
      <path
        d="M34.9796 19.8446C33.0071 20.6177 29.6751 22.0587 27.1409 20.7165C29.1158 18.9447 32.8522 19.608 34.9796 19.8446Z"
        fill="white"
      />
      <path
        d="M29.8357 22.6934C28.9964 22.4942 27.5471 22.2028 27.0108 21.1654C28.0828 21.0311 29.1789 22.1204 29.8357 22.6934Z"
        fill="white"
      />
    </SvgIcon>
  );
}
