import { Auth } from "aws-amplify";
import WebServiceUtils from "../core/webservice/WebServiceUtils";
import { AuthResponse } from "../core/models/AuthResponse";
import SessionManager from "../core/utils/SessionManager";
import TextUtils from "../core/utils/TextUtils";
import { ApiError } from '../core/webservice/ApiError';
import Strings from '../core/utils/Strings';
import AmplifyManager from "../core/utils/AmplifyManager";

export default class UserAuthService {

    public static async signInUser(username: string, password: string) {
        if(TextUtils.isEmpty(username) || TextUtils.isEmpty(password)) {
            return Promise.reject(
                new ApiError(400, Strings.REQUEST_PARAMS_MISSING)
            );
        }

        try {
            const data = {username,password};
            const response = await Auth.signIn(data);
            console.log('signIn response :'+JSON.stringify(response))
            return Promise.resolve(response);
        } catch (error: any) {
            console.log("error sign in:", error);
            const { message } = error
            let msg = message
            let errorCode = 500
            if(error['code'] === "UserNotConfirmedException"){ 
                errorCode = 601
            }
            else if(error['code'] === "UserNotFoundException"){ 
              errorCode = 602
              msg = Strings.USER_NOT_FOUND_ERROR
            }
            return Promise.reject(
                new ApiError(errorCode, msg)
            );
        }
    }
    public static async signUp(
      username: string,
      password: string,
      phone: string
    ) {

        let sPhone = phone
        if(sPhone && sPhone.startsWith('+1')) {
            sPhone = TextUtils.trimString(sPhone)
            sPhone = sPhone.replace(' ', '')
            sPhone = sPhone.replace('(', '')
            sPhone = sPhone.replace(')', '')
            sPhone = sPhone.replace('-', '')
            sPhone = sPhone.replace('+1','')
        }
        sPhone = (sPhone && sPhone.length === 10) ? `+1${sPhone}` : ''
        try {
            const data = {
                username, // will be users email
                password,
                attributes: {
                    given_name: "",
                    family_name: "",
                    phone_number: `${sPhone}`,
                    // "custom:zipcode": "12345",
                    // "custom:dispensary": "My Dispensary",
                },
            };
            const response = await Auth.signUp(data);
            return Promise.resolve(response);
        } catch (error: any) {
            console.log("error --:", error);
            console.log("error code:", error['code']);
            const { message } = error
            let msg = message
            let errorCode = 500
            /*if(error['code'] === "InvalidPasswordException"){ 
                errorCode = 603
                msg = Strings.PASSWORD_CONSTRAINT_ERROR
            }
            else*/ 
            if(error['code'] === "UserLambdaValidationException"){ 
              errorCode = 604
              msg = Strings.INVALID_PHONE_NUMBER_ERROR
            }
            return Promise.reject(
                new ApiError(errorCode, msg)
            );
        }
  }

  public static async forgetPassword(username: string) {
    try {
      const response = await Auth.forgotPassword(username);
      console.log("forgetPassword response :" + JSON.stringify(response));
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error;
      return Promise.reject(new ApiError(500, message));
    }
  }

  public static async confirmForgetPasswordCode(
    username: string,
    code: string,
    new_password: string
  ) {
    try {
      const response = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password
      );
      console.log(
        "confirmForgetPasswordCode response :" + JSON.stringify(response)
      );
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error;
      return Promise.reject(new ApiError(500, message));
    }
  }

  public static async resendSignUpCode(username: string) {
    try {
      const response = await Auth.resendSignUp(username);
      console.log("resendSignUpCode response :" + JSON.stringify(response));
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error;
      return Promise.reject(new ApiError(500, message));
    }
  }

  public static async confirmSignUpCode(username: string, code: string) {
    try {
      const response = await Auth.confirmSignUp(username, code);
      console.log("confirmSignUpCode response :" + JSON.stringify(response));
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error;
      return Promise.reject(new ApiError(500, message));
    }
  }

  public static async changePassword(oldPassword: string, newPassword: string) {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const response = await Auth.changePassword(
        currentUser,
        oldPassword,
        newPassword
      );
      console.log("changePassword response :" + JSON.stringify(response));
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error
      let msg = message
      let errorCode = 500
      if(error['code'] === "NotAuthorizedException"){ 
          errorCode = 605
          msg = Strings.INCORRECT_PASSWORD_ON_CHANGE
      } else if(error['code'] === "InvalidPasswordException"){ 
        errorCode = 603
        msg = Strings.PASSWORD_CONSTRAINT_ERROR
      }
      return Promise.reject(new ApiError(errorCode, msg));
    }
  }

  public static async signOut() {
    try {
      const response = await Auth.signOut();
      console.log("signOut response :" + JSON.stringify(response));
      AmplifyManager.shared().signOutUserSession();
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error;
      return Promise.reject(new ApiError(500, message));
    }
  }

  public static async currentSession() {
    try {
      const response = await Auth.currentSession();
      console.log("currentSession response :" + JSON.stringify(response));
      return Promise.resolve(response);
    } catch (error: any) {
      console.log("error :", error);
      const { message } = error;
      return Promise.reject(new ApiError(500, message));
    }
  }
}
