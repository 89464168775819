import { createTheme } from "@mui/material/styles";
import PixelsToViewPortUnitConverter from "../core/utils/PixelsToViewPortUnitConverter";

export function getDefaultTheme() {
  return createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: "#fff",
        contrastText: "#000",
      },
      secondary: {
        main: "#000",
        contrastText: "#fff",
      },
      background: {
        default: "#ffffff",
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          fullWidth: false,
          color: "secondary",
        },
        styleOverrides: {
          root: {
            color: "#fff",
            fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
            fontFamily: "Overpass",
            fontWeight: 700,
            width: "fit-content",
            paddingTop: PixelsToViewPortUnitConverter.getvw(30),
            paddingBottom: PixelsToViewPortUnitConverter.getvw(30),
            paddingRight: PixelsToViewPortUnitConverter.getvw(50),
            paddingLeft: PixelsToViewPortUnitConverter.getvw(50),
          },
          contained: {
            borderRadius: "47px",
          },
          startIcon: {
            marginRight: PixelsToViewPortUnitConverter.getvw(16),
            marginLeft: "0px",
          },
          endIcon: {
            maring: "0px",
            marginRight: "0px",
            marginLeft: PixelsToViewPortUnitConverter.getvw(8),
          },
          iconSizeMedium: {
            "& > *:first-child": {
              fontSize: "inherit",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            height: "20px",
            boxSizing: "border-box",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "0px",
          },
        },
      },
    },
  });
}
