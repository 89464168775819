import DailyWorkingHours from "./DaliyWorkingHours";

export interface IStore {
  id: number;
  delivery: boolean;
  description: string;
  full_address: string;
  lat: number;
  long: number;
  medical: boolean;
  name: string;
  phone: string;
  photo: string;
  pickup: boolean;
  recreational: boolean;
  rating: number;
  reviews_count: number;
  time_zone_identifier: string;
  working_hours: [];
  url_slug: string;
}

export default class Store {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mName: string;
  public get name() {
    return this.mName;
  }

  private mDescription: string;
  public get description() {
    return this.mDescription;
  }

  private mPhoto: string;
  public get photo() {
    return this.mPhoto;
  }

  private mFullAddress: string;
  public get fullAddress() {
    return this.mFullAddress;
  }

  private mPhone: string;
  public get phone() {
    return this.mPhone;
  }

  private mTimeZone: string;
  public get timezone() {
    return this.mTimeZone;
  }

  private mIsDelivery: boolean;
  public get isDelivery() {
    return this.mIsDelivery;
  }

  private mIsPickup: boolean;
  public get isPickup() {
    return this.mIsPickup;
  }

  private mIsMedical: boolean;
  public get isMedical() {
    return this.mIsMedical;
  }

  private mIsRecreational: boolean;
  public get isRecreational() {
    return this.mIsRecreational;
  }

  private mLatitude: number;
  public get latitude() {
    return this.mLatitude;
  }

  private mLongitude: number;
  public get longitude() {
    return this.mLongitude;
  }

  private mStoreWorkingHours: DailyWorkingHours[];
  public get storeWorkingHours() {
    return this.mStoreWorkingHours;
  }

  private mRating: number;
  public get rating() {
    return this.mRating;
  }

  private mReviewsCount: number;
  public get reviewsCount() {
    return this.mReviewsCount;
  }

  private mUrlSlug: string;
  public get urlSlug() {
    return this.mUrlSlug;
  }

  public constructor(responseJson: IStore) {
    this.mId = responseJson.id;
    this.mName = responseJson.name;
    this.mDescription = responseJson.description;
    this.mPhoto = responseJson.photo;
    this.mFullAddress = responseJson.full_address;
    this.mPhone = responseJson.phone;
    this.mTimeZone = responseJson.time_zone_identifier;
    this.mIsDelivery = responseJson.delivery;
    this.mIsPickup = responseJson.pickup;
    this.mIsMedical = responseJson.medical;
    this.mIsRecreational = responseJson.recreational;
    this.mLatitude = responseJson.lat;
    this.mLongitude = responseJson.long;
    this.mRating = responseJson.rating;
    this.mReviewsCount = responseJson.reviews_count;
    this.mUrlSlug = responseJson.url_slug;

    if (responseJson.working_hours) {
      this.mStoreWorkingHours = [];

      for (let dailyWorkingHours of responseJson.working_hours) {
        this.mStoreWorkingHours.push(new DailyWorkingHours(dailyWorkingHours));
      }
    }
  }

  public static list(arrayOfStore: any): Store[] {
    let finalArray: Store[] = [];
    for (let store of arrayOfStore) {
      let storeObj = new Store(store);
      finalArray.push(storeObj);
    }
    return finalArray;
  }

  public static storeWithAlgoliaResult(response: any): Store {
    let store: Store = new Store(response);
    return store;
  }
}
