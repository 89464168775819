
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ExpandIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 23 23"
    return (
        <SvgIcon {...uProps}>
            <rect width="23" height="23"/>
            <rect x="8" y="11" width="8" height="1" fill="black"/>
            <rect x="12.5" y="7.5" width="8" height="1" transform="rotate(90 12.5 7.5)" fill="black"/>
        </SvgIcon>
    );
}