import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box/Box";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import withRouter from "../../../../withRouter";
import BannerComponent from "./BannerComponent";
import CustomerReviewsComponent from "../../effectsv2/home/CustomerReviewsComponent";
import StoreLocationMapComponent from "../../effectsv2/home/StoreLocationMapComponent";
import PopularProducts from "../../effectsv2/menu_products/PopularProducts";
import { CartProduct } from "../../../../core/models/Product";
import { connect } from "react-redux";
import { CartActionType } from "../../../common/cart/CartActionTypes";
import { Cart } from "../../../common/cart/CartReducer";
import { RootState } from "../../../../redux/Reducer";
import CategoriesComponent from "./CategoriesComponent";
import { alpha } from "@mui/material/styles";
import { NavigationProps } from "../../../../Navigation.types";
import LocationBtn from "../../../common/locationBtn/LocationBtn";

interface ICategoriesHomeComponentProps {
  router: NavigationProps;
  cart: Cart | undefined;
  pStoreId: number;
  addToCartOrUpdate: (product: CartProduct) => void;
}

interface ICategoriesHomeComponentState {
  cart: Cart | undefined;
  forceRefresh: boolean;
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cartProducts.cart,
  pStoreId: state.cartProducts.cart.storeId,
});

class CategoriesHomeComponent extends React.Component<
  ICategoriesHomeComponentProps,
  ICategoriesHomeComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      cart: props.cart,
      forceRefresh: false,
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.cart !== prevState.cart) {
      return { cart: nextProps.cart };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.pStoreId != prevProps.pStoreId) {
      this.setState({ forceRefresh: true });
      setTimeout(() => {
        this.setState({ forceRefresh: false });
      }, 100);
    }
  }

  render() {
    return (
      !this.state.forceRefresh && (
        <Stack
          sx={{
            position: "relative",
            width: "100%",
            marginTop: PixelsToViewPortUnitConverter.getvw(116),
          }}
        >
          <LocationBtn />
          <BannerComponent />
          <Box
            sx={{
              marginTop: PixelsToViewPortUnitConverter.getvw(54),
            }}
          />
          <PopularProducts
            router={this.props.router}
            marginLeft={118}
            marginRight={118}
            pCart={this.state.cart}
            pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
          />
          <Box
            sx={{
              marginX: "auto",
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.contrastText, 0.35),
              width: "1px",
              height: "14vh",
              marginBottom: PixelsToViewPortUnitConverter.getvw(48),
              display:{
                sm:"block",
                xs:"none"
              },
            }}
          />
          <CategoriesComponent />
          <Box
            sx={{
              marginX: "auto",
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.contrastText, 0.35),
              width: "1px",
              height: "12vh",
              marginTop: PixelsToViewPortUnitConverter.getvw(80),
              marginBottom: PixelsToViewPortUnitConverter.getvw(70),
              display:{
                sm:"block",
                xs:"none"
              },
            }}
          />
          <StoreLocationMapComponent />
          <Box
            sx={{
              marginTop: {
                sm:PixelsToViewPortUnitConverter.getvw(134),
                xs:PixelsToViewPortUnitConverter.getvw(200),
              },
            }}
          />
          <CustomerReviewsComponent />
        </Stack>
      )
    );
  }

  private onAddOrRemoveProductFromCart = (product: CartProduct) => {
    this.props.addToCartOrUpdate(product);
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  addToCartOrUpdate: (product: CartProduct) =>
    dispatch({
      type: CartActionType.ADD_TO_CART_OR_UPDATE,
      payload: product,
    }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoriesHomeComponent)
);
