import React from "react";
import Box from "@mui/material/Box";
import SolarSystemLoading from "react-loadingg/lib/LoopCircleLoading";
import PartnerManager from "../../../core/utils/PartnerManager";
import { PartnerAppThemeType } from "../../../core/models/PartnerTheme";
interface ILoaderProps {
  pshow: boolean;
}
class Loader extends React.Component<ILoaderProps, any> {
  public constructor(props: ILoaderProps) {
    super(props);
  }

  public render() {
    const loaderColor = PartnerManager.shared().partner
      ? PartnerManager.shared().partner.theme.name ==
        PartnerAppThemeType.EFFECTS
        ? PartnerManager.shared().partner.theme.backgroundColor
        : PartnerManager.shared().partner.theme.pageHighlightColor
      : "#000";
    return (
      this.props.pshow && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <SolarSystemLoading color={loaderColor} />
        </Box>
      )
    );
  }
}

export default Loader;
