import SvgIcon from "@mui/material/SvgIcon";
export default function LocationImg(props) {
  var uProps = { ...props };
  uProps.viewBox = "0 0 291 315";
  return (
    <SvgIcon {...uProps}>
      <g filter="url(#filter0_d_3410_1192)">
        <path
          d="M145.5 152.062C148.249 152.062 150.595 151.08 152.538 149.116C154.482 147.151 155.453 144.779 155.453 142C155.453 139.221 154.482 136.849 152.538 134.884C150.595 132.92 148.249 131.938 145.5 131.938C142.751 131.938 140.405 132.92 138.462 134.884C136.518 136.849 135.547 139.221 135.547 142C135.547 144.779 136.518 147.151 138.462 149.116C140.405 151.08 142.751 152.062 145.5 152.062ZM145.5 211C130.239 197.871 118.84 185.676 111.304 174.416C103.768 163.155 100 152.733 100 143.15C100 128.775 104.574 117.323 113.721 108.794C122.868 100.265 133.461 96 145.5 96C157.539 96 168.132 100.265 177.279 108.794C186.426 117.323 191 128.775 191 143.15C191 152.733 187.232 163.155 179.696 174.416C172.16 185.676 160.761 197.871 145.5 211Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3410_1192"
          x="0"
          y="0"
          width="291"
          height="315"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="50" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.333333 0 0 0 0 1 0 0 0 0 0.772549 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3410_1192"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3410_1192"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}
