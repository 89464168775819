import Filter, { FilterType } from './Filter';
import FilterUtils from '../utils/FilterUtils';
import TextUtils from '../utils/TextUtils';
import AppliedFilter from './AppliedFilter';
export default class FilterAttribute {

    // index of attribute
    private mIndex: number;
    public get index() {
      return this.mIndex;
    }

    // name of attribute
    private mName: string;
    public get name() {
      return this.mName;
    }

    // slug of attribute
    private mSlug: string;
    public get slug() {
      return this.mSlug;
    }

    // parent_slug of attribute
    private mParentSlug: string;
    public get parentSlug() {
      return this.mParentSlug;
    }

    // slug of filter
    private mFilterSlug: string;
    public get filterSlug() {
      return this.mFilterSlug;
    }

    // value of applied filter
    private mAppliedFilterValue: string;
    public get appliedFilterValue() {
      return this.mAppliedFilterValue;
    }

    // filter type
    private mType: FilterType;
    public get type() {
      return this.mType;
    }

    // product count
    private mProductsCount: number;
    public get productsCount() {
      return this.mProductsCount;
    }

    // enabled or not
    private mEnabled: boolean;
    public get enabled() {
      return this.mEnabled;
    }

    // selected or not
    private mSelected: boolean;
    public get isSelected() {
      return this.mSelected;
    }

    // array of sub attributes if any
    private mSubFilterAttributes: FilterAttribute[];
    public get subAttributes() {
      return this.mSubFilterAttributes;
    }

    // isAll attribute
    private mIsAll: boolean;
    public get isAll() {
      return this.mIsAll;
    }

    // isChild attribute
    private mIsChild: boolean;
    public get isChild() {
      return this.mIsChild;
    }

    // isDummy attribute
    private mIsDummy: boolean;
    public get isDummy() {
      return this.mIsDummy;
    }

    public setSubFilterAttributes(filterAttributes: FilterAttribute[]) {
        this.mSubFilterAttributes = filterAttributes
    }
  
    public constructor() {
        this.mEnabled = true
        this.mSelected = false
        this.mSubFilterAttributes = []
        this.mIsAll = false
        this.mIsChild = false
        this.mParentSlug = ''
        this.mIsDummy = false
    }

    public init() {
        this.mEnabled = true
        this.mSelected = false
    }

    public initWith(name: string, slug: string, appliedFilterValue: any,productCount: number, filter: Filter) {
        this.mName = name
        this.mSlug = slug
        this.mProductsCount = productCount
        this.mType  = filter.type
        this.mFilterSlug = filter.slug
        this.mAppliedFilterValue = appliedFilterValue
    }

    public setFilterType(fType: FilterType) {
        this.mType = fType
    }

    public setFilterSlug(filterSlug: string) {
        this.mFilterSlug = filterSlug
    }

    public displayLabel() {
        if(this.mType === FilterType.CUSTOMER_REVIEWS) {
            const val = `${this.mAppliedFilterValue}`
            return `${val.replace(':','')} and up`
        }
        return `${this.mName}`
    }

    public displayName(){
        return `${this.mName} (${this.mProductsCount})`
    }

    public selected(isSelected: boolean) {
        this.mSelected = isSelected
    }

    private setSubAttributes(subAttr: FilterAttribute[]) {
        this.mSubFilterAttributes = subAttr
    }

    public static getAllFilterAttribute(filter: Filter, isOtherFilterApplied: boolean) {
        let filterAttributeObj: FilterAttribute = new FilterAttribute()
        filterAttributeObj.init()
        filterAttributeObj.mName = 'All'
        filterAttributeObj.mSlug = 'all'
        filterAttributeObj.selected(!isOtherFilterApplied)
        filterAttributeObj.setFilterType(filter.type)
        filterAttributeObj.mAppliedFilterValue = ''
        filterAttributeObj.mIsAll = true
        return filterAttributeObj
    }

    public static getAllSubCategoryFilterAttribute(filter: Filter, parentCategory: FilterAttribute ,isFilterApplied: boolean) {
        let filterAttributeObj: FilterAttribute = new FilterAttribute()
        filterAttributeObj.init()
        filterAttributeObj.mName = 'All'
        filterAttributeObj.mSlug = parentCategory.slug
        filterAttributeObj.selected(isFilterApplied) 
        filterAttributeObj.setFilterType(filter.type)
        filterAttributeObj.mAppliedFilterValue = parentCategory.slug
        filterAttributeObj.mIsAll = true
        filterAttributeObj.mIsChild = true
        filterAttributeObj.mParentSlug = parentCategory.slug
        return filterAttributeObj
    }

    public static findParentFilterAttribute(arr: FilterAttribute[], slugName: string): FilterAttribute | undefined {
        let parentFAttribute = arr.find((item: FilterAttribute)=> item.slug == slugName);
        if(parentFAttribute) { 
            return parentFAttribute
        }
        return undefined
    }

    public static transformName(originalTitle: string|undefined|null, slug: string) {
        if(slug === 'available_weights') {
            return FilterUtils.getWeightTitle(originalTitle)
        }
        if(originalTitle && slug === 'root_types') {
            if(originalTitle.includes('-')) {
                const arrOfTitles = originalTitle.split('-')
                if(arrOfTitles && arrOfTitles.length > 0) {
                    return TextUtils.capitalize(arrOfTitles[0])
                }
            } else if(originalTitle.includes('_')) {
                const mStr = originalTitle.replace("_", " ");
                return TextUtils.capitalize(mStr)
            } else {
                return TextUtils.capitalize(originalTitle)
            }
        } else if(originalTitle && originalTitle.length > 0) {
            return TextUtils.capitalize(originalTitle)
        }
        return originalTitle
    }

    public static list(attributeItems: any, filter: Filter, appliedFiltersList: AppliedFilter[]): FilterAttribute[] {
        
        let finalArray: FilterAttribute[] = [];
        let tempArray: FilterAttribute[] = [];

        if(filter.type === FilterType.PRICE) {
            tempArray = FilterUtils.createPriceFilters(attributeItems,filter,appliedFiltersList)
        } else if(filter.type === FilterType.CUSTOMER_REVIEWS) {
            tempArray = FilterUtils.createCustomerReviewsFilters(attributeItems,filter,appliedFiltersList)
        } else if(filter.type === FilterType.AVAILABLE_WEIGHTS) { 
            tempArray = FilterUtils.createAvailableWeightsFilters(attributeItems,filter,appliedFiltersList)
        } else {
            Object.entries(attributeItems).forEach(
                ([key, value]) =>  {
                    let filterAttributeObj: FilterAttribute = new FilterAttribute()
                    filterAttributeObj.init()
                    filterAttributeObj.mName = this.transformName(key,filter.slug)
                    filterAttributeObj.mSlug = key
                    filterAttributeObj.mType = filter.type
                    filterAttributeObj.mFilterSlug = filter.slug
                    filterAttributeObj.mAppliedFilterValue = key
                    filterAttributeObj.mProductsCount = value as number
                    filterAttributeObj.mSelected = AppliedFilter.isFilterAttibuteSlugApplied(filterAttributeObj,appliedFiltersList)           
                    const categorySeparator = ":"
                    if(filter.type === FilterType.CATEGORIES && key.includes(categorySeparator)) {   
                        // child category 
                        if(key.includes(categorySeparator)) {
                            const categoryArr = key.split(categorySeparator)
                            const parentName = categoryArr[0] // parent name
                            filterAttributeObj.mName = this.transformName(categoryArr[1],filter.slug) // child name
                            filterAttributeObj.mParentSlug = parentName
                            filterAttributeObj.mIsChild = true
                            const parentFilterAttribute = FilterAttribute.findParentFilterAttribute(tempArray,parentName)
                            if(parentFilterAttribute) {
                                var currentAttr =  parentFilterAttribute.subAttributes
                                if(currentAttr) {
                                    if(currentAttr.length == 0) {
                                        const isFilterApplied = AppliedFilter.isChildFilterApplied(parentFilterAttribute, appliedFiltersList)
                                        currentAttr.push(FilterAttribute.getAllSubCategoryFilterAttribute(filter,parentFilterAttribute,isFilterApplied))
                                    }
                                    currentAttr.push(filterAttributeObj)
                                    parentFilterAttribute.setSubAttributes(currentAttr)
                                }
                            }
                        }
                    } else {
                        // root category
                        tempArray.push(filterAttributeObj)
                    }
                }
            );
        }

        // Sorting by A-Z
        if(filter.type === FilterType.LINEAGE ||
            filter.type === FilterType.CATEGORIES ||
            filter.type === FilterType.FEELINGS ||
            filter.type === FilterType.ROOT_CATEGORIES ||
            filter.type === FilterType.BRANDS) {
            tempArray.sort((a: FilterAttribute, b: FilterAttribute) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        }

        if(filter.type === FilterType.ACTIVITIES) {
            tempArray.sort((a: FilterAttribute, b: FilterAttribute) => (a.productsCount > b.productsCount ? -1 : 1))
        }

        // Sub categories arrangement
        if(filter.type === FilterType.CATEGORIES) {
            const subCategoriesItems = tempArray.filter((item: FilterAttribute)=> item.subAttributes.length > 0);
            if(subCategoriesItems.length > 0) { 
                const length = subCategoriesItems.length
                for (var i = 0; i < length ; i++) {
                    const indexTobeDelete = tempArray.indexOf(subCategoriesItems[i])
                    if(indexTobeDelete != -1) {
                        tempArray.splice(indexTobeDelete, 1);
                    }
                }
            }
            tempArray = tempArray.concat(subCategoriesItems)
        }

        // Add All element
        if(tempArray.length > 0) {
            const isOtherFilterApplied = AppliedFilter.isFilterApplied(filter.slug, appliedFiltersList)
            finalArray.push(FilterAttribute.getAllFilterAttribute(filter,isOtherFilterApplied))
            finalArray = finalArray.concat(tempArray)
        }
        
        // Assigning Index
        const length = finalArray.length
        for (var i = 0; i < length ; i++) {
          let mFilterAttrib = finalArray[i]
          /*if(mFilterAttrib.subAttributes.length > 0) {
            const lengthB = mFilterAttrib.subAttributes.length
            for (var j = 0; j < lengthB ; j++) {
                let mFilterAttribB = mFilterAttrib.subAttributes[j]
                mFilterAttribB.mIndex = j
            }
          }*/

          mFilterAttrib.mIndex = i
        }

        return finalArray;
    }

    public getQuery(): string {
        if(this.isAll === true) return undefined
        if(this.type === FilterType.CATEGORIES) {
            return `kind:"${this.slug}" OR root_types:"${this.slug}"`
        } else if(this.type === FilterType.LINEAGE || 
            this.type === FilterType.BRANDS ||
            this.type === FilterType.FEELINGS ||
            this.type === FilterType.ACTIVITIES ||
            this.type === FilterType.AVAILABLE_WEIGHTS) {
            return `${this.filterSlug}:"${this.slug}"`
        } else if(this.type === FilterType.PRICE ||
            this.type === FilterType.CUSTOMER_REVIEWS){
                return `${this.slug}`
        } else {
            return undefined
        }
    }

    public static dummyFilterAttribute(appliedFilter: AppliedFilter) {
        let dummyAttribute: FilterAttribute = undefined
        if(appliedFilter) {
            if(appliedFilter.slug === 'root_types') {
                // categories
                const isChildCategory = FilterUtils.isChildCategory(appliedFilter.value)
                
                if(!isChildCategory) {
                    // parent
                    dummyAttribute = new FilterAttribute()
                    dummyAttribute.init()
                    dummyAttribute.mName = this.transformName(appliedFilter.value,appliedFilter.slug)
                    dummyAttribute.mSlug = appliedFilter.value
                    dummyAttribute.mType = FilterUtils.getFilterEnums(appliedFilter.slug).filterType
                    dummyAttribute.mFilterSlug = appliedFilter.slug
                    dummyAttribute.mAppliedFilterValue = appliedFilter.value
                    dummyAttribute.mProductsCount = 0
                    dummyAttribute.mSelected = true 
                    dummyAttribute.mIsAll = false
                    dummyAttribute.mIsDummy = true
                } else {
                    // child
                    const childCategoryName = FilterUtils.getChildSlugName(appliedFilter.value)
                    const parentCategoryName = FilterUtils.getParentSlugName(appliedFilter.value)

                    dummyAttribute = new FilterAttribute()
                    dummyAttribute.init()
                    dummyAttribute.mName = this.transformName(childCategoryName,appliedFilter.slug)
                    dummyAttribute.mSlug = appliedFilter.value
                    dummyAttribute.mType = FilterUtils.getFilterEnums(appliedFilter.slug).filterType
                    dummyAttribute.mFilterSlug = appliedFilter.slug
                    dummyAttribute.mAppliedFilterValue = appliedFilter.value
                    dummyAttribute.mProductsCount = 0
                    dummyAttribute.mSelected = true 
                    dummyAttribute.mIsAll = false
                    dummyAttribute.mIsDummy = true
                    dummyAttribute.mParentSlug = parentCategoryName
                    dummyAttribute.mIsChild = true
                }

            } else if(appliedFilter.slug !== 'percent_thc' && appliedFilter.slug !== 'percent_cbd') {
                if(appliedFilter.slug === 'bucket_price') {
                    const range = FilterUtils.getMinMaxFromPriceRange(appliedFilter.value)
                    dummyAttribute = new FilterAttribute()
                    dummyAttribute.init()
                    dummyAttribute.mName = range.name
                    dummyAttribute.mSlug = range.slug
                    dummyAttribute.mType = FilterUtils.getFilterEnums(appliedFilter.slug).filterType
                    dummyAttribute.mFilterSlug = appliedFilter.slug
                    dummyAttribute.mAppliedFilterValue = range.rangeValue
                    dummyAttribute.mProductsCount = 0
                    dummyAttribute.mSelected = true 
                    dummyAttribute.mIsAll = false
                    dummyAttribute.mIsDummy = true
                } else if(appliedFilter.slug === 'aggregate_rating') {
                    const range = FilterUtils.getMinMaxFromCustomerReviewRange(appliedFilter.value)
                    dummyAttribute = new FilterAttribute()
                    dummyAttribute.init()
                    dummyAttribute.mName = range.name
                    dummyAttribute.mSlug = range.slug
                    dummyAttribute.mType = FilterUtils.getFilterEnums(appliedFilter.slug).filterType
                    dummyAttribute.mFilterSlug = appliedFilter.slug
                    dummyAttribute.mAppliedFilterValue = range.rangeValue
                    dummyAttribute.mProductsCount = 0
                    dummyAttribute.mSelected = true 
                    dummyAttribute.mIsAll = false
                    dummyAttribute.mIsDummy = true
                } else if(appliedFilter.slug === 'available_weights') {
                    //initWith('0.5g', 'half gram','half gram', pCountHalfGram, filter)
                    dummyAttribute = new FilterAttribute()
                    dummyAttribute.init()
                    dummyAttribute.mName = this.transformName(appliedFilter.value,appliedFilter.slug)
                    dummyAttribute.mSlug = appliedFilter.value
                    dummyAttribute.mType = FilterUtils.getFilterEnums(appliedFilter.slug).filterType
                    dummyAttribute.mFilterSlug = appliedFilter.slug
                    dummyAttribute.mAppliedFilterValue = appliedFilter.value
                    dummyAttribute.mProductsCount = 0
                    dummyAttribute.mSelected = true 
                    dummyAttribute.mIsAll = false
                    dummyAttribute.mIsDummy = true
                } else {
                    dummyAttribute = new FilterAttribute()
                    dummyAttribute.init()
                    dummyAttribute.mName = this.transformName(appliedFilter.value,appliedFilter.slug)
                    dummyAttribute.mSlug = appliedFilter.value
                    dummyAttribute.mType = FilterUtils.getFilterEnums(appliedFilter.slug).filterType
                    dummyAttribute.mFilterSlug = appliedFilter.slug
                    dummyAttribute.mAppliedFilterValue = appliedFilter.value
                    dummyAttribute.mProductsCount = 0
                    dummyAttribute.mSelected = true 
                    dummyAttribute.mIsAll = false
                    dummyAttribute.mIsDummy = true
                }                
            }
        }
        return dummyAttribute
    }
}