import { Box } from "@mui/system";
import { useState } from "react";
import "./Geolocation.scss";
import PartnerManager from "../../../core/utils/PartnerManager";
import { alpha } from "@mui/material/styles";

function GeolocationResults({ suggestion, handleSelect }) {
  const [hover, setHover] = useState(false);
  const {
    place_id,
    structured_formatting: { main_text, secondary_text },
    description,
  } = suggestion;

  return (
    <Box
      key={place_id}
      onClick={handleSelect(suggestion)}
      className="hoverLocationBox"
      sx={{
        backgroundColor: (theme) =>
          hover && alpha(theme.palette.primary.contrastText, 0.1),
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p>{`${description}`}</p>
    </Box>
  );
}

export default GeolocationResults;
