import React from "react";
import Store from "../../../core/models/Store";
import PartnerService from "../../../services/PartnerService";
import StoreOperationsService from "../../../services/StoreOperationsService";
import PartnerManager from "../../../core/utils/PartnerManager";
import Loader from "../loader/Loader";
import MessageComponent from "../message/MessageComponent";
import Box from "@mui/material/Box/Box";
import { ApiError } from "../../../core/webservice/ApiError";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { PartnerAppThemeType } from "../../../core/models/PartnerTheme";
import GeneralUtils from "../../../core/utils/GeneralUtils";
import connect from "react-redux/es/components/connect";
import { RootState } from "../../../redux/Store";
import { Cart } from "../cart/CartReducer";
import { CartActionType } from "../cart/CartActionTypes";
import TextUtils from "../../../core/utils/TextUtils";
import { withMediaQuery } from "../../../withMediaQuery";

interface LandingComponentProps {
  isMobile: boolean;
  onInitailDataLoadSccess(): void;
  onInitailDataLoadingFailure(): void;
  pCart: Cart | undefined;
  cartCreateOrUpdate: (cart: Cart | undefined) => void;
}

interface LandingComponentState {
  sLoadingError: boolean;
  sLoadingErrorTitle: string;
  sLoadingErrorMessage: string;
}

const mapDispatchToProps = (dispatch: any) => ({
  cartCreateOrUpdate: (cart: Cart | undefined) =>
    dispatch({
      type: CartActionType.CART_CREATE_OR_UPDATE,
      payload: cart,
    }),
});

const mapStateToProps = (state: RootState) => ({
  pCart: state.cartProducts.cart,
});

class LandingComponent extends React.Component<
  LandingComponentProps,
  LandingComponentState
> {
  constructor(props: LandingComponentProps) {
    super(props);
    this.state = {
      sLoadingError: false,
      sLoadingErrorTitle: "",
      sLoadingErrorMessage: "",
    };
  }

  componentDidMount() {
    const host = GeneralUtils.getHostName(window.location);
    if (!host) return;
    Promise.all([
      PartnerService.getPartnerInfo(host),
      PartnerService.generateJaneRootsToken(host),
    ])
      .then((_results) => {
        StoreOperationsService.getStores()
          .then((stores: Store[]) => {
            if (stores.length > 0) {
              const store = this.props.pCart
                ? stores.find((store) => store.id == this.props.pCart.storeId)
                : stores[0];

              PartnerManager.shared().setCurrentSelectedStore(store);

              if (!this.props.pCart)
                this.props.cartCreateOrUpdate({
                  storeId: store.id,
                  products: [],
                });

              if (
                PartnerManager.shared().partner.theme.name !=
                PartnerAppThemeType.EFFECTS
              ) {
                PixelsToViewPortUnitConverter.changeBaseWidth(1512);
                PixelsToViewPortUnitConverter.changeFontBaseWidth(1512);
                document.body.style.background = PartnerManager.shared().partner.theme.backgroundColor;
              }

              if (this.props.isMobile)
                PixelsToViewPortUnitConverter.changeFontBaseWidth(428);

              document.title = PartnerManager.shared().partner.appName;

              (document.getElementById(
                "favicon"
              ) as HTMLLinkElement).href = PartnerManager.shared().partner.theme.favIconUrl;

              (document.getElementById(
                "apple-touch-icon"
              ) as HTMLLinkElement).href = PartnerManager.shared().partner.theme.favIconUrl;

              this.props.onInitailDataLoadSccess();
            }
          })
          .catch((apiError: ApiError) => {
            this.handleApiFailure(apiError);
          });
      })
      .catch((apiError: ApiError) => {
        this.handleApiFailure(apiError);
      });
  }

  private handleApiFailure = (apiError: ApiError) => {
    this.setState({
      sLoadingError: true,
      sLoadingErrorMessage:
        apiError && apiError.message ? apiError.message : "",
    });
    this.props.onInitailDataLoadingFailure();
  };

  render() {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!this.state.sLoadingError && <Loader pshow={true} />}
        {this.state.sLoadingError && (
          <MessageComponent
            pShowGoToHomeBtn={false}
            pTitle={this.state.sLoadingErrorTitle}
            pMessage={
              TextUtils.isEmpty(this.state.sLoadingErrorMessage)
                ? "Sorry, this shop is currently unavailable."
                : this.state.sLoadingErrorMessage
            }
          />
        )}
      </Box>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQuery(LandingComponent));
