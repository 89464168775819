import React from "react";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import PartnerManager from "../../../../core/utils/PartnerManager";
import IconButton from "@mui/material/IconButton/IconButton";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import TextUtils from "../../../../core/utils/TextUtils";
import DailyWorkingHours from "../../../../core/models/DaliyWorkingHours";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Store from "../../../../core/models/Store";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import { RootState } from "../../../../redux/Store";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Facebook from "../../../../statics/images/facebook.png";
import Instagram from "../../../../statics/images/instagram.png";
import Pinterest from "../../../../statics/images/pinterest.png";
import Snap from "../../../../statics/images/snap.png";
import Twitter from "../../../../statics/images/twitter.png";
import Youtube from "../../../../statics/images/youtube.png";

interface IFooterState {
  sStoreDetails: Store | undefined;
}
class Footer extends React.Component<any, IFooterState> {
  constructor(props: any) {
    super(props);
    this.state = { sStoreDetails: undefined };
  }
  componentDidMount() {
    this.fetchStoreDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pStoreId != prevProps.pStoreId) this.fetchStoreDetails();
  }

  private fetchStoreDetails() {
    StoreOperationsService.getStoreDetails(
      PartnerManager.shared().currentSelectedStore.id
    ).then((storeDetails) => {
      this.setState({
        sStoreDetails: storeDetails,
      });
    });
  }

  render() {
    return (
      <Stack
        sx={{
          flexShrink: "0",
          backgroundColor: "primary.main",
          marginTop: PixelsToViewPortUnitConverter.getvw(100),
          padding: PixelsToViewPortUnitConverter.getvw(50),
        }}
      >
        <Stack direction={{ xs: "column", sm: "row" }}>
          <Box sx={{ flex: 1.5 }}>
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Overpass",
                  fontWeight: 600,
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(40),
                  textAlign: "start",
                  color: "primary.contrastText",
                  marginBottom: PixelsToViewPortUnitConverter.getvw(20),
                }}
              >
                RETAIL HOURS
              </Typography>
              {this.state.sStoreDetails && (
                <Stack direction="row">
                  {this.workingHoursTableRender(
                    this.state.sStoreDetails.storeWorkingHours.slice(0, 4)
                  )}
                  {this.workingHoursTableRender(
                    this.state.sStoreDetails.storeWorkingHours.slice(4)
                  )}
                </Stack>
              )}
            </Stack>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Overpass",
                  fontWeight: 600,
                  fontSize: PixelsToViewPortUnitConverter.getvw(40),
                  textAlign: "start",
                  color: "primary.contrastText",
                  marginBottom: PixelsToViewPortUnitConverter.getvw(20),
                }}
              >
                CONTACT US
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Overpass",
                  fontWeight: 400,
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                  textAlign: "start",
                  color: "primary.contrastText",
                }}
              >
                {PartnerManager.shared().currentSelectedStore.phone}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Overpass",
                  fontWeight: 600,
                  fontSize: PixelsToViewPortUnitConverter.getvw(40),
                  textAlign: "start",
                  color: "primary.contrastText",
                  marginBottom: PixelsToViewPortUnitConverter.getvw(20),
                }}
              >
                LOCATION
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Overpass",
                  fontWeight: 400,
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                  textAlign: "start",
                  color: "primary.contrastText",
                }}
              >
                {PartnerManager.shared().currentSelectedStore.fullAddress}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack
          sx={{
            justifyContent: "center",
            marginTop: PixelsToViewPortUnitConverter.getvw(80),
          }}
          direction="row"
        >
          {this.storeSocialMedialLinkButton(
            PartnerManager.shared().partner.instagramUrl,
            Instagram
          )}
          {this.storeSocialMedialLinkButton(
            PartnerManager.shared().partner.facebookUrl,
            Facebook
          )}
          {this.storeSocialMedialLinkButton(
            PartnerManager.shared().partner.twitterUrl,
            Twitter
          )}
          {this.storeSocialMedialLinkButton(
            PartnerManager.shared().partner.youtubeUrl,
            Youtube
          )}
          {this.storeSocialMedialLinkButton(
            PartnerManager.shared().partner.pinterestUrl,
            Pinterest
          )}
          {this.storeSocialMedialLinkButton(
            PartnerManager.shared().partner.snapchatUrl,
            Snap
          )}
        </Stack>
      </Stack>
    );
  }

  private workingHoursTableRender(workingHours: DailyWorkingHours[]) {
    return (
      <Table
        sx={{
          width: "auto",
          flex: "1",
          marginRight: "0px",
          height: "min-content",
        }}
      >
        <TableBody>
          {workingHours.map((workingHours) => (
            <TableRow key={workingHours.day}>
              <TableCell sx={{ padding: 0, borderBottom: "unset" }}>
                <Typography
                  sx={{
                    fontFamily: "Overpass",
                    fontWeight: 400,
                    fontSize: PixelsToViewPortUnitConverter.getvw(24),
                    textAlign: "start",
                    color: "primary.contrastText",
                    textTransform: "capitalize",
                  }}
                >
                  {workingHours.day}
                </Typography>
              </TableCell>
              <TableCell sx={{ padding: 0, borderBottom: "unset" }}>
                <Typography
                  sx={{
                    fontFamily: "Overpass",
                    fontWeight: 400,
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                    textAlign: "start",
                    color: "primary.contrastText",
                  }}
                >
                  {workingHours.period()}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  private storeSocialMedialLinkButton(url: string, imageSrc: any) {
    return (
      !TextUtils.isEmpty(url) && (
        <IconButton
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            padding: "0px",
            marginX: "14px",
          }}
        >
          <Avatar
            sx={{
              width: PixelsToViewPortUnitConverter.getvw(25),
              height: PixelsToViewPortUnitConverter.getvw(25),
            }}
            src={imageSrc}
          />
        </IconButton>
      )
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  pStoreId: state.cartProducts.cart
    ? state.cartProducts.cart.storeId
    : undefined,
});

export default connect(mapStateToProps, null)(Footer);
