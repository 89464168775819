import SvgIcon from "@mui/material/SvgIcon";
export default function Cart(props) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M18.5507 17.8489C17.2754 17.8489 16.2435 18.8313 16.2435 20.0321C16.2435 21.2329 17.2754 22.2154 18.5507 22.2154C19.8261 22.2154 20.8696 21.2329 20.8696 20.0321C20.8696 18.8313 19.8261 17.8489 18.5507 17.8489ZM6.95652 17.8489C5.68116 17.8489 4.64928 18.8313 4.64928 20.0321C4.64928 21.2329 5.68116 22.2154 6.95652 22.2154C8.23188 22.2154 9.27536 21.2329 9.27536 20.0321C9.27536 18.8313 8.23188 17.8489 6.95652 17.8489ZM6.95652 14.574L8.23188 12.3907H16.8696C17.7391 12.3907 18.5043 11.9431 18.8986 11.2663L24 2.56607H4.88116L3.7913 0.382812H0V2.56607H2.31884L6.49275 10.8515L3.03768 16.7572H20.8696V14.574H6.95652Z"
      />
    </SvgIcon>
  );
}
