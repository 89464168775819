import React from "react";
import GoogleMapReact, { Coords } from "google-map-react";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PinIcon from "../../../../statics/svgs/PinIcon";
import IconButton from "@mui/material/IconButton/IconButton";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Typography from "@mui/material/Typography/Typography";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import googleMapStyles from "./GoogelMapStyle";
import { Box } from "@mui/material";

const MarkerTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: PixelsToViewPortUnitConverter.getFontVw(30),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

export default class StoreLocationMapComponent extends React.Component<
  any,
  any
> {
  static defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 17,
    zoomControl: false,
    fullscreenControl: false,
  };

  render() {
    return (
      <Box
        sx={{
          // marginTop: {
          //   xs: PixelsToViewPortUnitConverter.getvw(150),
          //   sm: "0px",
          // },
          marginRight: {
            sm: PixelsToViewPortUnitConverter.getvw(200),
            xs: PixelsToViewPortUnitConverter.getvw(130),
          },
          border: {
            sm: "0",
            xs: "1px solid #000000",
          },
          marginLeft: {
            sm: PixelsToViewPortUnitConverter.getvw(200),
            xs: PixelsToViewPortUnitConverter.getvw(130),
          },
          height:{
            sm:"30vw",
            xs:"60vw",
          },
          maxHeight: {
            sm: PixelsToViewPortUnitConverter.getvw(416),
          },
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBRyoewfQ9snnnx59ZsLeJletbzt9bqVDk" }}
          defaultCenter={{
            lat: PartnerManager.shared().currentSelectedStore.latitude,
            lng: PartnerManager.shared().currentSelectedStore.longitude,
          }}
          defaultZoom={StoreLocationMapComponent.defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          draggable={false}
          options={{
            zoomControl: false,
            fullscreenControl: false,
            styles: googleMapStyles.mapStyle,
            clickableIcons: false,
          }}
        >
          <Marker
            lat={PartnerManager.shared().currentSelectedStore.latitude}
            lng={PartnerManager.shared().currentSelectedStore.longitude}
          />
        </GoogleMapReact>
      </Box>
    );
  }
}

class Marker extends React.Component<any, any> {
  render() {
    return (
      <div style={{ height: "416px", width: "100px" }}>
        <MarkerTooltip
          placement="top"
          arrow
          title={
            <React.Fragment>
              <Typography
                textAlign="center"
                color="inherit"
                fontFamily="Overpass"
                fontWeight="700"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
              >
                {PartnerManager.shared().currentSelectedStore.name}
              </Typography>
              <Typography
                textAlign="center"
                color="inherit"
                fontFamily="Overpass"
                fontWeight="400"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
              >
                {PartnerManager.shared().currentSelectedStore.fullAddress}
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton>
            <PinIcon
              sx={{
                width: {
                  sm: PixelsToViewPortUnitConverter.getvw(52),
                  xs: PixelsToViewPortUnitConverter.getvw(200),
                },
                height: {
                  sm: PixelsToViewPortUnitConverter.getvw(39),
                  xs: PixelsToViewPortUnitConverter.getvw(107),
                },
              }}
              viewBox="0 0 52 39"
            />
          </IconButton>
        </MarkerTooltip>
      </div>
    );
  }
}
