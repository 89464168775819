import React, { useEffect, useRef, useState } from "react";
import "./LoadMoreComponent.scss";
import { alpha, Box, Button, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ProductList from '../../../../core/models/ProductList';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';

type LoadMoreComponentProps = {
    productList: ProductList
    pOnLoadMoreTapped(productList: ProductList): void;
};


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main //theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export const LoadMoreComponent: React.FC<LoadMoreComponentProps> = (props: LoadMoreComponentProps) => {

    const num = (props.productList.products.length/props.productList.productsCount) * 100
    const progress = num | 0

    const handleClickEvent = () => {
        props.pOnLoadMoreTapped(props.productList)
    };
    
    return(
        <Box
            sx={{
                gridArea: '1 / 1 / auto / span 2'
            }}>
                {/* <Typography
                    sx={{
                        color: (theme) => theme.palette.primary.main
                    }}
                    className="pagination-label">
                        {`You've viewed ${props.productList.products.length} of ${props.productList.productsCount}`}
                </Typography>
                <Box sx={{mt:'10px'}}>
                    <BorderLinearProgress variant="determinate" value={progress} />
                </Box> */}
                <Box 
                    sx={{
                        display: 'flex',
                        mt:'0px', 
                        // boxShadow: 'rgb(188 188 188 / 50%) 0px 2px 4px 0px'
                    }}>
                    <Button
                        sx={{
                            border: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.primary.contrastText,0.28),
                            backgroundColor: (theme) => theme.palette.primary.main,
                            color: (theme) => theme.palette.primary.contrastText,
                            borderRadius: PixelsToViewPortUnitConverter.getFontVw(38),
                            boxShadow: 'none',
                            textTransform: 'none',
                            fontFamily: 'Bebas',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            width: '100%',
                            minWidth: PixelsToViewPortUnitConverter.getFontVw(156),
                            height: PixelsToViewPortUnitConverter.getFontVw(43),
                            fontSize: PixelsToViewPortUnitConverter.getFontVw(19),
                            lineHeight: PixelsToViewPortUnitConverter.getFontVw(23),
                            ':hover': {
                                // color: (theme) => theme.palette.primary.main,
                                backgroundColor: (theme) => theme.palette.primary.main,
                             }
                        }}
                        variant="contained"
                        // variant="outlined" 
                        onClick={handleClickEvent}>
                        {`Load More`}
                    </Button>
                </Box>
        </Box>      
    )
}





