import React, { useEffect, useState } from "react";
import "./ErrorLabel.scss";
import Typography from '@mui/material/Typography/Typography';
import { Box } from "@mui/system";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import TextUtils from "../../../../core/utils/TextUtils";

type ErrorLabelProps = {
    errorMessage: string
    mt?: number
};

const ErrorLabel: React.FC<ErrorLabelProps> = (props: ErrorLabelProps) => {
    const { mt = 0, errorMessage } = props
    if (!errorMessage || (errorMessage && TextUtils.isEmpty(errorMessage))) return null
    return (
        <Box className="error-label-container" sx={{
            mt: PixelsToViewPortUnitConverter.getvw(mt),
        }}>
            <Typography
                variant="h2"
                className="error-label"
                sx={{
                    color: '#FF0000',
                    fontSize: {
                        sm: `${PixelsToViewPortUnitConverter.getFontVw(14)} !important`,
                        xs: `${PixelsToViewPortUnitConverter.getFontVw(16)} !important`,
                    },
                    lineHeight: {
                        sm: `${PixelsToViewPortUnitConverter.getFontVw(17)} !important`,
                        xs: `${PixelsToViewPortUnitConverter.getFontVw(17)} !important`,
                    }
                }}
            >
                {errorMessage}
            </Typography>
        </Box>
    )
}
export default ErrorLabel





