import { Star } from "@mui/icons-material";
import { Stack, Box, Typography } from "@mui/material";
import React from "react";
import Product from "../../../../core/models/Product";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { alpha } from "@mui/material/styles";
import { withMediaQuery } from "../../../../withMediaQuery";

interface ISearchSuggestionItemProps {
  pProduct: Product;
  isMobile:boolean;
}

class SearchSuggestionItem extends React.Component<
  ISearchSuggestionItemProps,
  any
> {
  constructor(props: ISearchSuggestionItemProps) {
    super(props);
  }

  render() {
    return (
      <Stack direction="row" display="flex" flex={1} sx={{
        alignItems: "center",}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 
            {sm:PixelsToViewPortUnitConverter.getvw(56),
             xs:PixelsToViewPortUnitConverter.getFontVw(44)
            },
            height: 
            {sm:PixelsToViewPortUnitConverter.getvw(56),
             xs:PixelsToViewPortUnitConverter.getFontVw(48)
            },
            paddingLeft:{
              sm:PixelsToViewPortUnitConverter.getvw(4),
              xs:PixelsToViewPortUnitConverter.getvw(6)
            },
            paddingRight:{
              sm:PixelsToViewPortUnitConverter.getvw(4) ,
              xs:PixelsToViewPortUnitConverter.getvw(6) 
            },
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            style={{
              width:!this.props.isMobile ? PixelsToViewPortUnitConverter.getvw(56):PixelsToViewPortUnitConverter.getFontVw(44),
              height:!this.props.isMobile ? PixelsToViewPortUnitConverter.getvw(56):PixelsToViewPortUnitConverter.getFontVw(44) ,
            }}
            src={this.props.pProduct.getSmallImageUrlWithHeight(56, 56)}
            alt={this.props.pProduct.name}
          />
        </Box>
        <Stack
          flex={1}
          sx={{
            marginLeft: {sm:PixelsToViewPortUnitConverter.getvw(28),
             xs: PixelsToViewPortUnitConverter.getvw(40)
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Barlow",
              fontWeight: 600,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            {this.props.pProduct.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Barlow",
              fontWeight: 500,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(12),
              color: (theme) => alpha(theme.palette.primary.contrastText, 0.7),
              letterSpacing: "0.2em",
              marginTop: PixelsToViewPortUnitConverter.getvw(6),
            }}
          >
            {this.props.pProduct.brand}
          </Typography>
        </Stack>
        <Stack sx={{ width: "30%" }}>
          <Stack direction="row">
            <Typography
              sx={{
                fontFamily: "Bebas",
                fontWeight: 400,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                color: (theme) => theme.palette.primary.contrastText,
                letterSpacing: "0.2em",
              }}
            >
              {this.props.pProduct.defaultSellingPrice}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 400,
                marginLeft:  {sm:PixelsToViewPortUnitConverter.getvw(6),
                  xs: PixelsToViewPortUnitConverter.getvw(35)
                 },
                fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
                color: (theme) => theme.palette.primary.contrastText,
                letterSpacing: "0.2em",
              }}
            >
              {this.props.pProduct.defaultWeightTitle}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: PixelsToViewPortUnitConverter.getvw(6),
              alignItems: "center",
            }}
          >
            <Star
              sx={{ fontSize:{
                sm: PixelsToViewPortUnitConverter.getFontVw(16) ,
                xs: PixelsToViewPortUnitConverter.getFontVw(20) 
              }}}
            />
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 700,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(12),
                color: (theme) => theme.palette.primary.contrastText,
                letterSpacing: "0.2em",
                marginLeft: PixelsToViewPortUnitConverter.getvw(4),
              }}
            >
              {this.props.pProduct.aggregateRating}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}
export default (withMediaQuery(SearchSuggestionItem))