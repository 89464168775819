import React from 'react';
import './FiltersComponent.scss'
import { FiltersContainer } from './FiltersContainer';
import Typography from '@mui/material/Typography';
import Filter from '../../../../core/models/Filter';
import FilterAttribute from '../../../../core/models/FilterAttribute';
import RangeAttribute from '../../../../core/models/RangeAttribute';
import FilterIcon from '../../../../statics/svgs/FilterIcon';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';

interface FiltersComponentProps {
    pFilters?: Filter[];
    pOnFilterHeaderTapped?(filter: Filter): void;
    pOnSelectFilterAttribute?(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
    pOnSelectRangeAttribute?(filter: Filter, rangeAttribute: RangeAttribute): void;
};
interface FiltersComponentState {

};

export default class FiltersComponent extends React.Component<FiltersComponentProps, FiltersComponentState> {
    
    constructor(props: FiltersComponentProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        
    }

    render() {
        if(this.props.pFilters.length == 0) return null;
        return(
            <div className='product-filters-container'>
                <div className='product-main-filter-header'>
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.primary.main
                        }}
                        className='main-filter-title'>FILTER</Typography>
                    <FilterIcon
                        sx={{
                            width: PixelsToViewPortUnitConverter.getvw(30),
                            height: PixelsToViewPortUnitConverter.getvw(30),
                            fill: (theme) => theme.palette.secondary.main,
                        }}
                    />
                </div>
                <FiltersContainer 
                    pFilters={this.props.pFilters} 
                    pOnFilterHeaderTapped={this.props.pOnFilterHeaderTapped} 
                    pOnSelectFilterAttribute={this.props.pOnSelectFilterAttribute}
                    pOnSelectRangeAttribute={this.props.pOnSelectRangeAttribute}
                />
            </div>
        )
    }
}  