import React, { useEffect, useRef, useState } from "react";
import "./ProductListHeader.scss";
import { AppliedFiltersComponent } from "./AppliedFiltersComponent";
import { SortingOption } from "./SortingOption";
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material';
import FilterList from "../../../../core/models/FilterList";
import AppliedFilter from '../../../../core/models/AppliedFilter';
import SortingItem from '../../../../core/models/SortingItem';

type ProductListHeaderProps = {
    filterList: FilterList;
    appliedFilterList: AppliedFilter[];
    onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
    onSelectSortingOption(sortingItem: SortingItem): void;
};

const ProductListHeader: React.FC<ProductListHeaderProps> = (props: ProductListHeaderProps) => {
    if (!props.filterList) return null
    return(
        <div className="product-list-header-container">
            <div className="product-list-header-section-a">
                <Typography 
                    sx={{color: (theme) => theme.palette.primary.main }}
                    className="all-products-header-title">{`ALL PRODUCTS`}</Typography>
                <SortingOption 
                    pSortingList={props.filterList.sortingList}
                    pOnSelectSortingOption={props.onSelectSortingOption}
                />
            </div>
            <div className="product-list-header-section-b">
                <Typography 
                    sx={{ color: (theme) => alpha(theme.palette.primary.main, 0.5) }}
                    className="product-count-info"
                    >{`${props.filterList.productsCount} Results`}
                </Typography>
            </div>
            <AppliedFiltersComponent 
                appliedFiltersList={props.appliedFilterList}
                onRemoveAppliedFilter={props.onRemoveAppliedFilter}
            />
        </div>        
    )
}
export default ProductListHeader