import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CollapseIconV2(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 23 23"
    return (
        <SvgIcon {...uProps}>
            <rect width="23" height="23"/>
            <rect x="8" y="11" width="8" height="1" fill="black"/>
        </SvgIcon>
    );
}

//fill="#F0E8D8"