import React from "react";
import "./ProductDetailsComponent.scss";
import withRouter from "../../../withRouter";
import BreadcrumbsComponent from "../../themes/effects/breadcrumbs/BreadcrumbsComponent";
import EffectV2PDPComponent from "../../themes/effectsv2/product_details/EffectV2PDPComponent";
import { Box } from "@mui/material";
import { CartActionType } from "../cart/CartActionTypes";
import { connect } from "react-redux";
import { Cart } from "../cart/CartReducer";
import { RootState } from "../../../redux/Reducer";
import { CartProduct } from "../../../core/models/Product";
import { NavigationProps, NavigationState } from "../../../Navigation.types";
import Navigation from "../../../Navigation";

interface ProductDetailsComponentProps {
  router: NavigationProps;
  cart: Cart | undefined;
  pStoreId: number;
  addToCartOrUpdate: (product: CartProduct) => void;
}

interface ProductDetailsComponentState {
  cart: Cart | undefined;
  productId: number | undefined;
  forceRefersh: boolean;
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cartProducts.cart,
  pStoreId: state.cartProducts.cart.storeId,
});

class ProductDetailsComponent extends React.Component<
  ProductDetailsComponentProps,
  ProductDetailsComponentState
> {
  constructor(props: ProductDetailsComponentProps) {
    super(props);
    this.state = {
      cart: props.cart,
      productId: Number(this.props.router.params.productId),
      forceRefersh: false,
    };
  }

  componentDidMount() {
    const state = this.props.router.location.state as NavigationState;
    if (state && state.scrollToTop) {
      this.onScrollToTopTapped();
    }
  }

  private onScrollToTopTapped = () => {
    window.scrollTo(0, 1);
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.pStoreId != prevProps.pStoreId) {
      this.onStoreChange();
    }
    if (prevState.productId !== this.state.productId) {
      this.setState({ productId: this.state.productId });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.cart !== prevState.cart) {
      return { cart: nextProps.cart };
    }
    if (nextProps.router.params.productId !== prevState.productId) {
      return { productId: nextProps.router.params.productId };
    }
    return null;
  }

  private onStoreChange = () => {
    this.setState({ forceRefersh: true });
    setTimeout(() => {
      this.setState({ forceRefersh: false });
    }, 100);
  };

  private onAddOrRemoveProductFromCart = (product: CartProduct) => {
    this.props.addToCartOrUpdate(product);
  };

  private renderEffectThemeContainer() {
    return (


      
      <Box
        className="product-details-container"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {/* <BreadcrumbsComponent router={this.props.router}/> */}
        <EffectV2PDPComponent
          router={this.props.router}
          storeId={this.props.pStoreId}
          productId={this.state.productId}
          pCart={this.state.cart}
          pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
        />
        {/* <div className="product-details-inner-container">
          <p>{`PRODUCT DETAILS : ${this.props.router.params.productId}`}</p>
        </div> */}
      </Box>
    );
  }

  private renderCategoryOrEffectV2ThemeContainer() {
    return (
      <Box
        className="product-details-container"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {/* <BreadcrumbsComponent router={this.props.router}/> */}
        <EffectV2PDPComponent
          router={this.props.router}
          storeId={this.props.pStoreId}
          productId={this.state.productId}
          pCart={this.state.cart}
          pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
        />
        {/* <div className="product-details-inner-container">
          <p>{`PRODUCT DETAILS : ${this.props.router.params.productId}`}</p>
        </div> */}
      </Box>
    );
  }

  render() {
    return (
      !this.state.forceRefersh && this.renderCategoryOrEffectV2ThemeContainer()
    );
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  addToCartOrUpdate: (product: CartProduct) =>
    dispatch({
      type: CartActionType.ADD_TO_CART_OR_UPDATE,
      payload: product,
    }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailsComponent)
);
