import React from 'react';
import './FiltersComponent.scss'
import { FiltersContainer } from './FiltersContainer';
import Typography from '@mui/material/Typography';
import Filter from '../../../../core/models/Filter';
import FilterAttribute from '../../../../core/models/FilterAttribute';
import RangeAttribute from '../../../../core/models/RangeAttribute';
import FilterIcon from '../../../../statics/svgs/FilterIcon';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import { alpha, Box } from '@mui/material';
import AppliedFilter from '../../../../core/models/AppliedFilter';
import Loader from '../../../common/loader/Loader';

interface FiltersComponentProps {
    pFilters?: Filter[];
    appliedFilterList: AppliedFilter[];
    pIsFiltersLoading: boolean;
    pOnFilterHeaderTapped?(filter: Filter): void;
    pOnSelectFilterAttribute?(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
    pOnSelectRangeAttribute?(filter: Filter, rangeAttribute: RangeAttribute): void;
    pOnResetClicked(): void;
};
interface FiltersComponentState {

};

export default class FiltersComponent extends React.Component<FiltersComponentProps, FiltersComponentState> {

    constructor(props: FiltersComponentProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    private insertLoader() {
        return (
            <Box sx={{ position: "relative", minHeight: "500px", width: "100%" }}>
                <Loader pshow={true} />
            </Box>
        )
    }

    render() {
        // if(this.props.pFilters.length == 0) return null;
        return (
            <Box
                className='cmenu-product-filters-container'
                sx=
                // {window.innerWidth>480 ?
                {{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.20),
                    display: {
                        sm: "block",
                        xs: "none"
                    },
                }} >
                {/* {this.props.pIsFiltersLoading && this.insertLoader()} */}
                <FiltersContainer
                    pFilters={this.props.pFilters}
                    appliedFilterList={this.props.appliedFilterList}
                    pOnFilterHeaderTapped={this.props.pOnFilterHeaderTapped}
                    pOnSelectFilterAttribute={this.props.pOnSelectFilterAttribute}
                    pOnSelectRangeAttribute={this.props.pOnSelectRangeAttribute}
                    pOnResetClicked={this.props.pOnResetClicked}
                />
            </Box>
        )
    }
}  