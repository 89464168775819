
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function StrikeIconV2(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 71 10"
    return (
        <SvgIcon {...uProps}>
            <line x1="0.91484" y1="9.25485" x2="70.9148" y2="1.25485" strokeWidth="1.5"/>        
        </SvgIcon>
    );
}
