import React from "react";
import "./FilterHeader.scss";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Filter from "../../../../core/models/Filter";
import CollapseIcon from "../../../../statics/svgs/CollapseIcon";
import ExpandIcon from "../../../../statics/svgs/ExpandIcon";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";

type FilterHeaderProps = {
  filter: Filter;
  onFilterHeaderTapped(filter: Filter): void;
};

export const FilterHeader: React.FC<FilterHeaderProps> = (
  props: FilterHeaderProps
) => {
  const expandMoreIcon = (filter: Filter) => {
    if (filter.isSelected) {
      return (
        <CollapseIcon
          className="expandMoreIcon"
          viewBox="0 0 24 24"
          sx={{
            width: PixelsToViewPortUnitConverter.getvw(20),
            height: PixelsToViewPortUnitConverter.getvw(20),
            stroke: (theme) => theme.palette.secondary.main,
            fill: (theme) => theme.palette.secondary.main,
          }}
        />
      );
    }
    return (
      <ExpandIcon
        className="expandMoreIcon"
        viewBox="0 0 24 24"
        sx={{
          width: PixelsToViewPortUnitConverter.getvw(20),
          height: PixelsToViewPortUnitConverter.getvw(20),
          stroke: (theme) => theme.palette.primary.main,
          fill: (theme) => theme.palette.primary.main,
        }}
      />
    );
  };

  const onClickFilterHeader = (filter: Filter) => {
    props.onFilterHeaderTapped(filter);
  };

  return (
    <AccordionSummary
      expandIcon={expandMoreIcon(props.filter)}
      aria-controls="panel1a-content"
      onClick={() => {
        onClickFilterHeader(props.filter);
      }}
      className="product-filter-header"
      sx={{
        "&.MuiAccordionSummary-root": {
          minHeight: 0,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          // margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
          margin: "12px 0",
        },
      }}
    >
      <Typography
        sx={{ color: (theme) => theme.palette.primary.main }}
        className="product-filter-header-title"
      >
        {props.filter.name}
      </Typography>
    </AccordionSummary>
  );
};
