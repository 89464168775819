import Strings from "./Strings";

export default class TextUtils {
  public static isEmpty(text: string | undefined): boolean {
    return !text || /^\s*$/.test(text);
  }

  public static toString(anyData: any): string {
    return "" + anyData;
  }

  public static stringCompare(text1: string, text2: string): number {
    if (text1 === text2) return 0;
    else if (text1 > text2) return 1;
    else return -1;
  }

  public static formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

  public static formatUSPhoneNumber(phoneNumberString: string) {
    if (phoneNumberString && phoneNumberString.length > 0) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
      } else {
        return phoneNumberString;
      }
    }
    return "";
  }

  public static isValidEmailId(text: string): boolean {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return regexp.test(text);
  }

  public static checkPhoneFormat(phone: string): boolean {
    if (!phone) return false;
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone);
  }

  public static drivingLicenseLengthValid = (
    value: string,
    minlength: number,
    maxlength: number
  ): boolean => {
    var field = value;
    var mnlen = minlength;
    var mxlen = maxlength;
    if ((field && field.length < mnlen) || (field && field.length > mxlen)) {
      return false;
    } else {
      return true;
    }
  };

  public static isDLNumberValid = (value: string): boolean => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (value && value.match(letters)) {
      return true;
    } else {
      return false;
    }
  };

  public static stripNonNumeric(text: string): string {
    if (TextUtils.isEmpty(text)) {
      return text;
    } else {
      return text.replace(/\D/g, "");
    }
  }

  public static toUsPhoneNumberFormat(phoneNumber: string): string {
    phoneNumber = TextUtils.stripNonNumeric(phoneNumber);

    if (!TextUtils.isEmpty(phoneNumber) && phoneNumber.length === 10) {
      return (
        "+1" +
        TextUtils.formatString(
          " ({0}) {1}-{2}",
          phoneNumber.substring(0, 3),
          phoneNumber.substring(3, 6),
          phoneNumber.substring(6)
        )
      );
    } else {
      return phoneNumber;
    }
  }

  public static isDigitsOnly(text: string): boolean {
    return /^\d+$/.test(text);
  }

  public static isNonZeroDigitsOnly(text: string): boolean {
    return new RegExp("^[1-9]+$").test(text);
  }

  public static decimalRoundOff(num: number, uptoPlace: number) {
    return num.toLocaleString(undefined, {
      useGrouping: false,
      minimumFractionDigits: uptoPlace,
      maximumFractionDigits: uptoPlace,
    });
  }

  public static trimString(originalString: string | undefined) {
    if (originalString && originalString.length > 0) {
      var newString = originalString.replace(/^\s+|\s+$/g, "");
      return newString;
    }
    return originalString;
  }

  public static numberFormatWithLocale(
    x: string | undefined,
    withFractionDigits: boolean
  ): string {
    if (x && !TextUtils.isEmpty(x)) {
      const userLocale = "en-US";
      let withFractionDigitsA = this.hasDecimalValue(Number(x));
      if (withFractionDigitsA) {
        return new Intl.NumberFormat(userLocale, {
          minimumFractionDigits: 2,
        }).format(Number(x));
      }
      return new Intl.NumberFormat(userLocale, {}).format(Number(x));
    }
    if (!x) return "0.00";
    return x;
  }

  public static hasDecimalValue(amt: number): boolean {
    var result = amt - Math.floor(amt) !== 0;
    if (result) {
      return true;
    } else {
      return false;
    }
  }

  public static capitalize(x: string): string {
    if (x && x.length > 0) {
      const mySentence = x;
      const words = mySentence.split(" ");
      return words
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1).toLowerCase();
        })
        .join(" ");
    }
    return x;
  }

  public static getPriceWithCurrencyUnit(price: number): string {
    if (price) {
      return "$" + this.decimalRoundOff(price, 2);
    }

    return "";
  }

  public static normalizeInputToUSPhoneNum(value, previousValue): string {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const preValue = previousValue
      ? previousValue.replace(/[^\d]/g, "")
      : previousValue;
    const cvLength = currentValue.length;

    if (!preValue || value.length > preValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }
}
