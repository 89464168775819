import React from "react";
import "./ProductListHeader.scss";
import { alpha, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { Box } from "@mui/system";
import SortingList from "../../../../core/models/SortingList";
import SortingItem from '../../../../core/models/SortingItem';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import DownArrowIcon from "../../../../statics/svgs/DownArrowIcon";

type SortingOptionProps = {
    pSortingList: SortingList;
    pOnSelectSortingOption(sortingItem: SortingItem): void;
};

export const SortingOption: React.FC<SortingOptionProps> = (props: SortingOptionProps) => {

    const handleChange = (event: SelectChangeEvent) => {
        const selectedItem = props.pSortingList.getSortingItemByValue(event.target.value)
        props.pOnSelectSortingOption(selectedItem)
    };

    const renderSortingList = () => {
        const items = props.pSortingList.sortingList
        return items.map((item: SortingItem,index: number) => renderSortingItem(item,index));
    }

    const renderSortingItem = (item: SortingItem, index: number) => {
        // console.log('renderSortingItem start:'+index)
        // console.log('name :'+item.name)
        // console.log('value :'+item.value)
        // console.log('selected :'+item.selected)
        return(
            <MenuItem 
                key={index.toString()} 
                sx={{
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
                    lineHeight: PixelsToViewPortUnitConverter.getFontVw(20),
                    fontWeight: "300",
                    fontFamily: "Overpass",
                    color: (theme) => theme.palette.primary.main
                }}
                value={item.value}>{item.name}</MenuItem>
        )
    }

    const renderSelectedValue = (item: SortingItem) => {
        return(
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography sx={{
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                    lineHeight: PixelsToViewPortUnitConverter.getFontVw(30),
                    fontWeight: "600",
                    fontFamily: "Overpass",
                    color: (theme) => theme.palette.primary.main
                }}>
                    {'Sort by:'}
                </Typography>
                <Typography sx={{
                    pl: PixelsToViewPortUnitConverter.getFontVw(10),
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
                    lineHeight: PixelsToViewPortUnitConverter.getFontVw(20),
                    fontWeight: "300",
                    fontFamily: "Overpass",
                    color: (theme) => theme.palette.primary.main
                }}>
                    {item.name}
                </Typography>
            </Box>
        )   
    }

    return(
        <FormControl sx={{ 
                m: 1,
                mt: 0,
                mb: 0,
                minWidth: PixelsToViewPortUnitConverter.getFontVw(170),
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.primary.main,0.2),
                    borderRadius: PixelsToViewPortUnitConverter.getFontVw(10)
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.primary.main,0.2),
                    borderRadius: PixelsToViewPortUnitConverter.getFontVw(10)
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.primary.main,0.2),
                    borderRadius: PixelsToViewPortUnitConverter.getFontVw(10)
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: '1px solid',
                    borderColor: (theme) => alpha(theme.palette.primary.main,0.2),
                    borderRadius: PixelsToViewPortUnitConverter.getFontVw(10)
                }}}>
            <Select
                value={`${props.pSortingList.getSelectedSortingItem().value}`}
                onChange={handleChange}
                displayEmpty
                sx={{
                    height:42,
                }}
                SelectDisplayProps={{ style: { 
                    minHeight: 0, 
                    padding: PixelsToViewPortUnitConverter.getFontVw(20),
                } }}
                renderValue={(selected) => {return renderSelectedValue(props.pSortingList.getSelectedSortingItem())}}
                IconComponent={() => <DownArrowIcon
                    sx={{ 
                        width: PixelsToViewPortUnitConverter.getFontVw(12),
                        height: PixelsToViewPortUnitConverter.getFontVw(12),
                        stroke: (theme) => theme.palette.secondary.main,
                    }}
                    style={{
                        color: '#fff',
                        paddingRight: PixelsToViewPortUnitConverter.getFontVw(20) }} />}
            >
                {renderSortingList()}
            </Select>
      </FormControl>       
    )
}





