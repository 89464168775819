import { NavigationProps, NavigationState } from "./Navigation.types";
import Effect from "./core/models/Effect";
import Category from "./core/models/Category";
import Product from "./core/models/Product";
import Breadcrumb, {
  BreadcrumbType,
  IBreadcrumb,
} from "./core/models/Breadcrumb";
import TextUtils from "./core/utils/TextUtils";
import AppRoutes from './routes';

export default class Navigation {
  public static readonly SHOP_ALL_PATH = AppRoutes.PRODUCTS;
  public static readonly SHOP_ALL_BY_EFFECT_PATH = `${this.SHOP_ALL_PATH}?facets.feelings[0]=`;
  public static readonly SHOP_ALL_BY_CATEGORY_PATH = `${this.SHOP_ALL_PATH}?facets.root_types[0]=`;
  public static readonly PRODUCT_DETAILS_PATH = `${AppRoutes.PRODUCTS}/`;
  public static readonly PROFILE_PATH = AppRoutes.ACCOUNT;
  public static readonly SIGN_UP = AppRoutes.SIGNUP;
  public static readonly VERIFY_CODE = AppRoutes.VERIFY_CODE;
  public static readonly LOGIN = AppRoutes.LOGIN;

  /* navigate to breadcrum */
  public static toBreadcrumb(router: NavigationProps, breadCrumb: Breadcrumb) {
    const { navigate } = router;
    if (breadCrumb.type === BreadcrumbType.HOME) {
      this.toHome(router);
    } else if (breadCrumb.type === BreadcrumbType.ALL_PRODUCTS) {
      this.toShopAll(router);
    } else if (breadCrumb.index) {
      navigate(breadCrumb.index);
    }
  }

  /* navigate to home page */
  public static toHome(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.ROOT,
    });
  }

  public static logOut(router: NavigationProps) {
    const { navigate } = router;
    navigate(AppRoutes.ROOT, {replace:true})
  }

  /* navigate back to page */
  public static back(router: NavigationProps) {
    const { navigate } = router;
    navigate(-1);
  }

  public static refreshPage(router: NavigationProps) {
    const { navigate } = router;
    navigate(0);
  }

  /* navigate to shop all (with popular products) */
  public static toShopAllWithPopularProducts(router: NavigationProps) {
    const { navigate } = router;
    const categoryName = "best_selling";
    const pathTo = `${this.SHOP_ALL_BY_CATEGORY_PATH}${categoryName}`;
    const activeItem = {
      ...Breadcrumb.POPULAR,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [
      { ...Breadcrumb.HOME, index: -1, isPushed: true },
      Breadcrumb.SHOP_ALL,
      activeItem,
    ];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    navigate(pathTo, {
      state,
    });
  }

  /* Navigate to effect details */
  public static toShopAllByEffect(
    router: NavigationProps,
    effect: Effect | undefined
  ) {
    const { navigate } = router;
    const effectName = effect ? effect.name : "";
    const pathTo = effect
      ? `${this.SHOP_ALL_BY_EFFECT_PATH}${effectName}`
      : this.SHOP_ALL_PATH;
    const activeItem = {
      name: effectName,
      type: BreadcrumbType.EFFECT,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [
      { ...Breadcrumb.HOME, index: -1, isPushed: true },
      Breadcrumb.SHOP_ALL,
      activeItem,
    ];

    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    navigate(pathTo, {
      state,
    });
  }

  /* navigate to category details */
  public static toShopAllByCategory(
    router: NavigationProps,
    category: Category | undefined
  ) {
    const { navigate } = router;
    const categoryName = category ? category.name : "";
    const pathTo = category
      ? `${this.SHOP_ALL_BY_CATEGORY_PATH}${categoryName}`
      : this.SHOP_ALL_PATH;
    const activeItem = {
      name: categoryName,
      type: BreadcrumbType.CATEGORY,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [
      { ...Breadcrumb.HOME, index: -1, isPushed: true },
      Breadcrumb.SHOP_ALL,
      activeItem,
    ];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    navigate(pathTo, {
      state,
    });
  }

  /* navigate to shop all (header) */
  public static toShopAllHeader(router: NavigationProps, replace?: boolean) {
    const { navigate } = router;
    const pathTo = `${this.SHOP_ALL_PATH}`;
    const activeItem = { ...Breadcrumb.SHOP_ALL, index: 0, isPushed: true };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    const toBeReplaced = replace ? true : false;
    navigate(pathTo, {
      state,
      replace: toBeReplaced,
    });
  }

  /* navigate to shop all (header) */
  public static toShopAll(router: NavigationProps, replace?: boolean) {
    const { navigate } = router;
    const pathTo = `${this.SHOP_ALL_PATH}`;
    const activeItem = { ...Breadcrumb.SHOP_ALL, index: 0, isPushed: true };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      breadCrumbsArray: arr,
    };
    const toBeReplaced = replace ? true : false;
    navigate(pathTo, {
      state,
      replace: toBeReplaced,
    });
  }

  /* navigate to shop all tab - mobile specific */
  public static toShopAllTab(router: NavigationProps) {
    const { navigate } = router;
    const pathTo = `${this.SHOP_ALL_PATH}`;
    const activeItem = { ...Breadcrumb.SHOP_ALL, index: 0, isPushed: true };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr
    };
    navigate(pathTo, {
      state,
    });
  }

  /* navigate to shop all when applied filters */
  public static toShopAllWithQuery(router: NavigationProps, query: string) {
    const { navigate, location } = router;
    let q = undefined;
    if (query && !query.startsWith("?")) {
      q = `?${query}`;
    } else if (query) {
      q = `${query}`;
    }
    const pathTo = q ? `${this.SHOP_ALL_PATH}${q}` : `${this.SHOP_ALL_PATH}`;
    const activeItem = {
      ...Breadcrumb.SHOP_ALL,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];

    let state: NavigationState = {
      breadCrumbsArray: arr,
    };
    const previousState = location.state as NavigationState;
    if (previousState) {
      const breadCrumbTypeArr: IBreadcrumb[] =
        previousState && previousState.breadCrumbsArray
          ? previousState.breadCrumbsArray
          : [];
      const isActive = this.isBreadCrumbActive(breadCrumbTypeArr, activeItem);
      if (isActive) {
        state = previousState;
      }
    }
    state.breadCrumbsArray = this.assignNavigationIndex(state.breadCrumbsArray);
    navigate(pathTo, {
      state,
    });
  }

  /* navigate to search page */
  public static toShopAllWithSearchQuery(
    router: NavigationProps,
    query: string
  ) {
    const { navigate, location } = router;
    let q = undefined;
    if (query && !query.startsWith("?")) {
      q = `?${query}`;
    } else if (query) {
      q = `${query}`;
    }
    const pathTo = q ? `${this.SHOP_ALL_PATH}${q}` : `${this.SHOP_ALL_PATH}`;
    const activeItem = {
      ...Breadcrumb.SEARCH_RESULTS,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [
      { ...Breadcrumb.HOME, index: -1, isPushed: true },
      Breadcrumb.SHOP_ALL,
      activeItem,
    ];
    let state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    const previousState = location.state as NavigationState;
    if (previousState) {
      const breadCrumbTypeArr: IBreadcrumb[] =
        previousState && previousState.breadCrumbsArray
          ? previousState.breadCrumbsArray
          : [];
      const isActive = this.isBreadCrumbActive(breadCrumbTypeArr, activeItem);
      if (isActive) {
        state = previousState;
      } else {
        const newArr = [...breadCrumbTypeArr];
        newArr.push(activeItem);
        state.breadCrumbsArray = newArr;
      }
    }
    state.breadCrumbsArray = this.assignNavigationIndex(state.breadCrumbsArray);
    navigate(pathTo, {
      state,
    });
  }

  /* navigate to product details */
  public static toProductDetails(router: NavigationProps, product: Product) {
    const { navigate, location } = router;
    const pathTo = `${this.PRODUCT_DETAILS_PATH}` + product.id;
    const activeItem = {
      name: product.name,
      type: BreadcrumbType.PRODUCT_DETAILS,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    let state: NavigationState = {
      isFromShopAll: true,
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    const previousState = location.state as NavigationState;
    if (previousState) {
      const breadCrumbTypeArr: IBreadcrumb[] =
        previousState && previousState.breadCrumbsArray
          ? previousState.breadCrumbsArray
          : [];
      const isActive = this.isBreadCrumbActive(breadCrumbTypeArr, activeItem);
      if (isActive) {
        state = previousState;
      } else {
        const newArr = [...breadCrumbTypeArr];
        newArr.push(activeItem);
        state.breadCrumbsArray = newArr;
      }
    }
    state.breadCrumbsArray = this.assignNavigationIndex(state.breadCrumbsArray);
    navigate(pathTo, {
      state,
    });
  }

  public static isBreadCrumbActive(
    arr: IBreadcrumb[],
    activeItem: IBreadcrumb
  ): boolean {
    let isActive = false;
    let itemFound = arr.find(
      (item) => item.name === activeItem.name && item.type === activeItem.type
    );
    if (itemFound) {
      isActive = true;
    }
    return isActive;
  }

  public static assignNavigationIndex(arr: IBreadcrumb[]): IBreadcrumb[] {
    let revArr = arr.reverse();
    let index = 0;
    revArr.forEach((item, i) => {
      if (item.isPushed) {
        item.index = index;
        index = index - 1;
      }
    });
    return revArr.reverse();
  }

  /* navigate to profile page */
  public static toProfile(router: NavigationProps) {
    const { navigate } = router;
    const pathTo = `${this.PROFILE_PATH}`;
    const activeItem = {
      ...Breadcrumb.PROFILE,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    navigate(pathTo, {
      state,
    });
  }

  public static toLogin(router: NavigationProps) {
    const { navigate } = router;
    const pathTo = `${this.LOGIN}`;
    const activeItem = {
      ...Breadcrumb.LOGIN,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    navigate(pathTo, {
      state,
    });
  }

  public static toSignUp(router: NavigationProps) {
    const { navigate } = router;
    const pathTo = `${this.SIGN_UP}`;
    const activeItem = {
      ...Breadcrumb.SIGNUP,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
    };
    navigate(pathTo, {
      state,
    });
  }

  public static toVerifyCode(
    router: NavigationProps,
    username: string,
    password: string
  ) {
    const { navigate } = router;
    const pathTo = `${this.VERIFY_CODE}`;
    const activeItem = {
      ...Breadcrumb.VERIFY_CODE,
      url: pathTo,
      index: 0,
      isPushed: true,
    };
    const arr = [{ ...Breadcrumb.HOME, index: -1, isPushed: true }, activeItem];
    const state: NavigationState = {
      scrollToTop: true,
      breadCrumbsArray: arr,
      username,
      password,
    };
    navigate(pathTo, {
      state,
    });
  }

  /* navigate to search page */
  public static toSearch(router: NavigationProps) {
    const { navigate } = router;
    navigate({
      pathname: AppRoutes.SEARCH,
    });
  }
}
