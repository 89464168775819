import React from "react";
import "./CustomerSignUpComponent.scss";
import withRouter from "../../../withRouter";
import { NavigationProps } from "../../../Navigation.types";
import Navigation from '../../../Navigation';
import { Box } from "@mui/material";
import SignUpComponent from "../../themes/effectsv2/signup/SignUpComponent";

interface CustomerSignUpComponentProps {
  router: NavigationProps;
}

interface CustomerSignUpComponentState {

}

class CustomerSignUpComponent extends React.Component<
    CustomerSignUpComponentProps,
    CustomerSignUpComponentState
> {
  constructor(props: CustomerSignUpComponentProps) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {}
  
  private handleSignUpCompleted() {
    Navigation.toHome(this.props.router)
  }

  private renderSignUpComponentEffectV2ThemeContainer() {
    return(
        <SignUpComponent 
            router={this.props.router}
            onSignUpCompleted={() => {
                this.handleSignUpCompleted()
            }}
        />
    )
  }

  render() {
    return(
        <Box className="customer-signup-container" 
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
            }}>
            {this.renderSignUpComponentEffectV2ThemeContainer()}
        </Box>
    )
    return null;
  }
  /*
  
  */
}

export default withRouter(CustomerSignUpComponent);