import React, { useEffect, useState } from "react";
import { Box } from '@mui/material'
import ProductSectionModel from '../../../../core/models/ProductSectionModel';
import FilterList from '../../../../core/models/FilterList';
import Product, { CartProduct } from '../../../../core/models/Product';
import ProductSection from './ProductSection';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import { Cart } from "../../../common/cart/CartReducer";
import { NavigationProps } from "../../../../Navigation.types";
import Navigation from '../../../../Navigation';

type RelatedProductsProps = {
    router: NavigationProps;
    product: Product;
    title?: string;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    pCart: Cart | undefined;
    pOnAddOrRemoveProductFromCart?(product: CartProduct): void;
};

const RelatedProducts: React.FC<RelatedProductsProps> = (props: RelatedProductsProps) => {
    const sectionTitle = props.title ? props.title : 'RELATED PRODUCTS'
    const mt = props.marginTop ? PixelsToViewPortUnitConverter.getvw(props.marginTop) : 0
    const ml = props.marginLeft ? PixelsToViewPortUnitConverter.getvw(props.marginLeft) : 0
    const mr = props.marginRight ? PixelsToViewPortUnitConverter.getvw(props.marginRight) : 0
    const mb = props.marginBottom ? PixelsToViewPortUnitConverter.getvw(props.marginBottom) : 0
    const pModel = ProductSectionModel.getRelatedProductsSectionModel(props.product,sectionTitle)
    if(!pModel) return null
    return (
        <Box
            sx={{
                marginTop: mt,
                marginLeft: ml,
                marginRight: mr,
                marginBottom: mb
            }}>
            <ProductSection
                key={'best_selling'}
                productSectionModel={pModel}
                filterList={FilterList.defaultFilterList(undefined)}
                isSeparateComponent={true}
                pCart={props.pCart}
                pSeeAllButtonShow={false}
                pOnSeeAllTapped={(p: ProductSectionModel) => {
                    
                }}
                pOnProductClicked={(p: Product) => {
                    Navigation.toProductDetails(props.router,p)
                }}
                pOnAddOrRemoveProductFromCart={(p) => {
                    if(props.pOnAddOrRemoveProductFromCart) {
                        props.pOnAddOrRemoveProductFromCart(p)
                    }
                }}
            />
        </Box>
    );
};
export default React.memo(RelatedProducts)