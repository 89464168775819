import * as React from "react";
import PartnerManager from "../../../../core/utils/PartnerManager";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box/Box";
import Header from "../header/Header";
import Typography from "@mui/material/Typography/Typography";
import SearchIcon from "../../../../statics/svgs/SearchIcon";
import InputBase from "@mui/material/InputBase/InputBase";
import { BestSellingProductsComponent } from "./BestSellingProductsComponent";
import EffectsComponent from "./EffectsComponent";
import StoreLocationMapComponent from "./StoreLocationMapComponent";
import CustomerReviewsComponent from "./CustomerReviewsComponent";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import MenuService from "../../../../services/MenuService";
import { ApiError } from "../../../../core/webservice/ApiError";
import ProductList from "../../../../core/models/ProductList";
import {
  ClickAwayListener,
  IconButton,
  List,
  ListItemButton,
} from "@mui/material";
import TextUtils from "../../../../core/utils/TextUtils";
import withRouter from "../../../../withRouter";
import Navigation from "../../../../Navigation";

interface sEffectsHomeComponentState {
  sShowTransparentHeader: boolean;
  sSearchResults: ProductList | undefined;
  sSearchValue: string | undefined;
  sSearchSuggestions: boolean;
}

const queryString = require("query-string");

class EffectsHomeComponent extends React.Component<
  any,
  sEffectsHomeComponentState
> {
  private mSearchDivRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      sShowTransparentHeader: true,
      sSearchResults: undefined,
      sSearchValue: undefined,
      sSearchSuggestions: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.mSearchDivRef.current.getBoundingClientRect().top < 64) {
      if (this.state.sShowTransparentHeader) {
        this.setState({
          sShowTransparentHeader: false,
        });
      }
    } else {
      if (!this.state.sShowTransparentHeader) {
        this.setState({
          sShowTransparentHeader: true,
        });
      }
    }
  };

  render() {
    return (
      <Stack
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
          display: "block",
        }}
      >
        <Header pShowTransperentHeader={this.state.sShowTransparentHeader} />
        <Box
          sx={{
            display: "flex",
            height: "65vh",
            width: "100%",
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 29.81%, rgba(0, 0, 0, 0) 100%), url(https://d2o1iceed9pvab.cloudfront.net/47a52342036a49ee8891bbb12349d636.jpg)`,
            backgroundSize: "cover",
          }}
        >
          <Stack sx={{ marginLeft: "18%", width: "82%", marginTop: "20vh" }}>
            <Typography
              sx={{
                color: "primary.contrastText",
                fontFamily: "Overpass",
                fontWeight: 700,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(70),
                textAlign: "start",
              }}
            >
              {PartnerManager.shared().currentSelectedStore.name}
            </Typography>
            <Stack
              sx={{
                position: "relative",
                borderRadius: PixelsToViewPortUnitConverter.getvw(60),
                display: "flex",
                border: "2px solid rgba(0, 0, 0, 0.3)",
                backgroundColor: (theme) => theme.palette.background.default,
                width: "40%",
                marginTop: "4px",
                paddingLeft: PixelsToViewPortUnitConverter.getvw(40),
                paddingRight: PixelsToViewPortUnitConverter.getvw(40),
                paddingTop: PixelsToViewPortUnitConverter.getvw(6),
                paddingBottom: PixelsToViewPortUnitConverter.getvw(6),
                zIndex: "4",
              }}
            >
              <Box
                ref={this.mSearchDivRef}
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBase
                  sx={{
                    fontFamily: "Overpass",
                    fontWeight: "700",
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(30),
                    width: "100%",
                    marginRight: "6px",
                  }}
                  placeholder="Strain, brand, effect, location, etc."
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    this.setState({
                      sSearchResults: undefined,
                      sSearchValue: e.currentTarget.value,
                      sSearchSuggestions: true,
                    });
                    if (!TextUtils.isEmpty(e.currentTarget.value)) {
                      MenuService.getProducts("", e.currentTarget.value)
                        .then((results) => {
                          if (results.query == this.state.sSearchValue) {
                            this.setState({ sSearchResults: results });
                          }
                        })
                        .catch((apiError: ApiError) => {});
                    } else {
                      this.setState({ sSearchResults: undefined });
                    }
                  }}
                  onClick={() => this.setState({ sSearchSuggestions: true })}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const parsed = queryString.parse(
                        this.props.router.location.search
                      );
                      parsed["query"] = this.state.sSearchValue;
                      Navigation.toShopAllWithSearchQuery(
                        this.props.router,
                        `${queryString.stringify(parsed)}`
                      );
                    }
                  }}
                />
                <Box
                  sx={{
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: PixelsToViewPortUnitConverter.getvw(30),
                  }}
                >
                  <IconButton
                    onClick={() => {
                      const parsed = queryString.parse(
                        this.props.router.location.search
                      );
                      parsed["query"] = this.state.sSearchValue;
                      Navigation.toShopAllWithSearchQuery(
                        this.props.router,
                        `${queryString.stringify(parsed)}`
                      );
                    }}
                  >
                    <SearchIcon color="secondary" viewBox="0 0 31 31" />
                  </IconButton>
                </Box>
              </Box>
              {this.state.sSearchSuggestions && this.state.sSearchResults && (
                <ClickAwayListener
                  onClickAway={() => {
                    this.setState({ sSearchSuggestions: false });
                  }}
                >
                  <List sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                    {this.state.sSearchResults.products.map((product) => (
                      <ListItemButton
                        sx={{
                          paddingX: "0px",
                          fontFamily: "Overpass",
                          fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                          fontWeight: "400",
                          color: "rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        {product.name}
                      </ListItemButton>
                    ))}
                  </List>
                </ClickAwayListener>
              )}
            </Stack>
          </Stack>
        </Box>
        <BestSellingProductsComponent />
        <EffectsComponent router={this.props.router} />
        <StoreLocationMapComponent />
        <CustomerReviewsComponent />
      </Stack>
    );
  }
}

export default withRouter(EffectsHomeComponent);
