import { legacy_createStore as createStore } from "redux";
import LocalStorageUtils from "../core/utils/LocalStorageUtils";

import reducers from "./Reducer";

const persistedState = LocalStorageUtils.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : {};

export const store = createStore(reducers, persistedState);

store.subscribe(() => {
  LocalStorageUtils.storeItem("cart", JSON.stringify(store.getState()));
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
