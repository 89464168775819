import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export default function RoundMinus(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 23 24"
    return (
        <SvgIcon {...uProps} fill="none">
            <circle cx="11.5" cy="12.0781" r="11" stroke="#A1A1A1"/>
            <path d="M15.0062 12V13.3267H7.00256V12H15.0062Z" fill="#A2A2A2"/>
        </SvgIcon>
    );
}



