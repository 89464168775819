import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "../../../../statics/svgs/Cart";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import SearchIcon from "../../../../statics/svgs/SearchIcon";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import ProductList from "../../../../core/models/ProductList";
import TextUtils from "../../../../core/utils/TextUtils";
import MenuService from "../../../../services/MenuService";
import { ApiError } from "../../../../core/webservice/ApiError";
import List from "@mui/material/List/List";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Box,
  Card,
  CardActionArea,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import withRouter from "../../../../withRouter";
import ProfileIcon from "../../../../statics/svgs/ProfileIcon";
import HeaderButton from "./HeaderButton";
import SearchSuggestionItem from "./SearchSuggestionItem";
import { alpha } from "@mui/material/styles";
import { connect } from "react-redux";
import { RootState } from "../../../../redux/Reducer";
import Navigation from "../../../../Navigation";
import LocationHeaderMenu from "./LocationHeaderMenu";
import { PartnerAppThemeType } from "../../../../core/models/PartnerTheme";
import LoginComponent from "../login/LoginComponent";
import AmplifyManager from "../../../../core/utils/AmplifyManager";
import backIcon from "../../../common/locationBtn/arrowDownIcon.svg";
import AppRoutes from "../../../../routes";

enum eHeaderButton {
  SHOP,
  DEALS,
  LOCATION,
}

const Search = styled(Stack)(({ theme }) => ({
  position: "relative",
  marginLeft: "auto",
  marginRight: "auto",
  width: "auto",
  display: "flex",
  borderRadius: PixelsToViewPortUnitConverter.getvw(23),
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: alpha(theme.palette.primary.contrastText, 0.17),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
  fontFamily: "Barlow",
  fontWeight: 500,
  letterSpacing: "0.15em",
  color: theme.palette.primary.contrastText,
  "& .MuiInputBase-input": {
    width: "19vw",
    marginLeft: PixelsToViewPortUnitConverter.getvw(10),
  },
}));

interface IHeaderProps {
  router: any;
  pCartCount: number;
  pStoreId: number;
}

interface IHeaderStates {
  sHeaderSelectedButton: eHeaderButton | undefined;
  sSearchResults: ProductList | undefined;
  sSearchValue: string;
  sSearchSuggestions: boolean;
  sShowSearchShadow: boolean;
  sShowLocations: boolean;
  sShowSearchBar: boolean;
  sShowProfileContent: boolean;
  sShowHeaderDivider: boolean;
}

const queryString = require("query-string");

class Header extends React.Component<IHeaderProps, IHeaderStates> {
  mAppBar = React.createRef<HTMLDivElement>();
  mSearch = React.createRef<HTMLDivElement>();
  mProfileIcon = React.createRef<HTMLButtonElement>();
  mSuggestionSearchInputRef = React.createRef<HTMLInputElement>();
  constructor(props: IHeaderProps) {
    super(props);
    const query = queryString.parse(this.props.router.location.search)["query"];
    this.state = {
      sHeaderSelectedButton: this.props.router.location.pathname.includes(
        "products"
      )
        ? eHeaderButton.SHOP
        : undefined,
      sSearchResults: undefined,
      sSearchValue: query ? query : "",
      sSearchSuggestions: false,
      sShowSearchShadow: false,
      sShowLocations: false,
      sShowSearchBar: this.shouldShowSearchBar(),
      sShowProfileContent: false,
      sShowHeaderDivider: false,
    };
  }
  private handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    Navigation.back(this.props.router);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.router.location.pathname != this.props.router.location.pathname
    ) {
      // if (this.state.sHeaderSelectedButton == eHeaderButton.SHOP) {
      this.setState({
        sHeaderSelectedButton: this.props.router.location.pathname.includes(
          "products"
        )
          ? eHeaderButton.SHOP
          : undefined,
        sShowSearchBar: this.shouldShowSearchBar(),
      });
      // }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      sShowHeaderDivider:
        this.props.router.location.pathname.length > 1 ||
        document.documentElement.scrollTop > 5,
    });
  };

  private shouldShowSearchBar(): boolean {
    if (
      PartnerManager.shared().partner &&
      PartnerManager.shared().partner.theme.name ===
        PartnerAppThemeType.CATEGORIES &&
      this.props.router.location.pathname === AppRoutes.ROOT
    ) {
      return false;
    }
    return true;
  }
  render() {
    return (
      <AppBar
        elevation={0}
        ref={this.mAppBar}
        sx={{
          borderWidth: "0px",
          borderBottomWidth: this.state.sShowHeaderDivider
            ? PixelsToViewPortUnitConverter.getvw(1)
            : "0px",
          borderStyle: "solid",
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.2),
        }}
      >
        <Toolbar
          style={{
            paddingLeft: PixelsToViewPortUnitConverter.getvw(62),
            paddingRight: PixelsToViewPortUnitConverter.getvw(62),
            justifyContent: window.innerWidth < 480 ? "center" : "unset",
          }}
        >
          <Card
            elevation={0}
            sx={{
              backgroundColor: "unset",
            }}
          >
            {this.props.router.location.pathname !== AppRoutes.ROOT &&
              this.props.router.location.pathname !== AppRoutes.PRODUCTS &&
              this.props.router.location.pathname !== AppRoutes.SEARCH &&
              this.props.router.location.pathname !== AppRoutes.ACCOUNT &&
              this.props.router.location.pathname !== AppRoutes.LOGIN && (
                <Box
                  sx={{
                    display: { sm: "none", xs: "block" },
                    position: "absolute",
                    left: "20px",
                    top: "13px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={backIcon}
                    onClick={this.handleBackClick}
                    style={{ transform: "rotate(90deg)" }}
                  />
                </Box>
              )}
            <CardActionArea
              sx={{
                display: "flex",
                marginTop: PixelsToViewPortUnitConverter.getvw(20),
                marginBottom: PixelsToViewPortUnitConverter.getvw(30),
              }}
              onClick={() => {
                this.setState({ sHeaderSelectedButton: undefined });
                Navigation.toHome(this.props.router);
              }}
            >
              <Box
                style={{ display: "flex", alignItems: "center" }}
                sx={{
                  height: {
                    sm: PixelsToViewPortUnitConverter.getvw(66),
                    xs: PixelsToViewPortUnitConverter.getvw(86),
                  },
                  width: {
                    sm: PixelsToViewPortUnitConverter.getvw(80),
                    xs: PixelsToViewPortUnitConverter.getvw(86),
                  },
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  src={PartnerManager.shared().partner.theme.logoUrl}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Bebas",
                  fontWeight: 400,
                  fontSize: {
                    sm: PixelsToViewPortUnitConverter.getFontVw(33),
                    xs: PixelsToViewPortUnitConverter.getFontVw(18),
                  },
                  textAlign: "start",
                  color: (theme) => theme.palette.primary.contrastText,

                  letterSpacing: "0.1em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  lineClamp: "1",
                  maxLines: "1",
                  marginLeft: PixelsToViewPortUnitConverter.getvw(34),
                  marginRight: PixelsToViewPortUnitConverter.getvw(20),
                  alignSelf: "flex-end",
                }}
              >
                {PartnerManager.shared().currentSelectedStore.name}
              </Typography>
            </CardActionArea>
          </Card>
          <HeaderButton
            pName="SHOP"
            pIsSelected={this.state.sHeaderSelectedButton == eHeaderButton.SHOP}
            onClick={() => {
              this.setState({ sHeaderSelectedButton: eHeaderButton.SHOP });
              Navigation.toShopAllHeader(this.props.router);
            }}
          />

          {/* <HeaderButton
            pName="DEALS"
            onClick={() => {
              this.setState({ sHeaderSelectedButton: eHeaderButton.DEALS });
            }}
            pIsSelected={
              this.state.sHeaderSelectedButton == eHeaderButton.DEALS
            }
          /> */}

          <HeaderButton
            pName={
              PartnerManager.shared().stores.length > 1
                ? "LOCATIONS"
                : "LOCATION"
            }
            onPointerEnter={() => {
              this.setState({
                sShowLocations: true,
              });
            }}
            onPointerLeave={() => {
              this.setState({
                sShowLocations: false,
              });
            }}
          />

          <Popover
            disableRestoreFocus={true}
            open={this.state.sShowLocations}
            onClose={() => {
              this.setState({ sShowLocations: false });
            }}
            anchorEl={this.mSearch.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            elevation={0}
            marginThreshold={0}
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "100%",
                marginTop: PixelsToViewPortUnitConverter.getvw(6),
                borderBottomWidth: "1px",
                borderTopWidth: "0px",
                borderStyle: "solid",
                pointerEvents: "auto",
                borderColor: (theme) =>
                  alpha(theme.palette.primary.contrastText, 0.17),
              },
              onPointerEnter: () => {
                this.setState({ sShowLocations: true });
              },
              onPointerLeave: () => {
                this.setState({ sShowLocations: false });
              },
            }}
            sx={{ pointerEvents: "none" }}
          >
            <LocationHeaderMenu
              onStoreSelected={() => {
                this.setState({ sShowLocations: false });
              }}
            />
          </Popover>

          <Search
            ref={this.mSearch}
            sx={{
              opacity: this.state.sShowSearchShadow ? 0 : 1,
              marginLeft: "auto",
              paddingLeft: PixelsToViewPortUnitConverter.getvw(12),
              paddingRight: PixelsToViewPortUnitConverter.getvw(12),
              visibility: this.state.sShowSearchBar ? "visible" : "hidden",
              display: {
                sm: "unset",
                xs: "none",
              },
            }}
          >
            {this.searchElement()}
          </Search>

          {this.state.sShowSearchShadow && (
            <ClickAwayListener
              onClickAway={() => {
                this.setState({
                  sSearchSuggestions: false,
                  sShowSearchShadow: false,
                });
              }}
            >
              <Search
                sx={{
                  position: "absolute",
                  left: this.mSearch.current.offsetLeft,
                  top: this.mSearch.current.offsetTop,
                  paddingBottom:
                    this.state.sSearchResults &&
                    this.state.sSearchResults.productsCount > 0
                      ? PixelsToViewPortUnitConverter.getvw(12)
                      : "unset",
                  width: this.mSearch.current.offsetWidth,
                  backgroundColor: (theme) => theme.palette.background.default,
                  boxShadow: "0px 4px 15px 0px rgba(0,0,0,0.15)",
                }}
              >
                {this.searchElement(true, true)}
                {this.state.sSearchResults &&
                  this.state.sSearchResults.products &&
                  this.state.sSearchResults.products.length > 0 && (
                    <Box
                      sx={{
                        maxHeight: "80vh",
                        overflowY: "auto",
                        backgroundColor: (theme) =>
                          theme.palette.background.default,
                      }}
                    >
                      <List>
                        {this.state.sSearchResults.products.map((product) => (
                          <ListItemButton
                            sx={{
                              overflowX: "hidden",
                              paddingRight: "0px",
                              paddingY: PixelsToViewPortUnitConverter.getvw(12),
                            }}
                            onClick={() => {
                              this.setState({
                                sSearchSuggestions: false,
                                sShowSearchShadow: false,
                              });
                              Navigation.toProductDetails(
                                this.props.router,
                                product
                              );
                            }}
                            key={product.id}
                          >
                            <SearchSuggestionItem pProduct={product} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Box>
                  )}
              </Search>
            </ClickAwayListener>
          )}

          <IconButton
            ref={this.mProfileIcon}
            sx={{
              stroke: (theme) => theme.palette.primary.contrastText,
              padding: "0px",
              marginLeft: PixelsToViewPortUnitConverter.getvw(20),
              marginRight: PixelsToViewPortUnitConverter.getvw(40),
              display: {
                sm: "unset",
                xs: "none",
              },
            }}
            onClick={() => {
              if (AmplifyManager.shared().isUserLoggedIn()) {
                // Navigate to Profile screen
                Navigation.toProfile(this.props.router);
              } else {
                this.setState({
                  sShowProfileContent: !this.state.sShowProfileContent,
                });
              }
            }}
          >
            <ProfileIcon
              sx={{
                width: PixelsToViewPortUnitConverter.getvw(43),
                height: PixelsToViewPortUnitConverter.getvw(43),
                fill: "none",
              }}
              viewBox="0 0 43 43"
            />
          </IconButton>

          <LoginComponent
            pShowProfileContent={this.state.sShowProfileContent}
            anchorEl={this.mAppBar.current}
            onOpen={() => {
              this.setState({ sShowProfileContent: true });
            }}
            onClose={() => {
              this.setState({ sShowProfileContent: false });
            }}
            onLoginSuccess={() => {
              this.setState({ sShowProfileContent: false });
              Navigation.toProfile(this.props.router);
            }}
            onSignUpClicked={() => {
              this.setState({ sShowProfileContent: false });
              Navigation.toSignUp(this.props.router);
            }}
            onNavigateToVerifyCode={(userName, password) => {
              this.setState({ sShowProfileContent: false });
              Navigation.toVerifyCode(this.props.router, userName, password);
            }}
          />

          <Button
            onClick={() => {
              this.setState({ sHeaderSelectedButton: undefined });
              this.props.router.navigate({
                pathname: "cart",
              });
            }}
            variant="contained"
            disableElevation={true}
            sx={{
              height: {
                sm: PixelsToViewPortUnitConverter.getvw(45),
                xs: PixelsToViewPortUnitConverter.getvw(90),
              },
              padding: {
                sm: `${PixelsToViewPortUnitConverter.getvw(0)} 
                ${PixelsToViewPortUnitConverter.getvw(26)}`,
                xs: `${PixelsToViewPortUnitConverter.getvw(40)} 
                ${PixelsToViewPortUnitConverter.getvw(50)}`,
              },
              fontSize: {
                sm: PixelsToViewPortUnitConverter.getFontVw(20),
                xs: PixelsToViewPortUnitConverter.getFontVw(12),
              },
              fontFamily: "Barlow",
              fontWeight: 700,
              borderRadius: PixelsToViewPortUnitConverter.getvw(40),
              minWidth: "0px",
              letterSpacing: "0.16em",
              position: {
                sm: "unset",
                xs: "absolute",
              },

              right: {
                sm: "unset",
                xs: "20px",
              },
              top: {
                sm: "unset",
                xs: "14px",
              },
            }}
            startIcon={
              <ShoppingCartIcon
                sx={{
                  marginLeft: "0px",
                  marginRight: "0px",
                  height: {
                    sm: PixelsToViewPortUnitConverter.getvw(24),
                    xs: PixelsToViewPortUnitConverter.getvw(70),
                  },
                  width: {
                    sm: PixelsToViewPortUnitConverter.getvw(21),
                    xs: PixelsToViewPortUnitConverter.getvw(55),
                  },
                }}
                viewBox="0 0 24 23"
              />
            }
          >
            {this.props.pCartCount}
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
  private searchElement(
    inAutoFocus: boolean = false,
    isSuggestionSearch: boolean = false
  ) {
    return (
      <Box>
        <IconButton
          focusRipple={true}
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.17),
          }}
          onClick={() => {
            const parsed = queryString.parse(this.props.router.location.search);
            parsed["query"] = this.state.sSearchValue;
            Navigation.toShopAllWithSearchQuery(
              this.props.router,
              `${queryString.stringify(parsed)}`
            );
            this.setState({ sShowSearchShadow: false });
          }}
        >
          <SearchIcon
            sx={{
              height: PixelsToViewPortUnitConverter.getvw(20),
            }}
            viewBox="0 0 31 31"
          />
        </IconButton>

        <StyledInputBase
          placeholder="Search strain, brand, effect, etc"
          inputProps={{ "aria-label": "search" }}
          value={this.state.sSearchValue}
          autoFocus={inAutoFocus}
          ref={isSuggestionSearch ? this.mSuggestionSearchInputRef : undefined}
          onChange={(e) => {
            this.setState({
              sSearchResults: undefined,
              sSearchValue: e.currentTarget.value,
              sSearchSuggestions: true,
              sShowSearchShadow: true,
            });
            if (!TextUtils.isEmpty(e.currentTarget.value)) {
              MenuService.getProducts("", e.currentTarget.value)
                .then((results) => {
                  if (results.query == this.state.sSearchValue) {
                    this.setState({ sSearchResults: results });
                  }
                })
                .catch((apiError: ApiError) => {});
            }
          }}
          onClick={(e) => {
            setTimeout(() => {
              this.setState({
                sSearchSuggestions: true,
                sShowSearchShadow: true,
              });
            }, 200);

            // e.stopPropagation();
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              const parsed = queryString.parse(
                this.props.router.location.search
              );
              parsed["query"] = this.state.sSearchValue;
              Navigation.toShopAllWithSearchQuery(
                this.props.router,
                `${queryString.stringify(parsed)}`
              );
              this.setState({ sShowSearchShadow: false });
            }
          }}
        />

        <Button
          variant="text"
          sx={{
            opacity: !TextUtils.isEmpty(this.state.sSearchValue) ? 1 : 0,
            fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
            fontFamily: "Barlow",
            fontWeight: 500,
            minWidth: "0px",
            minHeight: "0px",
            padding: "0px",
            paddingRight: PixelsToViewPortUnitConverter.getFontVw(4),
            paddingLeft: PixelsToViewPortUnitConverter.getFontVw(4),
            color: (theme) => theme.palette.primary.contrastText,
          }}
          onClick={() => {
            this.setState({
              sSearchResults: undefined,
              sSearchSuggestions: false,
              sSearchValue: "",
              sShowSearchShadow: false,
            });
          }}
        >
          Clear
        </Button>
      </Box>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  pCartCount: state.cartProducts.cart
    ? state.cartProducts.cart.products.length
    : 0,
  pStoreId: state.cartProducts.cart.storeId,
});

export default withRouter(connect(mapStateToProps, null)(Header));
