import FilterUtils from "../utils/FilterUtils";

export enum WeightType {
  HALF_GRAM = "half gram",
  GRAM = "gram",
  TWO_GRAM = "two gram",
  EIGHTH_OUNCE = "eighth ounce",
  QUARTER_OUNCE = "quarter ounce",
  HALF_OUNCE = "half ounce",
  OUNCE = "ounce",
  EACH = "each",
}

export const weightDisplayMapping = {
  half_gram: "0.5G",
  gram: "1G",
  two_gram: "2G",
  eighth_ounce: "3.5G",
  quarter_ounce: "7G",
  half_ounce: "14G",
  ounce: "28G",
  each: "ea.",
};

const weightRankMapping = {
  half_gram: 0,
  gram: 1,
  two_gram: 2,
  eighth_ounce: 3,
  quarter_ounce: 4,
  half_ounce: 5,
  ounce: 6,
  each: 7,
};

export default class Weight {
  private mName: string;
  public get name() {
    return this.mName;
  }

  private mType: WeightType;
  public get type() {
    return this.mType;
  }

  private mMrp: number;
  public get mrp() {
    return this.mMrp;
  }

  private mSellingPrice: number;
  public get sellingPrice() {
    return this.mSellingPrice;
  }

  private mIsDefault: boolean;
  public get isDefault() {
    return this.mIsDefault;
  }

  private mPriceId: string;
  public get priceId() {
    return this.mPriceId;
  }

  private mRank: number;
  public get rank() {
    return this.mRank;
  }

  public constructor() {
    this.mIsDefault = false;
  }

  public init(item: any) {
    this.mName = item.name;
    this.mType = item.type;
    this.mMrp = item.mrp;
    this.mSellingPrice = item.selling;
    this.mIsDefault = item.is_default;
  }

  public setIsDefault(isDef) {
    this.mIsDefault = isDef
  }

  public static list(item: any): Weight[] {
    let finalArray: Weight[] = [];
    const isPriceSortingActive = FilterUtils.isPriceSortingApplied()
    const bucketPrice = (isPriceSortingActive) ? -1 : item.bucket_price;
    const wArray =
      item.available_weights.length > 0 ? item.available_weights : ["each"];
    const length = wArray.length;
    let isDefaultFound = false;

    for (var i = 0; i < length; i++) {
      const weight = wArray[i];
      let wObject = new Weight();
      const weightName = weight.replace(" ", "_");
      const weightType = this.getWeightTypeByValue(weight);
      const weightDisplayName = weightDisplayMapping[weightName];
      const weightRank: number = weightRankMapping[weightName];
      const mrpKey = `price_${weightName}`;
      const sellingKey = `discounted_price_${weightName}`;

      const mrpPrice = item[mrpKey];
      const sellingPrice = item[sellingKey];

      let mrpFinal = 0;
      let sellingFinal = 0;

      if (sellingPrice && Number(sellingPrice | 0) === bucketPrice) {
        isDefaultFound = true;
      } else if (mrpPrice && Number(mrpPrice | 0) === bucketPrice) {
        isDefaultFound = true;
      } else {
        isDefaultFound = false;
      }
      
      if(isPriceSortingActive) {
        isDefaultFound = false;
      }

      mrpFinal = mrpPrice;
      sellingFinal = sellingPrice;

      const wItem = {
        name: weightDisplayName,
        type: weightType,
        mrp: mrpFinal,
        selling: sellingFinal,
        is_default: isDefaultFound,
      };
      wObject.init(wItem);
      wObject.mPriceId = weightName;
      wObject.mRank = weightRank;
      finalArray.push(wObject);
    }

    // Sorting applied as per the current filter.
    if(isPriceSortingActive) {
      let foundItem: Weight = null;
      if(FilterUtils.isPriceLowToHigh() || FilterUtils.isPriceHighToLow()) {
        foundItem = finalArray.reduce((prev, current) => (+prev.mrp < +current.mrp) ? prev : current)
      }
      if(foundItem) {
        console.log(foundItem)
        const indexTobeUpdate = finalArray.indexOf(foundItem)
        if (indexTobeUpdate !== -1) {
          foundItem.setIsDefault(true)
          finalArray[indexTobeUpdate] = foundItem
        }
      }
    }

    if (finalArray.length > 0) {
      finalArray.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    }

    return finalArray;
  }

  public static getWeightTypeByValue(weight: string) {
    const indexOfW = Object.values(WeightType).indexOf(
      (weight as unknown) as WeightType
    );
    const weightType = Object.keys(WeightType)[indexOfW];
    return weightType;
  }

  public static getDefaultWeight(weights: Weight[]): Weight | undefined {
    let wObject = weights.find((item: Weight) => item.isDefault === true);
    if (wObject) {
      return wObject;
    }
    return undefined;
  }

  public static getWeightByPriceID(
    priceId: string,
    weights: Weight[]
  ): Weight | undefined {
    let wObject = weights.find((item: Weight) => item.priceId === priceId);
    if (wObject) {
      return wObject;
    }
    return undefined;
  }

  public getDiscountPrice(): string | undefined {
    const mrpP = Number(this.mMrp).toFixed(2);
    const sellingP = Number(this.sellingPrice).toFixed(2);
    
    if (mrpP !== sellingP && sellingP !== '0.00') {
      return `$${mrpP}`;
    }
    return undefined;
  }

  public getSellingPrice() {
    const sellingP = Number(this.sellingPrice).toFixed(2);
    if(sellingP === '0.00') {
      const mrpP = Number(this.mMrp).toFixed(2);
      return `$${mrpP}`;
    }
    return `$${sellingP}`;
  }

  public getMrp() {
    const mrp = Number(this.mMrp).toFixed(2);
    return `$${mrp}`;
  }
}
