import WebServiceUtils from "../core/webservice/WebServiceUtils";
import Store from "../core/models/Store";
import Category from "../core/models/Category";
import AlgoliaManager from "../core/webservice/AlgoliaManager";
import Effect from "../core/models/Effect";
import PartnerManager from "../core/utils/PartnerManager";
import DateUtils from "../core/utils/DateUtils";
import StoreReview from "../core/models/StoreReview";

export default class StoreOperationsService {
  public static STORE_OPERATIONS_END_POINT = `${process.env.REACT_APP_JANE_ROOTS_BASE_URL}/store_operations`;
  public static STORE_REVIEWS_END_POINT = `${process.env.REACT_APP_JANE_ROOTS_BASE_URL}/store_review_operations`;

  public static async getStores() {
    await WebServiceUtils.validateJaneAuthToken();
    // https://dev-loq8-checkout.digcite.com/phoenix/store_operations/stores
    PartnerManager.shared().resetStores();
    const url = StoreOperationsService.STORE_OPERATIONS_END_POINT + "/stores";
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try {
        if (
          response.data &&
          response.data.data &&
          response.data.data.stores &&
          Array.isArray(response.data.data.stores)
        ) {
          let finalArray = Store.list(response.data.data.stores);
          PartnerManager.shared().setStores(finalArray);
          return Promise.resolve(finalArray);
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  /* will be replaced by algolia */
  public static async getStoreDetails(storeId: number) {
    await WebServiceUtils.validateJaneAuthToken();
    // https://staging.nonprod-iheartjane.com/api/roots/store_operations_api/v1/stores/27
    const url =
      StoreOperationsService.STORE_OPERATIONS_END_POINT + `/stores/${storeId}`;
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try {
        if (response.data && response.data.data && response.data.data.store) {
          let store: Store = new Store(response.data.data.store);
          return Promise.resolve(store);
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  /* will be replaced by algolia */
  public static async getRootCategories() {
    await WebServiceUtils.validateJaneAuthToken();
    // https://staging.nonprod-iheartjane.com/api/roots/store_operations_api/v1/product_types
    const url =
      StoreOperationsService.STORE_OPERATIONS_END_POINT +
      `/product_types?page_size=500`;
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try {
        if (
          response.data &&
          response.data.data &&
          response.data.data.product_types &&
          Array.isArray(response.data.data.product_types)
        ) {
          let arrayOfCategories = response.data.data.product_types;
          let finalArray: Category[] = Category.list(arrayOfCategories);
          return Promise.resolve(finalArray);
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  public static async getCategories(storeId: number) {
    await AlgoliaManager.shared().validateSearchClient();
    PartnerManager.shared().resetCategories();

    return new Promise<Category[]>((resolve, reject) => {
      let storeIdFilters = `store_id = ${storeId}`;

      const facets = ["root_types"];
      let searchIndex = AlgoliaManager.shared().menuProductsIndex();
      searchIndex
        .search("", { hitsPerPage: 0, filters: storeIdFilters, facets: facets })
        .then((results: any) => {
          try {
            let categoryArray: Category[] = Category.algoliaList(results);
            PartnerManager.shared().setCategories(categoryArray);
            return resolve(categoryArray);
          } catch (error) {
            return reject(AlgoliaManager.shared().commonAPIError());
          }
        });
    });
  }

  public static async getEffects(storeId: number) {
    await AlgoliaManager.shared().validateSearchClient();
    PartnerManager.shared().resetEffects();

    return new Promise<Effect[]>((resolve, reject) => {
      let storeIdFilters = `store_id = ${storeId}`;
      const facets = ["feelings"];
      let searchIndex = AlgoliaManager.shared().menuProductsIndex();
      searchIndex
        .search("", { hitsPerPage: 0, filters: storeIdFilters, facets: facets })
        .then((results: any) => {
          try {
            let effectsArray: Effect[] = Effect.list(results);
            PartnerManager.shared().setStoreEffects(effectsArray);
            return resolve(effectsArray);
          } catch (error) {
            return reject(AlgoliaManager.shared().commonAPIError());
          }
        });
    });
  }

  public static async getStoreReviews(
    storeId: number,
    count: number = 3,
    date: number = DateUtils.getCurrentTimeUnixTimestamp()
  ) {
    await WebServiceUtils.validateJaneAuthToken();
    const url =
      StoreOperationsService.STORE_REVIEWS_END_POINT +
      `/${storeId}` +
      `/reviews?count=${count}` +
      `&date=${date}`;
    const response = await WebServiceUtils.get({}, url);
    if (response.success) {
      try { 
        if (
          response.data &&
          response.data.reviews &&
          Array.isArray(response.data.reviews)
        ) {
          const storeReviews: StoreReview[] = [];
          let storeReviewJson;
          for (storeReviewJson of response.data.reviews) {
            storeReviews.push(new StoreReview(storeReviewJson));
          }

          return Promise.resolve(storeReviews);
        }
      } catch (error) {}
    }
    return WebServiceUtils.handleNetworkError(response);
  }
}
