import React, {useEffect, useState} from "react";
import "./WeightBox.scss";
import { alpha, Box, ButtonBase, Grid } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import Weight from "../../../../core/models/Weight";
import TextUtils from "../../../../core/utils/TextUtils";
import StrikeLabel from "./StrikeLabel";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";

type WeightBoxProps = {
    selectedWeight: Weight;
    weight: Weight
    xsItem:number
    onSelectedWeight(weight: Weight): void
};

const WeightBox: React.FC<WeightBoxProps> = (props: WeightBoxProps) => {

    const handleWeightClick = (event: React.MouseEvent<HTMLElement>, weight: Weight) => {
        props.onSelectedWeight(weight)
    }

    const isSelected = (props.selectedWeight && props.weight.name === props.selectedWeight.name)?true:false
    const weight = props.weight
    return(
        <Box className="weight-btn-box">
            <ButtonBase onClick={(e) => {handleWeightClick(e,weight)}} sx={{width:'100%'}}>
            <Box className="weight-box" 
                sx={{
                    backgroundColor: (theme) => alpha(theme.palette.primary.contrastText,0.05),
                    border: (isSelected) ? '2px solid' : '2px solid',
                    borderColor: (isSelected) ? (theme) => alpha(theme.palette.secondary.main,1.0) : 'transparent',
                    borderRadius: 0,
                    boxShadow: 'none'
                }}>
                    {(!TextUtils.isEmpty(weight.name)) && 
                        <Typography
                        variant="h1"
                        className="weight-title"
                        sx={{
                            color: (theme) => alpha(theme.palette.primary.contrastText,0.49)
                        }}>
                            {weight.name}
                        </Typography>}
                    {weight.getDiscountPrice() && 
                        <StrikeLabel value={weight.getDiscountPrice()} mb={5}/>
                    }
                    <Typography
                        variant="h1"
                        className="weight-price"
                        sx={{
                            color: (theme) => alpha(theme.palette.primary.contrastText,1.0)
                        }}>
                            {weight.getSellingPrice()}
                    </Typography>
            </Box>
            </ButtonBase>
        </Box>
        
        
    )
}
export default WeightBox





