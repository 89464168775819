import React, { useEffect, useRef, useState } from "react";
import "./LoadMoreComponent.scss";
import { Box, Button, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ProductList from '../../../../core/models/ProductList';

type LoadMoreComponentProps = {
    productList: ProductList
    pOnLoadMoreTapped(productList: ProductList): void;
};


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main //theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export const LoadMoreComponent: React.FC<LoadMoreComponentProps> = (props: LoadMoreComponentProps) => {

    const num = (props.productList.products.length/props.productList.productsCount) * 100
    const progress = num | 0

    const handleClickEvent = () => {
        props.pOnLoadMoreTapped(props.productList)
    };
    
    return(
        <Box
            sx={{
                gridArea: '1 / 1 / auto / span 2'
            }}>
                <Typography
                    sx={{
                        color: (theme) => theme.palette.primary.main
                    }}
                    className="pagination-label">
                        {`You've viewed ${props.productList.products.length} of ${props.productList.productsCount}`}
                </Typography>
                <Box sx={{mt:'10px'}}>
                    <BorderLinearProgress variant="determinate" value={progress} />
                </Box>
                <Box 
                    sx={{
                        display: 'flex',
                        mt:'12px', 
                        // boxShadow: 'rgb(188 188 188 / 50%) 0px 2px 4px 0px'
                    }}>
                    <Button
                        sx={{
                            // border: '1px solid',
                            // borderColor: (theme) => theme.palette.secondary.main,
                            // backgroundColor: (theme) => theme.palette.secondary.main,
                            textTransform: 'none',
                            fontFamily: 'Overpass',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            width: '100%',
                            height: '50px',
                            fontSize: '18px',//PixelsToViewPortUnitConverter.getFontVw(23),
                            lineHeight: '30px',//PixelsToViewPortUnitConverter.getFontVw(35),
                            // color: '#000'
                        }}
                        variant="contained"
                        // variant="outlined" 
                        onClick={handleClickEvent}>
                        {`Load More`}
                    </Button>
                </Box>
        </Box>      
    )
}





