import PartnerManager from "../utils/PartnerManager";

export default class Effect {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mName: string;
  public get name() {
    return this.mName;
  }

  private mRank: number;
  public get rank() {
    return this.mRank;
  }

  private mProductsCount: number;
  public get productsCount() {
    return this.mProductsCount;
  }

  private mImageUrl: string;
  public get imageUrl() {
    return this.mImageUrl;
  }

  public constructor() {}

  public init(effectName: string, productsCount: any) {
    this.mName = effectName;
    this.mProductsCount = productsCount;
  }

  public static list(response: any): Effect[] {
    let finalArray: Effect[] = [];
    let shouldApplySort = false;
    if (
      response &&
      response.facets &&
      response.facets.feelings &&
      Object.keys(response.facets.feelings).length > 0
    ) {
      let items = response.facets.feelings;
      let rankIndex = 1;
      Object.entries(items).forEach(([key, value]) => {
        let effectObj: Effect = new Effect();
        effectObj.init(key, value);
        effectObj.mId = 0;
        let partnerEffect = PartnerManager.shared().findEffect(key);
        if (partnerEffect) {
          effectObj.mId = partnerEffect.id;
          effectObj.mRank = partnerEffect.rank;
          effectObj.mImageUrl = partnerEffect.imageUrl;
          if (!shouldApplySort) shouldApplySort = true;
        } else {
          effectObj.mRank = rankIndex;
        }
        finalArray.push(effectObj);
        rankIndex += 1;
      });
    }
    if (shouldApplySort && finalArray.length > 0) {
      finalArray.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    }
    return finalArray;
  }

  public static partnerEffectList(arrayOfEffects: any): Effect[] {
    let finalArray: Effect[] = [];
    for (let effect of arrayOfEffects) {
      let effectObj: Effect = new Effect();
      effectObj.mId = effect.id;
      effectObj.mName = effect.name;
      effectObj.mRank = effect.rank;
      effectObj.mImageUrl = this.getEffectImage(effectObj.mName);
      finalArray.push(effectObj);
    }
    return finalArray;
  }

  public static productEffectList(arrayOfEffects: any): Effect[] {
    if(!arrayOfEffects) return []
    let finalArray: Effect[] = [];
    let id = 0;
    for (let effect of arrayOfEffects) {
      let effectObj: Effect = new Effect();
      effectObj.mId = id;
      effectObj.mName = effect;
      effectObj.mRank = id;
      effectObj.mImageUrl = undefined;
      finalArray.push(effectObj);
      id++;
    }
    return finalArray;
  }

  private static getEffectImage(effectName: string): string {
    const effectImages = new Map<string, string>([
      [
        "Relaxed",
        "https://d2o1iceed9pvab.cloudfront.net/446e977513a8433b99bffa9d7f04677a.jpg",
      ],
      [
        "Energetic",
        "https://d2o1iceed9pvab.cloudfront.net/a0e7ced3d0724d60b7ab7403386f0ccd.jpg",
      ],
      [
        "Hungry",
        "https://d2o1iceed9pvab.cloudfront.net/6babd99f8a5c40e7a2149c85967ae5a4.jpg",
      ],
      [
        "Creative",
        "https://d2o1iceed9pvab.cloudfront.net/74f137fc3d564f98bf9cd3248f3fa045.jpg",
      ],
      [
        "Pain free",
        "https://d2o1iceed9pvab.cloudfront.net/a512c50210704ec9b00d0e5ddc98fc72.jpg",
      ],
      [
        "Blissful",
        "https://d2o1iceed9pvab.cloudfront.net/08ae7751f5dd46d48310b3b81fb7572f.jpg",
      ],
      [
        "Sleepy",
        "https://d2o1iceed9pvab.cloudfront.net/f8d08ddc1cba490388a07866b2474479.jpg",
      ],
      [
        "Not high",
        "https://d2o1iceed9pvab.cloudfront.net/0aa50553fea2470c89867ee3284b0a90.jpg",
      ],
    ]);

    if (effectImages.has(effectName)) return effectImages.get(effectName);
    else return "";
  }

  public static getEffectImageUrl(
    themeName: string,
    effectName: string
  ): string {
    return (
      process.env.REACT_APP_CDN_BASE_URL +
      "effects_v2" +
      "_" +
      effectName.replace(/\s/g, "").toLowerCase() +
      ".svg"
    );
  }
}
