import { Box, Button, Stack } from "@mui/material";
import * as React from "react";
import Carousel from "../../../../core/customcomponents/carousel/Carousel";
import ProductList from "../../../../core/models/ProductList";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../../core/webservice/ApiError";
import MenuService from "../../../../services/MenuService";
import Loader from "../../../common/loader/Loader";
import BestSellingProductComponent from "./BestSellingProductComponent";

interface IBestSellingProductsComponentState {
  sIsLoadingProducts: boolean;
  sProducts: BestSellingProductCarouselItem[];
  sLoadingError: boolean;
  sLoadingErrorMsg: String;
}

interface BestSellingProductCarouselItem {
  key: string;
  content: any;
}

export class BestSellingProductsComponent extends React.Component<
  any,
  IBestSellingProductsComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      sIsLoadingProducts: true,
      sProducts: [],
      sLoadingError: false,
      sLoadingErrorMsg: "",
    };
  }

  componentDidMount() {
    MenuService.getPopularProducts(
      PartnerManager.shared().currentSelectedStore.id,
      10,
      1
    )
      .then((products) => {
        const carouselSlides = products.products.map((product) => {
          return {
            key: product.objectId,
            content: <BestSellingProductComponent pProduct={product} />,
          };
        });
        this.setState({
          sProducts: carouselSlides,
          sIsLoadingProducts: false,
        });
      })
      .catch((apiError: ApiError) => {
        this.setState({
          sLoadingError: true,
          sLoadingErrorMsg: apiError.message,
          sIsLoadingProducts: false,
        });
      });
  }

  moveLeft = () => {};

  moveRight = () => {};

  render() {
    return (
      <Box sx={{ width: "100%", position: "relative" }}>
        {this.state.sIsLoadingProducts ? (
          <Box sx={{ minHeight: "100px" }}>
            <Loader pshow={true} />
          </Box>
        ) : this.state.sProducts && this.state.sProducts.length > 0 ? (
          <Stack sx={{ width: "100%", position: "relative" }}>
            <Box
              sx={{
                height: "27vw",
                width: "100%",
                marginTop: "-6vw",
                position: "relative",
              }}
            >
              <Carousel
                slides={this.state.sProducts}
                goToSlide={1}
                offsetRadius={2}
                showNavigation={true}
                opacity={1}
                autoPlay={true}
                interval={5}
              />
            </Box>
            {this.state.sProducts.length > 5 && (
              <Button
                sx={{
                  position: "relative",
                  marginX: "auto",
                  marginTop: PixelsToViewPortUnitConverter.getvw(100),
                }}
                variant="contained"
                fullWidth={false}
              >
                Show All Popular Items
              </Button>
            )}
          </Stack>
        ) : (
          ""
        )}
      </Box>
    );
  }
}
