import React, { useEffect, useState } from "react";
import "./ProductSection.scss";
import { RoundedButton } from "./RoundedButton";
import { ProductCarousel } from "./ProductCarousel";
import ProductGridList from "./ProductGridList";
import { ProductGridFooter } from "./ProductGridFooter";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useElementSize } from "usehooks-ts";
import ProductSectionModel from "../../../../core/models/ProductSectionModel";
import MenuService from "../../../../services/MenuService";
import { ApiError } from "../../../../core/webservice/ApiError";
import ProductList from "../../../../core/models/ProductList";
import FilterList from "../../../../core/models/FilterList";
import PartnerManager from "../../../../core/utils/PartnerManager";
import Product, { CartProduct } from "../../../../core/models/Product";
import DimensionUtils from "../../../../core/utils/DimensionUtils";
import { Cart } from "../../../common/cart/CartReducer";
import { withMediaQuery } from "../../../../withMediaQuery";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import AppRoutes from "../../../../routes";

type ProductSectionProps = {
  isMobile: boolean;
  productSectionModel: ProductSectionModel;
  filterList: FilterList;
  isSeparateComponent?: boolean;
  pCart: Cart | undefined;
  pSeeAllButtonTitle?: string;
  pSeeAllButtonShow?: boolean;
  pOnSeeAllTapped(productSectionModel: ProductSectionModel): void;
  pOnProductClicked(product: Product): void;
  pOnAddOrRemoveProductFromCart(product: CartProduct): void;
  pOnDataLoadSuccess?(): void;
  pOnDataLoadFailed?(): void;
};

const ProductSection: React.FC<ProductSectionProps> = (
  props: ProductSectionProps
) => {
  const defProductList = new ProductList();
  defProductList.initWithDefault();
  const [productList, setProductList] = useState<ProductList>(defProductList);
  const [ref, { width, height }] = useElementSize();
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      if (props.isSeparateComponent && productList.dataLoaded) {
        // no data load required if loaded already - popular/related
        return;
      }
      getMenuProducts();
    }
  }, [props.filterList.filters]);

  useEffect(() => {
    getMenuProducts();
  }, [currentPage]);

  const getMenuProducts = () => {
    if (isDataLoading) return;
    setIsDataLoading(true);
    const pageSize = props.productSectionModel.isSelected ? 15 : 15;
    const storeId = PartnerManager.shared().currentSelectedStore
      ? PartnerManager.shared().currentSelectedStore.id
      : undefined;
    if (!storeId) {
      setIsDataLoading(false);
    }
    MenuService.getMenuProducts(
      [storeId],
      props.filterList,
      productList,
      props.productSectionModel,
      pageSize,
      currentPage
    )
      .then((rProductList: ProductList) => {
        setProductList(rProductList);
        // setIsDataLoading(false)
        setTimeout(() => {
          setIsDataLoading(false);
        }, 100);
        if (props.pOnDataLoadSuccess) {
          props.pOnDataLoadSuccess();
        }
      })
      .catch((error: ApiError) => {
        console.log("error code:" + error.errorCode);
        console.log("error message:" + error.message);
        setIsDataLoading(false);
        if (props.pOnDataLoadFailed) {
          props.pOnDataLoadFailed();
        }
      });
  };

  const handleSeeAllClickEvent = () => {
    props.pOnSeeAllTapped(props.productSectionModel);
  };

  const renderSeeAllButton = () => {
    // {`See All (${productList.productsCount})`}
    if (
      isDataLoading ||
      !productList.dataLoaded ||
      (productList.dataLoaded && productList.products.length === 0)
    )
      return null;
    if (
      props.productSectionModel.isSelected &&
      !props.productSectionModel.isPopularItems
    )
      return null;
    const btnTitle = props.pSeeAllButtonTitle
      ? props.pSeeAllButtonTitle
      : `See All Items`;
    const { pSeeAllButtonShow = true } = props;
    if (!pSeeAllButtonShow) return null;
    return <RoundedButton title={btnTitle} onClick={handleSeeAllClickEvent} />;
  };

  const renderCategoryProductCount = () => {
    if (
      !productList.dataLoaded ||
      (productList.dataLoaded && productList.products.length === 0)
    )
      return null;
    //if(!props.productSectionModel.isSelected) return null
    return (
      <Typography
        className="category-product-count-label"
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
          fontSize: {
            sm: `${PixelsToViewPortUnitConverter.getFontVw(14)} !important`,
            xs: `${PixelsToViewPortUnitConverter.getFontVw(11)} !important`,
          },
        }}
      >
        {productList.getProductCountInfo()}
      </Typography>
    );
  };

  const renderSectionHeader = (carouselDimension: any) => {
    return (
      <Stack
        className="section-header-container"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        spacing={0}
        sx={{
          ml: `${carouselDimension.parentLeftPadding}px`,
          mr: `${carouselDimension.parentRightPadding}px`,
        }}
      >
        <Box className="category-title-container">
          <Typography
            className="category-title"
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              fontSize: {
                sm: `${PixelsToViewPortUnitConverter.getFontVw(32)} !important`,
                xs: `${PixelsToViewPortUnitConverter.getFontVw(25)} !important`,
              },
            }}
          >
            {props.productSectionModel.name}
          </Typography>
          {window.location.pathname === AppRoutes.PRODUCTS &&
            renderCategoryProductCount()}
        </Box>
        {renderSeeAllButton()}
      </Stack>
    );
  };

  const renderProductList = (carouselDimension: any) => {
    {
      /* <p>{`The square width is ${width}px and height ${height}px`}</p> */
    }
    const itemDimension = carouselDimension;
    if (props.productSectionModel.isSelected) {
      return (
        <ProductGridList
          itemDimension={itemDimension}
          productList={productList}
          isLoading={isDataLoading}
          pCart={props.pCart}
          pOnProductClicked={props.pOnProductClicked}
          pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}
        />
      );
    }
    return (
      <ProductCarousel
        isMobile={props.isMobile}
        itemDimension={itemDimension}
        productList={productList}
        isLoading={isDataLoading}
        pCart={props.pCart}
        pOnProductClicked={props.pOnProductClicked}
        pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}
      />
    );
  };

  const renderLoadingAnimation = () => {};

  const onLoadMoreTapped = (productList: ProductList) => {
    let nextPage = productList.page;
    nextPage++;
    setCurrentPage(nextPage);
  };

  const onScrollToTopTapped = () => {
    window.scrollTo(0, 1);
  };

  const renderProductGridFooter = () => {
    if (
      isDataLoading ||
      !props.productSectionModel.isSelected ||
      !productList.dataLoaded
    )
      return null;
    if (props.productSectionModel.isPopularItems) return null;
    return (
      <ProductGridFooter
        productList={productList}
        pOnLoadMoreTapped={onLoadMoreTapped}
        pOnScrollToTopTapped={onScrollToTopTapped}
      />
    );
  };

  // to hide the whole section
  // console.log('productList.name :'+props.productSectionModel.name+' dataLoaded : '+productList.dataLoaded+' products.length : '+productList.products.length)
  if (productList.dataLoaded && productList.products.length === 0) {
    return null;
  }
  let carouselItemsSize = props.isMobile ? 2 : 4;
  let carouselDimension = DimensionUtils.getProductCardDimension(
    width,
    productList.products.length,
    carouselItemsSize,
    !props.isSeparateComponent
  );
  if (props.productSectionModel.isSelected) {
    carouselDimension = DimensionUtils.getProductCardGridDimension(
      width,
      productList.products.length
    );
  }
  const pIsSeparateComponent = props.isSeparateComponent
    ? props.isSeparateComponent
    : false;
  const mainClassName = `cmenu-product-section ${
    pIsSeparateComponent && "-separated"
  }`;

  // console.log(props.pOnSeeAllTapped);
  return (
    <Box className={mainClassName} ref={ref}>
      {renderSectionHeader(carouselDimension)}
      {productList.dataLoaded &&
        productList.products.length > 0 &&
        renderProductList(carouselDimension)}
      {productList.dataLoaded &&
        productList.products.length > 0 &&
        renderProductGridFooter()}
    </Box>
  );
};
export default withMediaQuery(ProductSection);
