export default class Strings {
    
  /* Common */

  /* Error messages */
  public static DEFAULT_ERROR_MSG = "Something went wrong, Please try again.";
  public static NETWORK_ERROR = "Lost internet connection. Please check and try again.";
  public static FIELD_IS_EMPTY = "Field is empty";
  public static UNAUTHORIZED_ERROR = "unauthorized";
  public static MISSING_CLIENT_ID_ERROR = "Please provide client_id and client_secret.";
  public static BEARER_TOKEN_NOT_AVAILABLE_ERROR = "Please include valid access_token value in the Authorization header field as an HTTP bearer authorization scheme.";
  public static MISSING_SEND_OTP_PARAMS_ERROR = "Please provide email."
  public static MISSING_VERIFY_OTP_PARAMS_ERROR = "Please provide email and otp."
  public static ALGOLIA_NOT_CONFIGURED_ERROR = "Please configure algoliaAppId and algoliaAppKey."
  public static INVALID_FILE_ERROR = "Invalid file extention pleasse select another file."
  public static PARTNER_ALREADY_CREATED_ERROR = "Partner already created."
  public static REQUEST_PARAMS_MISSING = "Request parameters missing."

  /* User Auth messages */
  public static USER_NOT_FOUND_ERROR = "We cannot find an account with that email address. Would you like to sign up?"
  public static PASSWORD_CONSTRAINT_ERROR = "Password must be atleast 8 Characters."
  public static INVALID_PHONE_NUMBER_ERROR = "Please enter valid phone number"
  public static INCORRECT_PASSWORD_ON_CHANGE = "Incorrect Password"

  public static LOCATION_INFO_KEY = "locationDetails"

  private constructor() { }
}
