export default class Photo {
    
    private mId: number;
    public get id() {
      return this.mId;
    }
    
    private mPosition: number;
    public get position() {
      return this.mPosition;
    }

    private mOriginal: string;
    public get original() {
      return this.mOriginal;
    }

    private mSmall: string;
    public get small() {
      return this.mSmall;
    }

    private mMedium: string;
    public get medium() {
      return this.mMedium;
    }

    private mXL: string;
    public get extraLarge() {
      return this.mXL;
    }

    public constructor() {
      this.mId = null
      this.mPosition = 0
      this.mOriginal = null
      this.mSmall = null
      this.mMedium = null
      this.mXL = null
    }

    public init(item: any) {
        this.mId = item.id
        this.mPosition = item.position
        if(item.urls) {
          if(item.urls.original) {
            this.mOriginal =  item.urls.original
          }
          if(item.urls.small) {
            this.mSmall =  item.urls.small
          }
          if(item.urls.medium) {
            this.mMedium =  item.urls.medium
          }
          if(item.urls.extraLarge) {
            this.mXL =  item.urls.extraLarge
          }
        }
    }

    public static list(items: any): Photo[] {
        let finalArray: Photo[] = []
        const photosArray = items
        const length = photosArray.length
        for (var i = 0; i < length ; i++) { 
            const photo = photosArray[i]
            let pObject = new Photo()
            pObject.init(photo)
            finalArray.push(pObject)
        }
        return finalArray
    }
}