import Navigation from "../../Navigation";
import TextUtils from "../utils/TextUtils";
import AppRoutes from '../../routes';
const queryString = require("query-string");
export type IBreadcrumb = {
  name: string;
  type: BreadcrumbType;
  url: string;
  isPushed: boolean;
  index?: number;
};

export enum BreadcrumbType {
  HOME = "HOME",
  ALL_PRODUCTS = "ALL_PRODUCTS",
  EFFECT = "EFFECT",
  CATEGORY = "CATEGORY",
  SEARCH_RESULTS = "SEARCH_RESULTS",
  PRODUCT_DETAILS = "PRODUCT_DETAILS",
  PROFILE = "PROFILE",
  LOGIN = "LOGIN",
  SIGN_UP = "SIGN_UP",
  VERIFY_CODE = "VERIFY_CODE",
}

export default class Breadcrumb {
  public static readonly HOME = {
    name: "Home",
    type: BreadcrumbType.HOME,
    url: AppRoutes.ROOT,
    isPushed: false,
  };
  public static readonly SHOP_ALL = {
    name: "All Products",
    type: BreadcrumbType.ALL_PRODUCTS,
    url: AppRoutes.PRODUCTS,
    isPushed: false,
  };
  public static readonly POPULAR = {
    name: "Popular",
    type: BreadcrumbType.CATEGORY,
    url: "",
    isPushed: false,
  };
  public static readonly SEARCH_RESULTS = {
    name: "Search Results",
    type: BreadcrumbType.SEARCH_RESULTS,
    url: "",
    isPushed: false,
  };
  public static readonly PROFILE = {
    name: "Account",
    type: BreadcrumbType.PROFILE,
    url: AppRoutes.ACCOUNT,
    isPushed: false,
  };
  public static readonly LOGIN = {
    name: "Login",
    type: BreadcrumbType.LOGIN,
    url: AppRoutes.LOGIN,
    isPushed: false,
  };
  public static readonly SIGNUP = {
    name: "SignUp",
    type: BreadcrumbType.SIGN_UP,
    url: AppRoutes.SIGNUP,
    isPushed: false,
  };
  public static readonly VERIFY_CODE = {
    name: "Verify Code",
    type: BreadcrumbType.VERIFY_CODE,
    url: AppRoutes.VERIFY_CODE,
    isPushed: false,
  };

  private mName: string;
  public get name() {
    return this.mName;
  }

  private mType: BreadcrumbType;
  public get type() {
    return this.mType;
  }

  private mUrl: string;
  public get url() {
    return this.mUrl;
  }

  private mSearchPath: string;
  public get searchPath() {
    return this.mSearchPath;
  }

  private mIndex: number;
  public get index() {
    return this.mIndex;
  }

  private mIsActive: boolean;
  public get isActive() {
    return this.mIsActive;
  }

  private mIsFromHome: boolean;
  public get isFromHome() {
    return this.mIsFromHome;
  }

  private mIsFromShopAll: boolean;
  public get isFromShopAll() {
    return this.mIsFromShopAll;
  }

  public constructor() {
    this.mIsActive = false;
  }

  public init(item: IBreadcrumb) {
    this.mName =
      item.type === BreadcrumbType.CATEGORY ||
      item.type === BreadcrumbType.EFFECT
        ? TextUtils.capitalize(item.name)
        : item.name;
    this.mType = item.type;
    this.mUrl = item.url;
    this.mIndex = item.index ? item.index : undefined;

    let path = item.url;
    if (path.includes(Navigation.SHOP_ALL_PATH)) {
      path = path.replace(Navigation.SHOP_ALL_PATH, "");
    }
    if (path.includes("?")) {
      path = path.replace("?", "");
    }
    this.mSearchPath = path;
  }

  public static listForHome(arr: IBreadcrumb[]): Breadcrumb[] {
    let finalArray: Breadcrumb[] = [];
    arr.forEach((item, i) => {
      let bcrum = new Breadcrumb();
      bcrum.init(item);
      bcrum.mIsFromHome = true;

      if (i == arr.length - 1) {
        // add active item as last item always active
        bcrum.mIsActive = true;
      }

      finalArray.push(bcrum);
    });
    return finalArray;
  }

  public isPresentInURL(finalUrl: string): boolean {
    // for removing breadcrum incase filter removed
    if (
      this.type === BreadcrumbType.CATEGORY ||
      this.type === BreadcrumbType.EFFECT ||
      this.type === BreadcrumbType.SEARCH_RESULTS
    ) {
      if (finalUrl.includes(this.searchPath)) {
        return true;
      }
      return false;
    }
    return true;
  }
}
