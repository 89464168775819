import WebServiceUtils from "../core/webservice/WebServiceUtils";
import Store from "../core/models/Store";
import Category from "../core/models/Category";
import AlgoliaManager from "../core/webservice/AlgoliaManager";
import Effect from "../core/models/Effect";
import PartnerManager from "../core/utils/PartnerManager";
import { ApiError } from "../core/webservice/ApiError";
import SessionManager from "../core/utils/SessionManager";
import Strings from "../core/utils/Strings";
import ProductList from "../core/models/ProductList";
import TextUtils from "../core/utils/TextUtils";
import FilterList from "../core/models/FilterList";
import FilterUtils from "../core/utils/FilterUtils";
import Filter from "../core/models/Filter";
import SortingList from "../core/models/SortingList";
import ProductSectionModel from "../core/models/ProductSectionModel";
import AppliedFilter from "../core/models/AppliedFilter";
import Product from "../core/models/Product";
import ProductReviewList from "../core/models/ProductReviewList";

export default class MenuService {
  public static async getProductFilters(
    storeId: number,
    appliedFilterList: AppliedFilter[]
  ) {
    await AlgoliaManager.shared().validateSearchClient();
    return new Promise<FilterList>((resolve, reject) => {
      //const facets = ["root_types"]
      //const facets = ["activities","percent_thc","percent_cbd"]
      //const facets = ["percent_thc","percent_cbd","category","aggregate_rating","activities","feelings","bucket_price","available_weights","root_types","brand"];
      //const facets = ["available_weights"]; // "kind",
      const facets = [
        "category",
        "percent_thc",
        "percent_cbd",
        "aggregate_rating",
        "activities",
        "feelings",
        "bucket_price",
        "available_weights",
        "root_types",
        "brand",
      ];
      const sFilters = `store_id = ${storeId}`;
      let searchIndex = AlgoliaManager.shared().menuProductsIndex();
      searchIndex
        .search("", { hitsPerPage: 0, filters: sFilters, facets: facets })
        .then((results: any) => {
          try {
            let filterList: FilterList = new FilterList();
            filterList.initWithData(results, appliedFilterList, undefined);
            return resolve(filterList);
          } catch (error) {
            return reject(AlgoliaManager.shared().commonAPIError());
          }
        });
    });
  }

  public static async getMenuFilters(
    storeId: number,
    existingFilterList: FilterList
  ) {
    await AlgoliaManager.shared().validateSearchClient();
    return new Promise<FilterList>((resolve, reject) => {
      const tempAppliedFiltersList = existingFilterList.tempAppliedFilterList;
      const sortingList = existingFilterList.sortingList;
      const queries = FilterUtils.getMultipleQueries(
        storeId,
        tempAppliedFiltersList,
        sortingList
      );
      const client = AlgoliaManager.shared().getSearchClient();
      client.multipleQueries(queries).then((results: any) => {
        try {
          let newfilterList: FilterList = new FilterList();
          newfilterList.initWithData(
            results,
            tempAppliedFiltersList,
            sortingList
          );
          return resolve(newfilterList);
        } catch (error) {
          return reject(AlgoliaManager.shared().commonAPIError());
        }
      });
    });
  }

  public static async getMenuProducts(
    storeIds: number[],
    filterList: FilterList,
    previousProductList: ProductList,
    productSectionModel: ProductSectionModel,
    pageSize: number,
    page: number
  ) {
    await AlgoliaManager.shared().validateSearchClient();
    let sFinalStoreIds = storeIds;
    if (!sFinalStoreIds) {
      sFinalStoreIds = PartnerManager.shared().storeIds();
    }
    return new Promise<ProductList>((resolve, reject) => {
      const filters = filterList.filters;
      const sortingList = filterList.sortingList;
      const searchQueryParam = filterList.getSearchQueryParameter();
      const sPageSize = pageSize ? pageSize : 48;
      const sPageIndex = page ? page : 0;
      const filtersQuery = FilterUtils.getQueryFilter(
        sFinalStoreIds,
        filters,
        productSectionModel,
        sPageSize,
        sPageIndex
      );
      // let searchIndex = AlgoliaManager.shared().menuProductsIndex();

      const currentSortItem = sortingList.getSelectedSortingItem();
      const searchIndex = AlgoliaManager.shared().searchIndexObject(
        currentSortItem.value
      );

      searchIndex
        .search(searchQueryParam, filtersQuery)
        .then((results: any) => {
          try {
            // console.log('getMenuProducts =>',JSON.stringify(results))
            let productList: ProductList = ProductList.initWithResult(
              results,
              previousProductList
            );
            // console.log('productSectionModel =>',productSectionModel.name)
            // console.log('productList =>',JSON.stringify(productList))
            return resolve(productList);
          } catch (error) {
            return reject(AlgoliaManager.shared().commonAPIError());
          }
        });
    });
  }

  public static async getPopularProducts(
    storeId: number,
    pageSize: number,
    page: number
  ) {
    await AlgoliaManager.shared().validateSearchClient();
    return new Promise<ProductList>((resolve, reject) => {
      const sPageSize = pageSize ? pageSize : 48;
      const sPageIndex = page ? page : 0;
      const bestSellingSlug = "best_selling";
      const sFilters = `store_id = ${storeId} AND kind:${bestSellingSlug} OR root_types:${bestSellingSlug}`;
      const filtersQuery = {
        hitsPerPage: sPageSize,
        filters: sFilters,
        page: sPageIndex,
      };
      let searchIndex = AlgoliaManager.shared().menuProductsIndex();
      searchIndex.search("", filtersQuery).then((results: any) => {
        try {
          let productList: ProductList = ProductList.initWithResult(
            results,
            undefined
          );
          // console.log('productList =>',JSON.stringify(productList))
          return resolve(productList);
        } catch (error) {
          return reject(AlgoliaManager.shared().commonAPIError());
        }
      });
    });
  }

  public static async getProducts(
    filters: string = "",
    query: string = "",
    storeId: number = PartnerManager.shared().currentSelectedStore.id,
    pageSize: number = 48,
    page: number = 0
  ) {
    await AlgoliaManager.shared().validateSearchClient();
    return new Promise<ProductList>((resolve, reject) => {
      const sFilters = TextUtils.isEmpty(filters)
        ? `store_id = ${storeId}`
        : `store_id = ${storeId} AND ${filters}`;
      const filtersQuery = {
        hitsPerPage: pageSize,
        filters: sFilters,
        page: page,
      };
      let searchIndex = AlgoliaManager.shared().menuProductsIndex();
      searchIndex.search(query, filtersQuery).then((results: any) => {
        try {
          let productList: ProductList = ProductList.initWithResult(
            results,
            undefined
          );
          // console.log("productList =>", JSON.stringify(productList));
          return resolve(productList);
        } catch (error) {
          return reject(AlgoliaManager.shared().commonAPIError());
        }
      });
    });
  }

  public static async getProductDetails(
    storeId: number = PartnerManager.shared().currentSelectedStore.id,
    productId: number
  ) {
    await AlgoliaManager.shared().validateSearchClient();
    if (!storeId || !productId) {
      return Promise.reject(new ApiError(400, Strings.DEFAULT_ERROR_MSG));
    }
    return new Promise<Product>((resolve, reject) => {
      const sFilters = `store_id:${storeId} AND product_id:${productId}`;
      const filtersQuery = {
        hitsPerPage: 1,
        filters: sFilters,
      };
      let searchIndex = AlgoliaManager.shared().menuProductsIndex();
      searchIndex.search("", filtersQuery).then((results: any) => {
        try {
          let productList: ProductList = ProductList.initWithResult(
            results,
            undefined
          );
          if (productList.products && productList.products.length > 0) {
            const product = productList.products[0];
            // console.log("productDetails =>", JSON.stringify(product));
            return resolve(product);
          }
          return reject(AlgoliaManager.shared().commonAPIError());
        } catch (error) {
          return reject(AlgoliaManager.shared().commonAPIError());
        }
      });
    });
  }

  public static async getProductReviews(productId: number) {
    await AlgoliaManager.shared().validateSearchClient();
    if (!productId) {
      return Promise.reject(new ApiError(400, Strings.DEFAULT_ERROR_MSG));
    }
    return new Promise<ProductReviewList>((resolve, reject) => {
      const sFilters = `product_id:${productId} AND NOT hidden:true`;
      const filtersQuery = {
        hitsPerPage: 3,
        filters: sFilters,
      };
      let searchIndex = AlgoliaManager.shared().productReviewsIndex();
      searchIndex.search("", filtersQuery).then((results: any) => {
        try {
          let reviewList: ProductReviewList = ProductReviewList.initWithResult(
            results,
            undefined
          );
          // console.log("reviewList =>", JSON.stringify(reviewList));
          return resolve(reviewList);
        } catch (error) {
          return reject(AlgoliaManager.shared().commonAPIError());
        }
      });
    });
  }
}
