import { Box, Button, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import StoreReview from "../../../../core/models/StoreReview";
import DateUtils from "../../../../core/utils/DateUtils";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../../core/webservice/ApiError";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import Loader from "../../../common/loader/Loader";
import { alpha } from "@mui/material/styles";

interface ICustomerReviewsComponentState {
  sStoreReviews: StoreReview[];
  sStoreReviewsCount: number;
  sStoreRating: number;
  sIsLoadingReviews: boolean;
  sLoadingError: boolean;
  sLoadingErrorMsg: String;
}

export default class CustomerReviewsComponent extends React.Component<
  any,
  ICustomerReviewsComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      sStoreReviews: [],
      sIsLoadingReviews: true,
      sLoadingError: false,
      sLoadingErrorMsg: "",
      sStoreRating: PartnerManager.shared().currentSelectedStore.rating,
      sStoreReviewsCount:
        PartnerManager.shared().currentSelectedStore.reviewsCount,
    };
  }

  componentDidMount() {
    Promise.all([
      StoreOperationsService.getStoreDetails(
        PartnerManager.shared().currentSelectedStore.id
      ),
      StoreOperationsService.getStoreReviews(
        PartnerManager.shared().currentSelectedStore.id,
        6
      ),
    ])
      .then((_results) => {
        this.setState({
          sStoreReviews: _results[1],
          sStoreReviewsCount: _results[0].reviewsCount,
          sStoreRating: _results[0].rating,
          sIsLoadingReviews: false,
        });
      })
      .catch((apiError: ApiError) => {
        this.setState({
          sLoadingError: true,
          sLoadingErrorMsg: apiError.message,
          sIsLoadingReviews: false,
        });
      });
  }

  render() {
    return (
      <Stack
        sx={{
          position: "relative",
          marginRight: {
            sm: PixelsToViewPortUnitConverter.getvw(200),
            xs: PixelsToViewPortUnitConverter.getvw(130),
          },
          marginLeft: {
            sm: PixelsToViewPortUnitConverter.getvw(200),
            xs: PixelsToViewPortUnitConverter.getvw(130),
          },
          marginBottom: PixelsToViewPortUnitConverter.getvw(149),
          display: "flex",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontFamily: "Bebas",
              fontWeight: 400,
              fontSize: {
                sm: PixelsToViewPortUnitConverter.getFontVw(28),
                xs: PixelsToViewPortUnitConverter.getFontVw(28),
              },
              letterSpacing: "0.1em",
              textAlign: "start",
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            OUR REVIEWS
          </Typography>
          {this.state.sStoreReviews.length > 0 &&
            this.state.sStoreReviewsCount > 6 && (
              <Button
                variant="text"
                sx={{
                  fontFamily: "Barlow",
                  fontWeight: 400,
                  fontStyle: "italic",
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                  letterSpacing: "0.1em",
                  textAlign: "end",
                  marginLeft: "auto",
                  color: (theme) => theme.palette.primary.contrastText,
                  padding: "0px",
                  minHeight: "0px",
                  minWidth: "0px",
                  display: "none",
                }}
              >
                SEE ALL REVIEWS
              </Button>
            )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginTop: PixelsToViewPortUnitConverter.getvw(20) }}
        >
          <Rating
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              fontSize: {
                sm: PixelsToViewPortUnitConverter.getFontVw(50),
                xs: PixelsToViewPortUnitConverter.getFontVw(28),
              },
            }}
            defaultValue={0}
            value={this.state.sStoreRating ? this.state.sStoreRating : 0}
            precision={0.5}
            readOnly
          />
          <Typography
            sx={{
              fontFamily: "Barlow",
              fontWeight: 500,
              fontSize: {
                sm: PixelsToViewPortUnitConverter.getFontVw(24),
                xs: PixelsToViewPortUnitConverter.getFontVw(12),
              },
              letterSpacing: "0.2em",
              alignSelf: "flex-end",
              color: (theme) => theme.palette.primary.contrastText,
              marginLeft: PixelsToViewPortUnitConverter.getvw(40),
            }}
          >
            {this.state.sStoreReviewsCount} reviews
          </Typography>
        </Stack>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            opacity: "0.35",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
            marginTop: PixelsToViewPortUnitConverter.getvw(56),
          }}
        />
        <Box sx={{ position: "relative", minHeight: "100px", width: "100%" }}>
          {this.state.sIsLoadingReviews ? (
            <Loader pshow={true} />
          ) : (
            this.renderReviews()
          )}
        </Box>
      </Stack>
    );
  }

  private renderReviews(): React.ReactNode {
    return (
      <Stack
        direction="row"
        sx={{
          width: "100%",
          marginTop: PixelsToViewPortUnitConverter.getvw(64),
          display: "flex",
          flexWrap: "wrap",
          alignItems: "space-between",
          justifyContent: "space-between",
        }}
      >
        {this.state.sStoreReviews.map((storeReview) => (
          <Stack
            sx={{
              width: {
                sm: "23vw",
                xs: "37vw",
              },
              height: {
                sm: PixelsToViewPortUnitConverter.getvw(175),
                xs: "auto",
              },
              display: "flex",
              alignItems: "center",
            }}
            key={storeReview.id}
          >
            <Stack width="100%" direction="row" alignItems="center">
              <Rating
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                  fontSize: {
                    sm: PixelsToViewPortUnitConverter.getFontVw(36),
                    xs: PixelsToViewPortUnitConverter.getFontVw(14),
                  },
                }}
                defaultValue={storeReview.rating}
                precision={0.5}
                readOnly
              />
              <Typography
                sx={{
                  fontFamily: "Barlow",
                  fontWeight: 500,
                  letterSpacing: "0.2em",
                  fontSize: {
                    sm: PixelsToViewPortUnitConverter.getFontVw(18),
                    xs: PixelsToViewPortUnitConverter.getFontVw(10),
                  },
                  marginLeft: {
                    sm: PixelsToViewPortUnitConverter.getvw(28),
                    xs: PixelsToViewPortUnitConverter.getvw(28),
                  },
                  color: (theme) => theme.palette.primary.contrastText,
                  lineHeight: "unset",
                }}
              >
                {DateUtils.timeStampToDisplayDateFormat(storeReview.reviewDate)}
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 400,
                fontSize: {
                  sm: PixelsToViewPortUnitConverter.getFontVw(16),
                  xs: PixelsToViewPortUnitConverter.getFontVw(13),
                },
                letterSpacing: "0.1em",
                marginRight: "auto",
                color: (theme) =>
                  alpha(theme.palette.primary.contrastText, 0.7),
                overflow: "hidden",
                textOverflow: "unset",
                display: "-webkit-box",
                WebkitLineClamp: "unset",
                WebkitBoxOrient: "vertical",
                marginTop: PixelsToViewPortUnitConverter.getvw(20),
                marginBottom: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(100),
                },
                lineClamp: "3",
                maxLines: "3",
                textAlign: "left",
              }}
            >
              {storeReview.review}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  }
}
