import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import React from "react";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";

export default class BannerComponent extends React.Component<any, any> {
  render() {
    return (
      <Box
        sx={{
          height: {
            sm: "64vh",
            xs: "25vh",
          },
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "664px",
        }}
      >
        <img
          style={{
            height: "100%",
            objectFit: "cover",
          }}
          src={process.env.REACT_APP_CDN_BASE_URL + "effects_v2_banner.png"}
        />
        <Typography
          sx={{
            position: "absolute",
            fontFamily: "Bebas",
            fontWeight: 400,
            letterSpacing: "0.2em",
            fontSize: 
            {sm:PixelsToViewPortUnitConverter.getFontVw(200),
             xs: PixelsToViewPortUnitConverter.getFontVw(50)
            },
            color: "#FFFFFF",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            lineClamp: "1",
            maxLines: "1",
            textIndent: "0.2em",
          }}
        >
          {PartnerManager.shared().partner.appName}
        </Typography>
      </Box>
    );
  }
}
