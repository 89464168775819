import * as React from "react";
import Box from "@mui/material/Box";
import "./ResponsiveLocationSlider.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LocationHeaderMenu from "../header/LocationHeaderMenu";
import PartnerManager from "../../../../core/utils/PartnerManager";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
interface Props {
  setOpenLocationDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openLocationDrawer: boolean;
}
export default function ResponsiveLocationSlider(props: Props): JSX.Element {
  const Puller = styled(Box)(({ theme }) => ({
    width: PixelsToViewPortUnitConverter.getvw(80),
    height: PixelsToViewPortUnitConverter.getvw(2),
    backgroundColor: "#dcdcdc",
    position: "absolute",
    top: PixelsToViewPortUnitConverter.getvw(20),
    left: "50%",
    transform: "translateX(-50%)",
  }));

  const list = () => (
    <>
      <Box
        sx={{
          display: { sm: "none", xs: "block" },
          overflowY: "auto",
          scrollbarWidth: "thin",
          maxHeight: "73vh",
          height: "100%",
          paddingBottom: PixelsToViewPortUnitConverter.getvw(10),
        }}
      >
        <Puller />
        <div>
          <p className="titleCss">Locations</p>
        </div>
        <List>
          <LocationHeaderMenu closeEvent={closeEvent} />
        </List>
      </Box>
      <Button
        sx={{
          background: "black",
          height: PixelsToViewPortUnitConverter.getvw(200),
          lineHeight: PixelsToViewPortUnitConverter.getvw(50),
          fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
          fontFamily: "Bebas",
          fontWeight: "400",
          width: "100%",
          color: "white",
          // boxShadow: "0 -5px 20px  white",
        }}
        onClick={closeEvent}
      >
        DONE
      </Button>
    </>
  );
  const closeEvent = () => {
    props.setOpenLocationDrawer(false);
  };

  const openEvent = () => {
    props.setOpenLocationDrawer(true);
  };
  return (
    <div>
      <React.Fragment>
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              height: "80vh",
              overflow: "visible",
              borderTopLeftRadius: PixelsToViewPortUnitConverter.getvw(100),
              borderTopRightRadius: PixelsToViewPortUnitConverter.getvw(100),
              paddingTop: PixelsToViewPortUnitConverter.getvw(162),
              paddingLeft: PixelsToViewPortUnitConverter.getvw(61),
              paddingRight: PixelsToViewPortUnitConverter.getvw(61),
              paddingBottom: PixelsToViewPortUnitConverter.getvw(101),
              background: `${
                PartnerManager.shared().partner.theme.backgroundColor
              } !important`,
            },
          }}
        />
        <SwipeableDrawer
          anchor={"bottom"}
          open={props.openLocationDrawer}
          onClose={closeEvent}
          onOpen={openEvent}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
