import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CustomButton.scss";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import DimensionUtils from "../../../../core/utils/DimensionUtils";
import { withMediaQuery } from "../../../../withMediaQuery";

type CustomButtonProps = {
  isMobile: boolean;
  title: string;
  variant: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?(): void;
  mt: string;
  mb: string;
};

const CustomButton: React.FC<CustomButtonProps> = (
  props: CustomButtonProps
) => {
  const { loading = false, disabled = false, mt = "0px", mb = "0px" } = props;
  return (
    <Button
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      sx={{
        width: "100%",
        lineHeight: {
          sm: PixelsToViewPortUnitConverter.getvw(51),
          xs: PixelsToViewPortUnitConverter.getFontVw(51),
        },
        height: {
          sm: PixelsToViewPortUnitConverter.getvw(51),
          xs: PixelsToViewPortUnitConverter.getFontVw(51),
        },
        marginBottom: mb,
        marginTop: mt,
        fontFamily: "Bebas",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
        letterSpacing: "0.1em",
        pointerEvents: loading ? "none" : "auto",
        border: "1px solid",
        borderColor: (theme) =>
          props.variant === "v1"
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
        backgroundColor: (theme) =>
          props.variant === "v1"
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        color: (theme) =>
          props.variant === "v1"
            ? theme.palette.secondary.main
            : theme.palette.secondary.contrastText,
        boxShadow: "none",
        borderRadius: "0px",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: (theme) =>
            props.variant === "v1"
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          color: (theme) =>
            props.variant === "v1"
              ? theme.palette.secondary.main
              : theme.palette.secondary.contrastText,
        },
      }}
      className="custom-button"
      variant="contained"
      disabled={disabled}
      fullWidth
    >
      {!loading && props.title}
      {loading && (
        <CircularProgress
          size={
            props.isMobile
              ? DimensionUtils.getVwNumber(80)
              : DimensionUtils.getVwNumber(20)
          }
          sx={{
            color: (theme) =>
              props.variant === "v1"
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
          }}
        />
      )}
      {/* <p className="loadbtn">
                {loading && "Please Wait"}
                {!loading && " Continue"}
                {loading && (
                <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
            </p> */}
    </Button>
  );
};
export default withMediaQuery(CustomButton);
