import React from "react";
import "./ProductListingComponent.scss";
import CategoryWebMenuComponent from "../../themes/effectsv2/menu_products/CategoryWebMenuComponent";
import EffectsWebMenuComponent from "../../themes/effects/menu_products/EffectsWebMenuComponent";
import ProductListHeader from "../../themes/effects/menu_products/ProductListHeader";
import ProductSection from "../../themes/effects/menu_products/ProductSection";
import ProductSectionModel from "../../../core/models/ProductSectionModel";
import Filter from "../../../core/models/Filter";
import FilterAttribute from "../../../core/models/FilterAttribute";
import RangeAttribute from "../../../core/models/RangeAttribute";
import FilterList from "../../../core/models/FilterList";
import AppliedFilter from "../../../core/models/AppliedFilter";
import SortingList from "../../../core/models/SortingList";
import SortingItem from "../../../core/models/SortingItem";
import Product from "../../../core/models/Product";
import MenuService from "../../../services/MenuService";
import FilterUtils from "../../../core/utils/FilterUtils";
import PartnerManager from "../../../core/utils/PartnerManager";
import { ApiError } from "../../../core/webservice/ApiError";
import { PartnerAppThemeType } from "../../../core/models/PartnerTheme";
import { CartProduct } from "../../../core/models/Product";
import { CartActionType } from "../cart/CartActionTypes";
import { connect } from "react-redux";
import { Cart } from "../cart/CartReducer";
import { RootState } from "../../../redux/Reducer";
import withRouter from "../../../withRouter";
import { NavigationProps, NavigationState } from "../../../Navigation.types";
import Navigation from "../../../Navigation";

const queryString = require("query-string");

interface ProductListingComponentProps {
  router: NavigationProps;
  storeId?: number;
  pStoreId: number;
  cart: Cart | undefined;
  addToCartOrUpdate: (product: CartProduct) => void;
}

interface ProductListingComponentState {
  filterList: FilterList;
  sStoreId: number | null;
  queryString: any;
  cart: Cart | undefined;
  isFiltersLoading: boolean;
  filterToggleInMobileMode:boolean;
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cartProducts.cart,
  pStoreId: state.cartProducts.cart.storeId,
});

export class ProductListingComponent extends React.Component<
  ProductListingComponentProps,
  ProductListingComponentState
> {
  constructor(props: ProductListingComponentProps) {
    super(props);
    const parsed = queryString.parse(props.router.location.search);
    // console.log(props)
    // console.log(parsed)
    // console.log(props.router.location.state)

    this.state = {
      sStoreId: props.pStoreId,
      filterList: FilterList.defaultFilterList(parsed),
      queryString: props.router.location.search,
      cart: props.cart,
      isFiltersLoading: true,
      filterToggleInMobileMode:false,
    };
  }

  componentDidMount() {
    const state = this.props.router.location.state as NavigationState;
    if (state && state.scrollToTop) {
      this.onScrollToTopTapped();
    }
    this.getProductFilters();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.pStoreId != prevProps.pStoreId) {
      this.onStoreChange();
    }
    if (prevState.queryString !== this.state.queryString) {
      this.setState({ queryString: this.state.queryString }, () => {
        this.newQueryStringReceived(this.state.queryString);
      });
    }
    if (prevState.cart !== this.state.cart) {
      /*console.log('previousState =>'+JSON.stringify(prevState.cart))
      this.setState({ cart: this.state.cart }, () => {
        this.newCartUpdatesReceived(this.state.cart);
      });*/
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.router.location.search !== prevState.queryString) {
      return { queryString: nextProps.router.location.search };
    }
    if (nextProps.cart !== prevState.cart) {
      return { cart: nextProps.cart };
    }
    return null;
  }

  public onSetFilterToggleInMobileMode = () => {
    this.setState({ filterToggleInMobileMode: !this.state.filterToggleInMobileMode });
  };



  private onStoreChange = () => {
    this.setState({ queryString: this.state.queryString }, () => {
      this.newQueryStringReceived(this.state.queryString);
    });
  };

  private newCartUpdatesReceived = (cart: Cart | undefined) => {
    console.log("newCartUpdatesReceived =>" + JSON.stringify(cart));
  };

  private onScrollToTopTapped = () => {
    window.scrollTo(0, 1);
  };

  private navigateToFilterChange(
    filter: Filter,
    filterAttribute: FilterAttribute,
    rangeAttribute: RangeAttribute,
    selected: boolean,
    toBeRemovedAppdFilter: AppliedFilter
  ) {
    let query = FilterUtils.createFiltersQueryString(
      this.state.queryString,
      filter,
      filterAttribute,
      rangeAttribute,
      selected,
      undefined,
      toBeRemovedAppdFilter
    );
    Navigation.toShopAllWithQuery(this.props.router, query);
  }

  private newQueryStringReceived(qString: string | undefined | null) {
    // console.log("newQueryStringReceived :" + this.state.queryString);
    const parsed = queryString.parse(this.state.queryString);
    // console.log(parsed);
    const appliedFilterList = AppliedFilter.list(parsed);
    const appliedSortingList = SortingList.list(parsed);
    // console.log(
    //   "newQueryStringReceived appliedFilterList :" +
    //     JSON.stringify(appliedFilterList)
    // );
    // console.log(
    //   "newQueryStringReceived appliedSortingList :" +
    //     JSON.stringify(appliedSortingList)
    // );

    const tempFilterList = this.state.filterList;
    tempFilterList.setSortingList(appliedSortingList);
    tempFilterList.setTempAppliedFilterList(appliedFilterList);

    this.setState({ filterList: tempFilterList }, () => {
      this.getProductFilters();
    });
  }

  private onProductClicked = (product: Product) => {
    Navigation.toProductDetails(this.props.router, product);
  };

  private onAddOrRemoveProductFromCart = (product: CartProduct) => {
    this.props.addToCartOrUpdate(product);
  };

  private onResetClicked = () => {
    Navigation.toShopAllWithQuery(this.props.router, null);
  };

  private onSelectFilterAttribute = (
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ) => {
    this.updateFilterAttributeObject(filter, filterAttribute, selected);
    this.navigateToFilterChange(
      filter,
      filterAttribute,
      undefined,
      selected,
      undefined
    );
  };

  private onSelectRangeAttribute = (
    filter: Filter,
    rangeAttribute: RangeAttribute
  ) => {
    filter.setRangeAttribute(rangeAttribute);
    this.updateFilterObject(filter);
    this.navigateToFilterChange(
      filter,
      undefined,
      rangeAttribute,
      true,
      undefined
    );
  };

  private onRemoveAppliedFilter = (appliedFilter: AppliedFilter) => {
    const filterTobeRemoved = appliedFilter;
    const currentFilters = this.state.filterList.filters;
    const currentAppliedFilters = this.state.filterList
      ? this.state.filterList.appliedFiltersList
      : undefined;
    let result = FilterUtils.removeAppliedFilter(
      currentFilters,
      currentAppliedFilters,
      appliedFilter
    );
    this.removeAppliedFilterObject(appliedFilter);
    if (result.filter) {
      this.updateFilterObject(result.filter);
      this.navigateToFilterChange(
        result.filter,
        result.filterAttribute,
        result.filter.rangeAttribute,
        false,
        filterTobeRemoved
      );
    } else {
      this.navigateToFilterChange(
        undefined,
        undefined,
        undefined,
        false,
        filterTobeRemoved
      );
    }
  };

  private onSelectSortingOption = (sortingItem: SortingItem) => {
    let query = FilterUtils.createFiltersQueryString(
      this.state.queryString,
      undefined,
      undefined,
      undefined,
      false,
      sortingItem,
      undefined
    );
    Navigation.toShopAllWithQuery(this.props.router, query);
  };

  // API : Get product filters
  private getProductFilters() {
    // console.log('call getProductFilters :')
    // console.log('pre filterList :'+JSON.stringify(this.state.filterList))
    let storeId = undefined;
    if (PartnerManager.shared().currentSelectedStore) {
      storeId = PartnerManager.shared().currentSelectedStore.id;
    }
    if (!storeId) return;
    this.setState({ isFiltersLoading: true });
    MenuService.getMenuFilters(storeId, this.state.filterList)
      .then((filterList: FilterList) => {
        // console.log('filterList :'+JSON.stringify(filterList))
        this.setState({
          filterList: filterList,
          isFiltersLoading: false,
        });
      })
      .catch((error: ApiError) => {
        // console.log('error code:'+error.errorCode)
        // console.log('error message:'+error.message)
        this.setState({ isFiltersLoading: false });
      });
  }

  private onClickFilterHeader = (filter: Filter) => {
    filter.selected(!filter.isSelected);
    this.updateFilterObject(filter);
  };

  private onSeeAllTapped = (productSectionModel: ProductSectionModel) => {
    /*const result = FilterUtils.findCategoryAttribute(
      this.state.filterList.filters,
      productSectionModel.slug
    );*/
    const result = FilterUtils.findFilterByProductSectionModel(
      this.state.filterList.filters,
      productSectionModel
    );
    if (result.filter && result.filterAttribute) {
      this.onSelectFilterAttribute(result.filter, result.filterAttribute, true);
    }
  };

  private updateFilterAttributeObject(
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ) {
    let updatedFilter = FilterUtils.onSelectOrDeselectFilterAttribute1(
      filter,
      filterAttribute,
      selected
    );
    this.updateFilterObject(updatedFilter);
  }

  private updateFilterObject(filter: Filter) {
    let fList = this.state.filterList;
    let filters = [...fList.filters];
    if (filter.index !== -1) {
      filters[filter.index] = filter;
      this.setState({ filterList: fList }, () => {});
    }
  }

  private removeAppliedFilterObject(appliedFilter: AppliedFilter) {
    if (this.state.filterList) {
      let aFilters = [...this.state.filterList.appliedFiltersList];
      const indexTobeDeleted = aFilters.indexOf(appliedFilter);
      if (indexTobeDeleted !== -1) {
        aFilters.splice(indexTobeDeleted, 1);
        this.state.filterList.setAppliedFilters(aFilters);
      }
    }
  }

  private renderProductSection(productSectionModel: ProductSectionModel) {
    return (
      <ProductSection
        key={productSectionModel.name}
        productSectionModel={productSectionModel}
        filterList={this.state.filterList}
        pOnSeeAllTapped={this.onSeeAllTapped}
        pOnProductClicked={this.onProductClicked}
        pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
      />
    );
  }

  private renderAllProductsInfo() {
    return (
      <ProductListHeader
        filterList={this.state.filterList}
        appliedFilterList={
          this.state.filterList && this.state.filterList.appliedFiltersList
        }
        onRemoveAppliedFilter={this.onRemoveAppliedFilter}
        onSelectSortingOption={this.onSelectSortingOption}
      />
    );
  }

  private renderSections() {
    if (this.state.filterList.filters.length == 0) return null;
    if (this.state.filterList.productsCount <= 0) return null;
    const filterList = [...this.state.filterList.filters];
    const productSectionsList = FilterUtils.getProductSections(filterList);
    return productSectionsList.map((pSectionModel: ProductSectionModel) =>
      this.renderProductSection(pSectionModel)
    );
  }

  private loadThemeBasedContainerComponent() {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.EFFECTS:
      case PartnerAppThemeType.EFFECTS2:
        return this.renderEffectThemeContainer();
      case PartnerAppThemeType.CATEGORIES:
      case PartnerAppThemeType.EFFECTS1:
        return this.renderEffectCategoryContainer();
      default:
        break;
    }
    return null;
  }

  private renderEffectThemeContainer() {
    return (
      <div className="product-results-container">
        <EffectsWebMenuComponent
          router={this.props.router}
          pFilters={this.state.filterList.filters}
          filterList={this.state.filterList}
          appliedFilterList={
            this.state.filterList && this.state.filterList.appliedFiltersList
          }
          pOnFilterHeaderTapped={this.onClickFilterHeader}
          pOnSelectFilterAttribute={this.onSelectFilterAttribute}
          pOnSelectRangeAttribute={this.onSelectRangeAttribute}
          onRemoveAppliedFilter={this.onRemoveAppliedFilter}
          onSelectSortingOption={this.onSelectSortingOption}
          pOnSeeAllTapped={this.onSeeAllTapped}
          pOnProductClicked={this.onProductClicked}
          pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
        />
      </div>
    );
  }

  private renderEffectCategoryContainer() {
    return (
      <div className="product-results-container">
        <CategoryWebMenuComponent
          router={this.props.router}
          pFilters={this.state.filterList.filters}
          filterList={this.state.filterList}
          appliedFilterList={
            this.state.filterList && this.state.filterList.appliedFiltersList
          }
          pCart={this.state.cart}
          pIsFiltersLoading={this.state.isFiltersLoading}
          pOnFilterHeaderTapped={this.onClickFilterHeader}
          pOnSelectFilterAttribute={this.onSelectFilterAttribute}
          pOnSelectRangeAttribute={this.onSelectRangeAttribute}
          onRemoveAppliedFilter={this.onRemoveAppliedFilter}
          onSelectSortingOption={this.onSelectSortingOption}
          pOnSeeAllTapped={this.onSeeAllTapped}
          pOnProductClicked={this.onProductClicked}
          pOnAddOrRemoveProductFromCart={this.onAddOrRemoveProductFromCart}
          pOnResetClicked={this.onResetClicked}

          onSetFilterToggleInMobileMode={this.onSetFilterToggleInMobileMode}
          filterToggleInMobileMode={this.state.filterToggleInMobileMode}
        />
      </div>
    );
  }

  render() {
    return this.loadThemeBasedContainerComponent();
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  addToCartOrUpdate: (product: CartProduct) =>
    dispatch({
      type: CartActionType.ADD_TO_CART_OR_UPDATE,
      payload: product,
    }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductListingComponent)
);
