import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import PixelsToViewPortUnitConverter from "../../core/utils/PixelsToViewPortUnitConverter";

export default function ExpandIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        d="M1 0.999998L9 5L17 0.999999"
        stroke="currentColor"
        strokeWidth={PixelsToViewPortUnitConverter.getvw(2)}
      />
    </SvgIcon>
  );
}
