import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import PartnerManager from "../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { useAppSelector, useAppDispatch } from "../../../redux/Hooks";
import { useNavigate } from "react-router-dom";
import { CartActionType } from "./CartActionTypes";
import AmplifyManager from "../../../core/utils/AmplifyManager";
import UserAuthService from "../../../services/UserAuthService";
import OrderConfirmation from "./OrderConfirmation";
import { SeoDisallowPageConfig } from "../../../seo/SeoDisallowPageConfig";

const CartComponent = () => {
  const localCart = useAppSelector((state) => state.cartProducts);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showCheckoutSuccess, setShowCheckoutSuccess] = useState(false);
  const [cartUUID, setCartUUID] = useState(undefined);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  function getData() {
    return {
      messageType: "buildCart",
      payload: {
        ...localCart.cart,

        cognitoToken: AmplifyManager.shared().jtwAccessToken,
        headlessPartnerName: "Warpspd",
        headlessCheckoutPartnerId:
          process.env.REACT_APP_HEADLESS_CHECKOUT_PARTNER_ID,
        options: {
          theme: {
            themeColor: PartnerManager.shared().partner.theme.buttonColor,
            navigationColor:
              PartnerManager.shared().partner.theme.backgroundColor,
            ctaTextColor: PartnerManager.shared().partner.theme.buttonTextColor,
            backgroundColor:
              PartnerManager.shared().partner.theme.backgroundColor,
            font: {
              fontFamily: "Gilroy",
              url: "//db.onlinewebfonts.com/t/1dc8ecd8056a5ea7aa7de1db42b5b639.woff2",
              color: PartnerManager.shared().partner.theme.pageHighlightColor,
            },
            calloutBackground:
              PartnerManager.shared().partner.theme.buttonColor,
            calloutTextColor:
              PartnerManager.shared().partner.theme.buttonTextColor,
            buttonBorderRadius: "24px",
          },
          redirectUrl: window.location.origin,
          disableWeightSelection: false, // setting this to true will disable changing weight selections
          disableLoadingSpinner: false,
          disableAuthFeatures: true, //setting this to false will allow users to sign in but only in the checkout iFrame
          disablePrepayment: false,
          kiosk: false, // set to true to enable kiosk mode
        },
      },
    };
  }

  useEffect(() => {
    if (isIframeLoaded) {
      const frame = document.getElementById("jane-menu") as HTMLIFrameElement;
      frame.contentWindow.postMessage(getData(), "*");
    }
  }, [isIframeLoaded]);

  useEffect(() => {
    function handleCartMsg(event) {
      var payload = event.data && event.data.payload;
      var messageType = event.data && event.data.messageType;

      if (messageType === "loadingEvent") {
        if (payload.name === "headlessAppLoaded") {
          UserAuthService.currentSession()
            .then((data) => {
              AmplifyManager.shared().setJwtAccessTokenFromCurrentUserSession(
                data
              );
              setIsIframeLoaded(true);
            })
            .catch((err) => {
              //TODO logo out user
              setIsIframeLoaded(true);
            });
        } else if (payload.name == "cartCreateOrUpdate") {
          dispatch({
            type: CartActionType.CART_CREATE_OR_UPDATE,
            payload: {
              storeId: localCart.cart.storeId,
              products: payload.properties.products,
            },
          });
        }
      }

      if (payload.name == "checkout") {
        if (payload && payload.properties && payload.properties.uuid)
          setCartUUID(payload.properties.uuid);

        setShowCheckoutSuccess(true);
        navigate(-3);
      }
    }

    window.addEventListener("message", handleCartMsg, false);

    if (document.getElementById("jane-frame-script") == null) {
      const script = document.createElement("script");
      script.id = "jane-frame-script";
      const body = document.getElementById("cart_wrapper");
      script.src = process.env.REACT_APP_HEADLESS_CHECKOUT_URL;
      body.appendChild(script);
    }
    return () => window.removeEventListener("message", handleCartMsg, false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showCheckoutSuccess]);

  return !showCheckoutSuccess ? (
    <Box
      id="cart_wrapper"
      sx={{
        display: "flex",
        width: "100%",
        marginTop: {
          sm: PixelsToViewPortUnitConverter.getvw(116),
          xs: PixelsToViewPortUnitConverter.getvw(220),
        },
      }}
    >
      <SeoDisallowPageConfig pTitle="Cart" />
    </Box>
  ) : (
    cartUUID && <OrderConfirmation pCartUUID={cartUUID} />
  );
};

export default CartComponent;
