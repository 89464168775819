import { ApiError } from "./ApiError";
import Strings from '../utils/Strings';
import TextUtils from '../utils/TextUtils';
import Partner from '../models/Partner';

const algoliasearch = require('algoliasearch/lite');

class AlgoliaManager {

    private static sAlgoliaManager: AlgoliaManager|undefined|null;
    private searchClient: any|undefined|null;
    private indexIdentifier = process.env.REACT_APP_ALGOLIA_INDEX_ID;
    private mAppId: string | undefined | null;
    private mApiKey: string | undefined | null;

    public static shared(): AlgoliaManager {
        if (!AlgoliaManager.sAlgoliaManager) {
            AlgoliaManager.sAlgoliaManager = new AlgoliaManager();
        }
        return AlgoliaManager.sAlgoliaManager;
    }

    private constructor() {}

    public initSearchClient(partner: Partner) {
        if(partner) {
            this.configureClient(partner.algoliaAppId,partner.algoliaAppKey)
        } else {
            this.resetSearchClient()
        }
    }

    public configureClient(appId: string, apiKey: string) {
        let shouldConfigure = true
        if(!TextUtils.isEmpty(this.mAppId) && 
            !TextUtils.isEmpty(this.mApiKey) &&
            !TextUtils.isEmpty(appId) && 
            !TextUtils.isEmpty(apiKey) &&
            this.mAppId == appId &&
            this.mApiKey == apiKey) {
            shouldConfigure = false
        }
        if(shouldConfigure && !TextUtils.isEmpty(appId) && !TextUtils.isEmpty(apiKey)) {
            this.mAppId = appId
            this.mApiKey = apiKey
            this.searchClient = algoliasearch(appId,apiKey);
        }
    }

    public resetSearchClient() {
        this.mAppId = undefined
        this.mApiKey = undefined
        this.searchClient = undefined
        AlgoliaManager.sAlgoliaManager = undefined
    }

    private initIndex(sClient: any, indexName: string) {
        const productsIndex = sClient.initIndex(indexName);
        return productsIndex;
    }

    public storesIndex() {
        const indexName = `stores-${this.indexIdentifier}`
        let sIndex = this.initIndex(this.searchClient,indexName)
        return sIndex;
    }

    public menuProductsIndex() {
        const indexName = `menu-products-${this.indexIdentifier}`
        let sIndex = this.initIndex(this.searchClient,indexName)
        return sIndex;
    }

    public productReviewsIndex() {
        const indexName = `product-reviews-${this.indexIdentifier}`
        let sIndex = this.initIndex(this.searchClient,indexName)
        return sIndex;
    }

    public productReviewsByUpvoteIndex() {
        const indexName = `product-reviews-by-upvote-${this.indexIdentifier}`
        let sIndex = this.initIndex(this.searchClient,indexName)
        return sIndex;
    }

    public searchIndexObject(indexName: string) {
        let sIndex = this.initIndex(this.searchClient,indexName)
        return sIndex;
    }

    public storesIndexName() {
        const indexName = `stores-${this.indexIdentifier}`
        return indexName
    }

    public menuProductsIndexName() {
        const indexName = `menu-products-${this.indexIdentifier}`
        return indexName
    }

    public productReviewsIndexName() {
        const indexName = `product-reviews-${this.indexIdentifier}`
        return indexName
    }

    public productReviewsByUpvoteIndexName() {
        const indexName = `product-reviews-by-upvote-${this.indexIdentifier}`
        return indexName
    }

    public menuProductsByPriceLowToHighIndexName() {
        const indexName = `menu-products-by-price-${this.indexIdentifier}`
        return indexName
    }

    public menuProductsByPriceHighToLowIndexName() {
        const indexName = `menu-products-by-price-desc-${this.indexIdentifier}`
        return indexName
    }

    public menuProductsByTHCLowToHighIndexName() {
        const indexName = `menu-products-by-thc-potency-asc-${this.indexIdentifier}`
        return indexName
    }

    public menuProductsByTHCHighToLowIndexName() {
        const indexName = `menu-products-by-thc-potency-desc-${this.indexIdentifier}`
        return indexName
    }

    public menuProductsByRatingIndexName() {
        const indexName = `menu-products-by-rating-${this.indexIdentifier}`
        return indexName
    }

    public commonAPIError() {
        return new ApiError(500, Strings.DEFAULT_ERROR_MSG);
    }

    public isSearchClientConfigured(): boolean {
        if(!TextUtils.isEmpty(this.mAppId) && 
        !TextUtils.isEmpty(this.mApiKey)) {
            return true
        }
        return false
    }

    public async validateSearchClient() {
        try{
          if(!this.isSearchClientConfigured()) {
            return Promise.reject(
              new ApiError(400, Strings.ALGOLIA_NOT_CONFIGURED_ERROR)
            );
          }
        }catch(e) {
    
        }
    }

    public getSearchClient(){
        return this.searchClient
    }

}

export default AlgoliaManager;