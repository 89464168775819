import Category from "./Category";
import Effect from "./Effect";
import PartnerTheme from "./PartnerTheme";
import TextUtils from "../utils/TextUtils";
import PartnerUserPool from "./PartnerUserPool";

export enum PartnerState {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
}

export enum PartnerAppState {
  LIVE = "LIVE",
  SETUP_COMPLETED = "SETUP_COMPLETED",
  SETUP_INCOMPETE = "SETUP_INCOMPETE",
}

export type PartnerSocialMedia = {
  instagramUrl: string | null;
  fbUrl: string | null;
  youTubUrl: string | null;
  twitterUrl: string | null;
  snapchatUrl: string | null;
  pinterestUrl: string | null;
};

export default class Partner {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mClientId: string;
  public get clientId() {
    return this.mClientId;
  }

  private mClientSecret: string;
  public get clientSecret() {
    return this.mClientSecret;
  }

  private mDomainName: string;
  public get domainName() {
    return this.mDomainName;
  }

  private mAlgoliaAppId: string;
  public get algoliaAppId() {
    return this.mAlgoliaAppId;
  }

  private mAlgoliaAppKey: string;
  public get algoliaAppKey() {
    return this.mAlgoliaAppKey;
  }

  private mInstagramUrl: string;
  public get instagramUrl() {
    return this.mInstagramUrl;
  }

  private mFacebookUrl: string;
  public get facebookUrl() {
    return this.mFacebookUrl;
  }

  private mYoutubeUrl: string;
  public get youtubeUrl() {
    return this.mYoutubeUrl;
  }

  private mTwitterUrl: string;
  public get twitterUrl() {
    return this.mTwitterUrl;
  }

  private mSnapchatUrl: string;
  public get snapchatUrl() {
    return this.mSnapchatUrl;
  }

  private mPinterestUrl: string;
  public get pinterestUrl() {
    return this.mPinterestUrl;
  }

  private mPartnerState: PartnerState;
  public get partnerState() {
    return this.mPartnerState;
  }

  private mPartnerAppState: PartnerAppState;
  public get partnerAppState() {
    return this.mPartnerAppState;
  }

  private mPartnerTheme: PartnerTheme;
  public get theme() {
    return this.mPartnerTheme;
  }

  private mPartnerCategories: Category[];
  public get categories() {
    return this.mPartnerCategories;
  }

  private mPartnerEffects: Effect[];
  public get effects() {
    return this.mPartnerEffects;
  }

  private mAppName: string;
  public get appName() {
    return this.mAppName;
  }

  private mUserPool: PartnerUserPool | undefined;
    public get userPool() {
      return this.mUserPool;
  }

  public constructor(responseJson: any) {
    this.mId = responseJson.id;
    this.mClientId = responseJson.clientId;
    this.mClientSecret = responseJson.clientSecret;
    this.mDomainName = responseJson.domainName;
    this.mAlgoliaAppId = responseJson.algoliaAppId;
    this.mAlgoliaAppKey = responseJson.algoliaAppKey;
    this.mInstagramUrl = responseJson.instagramUrl;
    this.mFacebookUrl = responseJson.fbUrl;
    this.mYoutubeUrl = responseJson.youTubUrl;
    this.mTwitterUrl = responseJson.twitterUrl;
    this.mSnapchatUrl = responseJson.snapchatUrl;
    this.mPinterestUrl = responseJson.pinterestUrl;
    this.mPartnerState = responseJson.state;
    this.mPartnerTheme = responseJson.theme
      ? new PartnerTheme(responseJson.theme)
      : null;
    this.mPartnerCategories = [];
    this.mPartnerEffects = [];
    if (responseJson.categories && Array.isArray(responseJson.categories)) {
      this.mPartnerCategories = Category.partnerCategoryList(
        responseJson.categories
      );
    }
    if (responseJson.effects && Array.isArray(responseJson.effects)) {
      this.mPartnerEffects = Effect.partnerEffectList(responseJson.effects);
    }

    this.mUserPool = new PartnerUserPool(undefined)
    if(responseJson.userPoolInfo) {
      this.mUserPool = new PartnerUserPool(responseJson.userPoolInfo)
    }

    let pAppState = PartnerAppState.SETUP_INCOMPETE;
    if (
      TextUtils.isEmpty(this.mClientId) ||
      TextUtils.isEmpty(this.mClientSecret) ||
      TextUtils.isEmpty(this.mDomainName) ||
      !this.mPartnerTheme ||
      !this.mPartnerTheme.isThemeConfigured() ||
      this.mPartnerCategories.length == 0 ||
      this.mPartnerEffects.length == 0
    ) {
      pAppState = PartnerAppState.SETUP_INCOMPETE;
    } else if (this.mPartnerState === PartnerState.DRAFT) {
      pAppState = PartnerAppState.SETUP_COMPLETED;
    } else {
      pAppState = PartnerAppState.LIVE;
    }
    this.mPartnerAppState = pAppState;

    this.mAppName = responseJson.appSettings
      ? responseJson.appSettings.appName
      : "";
  }
  
  public isUserPoolDetailsEmpty() {
    if(TextUtils.isEmpty(this.mUserPool.region) || 
      TextUtils.isEmpty(this.mUserPool.userPoolId) ||
      TextUtils.isEmpty(this.mUserPool.appClientId)) {
        return true
      }
      return false
  }
}
