import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export default function RoundPlus(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 23 24"
    return (
        <SvgIcon {...uProps} fill="none">
            <path d="M15.8204 11.1834V12.7021H7.1098V11.1834H15.8204ZM12.2768 7.474V16.7257H10.6621V7.474H12.2768Z" fill="#A2A2A2"/>
            <circle cx="11.5" cy="12.0781" r="11" stroke="#A1A1A1"/>
        </SvgIcon>
    );
}
