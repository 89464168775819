import React from "react";
import "./FilterHeader.scss";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Filter from "../../../../core/models/Filter";
import CollapseIconV2 from "../../../../statics/svgs/CollapseIconV2";
import ExpandIconV2 from "../../../../statics/svgs/ExpandIconV2";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { alpha } from "@mui/material";

type FilterHeaderProps = {
  filter: Filter;
  onFilterHeaderTapped(filter: Filter): void;
};

export const FilterHeader: React.FC<FilterHeaderProps> = (
  props: FilterHeaderProps
) => {
  const expandMoreIcon = (filter: Filter) => {
    if (filter.isSelected) {
      return (
        <CollapseIconV2
          className="expandMoreIcon"
          viewBox="0 0 23 23"
          sx={{
            width: PixelsToViewPortUnitConverter.getvw(23),
            height: PixelsToViewPortUnitConverter.getvw(23),
            // stroke: (theme) => theme.palette.secondary.main,
            // fill: (theme) => theme.palette.secondary.main,
            fill: "none",
            backgroundColor: (theme) =>
              alpha(theme.palette.primary.contrastText, 0.04),
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "transparent",
            ":hover": {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: (theme) => theme.palette.primary.contrastText,
            },
          }}
        />
      );
    }
    return (
      <ExpandIconV2
        className="expandMoreIcon"
        viewBox="0 0 23 23"
        sx={{
          width: PixelsToViewPortUnitConverter.getvw(23),
          height: PixelsToViewPortUnitConverter.getvw(23),
          // stroke: (theme) => theme.palette.secondary.main,
          // fill: (theme) => theme.palette.secondary.main,
          fill: "none",
          backgroundColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.04),
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "transparent",
          ":hover": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: (theme) => theme.palette.primary.contrastText,
          },
        }}
      />
    );
  };

  const onClickFilterHeader = (filter: Filter) => {
    props.onFilterHeaderTapped(filter);
  };

  return (
    <AccordionSummary
      expandIcon={expandMoreIcon(props.filter)}
      onClick={() => {
        onClickFilterHeader(props.filter);
      }}
      className="cmenu-product-filter-header"
      sx={{
        "&.MuiAccordionSummary-root": {
          minHeight: 0,
          padding: "0px",
        },
        "& .MuiAccordionSummary-content": {
          marginTop: PixelsToViewPortUnitConverter.getvw(3),
          marginBottom: PixelsToViewPortUnitConverter.getvw(3),
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          marginTop: PixelsToViewPortUnitConverter.getvw(3),
          marginBottom: PixelsToViewPortUnitConverter.getvw(3),
        },
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
          fontSize: {
            sm: `${PixelsToViewPortUnitConverter.getFontVw(19)} !important`,
            xs: `${PixelsToViewPortUnitConverter.getFontVw(28)} !important`,
          },
          marginBottom: {
            sm: PixelsToViewPortUnitConverter.getFontVw(0),
            xs: PixelsToViewPortUnitConverter.getFontVw(15),
          },
        }}
        className="product-filter-header-title"
      >
        {props.filter.name}
      </Typography>
    </AccordionSummary>
  );
};
