import React from "react";
import "./RoundedButton.scss";
import Button from '@mui/material/Button';
import { alpha } from "@mui/system";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';

type RoundedButtonProps = {
    title: string;
    onClick(): void;
};

export const RoundedButton: React.FC<RoundedButtonProps> = (props: RoundedButtonProps) => {
    return(
        <Button
            sx={{
                height: PixelsToViewPortUnitConverter.getvw(38),
                paddingTop: PixelsToViewPortUnitConverter.getvw(0),
                paddingBottom: PixelsToViewPortUnitConverter.getvw(0),
                paddingRight: PixelsToViewPortUnitConverter.getvw(0),
                paddingLeft: PixelsToViewPortUnitConverter.getvw(0),
                border: 'none',
                borderRadius: 0,
                textTransform: 'uppercase',
                fontFamily: 'Barlow',
                fontStyle: 'italic',
                fontWeight: 400,
                fontSize:{
                    sm: PixelsToViewPortUnitConverter.getFontVw(16),
                    xs:PixelsToViewPortUnitConverter.getFontVw(13),
                },
                
                lineHeight: PixelsToViewPortUnitConverter.getFontVw(19),
                color: (theme) => alpha(theme.palette.primary.contrastText,1.0)
            }}
            variant="text" 
            onClick={props.onClick}>
                {props.title}
        </Button>       
    )
}





