import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export default function PreviousIconV2(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 39 39"
    return (
        <SvgIcon {...uProps} fill="none">
            <circle cx="19.5" cy="19.5" r="19"/>
            <path d="M23 10L15 19L23 28"/>
        </SvgIcon>
    );
}

{/* <circle cx="19.5" cy="19.5" r="19" fill="#3A2D25" stroke="black"/>
            <path d="M23 10L15 19L23 28" stroke="white"/> */}