import React from "react";
import "./RoundedButton.scss";
import Button from '@mui/material/Button';
import { alpha } from "@mui/system";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';

type RoundedButtonProps = {
    title: string;
    onClick(): void;
};

export const RoundedButton: React.FC<RoundedButtonProps> = (props: RoundedButtonProps) => {
    return(
        <Button
            sx={{
                border: '1px solid',
                borderColor: (theme) => theme.palette.secondary.main,
                borderRadius: PixelsToViewPortUnitConverter.getFontVw(20),
                textTransform: 'none',
                fontFamily: 'Overpass',
                fontStyle: 'normal',
                fontWeight: 700,
                height: PixelsToViewPortUnitConverter.getvw(56),
                paddingTop: PixelsToViewPortUnitConverter.getvw(10),
                paddingBottom: PixelsToViewPortUnitConverter.getvw(10),
                paddingRight: PixelsToViewPortUnitConverter.getvw(30),
                paddingLeft: PixelsToViewPortUnitConverter.getvw(30),
                fontSize: PixelsToViewPortUnitConverter.getFontVw(23),
                lineHeight: PixelsToViewPortUnitConverter.getFontVw(35),
                color: (theme) => alpha(theme.palette.primary.main,0.5)
            }}
            variant="outlined" 
            onClick={props.onClick}>
                {props.title}
        </Button>       
    )
}





