import SvgIcon from "@mui/material/SvgIcon";
export default function RightNavIcon(props) {
  return (
    <SvgIcon {...props}>
      <ellipse
        xmlns="http://www.w3.org/2000/svg"
        cx="45.9505"
        cy="45"
        rx="45.0495"
        ry="45"
        transform="rotate(-180 45.9505 45)"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M39.9436 59L53.959 45L39.9436 31"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
