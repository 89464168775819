import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import * as React from "react";
import Effect from "../../../../core/models/Effect";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import withRouter from "../../../../withRouter";
import Loader from "../../../common/loader/Loader";
import Navigation from '../../../../Navigation';

interface IEffectsComponentState {
  sShowLoader: boolean;
  sExpandFullList: boolean;
  sCurHoveredListItem: number | undefined;
}

const EffectNameTypo = styled(Typography)(({ theme }) => ({
  position: "relative",

  borderBottomRightRadius: "14px",
  color: theme.palette.primary.contrastText,
  fontFamily: "Beloved",
  fontWeight: 400,
  lineHeight: 1,

  fontSize: PixelsToViewPortUnitConverter.getFontVw(64),
  paddingRight: PixelsToViewPortUnitConverter.getvw(40),
  paddingLeft: PixelsToViewPortUnitConverter.getvw(40),
  textAlign: "center",
  height: "75px",
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    paddingRight: "0px",
    paddingLeft: "0px",
    width: "100%",
  },
}));

class EffectsComponent extends React.Component<any, IEffectsComponentState> {
  INITIAL_VISIBLE_LIST_ITEMS_THRESHOLD = 9;

  constructor(props: any) {
    super(props);
    this.state = {
      sShowLoader:
        PartnerManager.shared().effects == undefined ||
        PartnerManager.shared().effects.length == 0,
      sExpandFullList:
        PartnerManager.shared().effects == undefined
          ? false
          : PartnerManager.shared().effects.length <=
            this.INITIAL_VISIBLE_LIST_ITEMS_THRESHOLD,
      sCurHoveredListItem: undefined,
    };
  }

  componentDidMount() {
    if (
      PartnerManager.shared().effects == undefined ||
      PartnerManager.shared().effects.length == 0
    ) {
      StoreOperationsService.getEffects(
        PartnerManager.shared().currentSelectedStore.id
      ).then((_) => {
        this.setState({
          sShowLoader: false,
          sExpandFullList:
            PartnerManager.shared().effects == undefined
              ? false
              : PartnerManager.shared().effects.length <=
                this.INITIAL_VISIBLE_LIST_ITEMS_THRESHOLD,
        });
      });
    }
  }

  render() {
    const rowHeight = (window.innerWidth * 0.6) / 6 - 8;
    const bannerHeight = rowHeight * 2 * 0.2;
    return (
      <Stack
        sx={{
          position: "relative",
          marginTop: PixelsToViewPortUnitConverter.getvw(118),
          minHeight: "180px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Overpass",
            fontWeight: 700,
            fontSize: PixelsToViewPortUnitConverter.getFontVw(50),
            marginBottom: PixelsToViewPortUnitConverter.getvw(70),
            textAlign: "center",
          }}
        >
          How would you like to feel today?
        </Typography>
        {this.state.sShowLoader ? (
          <Loader pshow={true} />
        ) : (
          <ImageList
            variant="quilted"
            cols={3}
            gap={10}
            rowHeight={rowHeight}
            sx={{ width: "60%", marginX: "auto", overflowY: "hidden" }}
          >
            {(this.state.sExpandFullList
              ? PartnerManager.shared().effects
              : PartnerManager.shared().effects.slice(
                  0,
                  this.INITIAL_VISIBLE_LIST_ITEMS_THRESHOLD
                )
            ).map((item, index) => {
              return this.state.sCurHoveredListItem == index
                ? this.hoveredListItem(index, item, bannerHeight)
                : this.normalListItem(index, item);
            })}
          </ImageList>
        )}
        {!this.state.sShowLoader && !this.state.sExpandFullList && (
          <Button
            sx={{ marginX: "auto", marginTop: "10px" }}
            variant="contained"
            fullWidth={false}
            onClick={this.handleFullExpandEffectList}
          >
            Shop All Effects
          </Button>
        )}
      </Stack>
    );
  }

  private hoveredListItem(index: number, item: Effect, bannerHeight: number) {
    return (
      <ImageListItem
        key={item.name}
        cols={1 || 1}
        rows={(index % 4 == 1 ? 3 : 2) || 1}
        onMouseLeave={() => {
          this.setState({ sCurHoveredListItem: undefined });
        }}
        onClick={() => {
          Navigation.toShopAllByEffect(this.props.router, item)
        }}
        sx={{ transition: "all 2s" }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "14px",
          }}
        >
          <img
            style={{
              width: "100%",
              height: `calc(100% - ${bannerHeight}px)`,
              objectFit: "cover",
              borderTopLeftRadius: "14px",
              borderTopRightRadius: "14px",
            }}
            src={item.imageUrl}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: "14px",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), rgba(217, 217, 217, 0.3)",
            }}
          />

          <Typography
            sx={{
              height: `${bannerHeight}px`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderBottomLeftRadius: "14px",
              borderBottomRightRadius: "14px",
              color: "primary.contrastText",
              fontFamily: "Beloved",
              fontWeight: 400,
              lineHeight: 1,
              zIndex: 1,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(64),
              textAlign: "center",
              backgroundColor: "secondary.main",
            }}
          >
            {item.name}
          </Typography>
          <Button
            variant="text"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "auto",
              borderRadius: "14px",
            }}
          />
        </Box>
      </ImageListItem>
    );
  }

  private normalListItem(index: number, item: Effect) {
    return (
      <ImageListItem
        key={item.name}
        cols={1 || 1}
        rows={(index % 4 == 1 ? 3 : 2) || 1}
        onMouseEnter={() => {
          this.setState({ sCurHoveredListItem: index });
        }}
      >
        <img
          style={{ borderRadius: "14px" }}
          src={item.imageUrl}
          loading="lazy"
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: "14px",
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), rgba(217, 217, 217, 0.54)",
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            borderBottomRightRadius: "14px",
            color: "primary.contrastText",
            fontFamily: "Beloved",
            fontWeight: 400,
            lineHeight: 1,
            paddingY: PixelsToViewPortUnitConverter.getvw(4),
            fontSize: PixelsToViewPortUnitConverter.getFontVw(64),
            paddingX: PixelsToViewPortUnitConverter.getvw(40),
            textAlign: "center",
            backgroundColor: "secondary.main",
          }}
        >
          {item.name}
        </Typography>
        <Button
          variant="text"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "auto",
          }}
        />
      </ImageListItem>
    );
  }

  private handleFullExpandEffectList = () => {
    this.setState({
      sExpandFullList: true,
    });
  };
}

export default withRouter(EffectsComponent);
