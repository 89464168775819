import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export default function QuestionMarkIconV2(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 22 22"
    return (
        <SvgIcon {...uProps} fill="none">
            <path d="M9.84359 13.4025C9.80313 13.4025 9.76672 13.3903 9.73435 13.3661C9.71008 13.3337 9.69794 13.2973 9.69794 13.2568V12.7592C9.69794 12.3708 9.79504 12.0592 9.98925 11.8246C10.1835 11.5818 10.4667 11.3269 10.8389 11.0599C11.1626 10.8252 11.4053 10.6148 11.5672 10.4287C11.729 10.2345 11.8099 9.99577 11.8099 9.71255C11.8099 9.39697 11.7007 9.14611 11.4822 8.96C11.2637 8.76579 10.9765 8.66869 10.6204 8.66869C10.2482 8.66869 9.94879 8.76984 9.72221 8.97214C9.49564 9.17444 9.38235 9.43338 9.38235 9.74897V10.0039C9.38235 10.0929 9.3338 10.1374 9.2367 10.1374L8.12001 10.0888C8.07955 10.0888 8.04313 10.0726 8.01077 10.0403C7.98649 10.0079 7.97435 9.97149 7.97435 9.93103V9.74897C7.97435 9.28772 8.08764 8.87908 8.31421 8.52303C8.54079 8.1589 8.85637 7.87972 9.26097 7.68552C9.66557 7.48322 10.1309 7.38207 10.6568 7.38207C11.4418 7.38207 12.0689 7.59246 12.5382 8.01324C13.0075 8.42593 13.2422 8.97618 13.2422 9.664C13.2422 10.02 13.1775 10.3275 13.048 10.5865C12.9266 10.8454 12.781 11.0558 12.611 11.2177C12.4492 11.3795 12.2267 11.5616 11.9435 11.7639C11.6602 11.9662 11.4498 12.1442 11.3123 12.2979C11.1747 12.4436 11.1059 12.6257 11.1059 12.8441V13.2568C11.1059 13.2973 11.0898 13.3337 11.0574 13.3661C11.0331 13.3903 11.0007 13.4025 10.9603 13.4025H9.84359ZM10.3655 16.0121C10.1147 16.0121 9.90833 15.9312 9.74649 15.7694C9.58465 15.6075 9.50373 15.4012 9.50373 15.1503C9.50373 14.8995 9.58465 14.6972 9.74649 14.5434C9.90833 14.3816 10.1147 14.3007 10.3655 14.3007C10.6164 14.3007 10.8187 14.3816 10.9724 14.5434C11.1343 14.6972 11.2152 14.8995 11.2152 15.1503C11.2152 15.4012 11.1343 15.6075 10.9724 15.7694C10.8106 15.9312 10.6083 16.0121 10.3655 16.0121Z" fill="black"/>
            <circle cx="10.6207" cy="11.3785" r="10.2414" stroke="#D7D7D7" strokeWidth="0.758621"/>
        </SvgIcon>
    );
}

