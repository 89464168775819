import SvgIcon from "@mui/material/SvgIcon";
export default function ProfileIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="21.4987"
        cy="21.4948"
        r="6.41667"
        strokeWidth="1.5"
      />
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="21.5"
        cy="21.5"
        r="20.75"
        strokeWidth="1.5"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M9.22266 38.836C11.4319 35.8256 16.1002 33.75 21.5019 33.75C26.9036 33.75 31.5719 35.8256 33.7811 38.836"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}
