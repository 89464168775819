import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import LocalStorageUtils from "../../../core/utils/LocalStorageUtils";
import PartnerManager from "../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import GeoLocation from "../Geolocation/GeoLocation";

function AgeVerficationComponent(props) {
  const [isUser21YearOld, setIsUser21YearOld] = useState(
    LocalStorageUtils.getItem("1s_user_21_yrs_old") == "true"
  );

  useEffect(() => {
    document.body.style.overflow = isUser21YearOld ? "unset" : "hidden";
    LocalStorageUtils.storeItem("1s_user_21_yrs_old", isUser21YearOld);
  }, [isUser21YearOld]);

  return !isUser21YearOld ? (
    <Stack
      sx={{
        position: "fixed",
        height: "100%",
        width: "100%",
        display: "flex",
        zIndex: 1500,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          height: {
            sm: PixelsToViewPortUnitConverter.getvw(132),
            xs: "auto",
          },
          width: {
            sm: PixelsToViewPortUnitConverter.getvw(156),
            xs: PixelsToViewPortUnitConverter.getvw(300),
          },
        }}
      >
        <img
          style={{
            // height: PixelsToViewPortUnitConverter.getvw(132),
            width: "100%",
          }}
          src={PartnerManager.shared().partner.theme.logoUrl}
        />
      </Box>
      <Typography
        sx={{
          fontSize: {
            sm: PixelsToViewPortUnitConverter.getFontVw(66),
            xs: PixelsToViewPortUnitConverter.getFontVw(48),
          },
          fontFamily: "Bebas",
          fontWeight: "400",
          lineHeight: "unset",
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(50),
            xs: PixelsToViewPortUnitConverter.getvw(50),
          },
          color: (theme) => theme.palette.primary.contrastText,
          letterSpacing: "0,2em",
        }}
      >
        {PartnerManager.shared().currentSelectedStore.name}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            sm: PixelsToViewPortUnitConverter.getFontVw(16),
            xs: PixelsToViewPortUnitConverter.getFontVw(16),
          },
          fontFamily: "Barlow",
          fontWeight: "700",
          color: (theme) => theme.palette.primary.contrastText,
          letterSpacing: "0.16em",
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(40),
          },
        }}
      >
        ARE YOU 21 OR OVER?
      </Typography>
      <Stack
        direction="row"
        sx={{
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(40),
            xs: PixelsToViewPortUnitConverter.getvw(80),
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            fontFamily: "Barlow",
            fontSize: {
              sm: PixelsToViewPortUnitConverter.getvw(20),
              xs: PixelsToViewPortUnitConverter.getvw(60),
            },
            width: {
              sm: PixelsToViewPortUnitConverter.getvw(182),
              xs: PixelsToViewPortUnitConverter.getvw(550),
            },
            height: {
              sm: PixelsToViewPortUnitConverter.getvw(48),
              xs: PixelsToViewPortUnitConverter.getvw(150),
            },
          }}
        >
          NO
        </Button>
        <Button
          variant="contained"
          disableElevation={true}
          onClick={() => {
            setIsUser21YearOld(true);
          }}
          sx={{
            fontFamily: "Barlow",
            fontSize: {
              sm: PixelsToViewPortUnitConverter.getvw(20),
              xs: PixelsToViewPortUnitConverter.getvw(60),
            },
            width: {
              sm: PixelsToViewPortUnitConverter.getvw(182),
              xs: PixelsToViewPortUnitConverter.getvw(550),
            },
            height: {
              sm: PixelsToViewPortUnitConverter.getvw(48),
              xs: PixelsToViewPortUnitConverter.getvw(150),
            },
            marginLeft: {
              sm: PixelsToViewPortUnitConverter.getvw(2),
              xs: PixelsToViewPortUnitConverter.getvw(10),
            },
          }}
        >
          YES
        </Button>
      </Stack>
    </Stack>
  ) : (
    <GeoLocation />
  );
}

export default AgeVerficationComponent;
