import ProductReview from './ProductReview';
export default class ProductReviewList {

    // total items received for current page
    private mReviewsCount: number;
    public get reviewsCount() {
        return this.mReviewsCount;
    }

    // current page
    private mPage: number;
    public get page() {
      return this.mPage;
    }

    // total pages
    private mTotalPages: number;
    public get totalPages() {
      return this.mTotalPages;
    }

    // items count per page
    private mReviewsPerPage: number;
    public get reviewsPerPage() {
      return this.mReviewsPerPage;
    }

    // products
    private mProductReviews: ProductReview[];
    public get reviews() {
      return this.mProductReviews;
    }

    // query
    private mQuery: string;
    public get query() {
      return this.mQuery;
    }

    // dataLoaded from API or not
    private mDataLoaded: boolean;
    public get dataLoaded() {
      return this.mDataLoaded;
    }

    public constructor() {}

    public init(itemsResult: any, previousReviewList: ProductReviewList) {
        this.mReviewsCount = itemsResult.nbHits
        this.mPage = itemsResult.page
        this.mTotalPages = itemsResult.nbPages
        this.mReviewsPerPage = itemsResult.hitsPerPage
        this.mQuery = itemsResult.query;

        let finalProductReviews: ProductReview[] = []
        const previousProductReviews = (previousReviewList && previousReviewList.reviews.length > 0) ? previousReviewList.reviews : []
        const newProductReviews = ProductReview.list(itemsResult.hits)
        if(this.mPage !== 0) {
            finalProductReviews = finalProductReviews.concat(previousProductReviews)
        }
        finalProductReviews = finalProductReviews.concat(newProductReviews)
        this.mProductReviews = finalProductReviews
        this.mDataLoaded = true
    }

    public initWithDefault() {
      this.mReviewsCount = 0
      this.mPage = 0
      this.mTotalPages = 0
      this.mReviewsPerPage = 0
      this.mProductReviews = []
      this.mDataLoaded = false
  }

    public static initWithResult(result: any, previousReviewList: ProductReviewList): ProductReviewList|undefined {
        let pList: ProductReviewList = undefined
        pList = new ProductReviewList()
        pList.init(result,previousReviewList)
        return pList
    }

    public getProductCountInfo() {
      if(this.mReviewsCount === 0) {
        return `No results found`
      } else if(this.mReviewsCount === 1) {
        return `1 Result`
      } else {
        return `${this.mReviewsCount} Results`
      }
    }
}