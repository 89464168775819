import React, { useEffect, useState } from "react";
import "./EffectV2PDPComponent.scss";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import Product from "../../../../core/models/Product";
import Weight from "../../../../core/models/Weight";
import Card from "@mui/material/Card";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Divider,
  ButtonBase,
  alpha,
} from "@mui/material";
import ShowMoreIconV2 from "../../../../statics/svgs/ShowMoreIconV2";
import QuestionMarkIconV2 from "../../../../statics/svgs/QuestionMarkIconV2";
import MenuService from "../../../../services/MenuService";
import PartnerManager from "../../../../core/utils/PartnerManager";
import { ApiError } from "../../../../core/webservice/ApiError";
import Loader from "../../../common/loader/Loader";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import BackIconV2 from "../../../../statics/svgs/BackIconV2";
import TextUtils from "../../../../core/utils/TextUtils";
import Effect from "../../../../core/models/Effect";
import RoundMinus from "../../../../statics/svgs/RoundMinus";
import RoundPlus from "../../../../statics/svgs/RoundPlus";
import ProductImageList from "./ProductImageList";
import RelatedProducts from "../menu_products/RelatedProducts";
import ProductReviewsComponent from "./ProductReviewsComponent";
import { Cart } from "../../../common/cart/CartReducer";
import { CartProduct } from "../../../../core/models/Product";
import CollapseIconV2 from "../../../../statics/svgs/CollapseIconV2";
import ExpandIconV2 from "../../../../statics/svgs/ExpandIconV2";
import DimensionUtils from "../../../../core/utils/DimensionUtils";
import { NavigationProps } from "../../../../Navigation.types";
import Navigation from "../../../../Navigation";
import StrikeLabel from "../other/StrikeLabel";
import WeightBoxList from "../other/WeightBoxList";
import MessageComponent from "../../../common/message/MessageComponent";
import { ProductDetailsSeo } from "../../../../seo/ProductDetailsSeo";
import { withMediaQuery } from "../../../../withMediaQuery";

const svg = require("external-svg-loader");

type EffectV2PDPComponentProps = {
  router: NavigationProps;
  productId: number;
  storeId: number;
  pCart: Cart | undefined;
  pOnAddOrRemoveProductFromCart(product: CartProduct): void;
  isMobile:boolean;
};

const EffectV2PDPComponent: React.FC<EffectV2PDPComponentProps> = (
  props: EffectV2PDPComponentProps
) => {
  const minimumQuantity = 1;
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [product, setProduct] = React.useState<Product>(undefined);
  const [selectedWeight, setSelectedWeight] = useState<Weight>(undefined);
  const [quantity, setQuantity] = useState(minimumQuantity);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [showProductNotFoundMsg, setShowProductNotFoundMsg] =
    React.useState(false);

  useEffect(() => {
    getProductDetails(props.productId);
  }, []);

  useEffect(() => {
    getProductDetails(props.productId);
  }, [props.productId, props.storeId]);

  useEffect(() => {
    updateProductCartInfo(props.pCart);
  }, [props.pCart, product]);

  const sendToCart = (product: CartProduct) => {
    if (product.count === 0) {
      props.pOnAddOrRemoveProductFromCart(product);
      return;
    }
    const idOfImage = `product-detail-image-${product.productId}-${0}`;
    const imageElement = document.getElementById(idOfImage) as HTMLImageElement;
    console.log("imageElement :" + imageElement);
    const newImageWidth = DimensionUtils.getVwNumber(160); //imgVwWidth
    const newImageHeight = DimensionUtils.getVwNumber(160); //imgVwHeight
    const clone = imageElement.cloneNode(false) as HTMLImageElement;
    clone.id = "animated-product-image";
    clone.className = "animated-product-image";
    clone.style.position = "absolute";
    clone.style.width = newImageWidth + "px"; //imageElement.style.width
    clone.style.height = newImageHeight + "px"; //imageElement.style.height
    clone.style.zIndex = "9999";
    clone.style.margin = "0px";
    clone.style.visibility = "hidden";
    clone.width = newImageWidth;
    clone.height = newImageHeight;
    const navBarHeight = DimensionUtils.getVwNumber(114) + 30; //navbar_h + extra_space
    const cartIconWidth = DimensionUtils.getVwNumber(105.34); // cart container height
    const imgWidth = newImageWidth; //Number(imageElement.style.width.replace('px',''))
    const interMargin = cartIconWidth / 2 - imgWidth / 2;
    const navBarRightPadding = DimensionUtils.getVwNumber(62) + interMargin;

    const { scrollX, scrollY } = window;
    const topPos = navBarHeight + scrollY;
    const rightPos = navBarRightPadding + scrollX;

    clone.style.right = rightPos + "px"; // right_p of image
    clone.style.top = topPos + "px"; // top_p of image

    document.body.appendChild(clone);
    clone.style.visibility = "visible";
    clone.className = "sendtocart";

    let mainTimer = undefined;
    let innerTimer = undefined;

    mainTimer = setTimeout(() => {
      clone.className = "part2";
      innerTimer = setTimeout(() => {
        clone.remove();
        if (innerTimer) {
          clearTimeout(innerTimer);
          innerTimer = undefined;
        }
        if (mainTimer) {
          clearTimeout(mainTimer);
          mainTimer = undefined;
        }
        props.pOnAddOrRemoveProductFromCart(product);
      }, 400);
    }, 800);
  };

  const updateProductCartInfo = (pCart: Cart | undefined) => {
    if (!product) return;
    const productId = product.id;
    let oQauntity = minimumQuantity;
    let oWeight = product.getSelectedWeight();
    let oIsAddedTocart = false;
    if (pCart) {
      const itemFound = pCart.products.find(
        (item: CartProduct) => item.productId == productId
      );
      if (itemFound) {
        const sWeight = product.getSelectedWeightByPriceId(itemFound.priceId);
        if (sWeight) {
          oQauntity = itemFound.count;
          oWeight = sWeight;
          oIsAddedTocart = true;
        }
      }
    }
    setQuantity(oQauntity);
    setSelectedWeight(oWeight);
    setIsAddedToCart(oIsAddedTocart);
  };

  const handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    Navigation.back(props.router);
  };

  const handleExpandCollapseClicked = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    const isExpanded = !isDescriptionExpanded;
    setIsDescriptionExpanded(isExpanded);
    let element = document.getElementById("product-description-field") as HTMLElement;
    if(!element) {
      element = document.getElementById("product-description-field-expanded") as HTMLElement;
    }
    if (!isExpanded) {
      element.id = "product-description-field";
    } else {
      element.id = "product-description-field-expanded";
    }
  };

  const handleWeightClick = (
    event: React.MouseEvent<HTMLElement>,
    weight: Weight
  ) => {
    setSelectedWeight(weight);
  };

  const handleAddToCartClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!product) return;
    const cartProduct: CartProduct = {
      productId: product.id,
      priceId: selectedWeight.priceId,
      count: quantity,
    };
    sendToCart(cartProduct);
    //props.pOnAddOrRemoveProductFromCart(cartProduct);
  };

  const increaseQuantity = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (quantity < product.maxCartQuantity) {
      setQuantity(quantity + 1);
      addIncreaseAnimation();
    }
  };

  const decreaseQuantity = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (quantity > minimumQuantity) {
      setQuantity(quantity - 1);
      addDecreaseAnimation();
    }
    // else if (isAddedToCart && quantity == 1) {
    //   setQuantity(0);
    // }
  };

  const isDecreaseDisabled = () => {
    return false;
    /*let isDisabled = quantity > minimumQuantity ? false : true;
    if (isDisabled && isAddedToCart) {
      isDisabled = quantity == minimumQuantity ? false : true;
    }
    return isDisabled;*/
  };

  const isIncreaseDisabled = () => {
    return false;
    /*const isDisabled = quantity < product.maxCartQuantity ? false : true;
    return isDisabled;*/
  };

  const addIncreaseAnimation = () => {
    const labelElement = document.getElementById(
      "quantity-title"
    ) as HTMLParagraphElement;
    if (labelElement) {
      const height = DimensionUtils.getVwNumber(25);
      labelElement.style.visibility = "hidden";
      labelElement.style.transform = `translateY(${height}px)`;
      labelElement.className += " -up-animated";

      let mainTimer = undefined;
      mainTimer = setTimeout(() => {
        labelElement.className = "quantity-title";
        labelElement.style.color =
          PartnerManager.shared().partner.theme.buttonTextColor;
        if (mainTimer) {
          clearTimeout(mainTimer);
          mainTimer = undefined;
          labelElement.style.transform = "translateY(0px)";
          labelElement.style.visibility = "visible";
        }
      }, 150);
    }
  };

  const addDecreaseAnimation = () => {
    const labelElement = document.getElementById(
      "quantity-title"
    ) as HTMLParagraphElement;
    if (labelElement) {
      const height = DimensionUtils.getVwNumber(25);
      labelElement.style.visibility = "hidden";
      labelElement.style.transform = `translateY(-${height}px)`;
      labelElement.className += " -down-animated";

      let mainTimer = undefined;
      mainTimer = setTimeout(() => {
        labelElement.className = "quantity-title";
        labelElement.style.color =
          PartnerManager.shared().partner.theme.buttonTextColor;
        if (mainTimer) {
          clearTimeout(mainTimer);
          mainTimer = undefined;
          labelElement.style.transform = "translateY(0px)";
          labelElement.style.visibility = "visible";
        }
      }, 150);
    }
  };

  const getProductDetails = (productId: number) => {
    if (isDataLoading) return;
    setIsDataLoading(true);
    MenuService.getProductDetails(
      PartnerManager.shared().currentSelectedStore.id,
      productId
    )
      .then((product: Product) => {
        setProduct(product);
        setIsDataLoading(false);
        //updateProductCartInfo(props.pCart)
      })
      .catch((apiError: ApiError) => {
        setProduct(undefined);
        setIsDataLoading(false);
        setShowProductNotFoundMsg(apiError && apiError.errorCode == 500);
      });
  };

  const renderEffectBox = (effect: Effect) => {
    return (
      <Grid className="effect-box" xs>
        <Typography
          variant="h1"
          className="effects-name"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.66),
          }}
        >
          {TextUtils.capitalize(effect.name)}
        </Typography>
        {!props.isMobile ? (
          <svg
            data-src={Effect.getEffectImageUrl("effectsv2", effect.name)}
            style={{
              // margin: "auto",
              height: PixelsToViewPortUnitConverter.getvw(22),
              width: PixelsToViewPortUnitConverter.getvw(22),
              color: PartnerManager.shared().partner.theme.pageHighlightColor,
            }}
          />
        ) : (
          <svg
            data-src={Effect.getEffectImageUrl("effectsv2", effect.name)}
            style={{
              // margin: "auto",
              height: PixelsToViewPortUnitConverter.getvw(70),
              width: PixelsToViewPortUnitConverter.getvw(70),
              color: PartnerManager.shared().partner.theme.pageHighlightColor,
            }}
          />
        )}
        {/* <img
                  style={{
                    // margin: "auto",
                    height: PixelsToViewPortUnitConverter.getvw(22),
                    width: PixelsToViewPortUnitConverter.getvw(22),
                  }}
                  src={Effect.getEffectImageUrl("effectv2", effect.name)}
                /> */}
      </Grid>
    );
  };

  const renderEffectBoxList = () => {
    return product.feelings.map((effect: Effect) => renderEffectBox(effect));
  };

  const renderEffects = () => {
    return (
      <Box>
        <Typography
          variant="h1"
          className="effects-header"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
            mt: 1,
            mb: 1,
          }}
        >
          {`EFFECTS`}
        </Typography>
        <Grid className="effect-box-container">{renderEffectBoxList()}</Grid>
        <Divider sx={{ mt: 2 }} />
      </Box>
    );
  };

  const renderWeights = () => {
    return (
      <WeightBoxList
        product={product}
        selectedWeight={selectedWeight}
        xsItem={4}
        mt={18}
        mb={18}
        onSelectedWeight={(w) => {
          handleWeightClick(null, w);
        }}
      />
      // <Grid container className="weight-box-container">
      //   {renderWeightBoxList()}
      // </Grid>
    );
  };

  const renderQuantityCounter = () => {
    return (
      <Box className="quantity-container">
        <IconButton onClick={decreaseQuantity} disabled={isDecreaseDisabled()}>
          <RoundMinus
            sx={{
              fill: "none",
            }}
          />
        </IconButton>
        <Typography
          id="quantity-title"
          variant="h1"
          className="quantity-title"
          sx={{
            color: (theme) => alpha(theme.palette.secondary.contrastText, 1.0),
          }}
        >
          {`${quantity}`}
        </Typography>
        <IconButton onClick={increaseQuantity} disabled={isIncreaseDisabled()}>
          <RoundPlus
            sx={{
              fill: "none",
            }}
          />
        </IconButton>
      </Box>
    );
  };

  const renderCartButton = () => {
    // let btnTitle = !isAddedToCart
    //   ? `ADD TO CART`
    //   : quantity !== 0
    //   ? `UPDATE`
    //   : `REMOVE`;
    const btnTitle = "ADD TO CART";
    // background: #3a2d25;
    // border: 0.6875px solid rgba(58, 45, 37, 0.98);
    return (
      <ButtonBase
        disableRipple
        onClick={handleAddToCartClick}
        sx={{ width: "100%" }}
      >
        <Box
          className="add-to-cart-container"
          sx={{
            borderWidth: "0.6875px",
            borderStyle: "solid",
            padding: "22px",
            borderColor: (theme) => alpha(theme.palette.secondary.main, 0.98),
            backgroundColor: (theme) =>
              alpha(theme.palette.secondary.main, 1.0),
          }}
        >
          <Typography
            variant="h1"
            className="add-to-cart-title"
            sx={{
              color: (theme) =>
                alpha(theme.palette.secondary.contrastText, 1.0),
            }}
          >
            {btnTitle}
          </Typography>

          {renderQuantityCounter()}
        </Box>
      </ButtonBase>
    );
  };

  const renderLineageInfo = () => {
    if (TextUtils.isEmpty(product.lineage)) return null;
    const lineageName = TextUtils.capitalize(product.lineage);
    return (
      <Grid item xs>
        <Typography
          variant="h1"
          className="other-info-title -lineage"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
          }}
        >
          {`LINEAGE`}
        </Typography>
        <Typography
          variant="h1"
          className="lineage-value"
          sx={{
            backgroundColor: (theme) =>
              alpha(theme.palette.primary.contrastText, 0.05),
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
          }}
        >
          {lineageName}
        </Typography>
      </Grid>
    );
  };

  const renderEmptyBlockInfo = () => {
    return (
      <Grid item xs>
        <Typography
          variant="h1"
          className="other-info-title"
          sx={{
            visibility: "hidden",
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
          }}
        >
          {`THC`}
        </Typography>
        <Typography
          variant="h1"
          className="other-info-value"
          sx={{
            visibility: "hidden",
            color: (theme) => alpha(theme.palette.primary.contrastText, 1.0),
          }}
        >
          {`0%`}
        </Typography>
      </Grid>
    );
  };

  const renderTHCInfo = () => {
    if (!product.percentTHC) return null;
    return (
      <Grid item xs>
        <Typography
          variant="h1"
          className="other-info-title"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
          }}
        >
          {`THC`}
        </Typography>
        <Typography
          variant="h1"
          className="other-info-value"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 1.0),
          }}
        >
          {`${product.percentTHC}%`}
        </Typography>
      </Grid>
    );
  };

  const renderCBDInfo = () => {
    if (!product.percentCBD) return null;
    return (
      <Grid item xs>
        <Typography
          variant="h1"
          className="other-info-title"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
          }}
        >
          {`CBD`}
        </Typography>
        <Typography
          variant="h1"
          className="other-info-value"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 1.0),
          }}
        >
          {`${product.percentCBD}%`}
        </Typography>
      </Grid>
    );
  };

  const renderQuestionMark = () => {
    return (
      <Grid item className="question-mark-container" xs>
        <QuestionMarkIconV2
          sx={{
            fill: "none",
          }}
        />
      </Grid>
    );
  };

  const renderTHCCBDInfo = () => {
    var array = [];
    if (product.percentTHC) {
      array.push(renderTHCInfo());
    }
    if (product.percentCBD) {
      array.push(renderCBDInfo());
    }
    let arrayCount = array.length;
    if (arrayCount < 2) {
      if (arrayCount === 0) {
        arrayCount = 2;
      }
      for (var i = 0; i < arrayCount; i++) {
        array.push(renderEmptyBlockInfo());
      }
    }
    return array;
  };

  const renderOtherInfo = () => {
    if (
      TextUtils.isEmpty(product.lineage) &&
      !product.percentTHC &&
      !product.percentCBD
    )
      return null;
    return (
      <Grid container className="other-info-container">
        {renderLineageInfo()}
        {renderTHCCBDInfo()}
        {renderQuestionMark()}
      </Grid>
    );
  };

  const isDescriptionLarge = () => {
    if (product && product.description && product.description.length > 270) {
      return true;
    }
    return false;
  };

  const renderProductDescription = () => {
    return (
      <Box className="product-description-container">
        <Typography
          variant="h1"
          className="description-header"
          sx={{
            color: (theme) => alpha(theme.palette.primary.contrastText, 0.51),
            mt: 2,
          }}
        >
          {`DESCRIPTION`}
        </Typography>
        <Grid item container>
          <Grid xs={11}>
            <Typography
              variant="h2"
              id="product-description-field"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
              }}
            >
              {product.description}
            </Typography>
          </Grid>
          {isDescriptionLarge() && (
            <Grid xs={1}>
              <IconButton
                onClick={(e) => {
                  handleExpandCollapseClicked(e);
                }}
                sx={{
                  backgroundColor: "transparent",
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {isDescriptionExpanded ? (
                  <CollapseIconV2
                    sx={{
                      fill: "none",
                      backgroundColor: (theme) =>
                        alpha(theme.palette.primary.contrastText, 0.04),
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "transparent",
                      ":hover": {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: (theme) =>
                          theme.palette.primary.contrastText,
                      },
                    }}
                  />
                ) : (
                  <ExpandIconV2
                    sx={{
                      fill: "none",
                      backgroundColor: (theme) =>
                        alpha(theme.palette.primary.contrastText, 0.04),
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "transparent",
                      ":hover": {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: (theme) =>
                          theme.palette.primary.contrastText,
                      },
                    }}
                  />
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  if (isDataLoading) {
    return (
      <Box sx={{ position: "relative", minHeight: "300px", width: "100%" }}>
        <Loader pshow={true} />
      </Box>
    );
  }

  if (showProductNotFoundMsg)
    return (
      <MessageComponent
        pTitle="PRODUCT NOT AVAILABLE"
        pMessage="This product is currently not available in your location. You can change location to view the product details or please visit the homepage to find products around your location"
        pShowGoToHomeBtn={true}
      />
    );

  if (!product || !props.router) return null;

  return (
    <Box
      className="cmenu-product-details-inner-container"
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
            <ProductDetailsSeo product={product} />

      <Box
        className="left-container"
        sx={{
          marginLeft: {
            xs: PixelsToViewPortUnitConverter.getvw(60),
            sm: PixelsToViewPortUnitConverter.getvw(96.5),
          },

          marginRight: {
            xs: PixelsToViewPortUnitConverter.getvw(0),
            sm: PixelsToViewPortUnitConverter.getvw(96.5),
          },
        }}
      >
        <IconButton
          onClick={(e) => {
            handleBackClick(e);
          }}
        >
          <BackIconV2
            sx={{
              fill: "none",
              stroke: (theme) => theme.palette.primary.contrastText,
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          />
        </IconButton>
      </Box>

      <Box className="center-container">
        <BreadcrumbsComponent router={props.router} isSectionExpanded={false} />

        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 5, mb: 2 }}>
          <Typography
            variant="h1"
            className="product-details-heading"
            sx={{
              color: (theme) => alpha(theme.palette.primary.contrastText, 1.0),
            }}
          >
            {`PRODUCT DETAILS`}
          </Typography>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} lg={6}>
            <ProductImageList product={product} isMobile={props.isMobile}/>
          </Grid>

          <Grid item xs={12} sm={6} lg={6}>
            {!TextUtils.isEmpty(product.brand) && (
              <Typography
                variant="h1"
                className="product-brand-title"
                sx={{
                  color: (theme) =>
                    alpha(theme.palette.primary.contrastText, 0.4),
                }}
              >
                {product.brand}
              </Typography>
            )}
            <Typography
              variant="h1"
              className="product-title"
              sx={{
                color: (theme) =>
                  alpha(theme.palette.primary.contrastText, 1.0),
              }}
            >
              {product.name}
            </Typography>
            {renderEffects()}
            <Box
              sx={{
                marginTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(60),
                },
              }}
            >
              {renderWeights()}
            </Box>
            <Box
              sx={{
                marginTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(60),
                },
              }}
            >
              {renderCartButton()}
            </Box>
            {renderOtherInfo()}
            <Divider
              sx={{
                marginTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(60),
                },
              }}
            />
            {renderProductDescription()}
          </Grid>
        </Grid>
        <Divider
          sx={{
            marginTop: PixelsToViewPortUnitConverter.getvw(100),
            marginBottom: PixelsToViewPortUnitConverter.getvw(60),
          }}
        />
        {product && (
          <RelatedProducts
            router={props.router}
            product={product}
            marginLeft={-65}
            marginRight={-65}
            pCart={props.pCart}
            pOnAddOrRemoveProductFromCart={(cartProduct) => {
              props.pOnAddOrRemoveProductFromCart(cartProduct);
            }}
          />
        )}
        <ProductReviewsComponent product={product} />
        <Box
          sx={{
            width: "100%",
            height: PixelsToViewPortUnitConverter.getvw(50),
          }}
        ></Box>
      </Box>
      <Box
        className="right-container"
        sx={{
          marginRight: {
            xs: PixelsToViewPortUnitConverter.getvw(100),
            sm: PixelsToViewPortUnitConverter.getvw(96.5),
          },

          marginLeft: {
            xs: PixelsToViewPortUnitConverter.getvw(0),
            sm: PixelsToViewPortUnitConverter.getvw(96.5),
          },
        }}
      ></Box>
    </Box>
  );
};

export default withMediaQuery(EffectV2PDPComponent);
