import React from "react";
import "./AppliedFilterButton.scss";
import { Box, Button } from '@mui/material';
import AppliedFilter from '../../../../core/models/AppliedFilter';
import CrossIcon from "../../../../statics/svgs/CrossIcon";
import StarIcon from "../../../../statics/svgs/StarIcon";

type AppliedFilterButtonProps = {
    appliedFilter: AppliedFilter;
    onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
};

export const AppliedFilterButton: React.FC<AppliedFilterButtonProps> = (props: AppliedFilterButtonProps) => {

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        props.onRemoveAppliedFilter(props.appliedFilter)
    };

    return (
        <Button 
            type="submit"
            color="primary"
            className="applied-filter-btn"
            sx={{
                color: (theme) => theme.palette.primary.main
            }}
            startIcon={<CrossIcon 
                sx={{
                    width: 15,
                    height: 15,
                    fill: (theme) => theme.palette.primary.main,
                    stroke: (theme) => theme.palette.primary.main
                }}/>}
            onClick={handleClick}
        >
                {(props.appliedFilter.slug === 'aggregate_rating') && <StarIcon style={{ fontSize: 14, paddingRight: '5px' }} />}
                {props.appliedFilter.getDisplayName()}
        </Button>
    )
}