import { ButtonBase, FormControl, FormHelperText, Input, Stack, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import "./CustomTextInput.scss";

type CustomTextInputProps = {
    defaultValue: string;
    value: string;
    placeholder: string;
    description?: string;
    rightActionTitle?: string;
    mt?:number;
    type?:string;
    maxLength?:number;
    onChangeText?(value: string): void;
    onRightActionClicked?(): void;
};

const CustomTextInput: React.FC<CustomTextInputProps> = (props: CustomTextInputProps) => {
    const { mt = 0, type = 'text', maxLength = 1000 } = props
    return(
        <FormControl 
            variant="standard"
            className="custom-text-input-control"
            sx={{
                mt: PixelsToViewPortUnitConverter.getvw(mt)
            }}
            fullWidth>
            <Input
                className="custom-text-input"
                type={type}
                placeholder={props.placeholder}
                autoComplete={"off"}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={(e) => {
                    if(props.onChangeText) {
                        props.onChangeText(e.target.value)
                    }
                }}
                aria-describedby="component-helper-text"
                sx={{
                    borderBottomColor: '#000',
                    ':before': { borderBottomColor: '#000' },
                    ':after': { borderBottomColor: '#000' },
                }}
                inputProps={{ maxLength: maxLength }}
            />
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                {props.description && 
                <FormHelperText className="textfield-helper-text">
                    {props.description}
                </FormHelperText>}
                {props.rightActionTitle && <ButtonBase onClick={() => {props.onRightActionClicked()}}>
                    <Typography variant="h2" className="right-title">
                        {props.rightActionTitle}
                    </Typography>
                </ButtonBase>}
            </Stack>
      </FormControl>
    )
}
export default CustomTextInput





