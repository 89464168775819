import { Amplify } from "aws-amplify";
import UserAuthService from "../../services/UserAuthService";
import Partner from "../models/Partner";
import { ApiError } from "../webservice/ApiError";
import TextUtils from "./TextUtils";

export default class AmplifyManager {
  public static shared(): AmplifyManager {
    if (!AmplifyManager.sAmplifyManager) {
      AmplifyManager.sAmplifyManager = new AmplifyManager();
    }
    return AmplifyManager.sAmplifyManager;
  }

  private static sAmplifyManager: AmplifyManager | null;

  private mRegion: string | null;
  public get region(): string | undefined {
    return this.mRegion;
  }

  private mUserPoolId: string | null;
  public get userPoolId(): string | undefined {
    return this.mUserPoolId;
  }

  private mUserPoolWebClientId: string | null;
  public get userPoolWebClientId(): string | undefined {
    return this.mUserPoolWebClientId;
  }

  private mJWTAccessToken: string | null | undefined;
  public get jtwAccessToken(): string | null | undefined {
    return this.mJWTAccessToken;
  }

  private constructor() {
    this.mJWTAccessToken = null;
  }

  // Needs to be called when we set partner object
  public setupPartnerUserPool(partner: Partner) {
    // need to set the userpool credentials from Partner object
    if(partner && !partner.isUserPoolDetailsEmpty()) {
      this.mRegion = partner.userPool.region;
      this.mUserPoolId = partner.userPool.userPoolId;
      this.mUserPoolWebClientId = partner.userPool.appClientId;
      this.setupConfiguration(); // setup amplify configuration
      this.setupJwtAccessTokenFromSession(); // setup jwt access_token if available in userSession
    }
  }

  public setupConfiguration() {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: this.mRegion,
        userPoolId: this.mUserPoolId,
        userPoolWebClientId: this.mUserPoolWebClientId,
        authenticationFlowType: "USER_PASSWORD_AUTH",
      },
    });
  }

  // Needs to be called when we set partner object
  public setupJwtAccessTokenFromSession() {
    UserAuthService.currentSession()
      .then((data: any) => {
        this.setJwtAccessTokenFromCurrentUserSession(data);
      })
      .catch((error: ApiError) => {});
  }

  // set accessToken from login response
  public setJwtAccessToken(loginResponse: any) {
    let accessToken = null;
    if (loginResponse && loginResponse.signInUserSession) {
      const signInUserSession = loginResponse.signInUserSession;
      if (
        signInUserSession.accessToken &&
        signInUserSession.accessToken.jwtToken
      ) {
        accessToken = signInUserSession.accessToken.jwtToken;
      }
    }
    this.mJWTAccessToken = accessToken;
  }

  // set accessToken from current user session
  public setJwtAccessTokenFromCurrentUserSession(userSession: any) {
    let accessToken = null;
    console.log();
    if (
      userSession &&
      userSession.accessToken &&
      userSession.accessToken.jwtToken
    ) {
      accessToken = userSession.accessToken.jwtToken;
    }
    this.mJWTAccessToken = accessToken;
  }

  public resetJwtAccessToken() {
    this.setJwtAccessToken(null);
  }

  public signOutUserSession() {
    this.resetJwtAccessToken();
  }

  public isUserLoggedIn() {
    if (this.jtwAccessToken && !TextUtils.isEmpty(this.jtwAccessToken)) {
      return true;
    }
    return false;
  }
}
