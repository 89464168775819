import Product from "../core/models/Product";
import PartnerManager from "../core/utils/PartnerManager";
import TextUtils from "../core/utils/TextUtils";
import { Helmet } from "react-helmet";

interface IProductDetailsSeoProps {
  product: Product | undefined;
}
export function ProductDetailsSeo(props: IProductDetailsSeoProps) {
  const url = window.location.href.split("?")[0];
  let title = TextUtils.isEmpty(props.product.brand)
    ? props.product.name
    : props.product.name + " | " + props.product.brand;

  title = TextUtils.isEmpty(props.product.brandSubType)
    ? title
    : title + " | " + props.product.brandSubType;

  const description = `Find information about the ${props.product.name} ${
    TextUtils.isEmpty(props.product.brandSubType)
      ? "strain"
      : props.product.brandSubType
  } ${
    TextUtils.isEmpty(props.product.brand) ? "" : `from ${props.product.brand} `
  }such as potency, common effects, and where to find it.`;

  const productImageUrl =
    props.product.imageUrls && props.product.imageUrls.length > 0
      ? props.product.imageUrls[0]
      : "";
  return (
    props.product && (
      <>
        <Helmet>
          <title>{title}</title>
          ‍<link rel="canonical" href={url} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={props.product.description} />
          <meta property="og:image" content={productImageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="product" />
          <meta
            property="og:site_name"
            content={PartnerManager.shared().partner.appName}
          />
          <meta property="og:locale" content="en_US" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title}></meta>
          <meta
            property="twitter:description"
            content={props.product.description}
          />
          <meta property="twitter:image" content={productImageUrl} />
          <script type="application/ld+json">
            {JSON.stringify(getProductDetailsJsonld())}
          </script>
        </Helmet>
      </>
    )
  );

  function getProductDetailsJsonld() {
    const offers = props.product.getAllWeightsForProductCard().map((weight) => {
      return {
        "@type": "Offer",
        price: weight.sellingPrice ? weight.sellingPrice : weight.mrp,
        availability: "InStock",
        priceCurrency: "USD",
      };
    });

    return {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: props.product.name,
      description: props.product.description,
      brand: props.product.brand,
      category: props.product.customProductType,
      image: props.product.imageUrls,
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: props.product.aggregateRating,
        reviewCount: props.product.reviewCount,
      },
      offers: offers,
      additionalProperty: [
        {
          "@type": "PropertyValue",
          name: "Lineage",
          value: props.product.lineage,
        },
        {
          "@type": "PropertyValue",
          name: "Flavors",
          value:
            props.product.flavors && props.product.flavors.length > 0
              ? props.product.flavors.toString()
              : "",
        },
        {
          "@type": "PropertyValue",
          name: "Effects",
          value:
            props.product.effects && props.product.effects.length > 0
              ? props.product.effects.map((effect) => effect.name).join(",")
              : "",
        },
      ],
    };
  }
}
