import RightArrow from "../../../statics/svgs/RightArrow";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../core/webservice/ApiError";
import Navigation from "../../../Navigation";
import UserService from "../../../services/UserService";
import BackIconV2 from "../../../statics/svgs/BackIconV2";
import Loader from "../loader/Loader";
import ReservationListItem from "../profile/ReservationListItem";
import AmplifyManager from "../../../core/utils/AmplifyManager";
import PlainRightTick from "../../../statics/svgs/PlainRightTick";
import { alpha } from "@mui/material/styles";
import LoginComponent from "../../themes/effectsv2/login/LoginComponent";
import { withMediaQuery } from "../../../withMediaQuery";
import AppRoutes from "../../../routes";

interface IOrderConfirmationProps {
  pCartUUID: string;
  isMobile: boolean;
}

function OrderConfirmation(props: IOrderConfirmationProps) {
  const navigate = useNavigate();
  const [reservation, setReservation] = useState(undefined);
  const [loadingReservation, setLoadingReservation] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const rootEl = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (!reservation) {
      setLoadingReservation(true);
      UserService.getReserversionDetails(props.pCartUUID)
        .then((reservation) => {
          setLoadingReservation(false);
          setReservation(reservation);
        })
        .catch((apiError: ApiError) => {
          setLoadingReservation(false);
        });
    }
  }, [props.pCartUUID]);

  return (
    <Stack
      sx={{
        position: "relative",
        display: "flex",
        width: "100%",
        marginTop: PixelsToViewPortUnitConverter.getvw(116),
      }}
    >
      <Box
        ref={rootEl}
        sx={{
          width: "100%",
          position: "fixed",
        }}
      ></Box>
      <Box
        sx={{
          alignSelf: "flex-start",
          marginLeft: PixelsToViewPortUnitConverter.getvw(80),
          marginTop: PixelsToViewPortUnitConverter.getvw(24),
        }}
      >
        <IconButton
          onClick={() => {
            Navigation.back({
              location: undefined,
              navigate: navigate,
              params: undefined,
            });
          }}
        >
          <BackIconV2
            sx={{
              fill: "none",
              stroke: (theme) => theme.palette.primary.contrastText,
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          />
        </IconButton>
      </Box>
      {loadingReservation ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            minHeight: PixelsToViewPortUnitConverter.getvw(200),
          }}
        >
          <Loader pshow={true} />
        </Box>
      ) : (
        reservation && (
          <Stack
            sx={{
              display: "flex",
              position: "relative",
              marginTop: PixelsToViewPortUnitConverter.getvw(60),
              paddingX: {
                sm: PixelsToViewPortUnitConverter.getvw(200),
                xs: PixelsToViewPortUnitConverter.getvw(60),
              },
              marginBottom: PixelsToViewPortUnitConverter.getvw(250),
            }}
          >
            {AmplifyManager.shared().isUserLoggedIn()
              ? topComponentIfLoggedIn()
              : topComponentIfNotLoggedIn()}
            <ReservationListItem
              pReservation={reservation}
              pShowInExpandMode={true}
            />
          </Stack>
        )
      )}

      <LoginComponent
        pShowProfileContent={showLogin}
        anchorEl={rootEl.current}
        onOpen={() => {
          setShowLogin(true);
        }}
        onClose={() => {
          setShowLogin(false);
        }}
        onLoginSuccess={() => {
          setShowLogin(false);
        }}
        onSignUpClicked={() => {
          setShowLogin(false);
          Navigation.toSignUp({
            navigate: navigate,
            location: undefined,
            params: undefined,
          });
        }}
        onNavigateToVerifyCode={(userName, password) => {
          setShowLogin(false);
          Navigation.toVerifyCode(
            {
              navigate: navigate,
              location: undefined,
              params: undefined,
            },
            userName,
            password
          );
        }}
      />
    </Stack>
  );

  function topComponentIfLoggedIn() {
    return (
      <Stack>
        <Typography
          sx={{
            fontSize: PixelsToViewPortUnitConverter.getFontVw(26),
            fontFamily: "Bebas",
            fontWeight: "400",
            textAlign: "left",
            color: (theme) => theme.palette.primary.contrastText,
            letterSpacing: "0.1em",
          }}
        >
          order placed successfuly!
        </Typography>

        <Button
          onClick={() => {
            Navigation.toProfile({
              navigate: navigate,
              location: undefined,
              params: undefined,
            });
          }}
          variant="text"
          sx={{
          marginTop: {
          sm:PixelsToViewPortUnitConverter.getvw(8),
          xs:PixelsToViewPortUnitConverter.getvw(12),
          },


            fontSize:{
              sm: PixelsToViewPortUnitConverter.getFontVw(16),
              xs: PixelsToViewPortUnitConverter.getFontVw(12),
            },
            width: "fit-content",
          }}
          endIcon={
            <RightArrow
              viewBox="0 0 7 18"
              sx={{
                fill: "none",
                color: (theme) => theme.palette.primary.contrastText,
              }}
            />
          }
        >
          View all your current and past orders in your Profile
        </Button>
      </Stack>
    );
  }

  function topComponentIfNotLoggedIn() {
    return (
      <Stack
        sx={{
          marginX: {
            sm: PixelsToViewPortUnitConverter.getvw(250),
            xs: PixelsToViewPortUnitConverter.getvw(10),
          },
          marginBottom: PixelsToViewPortUnitConverter.getvw(10),
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            backgroundColor: "#55FFB8",
            paddingLeft: PixelsToViewPortUnitConverter.getvw(30),
            paddingY: PixelsToViewPortUnitConverter.getvw(6),
          }}
        >
          <PlainRightTick
            viewBox="0 0 31 24"
            sx={{
              fontSize: PixelsToViewPortUnitConverter.getFontVw(34),
              color: "#000000",
            }}
          />
          <Typography
            sx={{
              fontSize: PixelsToViewPortUnitConverter.getFontVw(34),
              fontFamily: "Bebas",
              fontWeight: "400",
              color: "#000000",
              marginLeft: PixelsToViewPortUnitConverter.getvw(36),
              letterSpacing: "0.1em",
            }}
          >
            order placed successfuly!
          </Typography>
        </Stack>
        <Stack
          sx={{
            paddingY: PixelsToViewPortUnitConverter.getvw(50),
            paddingX: PixelsToViewPortUnitConverter.getvw(100),
            marginTop: PixelsToViewPortUnitConverter.getvw(50),
            borderStyle: "solid",
            textAlign: "left",
            borderWidth: PixelsToViewPortUnitConverter.getvw(1),
            borderColor: (theme) =>
              alpha(theme.palette.primary.contrastText, 0.36),
          }}
        >
          <Typography
            sx={{
              fontSize: PixelsToViewPortUnitConverter.getFontVw(26),
              fontFamily: "Bebas",
              fontWeight: "400",
              color: (theme) => theme.palette.primary.contrastText,
              textAlign: "left",
              letterSpacing: "0.1em",
            }}
          >
            create an account to unlock features and deals
          </Typography>
          {rightTickMsg("Track your orders")}
          {rightTickMsg("Save money with coupons and deals")}
          {rightTickMsg("Share reviews with the community")}
          <Button
            variant="contained"
            disableElevation={true}
            onClick={() => {
              Navigation.toSignUp({
                navigate: navigate,
                location: undefined,
                params: undefined,
              });
            }}
            sx={{
              width: {
                sm: PixelsToViewPortUnitConverter.getvw(377),
                xs: "100%",
              },
              height: {
                sm: PixelsToViewPortUnitConverter.getvw(54),
                xs: PixelsToViewPortUnitConverter.getvw(130),
              },
              marginTop: PixelsToViewPortUnitConverter.getvw(36),
              fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
              fontFamily: "Bebas",
              fontWeight: "400",
            }}
          >
            sign up
          </Button>
          <Stack
            direction="row"
            sx={{
              width: {
                sm: PixelsToViewPortUnitConverter.getvw(373),
                xs: "100%",
              },
              justifyContent: "center",
              alignItems: "center",
              marginTop: PixelsToViewPortUnitConverter.getvw(14),
              flexDirection: {
                sm: "row",
                xs: "column",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
                fontFamily: "Barlow",
                fontWeight: "400",
                color: (theme) => theme.palette.primary.contrastText,
                letterSpacing: "0.1em",
              }}
            >
              Already have an account?
            </Typography>
            <Button
              onClick={() => {
                if (props.isMobile) {
                  navigate(AppRoutes.LOGIN);
                } else {
                  setShowLogin(true);
                }
              }}
              variant="text"
              sx={{
                fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
              }}
            >
              Log in
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  function rightTickMsg(msg: string) {
    return (
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          marginTop: PixelsToViewPortUnitConverter.getvw(20),
        }}
      >
        <PlainRightTick
          viewBox="0 0 31 24"
          sx={{
            fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
            color: (theme) => theme.palette.primary.contrastText,
          }}
        />
        <Typography
          sx={{
            fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
            fontFamily: "Barlow",
            fontWeight: "400",
            color: (theme) => theme.palette.primary.contrastText,
            marginLeft: PixelsToViewPortUnitConverter.getvw(22),
            letterSpacing: "0.1em",
          }}
        >
          {msg}
        </Typography>
      </Stack>
    );
  }
}
export default withMediaQuery(OrderConfirmation);
