import Filter from './Filter';
import AppliedFilter from './AppliedFilter';
import FilterUtils from '../utils/FilterUtils';
import FilterAttribute from './FilterAttribute';
import { FilterType } from './Filter';
import SortingList from './SortingList';
export default class FilterList {

    private mProductsCount: number;
    public get productsCount() {
        return this.mProductsCount;
    }

    // products
    private mFilters: Filter[];
    public get filters() {
      return this.mFilters;
    }

    // applied filters to show on UI
    private mAppliedFiltersList: AppliedFilter[];
    public get appliedFiltersList() {
      return this.mAppliedFiltersList;
    }

    // applied filters to show on UI
    private mDummyAppliedFiltersList: AppliedFilter[];
    public get dummyAppliedFiltersList() {
      return this.mDummyAppliedFiltersList;
    }

    // temp applied filters
    private mTempAppliedFilterList: AppliedFilter[];
    public get tempAppliedFilterList() {
      return this.mTempAppliedFilterList;
    }

    private mSortingList: SortingList;
    public get sortingList() {
      return this.mSortingList;
    }

    public setAppliedFilters(list: AppliedFilter[]) {
      this.mAppliedFiltersList = list
    }

    public setSortingList(sortingList: SortingList) {
      this.mSortingList = sortingList
    }

    public setTempAppliedFilterList(tempList: AppliedFilter[]) {
      this.mTempAppliedFilterList = tempList
    }

    public constructor() {
      this.mFilters = []
      this.mAppliedFiltersList = []
      this.mDummyAppliedFiltersList = []
      this.mTempAppliedFilterList = []
    }

    public init() {

    }

    public static defaultFilterList(filterQueryParams: any): FilterList {
      const filterList: FilterList = new FilterList()
      filterList.init()
      filterList.mTempAppliedFilterList = AppliedFilter.list(filterQueryParams)
      filterList.mSortingList = SortingList.list(filterQueryParams)
      return filterList
    }

    public initWithData(results: any, appliedFiltersList: AppliedFilter[], sortingList: SortingList) {
      // console.log('initWithData :'+JSON.stringify(results))
      let masterFilterResults = undefined
      let responseFilterArray = []
      if(results.results && Array.isArray(results.results) && results.results.length > 0) {
        responseFilterArray = results.results
        masterFilterResults = responseFilterArray[0]
      } else if(results.index) {
        masterFilterResults = results
      }
      
      if(masterFilterResults) {
        // master filter List
        this.mProductsCount = masterFilterResults.nbHits
        this.mFilters = Filter.list(masterFilterResults,appliedFiltersList)

        const appliedFiltersInfoList = FilterUtils.getAppliedFiltersForUI(this.mFilters,appliedFiltersList) //refined for UI to display valid filters
        this.mAppliedFiltersList = appliedFiltersInfoList.appliedFilters
        this.mDummyAppliedFiltersList = appliedFiltersInfoList.dummyAppliedFilters
      }

      // console.log('this.mFilters =>'+JSON.stringify(this.mFilters))
      // refined sub filter queries
      let i = 0
      responseFilterArray.forEach((filter: any) => {
        if(i > 0) {
          // filter
          const tempFilters = Filter.list(filter,appliedFiltersList)
          if(tempFilters && tempFilters.length > 0) {
            const newFilter = tempFilters[0]
            if(this.mFilters.length > 0) {
              // check the new filter in master filter list
              const filterFound = this.mFilters.find((item: Filter)=> item.type === newFilter.type)
              if(filterFound) {
                // if found replace the new attributes
                if(filterFound.filterAttributes) {
                  filterFound.setFilterAttributes(newFilter.filterAttributes)
                } else if(filterFound.rangeAttribute) {
                  filterFound.setRangeAttribute(newFilter.rangeAttribute)
                }
              } else {
                // not found in master list [0] index. Lets add it.
                this.mFilters.push(newFilter)
                const appliedFiltersInfoList = FilterUtils.getAppliedFiltersForUI(this.mFilters,appliedFiltersList) //refined for UI to display valid filters
                this.mAppliedFiltersList = appliedFiltersInfoList.appliedFilters
                this.mDummyAppliedFiltersList = appliedFiltersInfoList.dummyAppliedFilters
              }
            } else {
              // empty filters received in master filters
              this.mFilters = tempFilters
              const appliedFiltersInfoList = FilterUtils.getAppliedFiltersForUI(this.mFilters,appliedFiltersList) //refined for UI to display valid filters
              this.mAppliedFiltersList = appliedFiltersInfoList.appliedFilters
              this.mDummyAppliedFiltersList = appliedFiltersInfoList.dummyAppliedFilters
            }
            
          }
        }
        i++
      })

      // Insert dummy filters into Filters Attributes list for valid items only
      this.mDummyAppliedFiltersList.forEach((appliedFilter: any) => {
          const dummyAttribute = FilterAttribute.dummyFilterAttribute(appliedFilter)
          if(dummyAttribute) {
            const filterObj = this.mFilters.find((item: Filter)=> item.slug === dummyAttribute.filterSlug)
            if(filterObj) {
              if(!dummyAttribute.isChild) {
                let existingAttributes = [...filterObj.filterAttributes]
                if(existingAttributes) {
                  existingAttributes.push(dummyAttribute)
                  filterObj.setFilterAttributes(existingAttributes)
                }
              } else {
                let existingAttributes = [...filterObj.filterAttributes]
                if(existingAttributes) {
                  const parentAttribute = existingAttributes.find((item: FilterAttribute)=> item.slug === dummyAttribute.parentSlug)
                  if(parentAttribute) {
                    let existingSubAttributes = [...parentAttribute.subAttributes]
                    if(existingSubAttributes) {
                      existingSubAttributes.push(dummyAttribute)
                      parentAttribute.setSubFilterAttributes(existingSubAttributes)
                    }
                  }
                }
              }
            }
          }
      })
      this.mSortingList = (sortingList) ? sortingList : SortingList.list(undefined)

      // Apply sorting based on rank of filter.
      if(this.mFilters.length > 0) {
        this.mFilters.sort((a, b) => (a.rank > b.rank ? 1 : -1))
      }

      // console.log('FINAL appliedFiltersList UI appliedFilters:'+JSON.stringify(this.mAppliedFiltersList))
      // console.log('FINAL appliedFiltersList UI dummyappliedFilters:'+JSON.stringify(this.mDummyAppliedFiltersList))
      // console.log('initWithData END :'+JSON.stringify(this))
    }

    public getSearchQueryParameter(): string {
      const searchQueryParam = FilterUtils.getSearchQueryParameterFilter(this.appliedFiltersList)
      return searchQueryParam
    }
}