import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CrossIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 15 15"
    return (
        <SvgIcon {...uProps}>
            <line x1="4" y1="8.65708" x2="9.65685" y2="3.00022" strokeWidth="2" strokeLinecap="round"/>
            <line x1="9.65708" y1="9.07129" x2="4.00022" y2="3.41443" strokeWidth="2" strokeLinecap="round"/>
        </SvgIcon>
    );
}