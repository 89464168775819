import Photo from "./Photo";
import Weight, { WeightType } from "./Weight";
import TextUtils from "../utils/TextUtils";
import Effect from "./Effect";
import AppRoutes from "../../routes";

export interface CartProduct {
  productId: number;
  priceId: string;
  count: number;
}

export default class Product {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mName: string;
  public get name() {
    return this.mName;
  }

  private mDescription: string;
  public get description() {
    return this.mDescription;
  }

  private mMaxCartQuantity: number;
  public get maxCartQuantity() {
    return this.mMaxCartQuantity;
  }

  private mAllowMultipleFlowerCount: boolean;
  public get allowMultipleFlowerCount() {
    return this.mAllowMultipleFlowerCount;
  }

  private mAggregateRating: number;
  public get aggregateRating() {
    return this.mAggregateRating;
  }

  private mBucketPrice: number;
  public get bucketPrice() {
    return this.mBucketPrice;
  }

  private mBrand: string;
  public get brand() {
    return this.mBrand;
  }

  private mBrandSubType: string;
  public get brandSubType() {
    return this.mBrandSubType;
  }

  private mLineage: string;
  public get lineage() {
    return this.mLineage;
  }

  private mKind: string;
  public get kind() {
    return this.mKind;
  }

  private mKindSubType: string;
  public get kindSubType() {
    return this.mKindSubType;
  }

  private mRootSubType: string;
  public get rootSubType() {
    return this.mRootSubType;
  }

  private mCustomProductType: string;
  public get customProductType() {
    return this.mCustomProductType;
  }

  private mReviewCount: number;
  public get reviewCount() {
    return this.mReviewCount;
  }

  private mAvailableForDelivery: boolean;
  public get availableForDelivery() {
    return this.mAvailableForDelivery;
  }

  private mAvailableForPickup: boolean;
  public get availableForPickup() {
    return this.mAvailableForPickup;
  }

  private mObjectId: string;
  public get objectId() {
    return this.mObjectId;
  }

  private mApplicableSpecialIDs: number[];
  public get applicableSpecialIDs() {
    return this.mApplicableSpecialIDs;
  }

  private mSpecialId: number;
  public get specialId() {
    return this.mSpecialId;
  }

  private mSpecialTitle: string;
  public get specialTitle() {
    return this.mSpecialTitle;
  }

  private mSpecialAmount: string;
  public get specialAmount() {
    return this.mSpecialAmount;
  }

  private mWeights: Weight[];
  public get weights() {
    return this.mWeights;
  }

  private mPhotos: Photo[];
  public get photos() {
    return this.mPhotos;
  }

  private mImagesUrls: string[];
  public get imageUrls() {
    return this.mImagesUrls;
  }

  private mDefaultWeightTitle: string;
  public get defaultWeightTitle() {
    return this.mDefaultWeightTitle;
  }

  private mDefaultSellingPrice: string;
  public get defaultSellingPrice() {
    return this.mDefaultSellingPrice;
  }

  private mDefaultMrp: string;
  public get defaultMrp() {
    return this.mDefaultMrp;
  }

  private mEffects: Effect[];
  public get effects() {
    return this.mEffects;
  }

  private mFeelings: Effect[];
  public get feelings() {
    return this.mFeelings;
  }

  private mActivities: string[];
  public get activities() {
    return this.mActivities;
  }

  private mFlavors: string[];
  public get flavors() {
    return this.mFlavors;
  }

  private mPercentCBD: number;
  public get percentCBD() {
    return this.mPercentCBD;
  }

  private mPercentTHC: number;
  public get percentTHC() {
    return this.mPercentTHC;
  }

  private mUrlSlug: string;
  public get urlSlug() {
    return this.mUrlSlug;
  }

  public getFallbackImageUrl(width?: number): string {
    let img_url = null;
    const finalWidth = width ? width : 250;
    const cloudfarePath = `cdn-cgi/image/width=${finalWidth},fit=scale-down,format=auto,metadata=none`;
    let kind = this.removeUnusedCharacters(this.kind);
    let lineage = this.removeUnusedCharacters(this.lineage);
    if (!TextUtils.isEmpty(kind) && !TextUtils.isEmpty(lineage)) {
      img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/${kind}/${lineage}.png`;
    } else if (!TextUtils.isEmpty(kind)) {
      img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/general/${kind}.png`;
    }
    return img_url;
  }

  public getCardDetailFallbackImageUrl(width?: number): string {
    let img_url = null;
    const finalWidth = width ? width : 465;
    const cloudfarePath = `cdn-cgi/image/width=${finalWidth},fit=scale-down,format=auto,metadata=none`;
    let kind = this.removeUnusedCharacters(this.kind);
    let lineage = this.removeUnusedCharacters(this.lineage);
    if (!TextUtils.isEmpty(kind) && !TextUtils.isEmpty(lineage)) {
      img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/${kind}/${lineage}.png`;
    } else if (!TextUtils.isEmpty(kind)) {
      img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/general/${kind}.png`;
    }
    return img_url;
  }

  public getProductCardImageUrl(width?: number): string {
    let img_url = null;
    const finalWidth = width ? width : 250;
    const cloudfarePath = `cdn-cgi/image/width=${finalWidth},fit=scale-down,format=auto,metadata=none`;
    if (this.mPhotos.length > 0) {
      const photo = this.mPhotos[0];
      img_url = photo.original as string;
      const url = new URL(img_url);
      const hostUrl = url.protocol + "//" + url.host + AppRoutes.ROOT;
      const updateUrl = hostUrl + cloudfarePath + AppRoutes.ROOT;
      img_url = img_url.replace(hostUrl, updateUrl);
    } else {
      let kind = this.removeUnusedCharacters(this.kind);
      let rooSubType = this.removeUnusedCharacters(this.rootSubType);
      if (!TextUtils.isEmpty(kind) && !TextUtils.isEmpty(rooSubType)) {
        img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/${kind}/${rooSubType}.png`;
      } else if (!TextUtils.isEmpty(kind)) {
        img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/general/${kind}.png`;
      }
    }
    return img_url;
  }

  public getProductDetailCardImageUrl(width?: number): string {
    let img_url = null;
    const finalWidth = width ? width : 465;
    const cloudfarePath = `cdn-cgi/image/width=${finalWidth},fit=scale-down,format=auto,metadata=none`;
    if (this.mPhotos.length > 0) {
      const photo = this.mPhotos[0];
      img_url = photo.original as string;
      const url = new URL(img_url);
      const hostUrl = url.protocol + "//" + url.host + AppRoutes.ROOT;
      const updateUrl = hostUrl + cloudfarePath + AppRoutes.ROOT;
      img_url = img_url.replace(hostUrl, updateUrl);
    } else {
      let kind = this.removeUnusedCharacters(this.kind);
      let rooSubType = this.removeUnusedCharacters(this.rootSubType);
      if (!TextUtils.isEmpty(kind) && !TextUtils.isEmpty(rooSubType)) {
        img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/${kind}/${rooSubType}.png`;
      } else if (!TextUtils.isEmpty(kind)) {
        img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/general/${kind}.png`;
      }
    }
    return img_url;
  }

  public getSmallImageUrlWithHeight(width: number, height: number): string {
    let img_url = null;
    // img_url = 'https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=200,fit=scale-down,format=auto,metadata=none/photos/e2/ce/e2cedd7e-7996-449b-a48f-8b2fa329da58.jpeg'
    // return img_url
    // background=%23F6F0E4
    const cloudfarePath = `cdn-cgi/image/width=${width},height=${height},fit=pad,format=auto,metadata=none`;
    if (this.mPhotos.length > 0) {
      const photo = this.mPhotos[0];
      img_url = photo.original as string;
      const url = new URL(img_url);
      const hostUrl = url.protocol + "//" + url.host + AppRoutes.ROOT;
      const updateUrl = hostUrl + cloudfarePath + AppRoutes.ROOT;
      img_url = img_url.replace(hostUrl, updateUrl);
    } else {
      let kind = this.removeUnusedCharacters(this.kind);
      let rooSubType = this.removeUnusedCharacters(this.rootSubType);
      if (!TextUtils.isEmpty(kind) && !TextUtils.isEmpty(rooSubType)) {
        img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/${kind}/${rooSubType}.png`;
      } else if (!TextUtils.isEmpty(kind)) {
        img_url = `https://product-assets.iheartjane.com/${cloudfarePath}/default-photos/general/${kind}.png`;
      }
    }
    return img_url;
  }

  public getPriceInfo(): string {
    let priceInfo = "";
    let wObject: Weight = this.getSelectedWeight();
    if (wObject) {
      const sellingP = Number(wObject.sellingPrice).toFixed(2);
      priceInfo = `$${sellingP} / ${wObject.name}`;
      if (wObject.type.toString() == "EACH") {
        priceInfo = `$${sellingP}`;
      }
    }
    return priceInfo;
  }

  public getSelectedWeight(): Weight | undefined {
    let wObject: Weight = Weight.getDefaultWeight(this.weights);
    return wObject;
  }

  public getSelectedWeightByPriceId(priceId: string): Weight | undefined {
    let wObject: Weight = Weight.getWeightByPriceID(priceId, this.weights);
    return wObject;
  }

  public getWeightsForProductCard(): Weight[] {
    // const noOfWeightsTobeVisible = 2
    let arrOfWeights = []; //[...this.weights]

    let wObject: Weight = this.getSelectedWeight();
    if (wObject) {
      arrOfWeights.push(wObject);
      const filteredArr = this.weights.filter(
        (w: Weight) => w.priceId !== wObject.priceId
      );
      if (filteredArr && filteredArr.length > 0) {
        arrOfWeights.push(filteredArr[0]);
      }
    }

    if (arrOfWeights.length > 0) {
      arrOfWeights.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    }

    /*if(this.weights.length >= noOfWeightsTobeVisible) {
			arrOfWeights = arrOfWeights.slice(0,noOfWeightsTobeVisible)
		}*/
    return arrOfWeights;
  }

  public getAllWeightsForProductCard(): Weight[] {
    // const noOfWeightsTobeVisible = 2
    let arrOfWeights = []; //[...this.weights]

    let wObject: Weight = this.getSelectedWeight();
    if (wObject) {
      arrOfWeights.push(wObject);
      const filteredArr = this.weights.filter(
        (w: Weight) => w.priceId !== wObject.priceId
      );
      if (filteredArr && filteredArr.length > 0) {
        arrOfWeights = arrOfWeights.concat(filteredArr);
        // arrOfWeights.push(filteredArr[0])
      }
    }

    if (arrOfWeights.length > 0) {
      arrOfWeights.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    }

    /*if(this.weights.length >= noOfWeightsTobeVisible) {
			arrOfWeights = arrOfWeights.slice(0,noOfWeightsTobeVisible)
		}*/
    return arrOfWeights;
  }

  public getDefaultMrp(): string {
    let wObject: Weight = Weight.getDefaultWeight(this.weights);
    if (wObject) {
      return wObject.getMrp();
    }
    return "";
  }

  public getDefaultSellingPrice(): string {
    let wObject: Weight = Weight.getDefaultWeight(this.weights);
    if (wObject) {
      return wObject.getSellingPrice();
    }
    return "";
  }

  public getDefaultWeightTitle(): string {
    let wObject: Weight = this.getSelectedWeight();
    if (wObject) {
      return wObject.name;
    }
    return "";
  }

  public constructor() {}

  public init(item: any) {
    this.mId = item.product_id;
    this.mName = item.name;
    this.mDescription = item.description;
    this.mMaxCartQuantity = item.max_cart_quantity;
    this.mAllowMultipleFlowerCount = item.allow_multiple_flower_count;
    this.mAggregateRating = item.aggregate_rating;
    this.mBucketPrice = item.bucket_price;
    this.mBrand = item.brand;
    this.mBrandSubType = item.brand_subtype;
    this.mLineage = item.category;
    this.mKind = item.kind;
    this.mKindSubType = item.kind_subtype;
    this.mRootSubType = item.root_subtype;
    this.mCustomProductType = item.custom_product_type;
    this.mReviewCount = item.review_count;
    this.mAvailableForDelivery = item.available_for_delivery;
    this.mAvailableForPickup = item.available_for_pickup;
    this.mObjectId = item.objectID;
    this.mApplicableSpecialIDs = item.applicable_special_ids;
    this.mSpecialId = item.special_id;
    this.mSpecialTitle = item.special_title;
    this.mSpecialAmount = item.special_amount;
    this.mWeights = Weight.list(item);
    this.mDefaultMrp = this.getDefaultMrp();
    this.mDefaultSellingPrice = this.getDefaultSellingPrice();
    this.mDefaultWeightTitle = this.getDefaultWeightTitle();
    this.mEffects = Effect.productEffectList(item.effects);
    this.mFeelings = Effect.productEffectList(item.feelings);
    this.mActivities = item.activities;
    this.mUrlSlug = item.url_slug;
    this.mPercentCBD =
      item.percent_cbd && item.percent_cbd > 0 ? item.percent_cbd : undefined;
    this.mPercentTHC =
      item.percent_thc && item.percent_thc > 0 ? item.percent_thc : undefined;
    if (Array.isArray(item.photos) && item.photos.length > 0) {
      this.mPhotos = Photo.list(item.photos);
    } else if (
      Array.isArray(item.product_photos) &&
      item.product_photos.length > 0
    ) {
      this.mPhotos = Photo.list(item.product_photos);
    } else {
      this.mPhotos = [];
    }

    this.mImagesUrls = item.image_urls;
    this.mFlavors = item.flavors;
  }

  public static list(items: any): Product[] {
    let finalArray: Product[] = [];
    const pArray = items;
    const length = pArray.length;
    for (var i = 0; i < length; i++) {
      const product = pArray[i];
      let pObject = new Product();
      pObject.init(product);
      finalArray.push(pObject);
    }
    return finalArray;
  }

  public getDiscountPrice(): string | undefined {
    if (this.mDefaultMrp !== this.mDefaultSellingPrice) {
      return this.mDefaultMrp;
    }
    return undefined;
  }

  public removeUnusedCharacters(str: string) {
    let newStr = str;
    if (!TextUtils.isEmpty(newStr)) {
      newStr = newStr.replaceAll(" ", "");
      newStr = newStr.replaceAll(AppRoutes.ROOT, "");
      newStr = newStr.replaceAll(":", "");
      newStr = newStr.replaceAll("-", "");
      newStr = newStr.toLowerCase();
    }
    return newStr;
  }
}

/*
{
		"store_notes": "",
		"_geoloc": {
			"lat": 36.9863034,
			"lng": -122.0325797
		},
		"allergens": [],
		"amount": "",
		"business_licenses": [],
		"cannabinoids": [],
		"dosage": "",
		"effects": [],
		"flavors": [],
		"image_urls": [],
		"ingredients": [],
		"percent_cbd": 0.0,
		"percent_cbda": 0.0,
		"percent_thc": 0.0,
		"percent_thca": 0.0,
		"percent_tac": 0.0,
		"photos": [],
		"has_photos": false,
		"has_thc_potency": false,
		"product_id": 1,
		"product_percent_cbd": 0.11,
		"product_percent_thc": 16.0,
		"product_photos": [],
		"custom_product_subtype": "",
		"sort_price": 9.26,
		"store_specific_product": false,
		"store_types": ["recreational"],
		"terpenes": [],
		"type": "hybrid",
		"url_slug": "abe-s-new-brand-blue-dream-up",
		"inventory_potencies": [],
		"operator_store_rank": 49,
		"photo_weights": [],
		"feelings": ["Relaxed", "Blissful", "Creative", "Sleepy", "Energetic", "Not high", "Pain free", "Hungry"],
		"activities": ["Stimulate my mind", "Get some sleep", "Hang with friends", "Get relief", "I don't know"],
		"root_types": ["flower", "sale", "best_selling"],
		"_highlightResult": {
			"store_notes": {
				"value": "",
				"matchLevel": "none",
				"matchedWords": []
			},
			"brand": {
				"value": "Abe's New Brand",
				"matchLevel": "none",
				"matchedWords": []
			},
			"category": {
				"value": "sativa",
				"matchLevel": "none",
				"matchedWords": []
			},
			"description": {
				"value": "Blue Dream is a legendary Sativa-Dominant Hybrid consisting of Blueberry Indica and Sativa Haze. Developed in Northern California, it has a fruity floral taste and is ideal for daytime or early evening use because consumers generally feel euphoric and relaxed while still maintaining mental clarity. This is the \"Gold Standard\" by which all other strains are measured. A Change ",
				"matchLevel": "none",
				"matchedWords": []
			},
			"name": {
				"value": "Blue Dream Up",
				"matchLevel": "none",
				"matchedWords": []
			},
			"root_types": [{
				"value": "flower",
				"matchLevel": "none",
				"matchedWords": []
			}, {
				"value": "sale",
				"matchLevel": "none",
				"matchedWords": []
			}, {
				"value": "best_selling",
				"matchLevel": "none",
				"matchedWords": []
			}]
		}
	}
*/

/*
each
{
		"max_cart_quantity": 5,
		"allow_multiple_flower_count": true,
		"store_notes": "",
		"_geoloc": {
			"lat": 36.9863034,
			"lng": -122.0325797
		},
		"aggregate_rating": 0,
		"allergens": [],
		"amount": ".5ml",
		"available_weights": [],
		"brand_subtype": "Tincture 123",
		"brand": "Dr. Solomon's",
		"bucket_price": 50,
		"business_licenses": [],
		"cannabinoids": [],
		"category": "cbd",
		"description": "123 Dr. Solomon’s Unwind CBD dominant and THC cannabis extract with comforting essential oils. Pop it under your tongue or add it to your favorite food or beverage, and give discomfort the unfriendly finger. Contains grapeseed oil, orange and comforting cinnamon because we like to treat you nice.  \r\n--\r\n0.5 mL (300mg)  CDB:THC 10:1\r\n- Price/Unit: $50\r\n- 30 Day Supply Price: $50\r\n- 50 Day Supply Price: $85\r\n- 70 Day Supply Price: $165",
		"dosage": "300mg",
		"effects": [],
		"flavors": [],
		"image_urls": ["https://staging-product-assets.iheartjane.com/photos/e4/06/e406fc47-509e-4c2d-a390-cce90c0a2a1a.png", "https://staging-product-assets.iheartjane.com/photos/08/95/08954610-0d37-4604-b982-87ff1fdd728b.png"],
		"ingredients": [],
		"kind_subtype": "Tincture 123",
		"kind": "tincture",
		"custom_product_type": "tincture",
		"name": "10:1 Unwind (300mg) 123",
		"percent_cbd": 0,
		"percent_cbda": 0,
		"percent_thc": 0,
		"percent_thca": 0,
		"percent_tac": 0,
		"photos": [],
		"has_photos": false,
		"has_thc_potency": false,
		"product_id": 229349,
		"product_percent_cbd": 0,
		"product_percent_thc": 0,
		"product_photos": [{
			"id": "https://staging-product-assets.iheartjane.com/photos/e4/06/e406fc47-509e-4c2d-a390-cce90c0a2a1a.png",
			"position": 0,
			"urls": {
				"original": "https://staging-product-assets.iheartjane.com/photos/e4/06/e406fc47-509e-4c2d-a390-cce90c0a2a1a.png",
				"small": "https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=174,fit=scale-down,format=auto,metadata=none/photos/e4/06/e406fc47-509e-4c2d-a390-cce90c0a2a1a.png",
				"medium": "https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=327,fit=scale-down,format=auto,metadata=none/photos/e4/06/e406fc47-509e-4c2d-a390-cce90c0a2a1a.png",
				"extraLarge": "https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=1000,fit=scale-down,format=auto,metadata=none/photos/e4/06/e406fc47-509e-4c2d-a390-cce90c0a2a1a.png"
			}
		}, {
			"id": "https://staging-product-assets.iheartjane.com/photos/08/95/08954610-0d37-4604-b982-87ff1fdd728b.png",
			"position": 1,
			"urls": {
				"original": "https://staging-product-assets.iheartjane.com/photos/08/95/08954610-0d37-4604-b982-87ff1fdd728b.png",
				"small": "https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=174,fit=scale-down,format=auto,metadata=none/photos/08/95/08954610-0d37-4604-b982-87ff1fdd728b.png",
				"medium": "https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=327,fit=scale-down,format=auto,metadata=none/photos/08/95/08954610-0d37-4604-b982-87ff1fdd728b.png",
				"extraLarge": "https://staging-product-assets.iheartjane.com/cdn-cgi/image/width=1000,fit=scale-down,format=auto,metadata=none/photos/08/95/08954610-0d37-4604-b982-87ff1fdd728b.png"
			}
		}],
		"review_count": 0,
		"root_subtype": "Sublinguals",
		"custom_product_subtype": "Sublinguals",
		"sort_price": 50,
		"special_amount": null,
		"special_id": null,
		"special_title": null,
		"store_specific_product": false,
		"store_types": ["recreational"],
		"terpenes": [],
		"type": "tincture",
		"url_slug": "dr-solomon-s-10-1-unwind-300mg-123",
		"inventory_potencies": [],
		"operator_store_rank": 49,
		"photo_weights": [],
		"feelings": [],
		"activities": [],
		"root_types": ["tincture", "tincture:Sublinguals"],
		"available_for_delivery": true,
		"available_for_pickup": true,
		"price_gram": null,
		"discounted_price_gram": null,
		"price_two_gram": null,
		"discounted_price_two_gram": null,
		"price_eighth_ounce": null,
		"discounted_price_eighth_ounce": null,
		"price_quarter_ounce": null,
		"discounted_price_quarter_ounce": null,
		"price_half_ounce": null,
		"discounted_price_half_ounce": null,
		"price_ounce": null,
		"discounted_price_ounce": null,
		"price_half_gram": null,
		"discounted_price_half_gram": null,
		"price_each": "50.0",
		"discounted_price_each": null,
		"applicable_special_ids": [],
		"objectID": "831099",
		"_highlightResult": {
			"store_notes": {
				"value": "",
				"matchLevel": "none",
				"matchedWords": []
			},
			"brand_subtype": {
				"value": "Tincture 123",
				"matchLevel": "none",
				"matchedWords": []
			},
			"brand": {
				"value": "Dr. Solomon's",
				"matchLevel": "none",
				"matchedWords": []
			},
			"category": {
				"value": "cbd",
				"matchLevel": "none",
				"matchedWords": []
			},
			"description": {
				"value": "123 Dr. Solomon’s Unwind CBD dominant and THC cannabis extract with comforting essential oils. Pop it under your tongue or add it to your favorite food or beverage, and give discomfort the unfriendly finger. Contains grapeseed oil, orange and comforting cinnamon because we like to treat you nice.  \r\n--\r\n0.5 mL (300mg)  CDB:THC 10:1\r\n- Price/Unit: $50\r\n- 30 Day Supply Price: $50\r\n- 50 Day Supply Price: $85\r\n- 70 Day Supply Price: $165",
				"matchLevel": "none",
				"matchedWords": []
			},
			"name": {
				"value": "10:1 Unwind (300mg) 123",
				"matchLevel": "none",
				"matchedWords": []
			},
			"root_types": [{
				"value": "tincture",
				"matchLevel": "none",
				"matchedWords": []
			}, {
				"value": "tincture:Sublinguals",
				"matchLevel": "none",
				"matchedWords": []
			}]
		}
	}
*/

/*
const productName = props.item.name
    const productRating = props.item.aggregate_rating
    let img_url = (props.item.image_urls && props.item.image_urls.length > 0) ? props.item.image_urls[0] : undefined
    if(!img_url) {
        img_url = `https://product-assets.iheartjane.com/cdn-cgi/image/height=200,fit=scale-down,format=auto,metadata=none/default-photos/${props.item.kind}/${props.item.category}.png`
    }
    const productPriceInfo = ProductUtils.getBucketPriceInfo(props.item)
    const def_price = productPriceInfo.price
    const pWeight = productPriceInfo.weight
    let priceInfo = `$${def_price} / ${pWeight}`
    if(pWeight === 'each') {
        priceInfo = `$${def_price}`
    }
*/
