import React from "react";
import { ReactNode, useState, useEffect } from "react";
import "./common-style.scss";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { ApiTestingComponent } from "./components/common/api_testing/ApiTestComponent";
import LandingComponent from "./components/common/landing/LandingComponent";
import EffectsHeader from "./components/themes/effects/header/Header";
import EffectsV2Header from "./components/themes/effectsv2/header/Header";
import EffectsFooter from "./components/themes/effects/footer/Footer";
import EffectsV2Footer from "./components/themes/effectsv2/footer/Footer";
import Box from "@mui/material/Box/Box";
import PartnerManager from "./core/utils/PartnerManager";
import { getTheme } from "./themes/Themes";
import { PartnerAppThemeType } from "./core/models/PartnerTheme";
import ProductListingComponent from "./components/common/menu_products/ProductListingComponent";
import withRouter from "./withRouter";
import { useLocation } from "react-router-dom";
import EffectsHomeComponent from "./components/themes/effects/home/EffectsHomeComponent";
import ProductDetailsComponent from "./components/common/productdetails/ProductDetailsComponent";
import EffectsV2HomeComponent from "./components/themes/effectsv2/home/EffectsV2HomeComponent";
import CartComponent from "./components/common/cart/CartComponent";
import CategoriesHomeComponent from "./components/themes/categories/home/CategoriesHomeComponent";
import AgeVerficationComponent from "./components/common/ageverification/AgeVerificationComponent";
import ProfileComponent from "./components/common/profile/ProfileComponent";
import CustomerSignUpComponent from "./components/common/signup/CustomerSignUpComponent";
import CustomerVerifyCode from "./components/common/verify_code/CustomerVerifyCode";
import MessageComponent from "./components/common/message/MessageComponent";
import PixelsToViewPortUnitConverter from "./core/utils/PixelsToViewPortUnitConverter";
import CustomerLoginComponent from "./components/common/login/CustomerLoginComponent";
import SearchComponent from "./components/common/search/SearchComponent";
// import GeoLocation from "./components/common/Geolocation/GeoLocation";
import AppRoutes from "./routes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DefaultSeoConfig } from "./seo/DefaultSeoConfig";

const ShopAll = withRouter(ProductListingComponent);

function App() {
  const [isInitialDataLoaded, setInitialDataLoadedFlag] = useState(false);

  const [showHeader, setShowHeader] = useState(false);

  const theme = React.useMemo(() => getTheme(), [isInitialDataLoaded]);

  const location = useLocation();

  // On window resize, reload the whole page to display proper UI.
  /*useEffect(() => {
    const handleResize = () => {
      window.location.reload();
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })*/

  const [isDesktop, setIsDesktop] = useState(null);
  const isMob = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    const shouldReload = isDesktop !== null ? true : false;
    setIsDesktop(!isMob);
    if (shouldReload) {
      window.location.reload();
    }
  }, [isMob]);

  useEffect(() => {}, [location]);

  return (
    <div className="App">
      <DefaultSeoConfig />
      <ThemeProvider theme={theme}>
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            // overflowX: {
            //   sm: "unset",
            //   xs: "hidden",
            // },
          }}
        >
          {isInitialDataLoaded && showHeader && loadThemeBasedHeader()}
          <Box
            sx={{
              flex: "1 0 auto",
              display: "flex",
              width: "100%",
            }}
          >
            <Routes>
              <Route
                path={AppRoutes.ROOT}
                element={loadRouteElement(() => loadThemeBasedHomeComponent())}
              />
              <Route
                path={AppRoutes.PRODUCTS}
                element={loadRouteElement(() => (
                  <ShopAll />
                ))}
              />
              <Route
                path={AppRoutes.PRODUCT_DETAILS}
                element={loadRouteElement(() => (
                  <ProductDetailsComponent />
                ))}
              />
              <Route
                path={AppRoutes.CART}
                element={loadRouteElement(() => (
                  <CartComponent />
                ))}
              />
              <Route
                path={AppRoutes.ACCOUNT}
                element={loadRouteElement(() => (
                  <ProfileComponent />
                ))}
              />
              <Route
                path={AppRoutes.LOGIN}
                element={loadRouteElement(() => (
                  <CustomerLoginComponent />
                ))}
              />
              <Route
                path={AppRoutes.SIGNUP}
                element={loadRouteElement(() => (
                  <CustomerSignUpComponent />
                ))}
              />
              <Route
                path={AppRoutes.VERIFY_CODE}
                element={loadRouteElement(() => (
                  <CustomerVerifyCode />
                ))}
              />
              <Route
                path={AppRoutes.SEARCH}
                element={loadRouteElement(() => (
                  <SearchComponent />
                ))}
              />
              <Route path={AppRoutes.API} element={<ApiTestingComponent />} />
              <Route
                path="*"
                element={loadRouteElement(() => (
                  <Box
                    position="relative"
                    sx={{
                      display: "flex",
                      alignSelf: "center",
                      marginX: "auto",
                      marginTop: {
                        xs: PixelsToViewPortUnitConverter.getvw(0),
                        sm: PixelsToViewPortUnitConverter.getvw(116),
                      },
                      height: { sm: "auto", xs: "calc(100vh - 72px)" },
                    }}
                  >
                    <MessageComponent
                      pTitle="Page not found"
                      pShowGoToHomeBtn={true}
                    />
                  </Box>
                ))}
              />
            </Routes>
          </Box>
          {isInitialDataLoaded && loadThemeBasedFooter()}
          {isInitialDataLoaded && <AgeVerficationComponent />}
        </Stack>
      </ThemeProvider>
    </div>
  );

  function loadRouteElement(elementToLoad: () => ReactNode) {
    return isInitialDataLoaded ? (
      elementToLoad()
    ) : (
      <LandingComponent
        onInitailDataLoadSccess={handleInitailDataLoadSccess}
        onInitailDataLoadingFailure={handleInitailDataLoadFailure}
      />
    );
  }

  function loadThemeBasedHeader() {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.EFFECTS:
      case PartnerAppThemeType.EFFECTS2:
        return <EffectsHeader pShowTransperentHeader={false} />;
      default:
        return <EffectsV2Header />;
    }
  }

  function loadThemeBasedHomeComponent() {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.EFFECTS2:
      case PartnerAppThemeType.EFFECTS:
        if (window.location.pathname.length > 1 && !showHeader)
          setShowHeader(true);

        if (window.location.pathname.length == 1 && showHeader)
          setShowHeader(false);
        return <EffectsHomeComponent />;
      case PartnerAppThemeType.EFFECTS1:
        return <EffectsV2HomeComponent />;
      case PartnerAppThemeType.CATEGORIES:
        return <CategoriesHomeComponent />;
      default:
        break;
    }

    return null;
  }

  function loadThemeBasedFooter() {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.EFFECTS:
        return <EffectsFooter />;
      default:
        return <EffectsV2Footer />;
    }
  }

  function handleInitailDataLoadSccess() {
    setInitialDataLoadedFlag(true);
    setShowHeader(
      PartnerManager.shared().partner.theme.name !=
        PartnerAppThemeType.EFFECTS || window.location.pathname.length > 1
    );
  }

  function handleInitailDataLoadFailure() {
    setInitialDataLoadedFlag(false);
  }
}
export default App;
