import SvgIcon from "@mui/material/SvgIcon";
export default function LeftNavIcon(props) {
  return (
    <SvgIcon {...props}>
      <ellipse
        xmlns="http://www.w3.org/2000/svg"
        cx="45.0495"
        cy="45"
        rx="45.0495"
        ry="45"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M51.0564 31L37.041 45L51.0564 59"
        stroke="white"
        strokeWidth="6"
      />
    </SvgIcon>
  );
}
