import {
  Box,
  Collapse,
  ListItem,
  Stack,
  Typography,
  List,
  Button,
  styled,
} from "@mui/material";
import React from "react";
import Reservation from "../../../core/models/Reservation";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { alpha } from "@mui/material/styles";
import TextUtils from "../../../core/utils/TextUtils";
import ExpandLess from "../../../statics/svgs/ExpandLess";
import ExpandMore from "../../../statics/svgs/ExpandMore";
import { weightDisplayMapping } from "../../../core/models/Weight";
import StrikeIconV2 from "../../../statics/svgs/StrikeIconV2";
import DateUtils from "../../../core/utils/DateUtils";

interface IReservationListItemProps {
  pReservation: Reservation;
  pShowInExpandMode: boolean;
}

interface IReservationListItemState {
  sIsExpanded: boolean;
}

const OrderDetailsFieldItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
  fontFamily: "Barlow",
  fontWeight: "600",
  color: alpha(theme.palette.primary.contrastText, 0.4),
  letterSpacing: "0.1em",
  marginBottom: PixelsToViewPortUnitConverter.getvw(10),
}));

const OrderDetailsFieldValueItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
  fontFamily: "Barlow",
  fontWeight: "600",
  color: theme.palette.primary.contrastText,
  letterSpacing: "0.1em",
  marginBottom: PixelsToViewPortUnitConverter.getvw(10),
  textTransform: "capitalize",
}));

const ReceiptFieldItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
  fontFamily: "Barlow",
  fontWeight: "600",
  color: alpha(theme.palette.primary.contrastText, 0.4),
  letterSpacing: "0.1em",
  marginBottom: PixelsToViewPortUnitConverter.getvw(6),
}));

const ReceiptFieldValueItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
  fontFamily: "Barlow",
  fontWeight: "600",
  color: theme.palette.primary.contrastText,
  letterSpacing: "0.1em",
  textAlign: "right",
  marginBottom: PixelsToViewPortUnitConverter.getvw(6),
}));

const ExpandableButton = styled(Button)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
  fontFamily: "Bebas",
  fontWeight: "400",
  color: theme.palette.primary.contrastText,
  letterSpacing: "0.2em",
  marginLeft: "auto",
  marginRight: PixelsToViewPortUnitConverter.getvw(30),
  marginTop: PixelsToViewPortUnitConverter.getvw(12),
  marginBottom: PixelsToViewPortUnitConverter.getvw(12),
  padding: PixelsToViewPortUnitConverter.getvw(12),
}));

const ExpandReceiptFieldItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
  fontFamily: "Barlow",
  fontWeight: "500",
  color: alpha(theme.palette.primary.contrastText, 0.8),
  letterSpacing: "0.1em",
  textAlign: "right",
  marginTop: PixelsToViewPortUnitConverter.getvw(20),
}));

const ExpandReceiptFieldValueItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
  fontFamily: "Barlow",
  fontWeight: "700",
  color: alpha(theme.palette.primary.contrastText, 0.8),
  letterSpacing: "0.1em",
  marginTop: PixelsToViewPortUnitConverter.getvw(20),
  textAlign: "right",
}));

const ProductListItem = styled(Typography)(({ theme }) => ({
  fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
  fontFamily: "Barlow",
  fontWeight: "500",
  color: alpha(theme.palette.primary.contrastText, 0.8),
  letterSpacing: "0.1em",
  alignSelf: "center",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  lineClamp: "2",
  maxLines: "2",
}));

export default class ReservationListItem extends React.Component<
  IReservationListItemProps,
  IReservationListItemState
> {
  constructor(props) {
    super(props);
    this.state = {
      sIsExpanded: this.props.pShowInExpandMode,
    };
  }

  handleExpandableArrowClick = () => {
    this.setState((prevState) => ({
      sIsExpanded: !prevState.sIsExpanded,
    }));
  };

  render() {
    const { pReservation } = this.props;
    return (
      <Box
        sx={{
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.24),
          borderStyle: "solid",
          borderWidth: PixelsToViewPortUnitConverter.getvw(1),
          marginTop: PixelsToViewPortUnitConverter.getvw(40),
          paddingTop: {
            sm:PixelsToViewPortUnitConverter.getvw(0),
            xs:PixelsToViewPortUnitConverter.getvw(100),
          }
        }}
      >
        <ListItem key={pReservation.id} sx={{ padding: "0px" }}>
          <Stack sx={{ flex: 1 }}>
            <Stack
              direction="row"
              sx={{
                marginTop: PixelsToViewPortUnitConverter.getvw(16),
                marginLeft: PixelsToViewPortUnitConverter.getvw(50),
                marginRight: PixelsToViewPortUnitConverter.getvw(20),
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    sm:PixelsToViewPortUnitConverter.getFontVw(16),
                    xs:PixelsToViewPortUnitConverter.getFontVw(14),
                  },
                  fontFamily: "Barlow",
                  fontWeight: "600",
                  color: (theme) =>
                    alpha(theme.palette.primary.contrastText, 0.4),
                  letterSpacing: "0.1em",
                }}
              >
                {DateUtils.dateToDisplayDateFormatV2(
                  pReservation.checkedOutTime
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    sm:PixelsToViewPortUnitConverter.getFontVw(20),
                    xs:PixelsToViewPortUnitConverter.getFontVw(18),
                  },
                  fontFamily: "Bebas",
                  fontWeight: "400",
                  color: (theme) => theme.palette.primary.contrastText,
                  letterSpacing: "0.1em",
                  marginLeft: "auto",
                  paddingRight: {
                    sm:PixelsToViewPortUnitConverter.getvw(0),
                    xs:PixelsToViewPortUnitConverter.getvw(30),
                  }
                }}
              >
                {pReservation.status.replaceAll("_", " ")}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{
                marginTop: PixelsToViewPortUnitConverter.getvw(40),
                marginLeft: {
sm:PixelsToViewPortUnitConverter.getvw(50),
xs:PixelsToViewPortUnitConverter.getvw(100),
                },
                marginRight: PixelsToViewPortUnitConverter.getvw(100),

                display: "flex",
                flexDirection:{
                 sm:'row',
                 xs: 'column' 
                }, 
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                    fontFamily: "Bebas",
                    fontWeight: "400",
                    color: (theme) => theme.palette.primary.contrastText,
                    letterSpacing: "0.1em",
                  }}
                >
                  ORDER DETAILS
                </Typography>
                <Stack
                  direction="row"
                  sx={{ marginTop: PixelsToViewPortUnitConverter.getvw(20) }}
                >
                  <Stack sx={{
                    width:{
                      xs:'100%'
                    }
                  }}>
                    <OrderDetailsFieldItem>Order ID</OrderDetailsFieldItem>
                    <OrderDetailsFieldItem>Store Name</OrderDetailsFieldItem>
                    <OrderDetailsFieldItem>Mode</OrderDetailsFieldItem>
                  </Stack>
                  <Stack  sx={{
                    marginLeft:{
                      sm:PixelsToViewPortUnitConverter.getvw(50),
                      xs:PixelsToViewPortUnitConverter.getvw(300)
                    }
                       }} 
                   
                  >
                    <OrderDetailsFieldValueItem>
                      {pReservation.id}
                    </OrderDetailsFieldValueItem>
                    <OrderDetailsFieldValueItem>
                      {pReservation.storeName}
                    </OrderDetailsFieldValueItem>
                    <OrderDetailsFieldValueItem>
                      {pReservation.reservationMode}
                    </OrderDetailsFieldValueItem>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={{marginLeft:{
                sm:"auto",
                xs:''
              },
              paddingTop: {
                sm:PixelsToViewPortUnitConverter.getvw(0),
                xs:PixelsToViewPortUnitConverter.getvw(105),
              }
              
              
              
              }}>
                <Typography
                  sx={{
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                    fontFamily: "Bebas",
                    fontWeight: "400",
                    color: (theme) => theme.palette.primary.contrastText,
                    letterSpacing: "0.1em",
                  }}
                >
                  RECEIPT
                </Typography>
                <Stack
                    direction="row"
                    sx={{ marginTop: PixelsToViewPortUnitConverter.getvw(20) }}
                  >
                  <Stack sx={{
                    width:{
                      xs:'55%'
                    }
                  }} direction="column">
                    <ReceiptFieldItem>
                      {pReservation.reservationProducts.length}{" "}
                      {pReservation.reservationProducts.length > 1
                        ? "Items"
                        : "Item"}
                    </ReceiptFieldItem>
                    {this.props.pReservation.discount > 0 && (
                      <ReceiptFieldItem>Discount</ReceiptFieldItem>
                    )}
                    <ReceiptFieldItem>Sales Tax</ReceiptFieldItem>
                    <ReceiptFieldItem>Service Fee</ReceiptFieldItem>
                    {this.props.pReservation.deliveryFee > 0 && (
                      <ReceiptFieldItem>Delivery Fee</ReceiptFieldItem>
                    )}
                    <ReceiptFieldItem
                      sx={{
                        color: (theme) =>
                          alpha(theme.palette.primary.contrastText, 0.8),
                      }}
                    >
                      Estimated Total
                    </ReceiptFieldItem>
                  </Stack>
                  <Stack sx={{
                    marginLeft:{
                      sm:PixelsToViewPortUnitConverter.getvw(50),
                      xs:PixelsToViewPortUnitConverter.getvw(190)
                    }
                       }} 
                    
                  >
                    <ReceiptFieldValueItem>
                      {TextUtils.getPriceWithCurrencyUnit(
                        this.props.pReservation.subtotal()
                      )}
                    </ReceiptFieldValueItem>
                    {this.props.pReservation.discount > 0 && (
                      <ReceiptFieldValueItem>
                        {"- " +
                          TextUtils.getPriceWithCurrencyUnit(
                            this.props.pReservation.discount
                          )}
                      </ReceiptFieldValueItem>
                    )}
                    <ReceiptFieldValueItem>
                      {TextUtils.getPriceWithCurrencyUnit(
                        this.props.pReservation.salesTax
                      )}
                    </ReceiptFieldValueItem>
                    <ReceiptFieldValueItem>
                      {this.props.pReservation.isServiceFeeFree()
                        ? "Free"
                        : TextUtils.getPriceWithCurrencyUnit(
                            this.props.pReservation.serviceFee
                          )}
                    </ReceiptFieldValueItem>
                    {this.props.pReservation.deliveryFee > 0 && (
                      <ReceiptFieldValueItem>
                        {TextUtils.getPriceWithCurrencyUnit(
                          this.props.pReservation.deliveryFee
                        )}
                      </ReceiptFieldValueItem>
                    )}
                    <ReceiptFieldValueItem>
                      {TextUtils.getPriceWithCurrencyUnit(
                        this.props.pReservation.amount
                      )}
                    </ReceiptFieldValueItem>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box
              sx={{
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.contrastText, 0.24),
                height: PixelsToViewPortUnitConverter.getvw(1),
                marginLeft: PixelsToViewPortUnitConverter.getvw(50),
                marginRight: PixelsToViewPortUnitConverter.getvw(50),
                marginTop: PixelsToViewPortUnitConverter.getvw(20),
              }}
            />
            {!this.props.pShowInExpandMode && this.state.sIsExpanded ? (
              <ExpandableButton
                onClick={this.handleExpandableArrowClick}
                variant="text"
                sx={{ marginBottom: "0px" }}
                endIcon={
                  <ExpandLess
                    viewBox="0 0 18 7"
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                  />
                }
              >
                COLLAPSE
              </ExpandableButton>
            ) : (
              !this.props.pShowInExpandMode && (
                <ExpandableButton
                  onClick={this.handleExpandableArrowClick}
                  variant="text"
                  endIcon={
                    <ExpandMore
                      viewBox="0 0 18 7"
                      sx={{
                        color: (theme) => theme.palette.primary.contrastText,
                      }}
                    />
                  }
                >
                  SEE ALL
                </ExpandableButton>
              )
            )}
          </Stack>
        </ListItem>
        <Collapse
          key={pReservation.id + "collapse"}
          in={this.state.sIsExpanded}
          timeout="auto"
          unmountOnExit
        >
          <List key={pReservation.id + "collapse_list"}>
            {pReservation.reservationProducts.map((product) => {
              return (
                <ListItem
                  sx={{ padding: "0px" }}
                  key={pReservation.id + product.id}
                >
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      flex: 1,
                      borderWidth: "0px",
                      borderColor: (theme) =>
                        alpha(theme.palette.primary.contrastText, 0.12),
                      borderStyle: "solid",
                      paddingY: PixelsToViewPortUnitConverter.getvw(50),
                      marginX: PixelsToViewPortUnitConverter.getvw(50),
                      borderBottomWidth: PixelsToViewPortUnitConverter.getvw(1),
                    }}
                  >
                    <Stack sx={{ flexGrow: 1.7, flexBasis: 0 }}>
                      <ProductListItem>{product.brand}</ProductListItem>
                      <ProductListItem
                        sx={{
                          
                          fontSize:{
                            sm:PixelsToViewPortUnitConverter.getFontVw(20),
                            xs:PixelsToViewPortUnitConverter.getFontVw(10)
                            },

                          fontWeight: "700",
                          marginTop: PixelsToViewPortUnitConverter.getvw(12),
                        }}
                      >
                        {product.name}
                      </ProductListItem>
                    </Stack>
                    <ProductListItem
                      sx={{
                        flexGrow: 1,
                        textAlign: {
                        sm:"right",
                        xs:"left"
                        },
                        fontSize:{
                          sm:PixelsToViewPortUnitConverter.getFontVw(20),
                          xs:PixelsToViewPortUnitConverter.getFontVw(10)
                          },
                        flexBasis: 0,
                        textTransform: "capitalize",                      
                      }}
                    >
                      {product.category}
                    </ProductListItem>
                    <ProductListItem
                      sx={{
                        flexGrow: 1,
                        textAlign: {
                          sm:"right",
                          xs:"left"
                          },
                          fontSize:{
                            sm:PixelsToViewPortUnitConverter.getFontVw(20),
                            xs:PixelsToViewPortUnitConverter.getFontVw(10)
                            },
                        flexBasis: 0,
                      }}
                    >
                      {weightDisplayMapping[product.priceId]}
                    </ProductListItem>
                    <Stack
                      direction="row"
                      sx={{
                        flexGrow: 1,
                        flexBasis: 0,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display:
                            this.props.pReservation.discount > 0 &&
                            product.discountedPrice == product.regularPrice
                              ? "none"
                              : "flex",
                          opacity:
                            product.discountedPrice == product.regularPrice
                              ? 0
                              : 1,
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: PixelsToViewPortUnitConverter.getvw(6),
                        }}
                      >
                        <StrikeIconV2
                          sx={{
                            position: "absolute",
                            stroke: "red",
                            width: "100%",
                            height: PixelsToViewPortUnitConverter.getvw(16),
                          }}
                        />
                        <ProductListItem
                          sx={{
                                fontSize:{
                                sm:PixelsToViewPortUnitConverter.getFontVw(20),
                                xs:PixelsToViewPortUnitConverter.getFontVw(10)
                                },
                            color: (theme) =>
                              alpha(theme.palette.primary.contrastText, 0.35),
                          }}
                        >
                          {TextUtils.getPriceWithCurrencyUnit(
                            product.regularPrice
                          )}
                        </ProductListItem>
                      </Box>
                      <ProductListItem
                        sx={{
                          width: "auto",
                          fontSize:{
                            sm:PixelsToViewPortUnitConverter.getFontVw(20),
                            xs:PixelsToViewPortUnitConverter.getFontVw(10)
                            },
                          
                        }}
                      >
                        {TextUtils.getPriceWithCurrencyUnit(
                          product.discountedPrice
                        )}
                      </ProductListItem>
                    </Stack>
                    <ProductListItem
                      sx={{
                        flexGrow: 1,
                        flexBasis: 0,
                        fontSize:{
                          sm:PixelsToViewPortUnitConverter.getFontVw(20),
                          xs:PixelsToViewPortUnitConverter.getFontVw(10)
                          },
                      }}
                    >
                      {"×" + product.quntity}
                    </ProductListItem>
                    <ProductListItem
                      sx={{
                         fontSize:{
                                sm:PixelsToViewPortUnitConverter.getFontVw(20),
                                xs:PixelsToViewPortUnitConverter.getFontVw(10)
                                },
                        fontWeight: "700",
                        flexGrow: 1,
                        flexBasis: 0,
                      }}
                    >
                      {TextUtils.getPriceWithCurrencyUnit(
                        product.discountedPrice * product.quntity
                      )}
                    </ProductListItem>
                  </Stack>
                </ListItem>
              );
            })}
            <ListItem
              sx={{
                padding: "0px",
              }}
              key="footer"
            >
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flex: 5.2,
                    marginLeft: PixelsToViewPortUnitConverter.getvw(50),
                  }}
                >
                  <ExpandReceiptFieldItem sx={{textAlign:{xs:"left", sm:"right"}}}>SUBTOTAL</ExpandReceiptFieldItem>
                  {this.props.pReservation.discount > 0 && (
                    <ExpandReceiptFieldItem sx={{textAlign:{xs:"left",sm:"right"}}}>DISCOUNT</ExpandReceiptFieldItem>
                  )}
                  <ExpandReceiptFieldItem sx={{textAlign:{xs:"left",sm:"right"}}}>TAXES</ExpandReceiptFieldItem>
                  <ExpandReceiptFieldItem sx={{textAlign:{xs:"left",sm:"right"}}}>SERVICE FEE</ExpandReceiptFieldItem>
                  {this.props.pReservation.deliveryFee > 0 && (
                    <ExpandReceiptFieldItem sx={{textAlign:{xs:"left",sm:"right"}}}>
                      DELIVERY FEE
                    </ExpandReceiptFieldItem>
                  )}
                  <ExpandReceiptFieldItem sx={{textAlign:{xs:"left",sm:"right"}}}>
                    ESTIMATED TOTAL
                  </ExpandReceiptFieldItem>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flex: 1.5,
                    alignItems: "center",
                    marginRight: PixelsToViewPortUnitConverter.getvw(50),
                  }}
                >
                  <Stack>
                    <ExpandReceiptFieldValueItem>
                      {TextUtils.getPriceWithCurrencyUnit(
                        this.props.pReservation.subtotal()
                      )}
                    </ExpandReceiptFieldValueItem>
                    {this.props.pReservation.discount > 0 && (
                      <ExpandReceiptFieldValueItem>
                        {"- " +
                          TextUtils.getPriceWithCurrencyUnit(
                            this.props.pReservation.discount
                          )}
                      </ExpandReceiptFieldValueItem>
                    )}
                    <ExpandReceiptFieldValueItem>
                      {TextUtils.getPriceWithCurrencyUnit(
                        this.props.pReservation.salesTax
                      )}
                    </ExpandReceiptFieldValueItem>
                    <ExpandReceiptFieldValueItem>
                      {this.props.pReservation.isServiceFeeFree()
                        ? "Free"
                        : TextUtils.getPriceWithCurrencyUnit(
                            this.props.pReservation.serviceFee
                          )}
                    </ExpandReceiptFieldValueItem>
                    {this.props.pReservation.deliveryFee > 0 && (
                      <ExpandReceiptFieldValueItem>
                        {TextUtils.getPriceWithCurrencyUnit(
                          this.props.pReservation.deliveryFee
                        )}
                      </ExpandReceiptFieldValueItem>
                    )}
                    <ExpandReceiptFieldValueItem>
                      {TextUtils.getPriceWithCurrencyUnit(
                        this.props.pReservation.amount
                      )}
                    </ExpandReceiptFieldValueItem>
                  </Stack>
                </Stack>
              </Stack>
            </ListItem>
          </List>
        </Collapse>
      </Box>
    );
  }
}
