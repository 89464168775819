import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export default function NextIconV3(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 8 23"
    return (
        <SvgIcon {...uProps} fill="none">
            <path d="M1 22L6 11.5L1 1" stroke="black" strokeWidth="2"/>
        </SvgIcon>
    );
}

{/* <circle cx="19.5" cy="19.5" r="19" fill="#3A2D25" stroke="black"/>
<path d="M17 29L25 20L17 11" stroke="white"/> */}