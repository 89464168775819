import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
export default function BackIconV2(props: SvgIconProps) {
  var uProps = { ...props };
  uProps.viewBox = "0 0 26 20";
  return (
    <SvgIcon {...uProps} fill="none">
      <path d="M10 1L2 10L10 19" strokeWidth="2" />
      <path d="M2.5 10H26" strokeWidth="2" />
    </SvgIcon>
  );
}
