import React from "react";
// import "./FiltersContainer.scss";
import { FilterMenuItem } from "./FilterMenuItem";
import Filter, { FilterType } from "../../../../core/models/Filter";
import FilterAttribute from '../../../../core/models/FilterAttribute';
import RangeAttribute from '../../../../core/models/RangeAttribute';


type FiltersContainerProps = {
  pFilters: Filter[];
  pOnFilterHeaderTapped(filter: Filter): void;
  pOnSelectFilterAttribute(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
  pOnSelectRangeAttribute(filter: Filter, rangeAttribute: RangeAttribute): void;
};

export const FiltersContainer: React.FC<FiltersContainerProps> = (props: FiltersContainerProps) => {  

    const renderFilterItem = (filter: Filter) => {    
        return(
            <FilterMenuItem 
                pFilter={filter} 
                pOnFilterHeaderTapped={props.pOnFilterHeaderTapped} 
                pOnSelectFilterAttribute={props.pOnSelectFilterAttribute} key={filter.slug}
                pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
            />
        )
    }

    const renderFilters = (filters: Filter[]) => {
        return filters.map((filter: Filter) => renderFilterItem(filter));
    }

    return (
        <div className="product-filters-inner-container">
            {renderFilters(props.pFilters)}
        </div>
    );
};