export default class PartnerUserPool {
    
    private mRegion: string;
    public get region() {
      return this.mRegion;
    }

    private mUserPoolId: string;
    public get userPoolId() {
      return this.mUserPoolId;
    }

    private mAppClientId: string;
    public get appClientId() {
      return this.mAppClientId;
    }

    public constructor(responseJson: any) {
        this.mRegion = (responseJson && responseJson.poolId) ? responseJson.poolId : ""
        this.mUserPoolId = (responseJson && responseJson.clientName) ? responseJson.clientName : ""
        this.mAppClientId = (responseJson && responseJson.clientId) ? responseJson.clientId : ""
    }
}