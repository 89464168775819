import React, { useEffect, useRef, useState } from "react";
import "./ProductSection.scss";
import { RoundedButton } from './RoundedButton';
import { ProductCarousel } from './ProductCarousel';
import { ProductGridList } from './ProductGridList';
import { ProductGridFooter } from './ProductGridFooter';
import { Box, Stack } from '@mui/material'
import Typography from '@mui/material/Typography';
import { useElementSize } from 'usehooks-ts'
import ProductSectionModel from '../../../../core/models/ProductSectionModel';
import MenuService from '../../../../services/MenuService';
import { ApiError } from '../../../../core/webservice/ApiError';
import ProductList from '../../../../core/models/ProductList';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import FilterList from '../../../../core/models/FilterList';
import PartnerManager from '../../../../core/utils/PartnerManager';
import Product, { CartProduct } from '../../../../core/models/Product';

type ProductSectionProps = {
    productSectionModel : ProductSectionModel;
    filterList: FilterList;
    pOnSeeAllTapped(productSectionModel: ProductSectionModel): void;
    pOnProductClicked(product: Product): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

const ProductSection: React.FC<ProductSectionProps> = (props: ProductSectionProps) => {

    const defProductList = new ProductList()
    defProductList.initWithDefault()
    const [productList, setProductList] = useState<ProductList>(defProductList)
    const [ref, { width, height }] = useElementSize()
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);

    useEffect(() => {
        if(currentPage !== 0) {
            setCurrentPage(0)
        } else {
            getMenuProducts()
        }        
    },[props.filterList.filters])

    useEffect(() => {
        getMenuProducts()
    },[currentPage])

    const getMenuProducts = () => {
        if(isDataLoading) return;
        setIsDataLoading(true)
        const pageSize = props.productSectionModel.isSelected ? 10 : 10
        const storeId = (PartnerManager.shared().currentSelectedStore) ? PartnerManager.shared().currentSelectedStore.id : undefined
        if(!storeId) {
            setIsDataLoading(false)
        }
        MenuService.getMenuProducts([storeId],props.filterList,productList,props.productSectionModel,pageSize,currentPage)
            .then((rProductList: ProductList) => {
                setProductList(rProductList)
                setTimeout(() => {
                    setIsDataLoading(false)
                },100)
            })
            .catch((error: ApiError) => {
                // console.log('error code:'+error.errorCode)
                // console.log('error message:'+error.message)
                setIsDataLoading(false)
            });
    }

    const handleSeeAllClickEvent = () => {
        props.pOnSeeAllTapped(props.productSectionModel)
    }

    const renderSeeAllButton = () => {
        if(isDataLoading || (!productList.dataLoaded) || (productList.dataLoaded && productList.products.length === 0)) return null
        if(props.productSectionModel.isSelected) return null
        return(
            <RoundedButton
                title={`See All (${productList.productsCount})`} 
                onClick={handleSeeAllClickEvent}/>
        )
    }

    const renderCategoryProductCount = () => {
        if((!productList.dataLoaded) || (productList.dataLoaded && productList.products.length === 0)) return null
        if(!props.productSectionModel.isSelected) return null
        return(
            <Typography className="category-product-count-label">{productList.getProductCountInfo()}</Typography>
        )
    }

    const renderSectionHeader = () => {
        return(
            <Stack
                className={`section-header-container`}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                spacing={0}
            >
                <Box className={`category-title-container`}>
                    <Typography className="category-title">{props.productSectionModel.name}</Typography>
                    {renderCategoryProductCount()}
                </Box>
                {renderSeeAllButton()}
            </Stack>
        );
    }

    const renderProductList = () => {
        {/* <p>{`The square width is ${width}px and height ${height}px`}</p> */}
        const itemDimension = PixelsToViewPortUnitConverter.getProductCardDimension(width,productList.products.length)
        if(props.productSectionModel.isSelected) {
            return(
                <ProductGridList 
                    itemDimension={itemDimension} 
                    productList={productList} 
                    isLoading={isDataLoading}
                    pOnProductClicked={props.pOnProductClicked}
                    pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}/>
            )
        }
        return(
            <ProductCarousel 
                itemDimension={itemDimension} 
                productList={productList} 
                isLoading={isDataLoading}
                pOnProductClicked={props.pOnProductClicked}
                pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}/>
        )
    }

    const renderLoadingAnimation = () => {
        
    }

    const onLoadMoreTapped = (productList: ProductList) => {
        let nextPage = productList.page
        nextPage++
        setCurrentPage(nextPage)
    }

    const onScrollToTopTapped = () => {
        window.scrollTo(0, 1);
    }

    const renderProductGridFooter = () => {
        if(isDataLoading || !props.productSectionModel.isSelected || !productList.dataLoaded) return null
        return(
            <ProductGridFooter 
                productList={productList}
                pOnLoadMoreTapped={onLoadMoreTapped} 
                pOnScrollToTopTapped={onScrollToTopTapped}
            />
        )
    }

    // to hide the whole section
    // console.log('productList.name :'+props.productSectionModel.name+' dataLoaded : '+productList.dataLoaded+' products.length : '+productList.products.length)
    if(productList.dataLoaded && productList.products.length === 0)  {
        return null
    } 
    return (
        <div className="product-section" ref={ref}>
            {renderSectionHeader()}
            {(productList.dataLoaded && productList.products.length > 0) && renderProductList()}
            {(productList.dataLoaded && productList.products.length > 0) && renderProductGridFooter()}
        </div>
    );
};
export default ProductSection