import React, { useEffect, useRef, useState } from "react";
import "./ProductListHeader.scss";
import { AppliedFiltersComponent } from "./AppliedFiltersComponent";
import { SortingOption } from "./SortingOption";
import Typography from "@mui/material/Typography";
import {
  alpha,
  Button,
  ButtonBase,
  IconButton,
  Stack,
  SwipeableDrawer,
} from "@mui/material";
import FilterList from "../../../../core/models/FilterList";
import AppliedFilter from "../../../../core/models/AppliedFilter";
import SortingItem from "../../../../core/models/SortingItem";
import Box from "@mui/material/Box";
import FilterMobileView from "../../../../statics/svgs/FilterMobileView";
import { FiltersContainer } from "../filter/FiltersContainer";
import Filter from "../../../../core/models/Filter";
import FilterAttribute from "../../../../core/models/FilterAttribute";
import RangeAttribute from "../../../../core/models/RangeAttribute";
import { Global } from "@emotion/react";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";

const Puller = styled(Box)(({ theme }) => ({
  width: "80px",
  height: "2px",
  backgroundColor: "#dcdcdc",
  position: "absolute",
  top: "20px",
  left: "50%",
  transform: "translateX(-50%)",
}));

type ProductListHeaderProps = {
  pFilters?: Filter[];
  pIsFiltersLoading: boolean;
  pOnFilterHeaderTapped?(filter: Filter): void;
  pOnSelectFilterAttribute?(
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ): void;
  pOnSelectRangeAttribute?(
    filter: Filter,
    rangeAttribute: RangeAttribute
  ): void;
  pOnResetClicked(): void;

  onSetFilterToggleInMobileMode: any;
  filterToggleInMobileMode: boolean;

  filterList: FilterList;
  appliedFilterList: AppliedFilter[];
  isSectionExpanded: boolean;
  onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
  onSelectSortingOption(sortingItem: SortingItem): void;
};

const ProductListHeader: React.FC<ProductListHeaderProps> = (
  props: ProductListHeaderProps
) => {
  // console.log('props.filterList :'+JSON.stringify(props.filterList))
  const [filterToggle, setFilterToggle] = useState<boolean>(false);
  const shouldVisible =
    !props.appliedFilterList ||
    (props.appliedFilterList && props.appliedFilterList.length == 0)
      ? false
      : true;
  const handleResetClicked = (event: React.MouseEvent<HTMLElement>) => {
    props.pOnResetClicked();
  };
  if (!props.filterList) return null;
  if (props.filterList.filters && props.filterList.filters.length === 0)
    return null;
  const className = props.isSectionExpanded
    ? "cmenu-product-list-header-container -open"
    : "cmenu-product-list-header-container";
  return (
    <div className={className}>
      <div className="product-list-header-section-a">
        {/* <Typography 
                    sx={{visibility: 'hidden', color: (theme) => (props.isSectionExpanded) ? theme.palette.primary.main : theme.palette.primary.contrastText }}
                    className="all-products-header-title">{`ALL PRODUCTS`}</Typography> */}
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              height: "75vh",
              overflow: "visible",
              borderRadius: "15px 15px 0 0",
              padding: "50px 15px 25px",
              background: `${
                PartnerManager.shared().partner.theme.backgroundColor
              } !important`,
            },
          }}
        />
        <Box
          sx={{
            display: {
              sm: "none",
              xs: "block",
            },
            marginBottom:PixelsToViewPortUnitConverter.getvw(100),
            marginTop:PixelsToViewPortUnitConverter.getvw(60),

          }}
        >
          {/* <img
            src={<FilterMobileView />}
            onClick={props.onSetFilterToggleInMobileMode}
          /> */}
          <IconButton 
          onClick={props.onSetFilterToggleInMobileMode}
          >
          <FilterMobileView  
          sx={{
            fill: (theme) =>theme.palette.primary.contrastText,
          }}
          />
          </IconButton>
          <SwipeableDrawer
            anchor={"bottom"}
            open={props.filterToggleInMobileMode}
            onClose={props.onSetFilterToggleInMobileMode}
            onOpen={props.onSetFilterToggleInMobileMode}
          >
            <Puller />
            <Stack
              sx={{
                display: {
                  sm: "none",
                  xs: "flex",
                },
                justifyContent: "space-between !important",
                alignItems: "center !important",
                flexDirection: "row  !important",
                paddingRight: PixelsToViewPortUnitConverter.getvw(80),
              }}
            >
              <Box
                sx={
                  {
                    // position: "absolute",
                    // top: "35px",
                    // left: "15px",
                  }
                }
              >
                <SortingOption
                  pSortingList={props.filterList.sortingList}
                  pOnSelectSortingOption={props.onSelectSortingOption}
                />
              </Box>
              <Box
                className="reset-btn-main-container"
                sx={{
                  visibility: shouldVisible ? "visible" : "hidden",
                  display: {
                    sm: "none !important",
                    xs: "block !important",
                  },
                }}
              >
                <ButtonBase
                  className="reset-btn-base"
                  onClick={handleResetClicked}
                  sx={{
                    verticalAlign: "top",
                  }}
                >
                  <Box className="reset-container">
                    <Typography
                      variant="h1"
                      className="reset-title"
                      sx={{
                        color: (theme) => theme.palette.primary.contrastText,
                        fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                        fontFamily: "Barlow",
                        fontWeight: 500,
                        letterSpacing: "0.1em",
                      }}
                    >
                      {`Reset Filters`}
                    </Typography>
                  </Box>
                </ButtonBase>
              </Box>
            </Stack>
            <Box
              sx={{
                display: { sm: "none", xs: "block" },
                overflowY: "auto",
                scrollbarWidth: "thin",
                marginTop: "25px",
                paddingLeft: "50px",
                paddingRight: "80px",
                maxHeight: "73vh",
                height: "100%",
                paddingBottom: "10px",
              }}
            >
              <FiltersContainer
                pFilters={props.pFilters}
                appliedFilterList={props.appliedFilterList}
                pOnFilterHeaderTapped={props.pOnFilterHeaderTapped}
                pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
                pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
                pOnResetClicked={props.pOnResetClicked}
              />
            </Box>
            <Button
              sx={{
                background: "black",
                height: "50px",
                lineHeight: "50px",
                fontSize: "20px",
                fontFamily: "Bebas",
                fontWeight: "400",
                width: "100%",
                color: "white",
                boxShadow: "0 -5px 20px  white",
              }}
              onClick={props.onSetFilterToggleInMobileMode}
            >
              DONE
            </Button>
          </SwipeableDrawer>
        </Box>

        {/* </Box>    */}
        <Box
          sx={{
            display: {
              sm: "block",
              xs: "none",
            },
          }}
        >
          <SortingOption
            pSortingList={props.filterList.sortingList}
            pOnSelectSortingOption={props.onSelectSortingOption}
          />
        </Box>
        {props.appliedFilterList.length > 0 && (
          <AppliedFiltersComponent
            appliedFiltersList={props.appliedFilterList}
            onRemoveAppliedFilter={props.onRemoveAppliedFilter}
          />
        )}
      </div>
      {/* <div className="product-list-header-section-b">
                <Typography 
                    sx={{ color: (theme) => alpha(theme.palette.primary.contrastText, 1.0) }}
                    className="product-count-info"
                    >{`${props.filterList.productsCount} Results`}
                </Typography>
            </div> */}
      {/* {props.appliedFilterList.length > 0 && 
                <AppliedFiltersComponent 
                    appliedFiltersList={props.appliedFilterList}
                    onRemoveAppliedFilter={props.onRemoveAppliedFilter}
            />} */}
    </div>
  );
};
export default ProductListHeader;
