import SvgIcon from "@mui/material/SvgIcon";
export default function RightTick(props) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2 11.5294L8.53226 21.0588L29 2"
        stroke="currentColor"
        strokeWidth="3.17647"
        fill="none"
      />
    </SvgIcon>
  );
}
