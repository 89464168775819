import SvgIcon from "@mui/material/SvgIcon";
export default function Error(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.6923 84.6924C93.6692 75.7155 99.2007 63.2935 99.2007 49.6004C99.2007 35.9072 93.6691 23.486 84.6923 14.5084C75.7155 5.53146 63.2935 0 49.6004 0C35.9072 0 23.486 5.53164 14.5084 14.5084C5.53146 23.4852 0 35.9072 0 49.6004C0 63.2935 5.53164 75.7148 14.5084 84.6924C23.4852 93.6692 35.9072 99.2007 49.6004 99.2007C63.2935 99.2007 75.7147 93.6691 84.6923 84.6924ZM56.3105 68.7336C56.3105 65.0157 53.3184 62.0236 49.6005 62.0236C45.8827 62.0236 42.7998 65.0157 42.7998 68.7336C42.7998 72.4514 45.8825 75.5342 49.6005 75.5342C53.3186 75.5342 56.3105 72.4516 56.3105 68.7336ZM49.6005 55.2229C47.1524 55.2229 45.3386 53.2279 45.0667 50.6891L42.7998 30.4682C42.4372 26.9316 46.1551 23.6675 49.6005 23.6675C53.0465 23.6675 56.7638 26.9323 56.3105 30.4682L54.1343 50.6891C53.8624 53.2279 52.0488 55.2229 49.6005 55.2229Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
