import {
  alpha,
  Box,
  formLabelClasses,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackIconV2 from "../../../../statics/svgs/BackIconV2";
import "./SignUpComponent.scss";
import BreadcrumbsComponent from "../breadcrumbs/BreadcrumbsComponent";
import { NavigationProps } from "../../../../Navigation.types";
import Navigation from "../../../../Navigation";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import CustomTextInput from "../other/CustomTextInput";
import ErrorLabel from "../other/ErrorLabel";
import CustomButton from "../other/CustomButton";
import TextUtils from "../../../../core/utils/TextUtils";
import VerifyOTPComponent from "../verify_otp/VerifyOTPComponent";
import UserAuthService from "../../../../services/UserAuthService";
import { ApiError } from "../../../../core/webservice/ApiError";
import SignUpCompleted from "../signup_done/SignUpCompleted";
import PartnerManager from "../../../../core/utils/PartnerManager";

type SignUpComponentProps = {
  router: NavigationProps;
  onSignUpCompleted(): void;
};

const SignUpComponent: React.FC<SignUpComponentProps> = (
  props: SignUpComponentProps
) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showSignUp, setShowSignUp] = useState(true);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showSignUpCompleted, setShowSignUpCompleted] = useState(false);

  const handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    Navigation.back(props.router);
  };

  const handleSignUpClicked = () => {
    if (!isFormValid()) {
      return;
    }
    // CALL Sign Up API
    if (isLoading) return;
    setErrorMessage(undefined);
    setIsLoading(true);
    UserAuthService.signUp(userName, password, phone)
      .then((response: any) => {
        setIsLoading(false);
        setShowSignUp(false);
        setShowVerifyOTP(true);
      })
      .catch((error: ApiError) => {
        setIsLoading(false);
        setErrorMessage(error.message);
      });
  };

  const isFormValid = () => {
    if (
      TextUtils.isEmpty(userName) ||
      TextUtils.isEmpty(password) ||
      !TextUtils.isValidEmailId(userName)
    ) {
      return false;
    }
    return true;
  };

  const renderVerifyOTP = () => {
    return (
      <VerifyOTPComponent
        userName={userName}
        password={password}
        signUpandSignIn={true}
        onVerificationSuccessful={() => {
          setShowSignUp(false);
          setShowVerifyOTP(false);
          setShowSignUpCompleted(true);
        }}
      />
    );
  };

  const renderSignUpCompleted = () => {
    return (
      <SignUpCompleted
        onSignUpCompleted={() => {
          props.onSignUpCompleted();
        }}
      />
    );
  };

  const renderSignUpBox = () => {
    return (
      <Box
        sx={{
          alignSelf: "center",
          width: {
            sm: "40vw",
            xs: "100vw",
          },
          height: {
            sm: "35vw",
            xs: "auto",
          },
          maxWidth: "100%",
          mt: PixelsToViewPortUnitConverter.getvw(25),
          mb: PixelsToViewPortUnitConverter.getvw(25),
          borderWidth: {
            sm: "1px",
            xs: "0",
          },
          borderStyle: "solid",
          borderRadius: "0px",
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.2),
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
        }}
      >
        <Box
          className="signup-content-container"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 1.0),
          }}
        >
          <Typography variant="h2" className="header-title">
            {`SIGN UP`}
          </Typography>
          <CustomTextInput
            placeholder="your email address"
            description="Enter your email"
            value={userName}
            defaultValue=""
            mt={60}
            onChangeText={(value) => {
              setUserName(value);
            }}
          />
          <CustomTextInput
            placeholder="phone number"
            description="Enter your phone number"
            value={phone}
            defaultValue=""
            mt={30}
            maxLength={10}
            onChangeText={(value) => {
              setPhone(value);
            }}
          />
          <CustomTextInput
            type="password"
            placeholder="your password"
            description="Enter your password"
            value={password}
            defaultValue=""
            mt={30}
            onChangeText={(value) => {
              setPassword(value);
            }}
          />
          <ErrorLabel errorMessage={errorMessage} mt={17} />
          <Box
            className="button-container"
            sx={{
              mt: {
                sm: PixelsToViewPortUnitConverter.getvw(60),
                xs: PixelsToViewPortUnitConverter.getvw(320),
              },
              display: {
                sm: "grid !important  ",
                xs: "block !important",
              },
            }}
          >
            <CustomButton
              variant="v2"
              title="SIGN UP"
              disabled={!isFormValid()}
              loading={isLoading}
              onClick={() => {
                handleSignUpClicked();
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      className="signup-inner-container"
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Box
        className="left-container"
        sx={{
          display: {
            sm: "block !important",
            xs: "none !important",
          },
        }}
      >
        <IconButton
          sx={{
            visibility: !showSignUpCompleted ? "visible" : "hidden",
          }}
          disabled={!showSignUpCompleted ? false : true}
          onClick={(e) => {
            handleBackClick(e);
          }}
        >
          <BackIconV2
            sx={{
              fill: "none",
              stroke: (theme) => theme.palette.primary.contrastText,
            }}
          />
        </IconButton>
      </Box>
      <Box
        className="center-container"
        sx={{
          zIndex: {
            sm: "0",
            xs: "1000",
          },
          top: {
            sm: "unset",
            xs: "0",
          },
          position: {
            sm: "unset",
            xs: "relative",
          },
          width: {
            sm: "auto",
            xs: "100vw",
          },
          height: {
            sm: "auto",
            xs: "auto",
          },
          justifyContent: {
            sm: "unset",
            xs: "start",
          },
          backgroundColor: {
            sm: "unset",
            xs: PartnerManager.shared().partner.theme.backgroundColor,
          },
          paddingTop: {
            sm: "0",
            xs: "100px",
          },
          paddingBottom: {
            sm: PixelsToViewPortUnitConverter.getFontVh(0),
            xs: PixelsToViewPortUnitConverter.getFontVh(100),
          },
        }}
      >
        <BreadcrumbsComponent router={props.router} isSectionExpanded={false} />

        {showSignUp && renderSignUpBox()}
        {showVerifyOTP && renderVerifyOTP()}
        {showSignUpCompleted && renderSignUpCompleted()}
      </Box>
      <Box
        className="right-container"
        sx={{
          display: {
            sm: "block",
            xs: "none",
          },
        }}
      ></Box>
    </Box>
  );
};
export default SignUpComponent;
