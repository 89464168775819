import TextUtils from "./TextUtils";
import LocalStorageUtils from "./LocalStorageUtils";
import Partner, { PartnerState } from "../models/Partner";
import Category from "../models/Category";
import Store from "../models/Store";
import Effect from "../models/Effect";
import SessionManager from "./SessionManager";
import AlgoliaManager from "../webservice/AlgoliaManager";
import AmplifyManager from './AmplifyManager';

export default class PartnerManager {
  public static shared(): PartnerManager {
    if (!PartnerManager.sPartnerManager) {
      PartnerManager.sPartnerManager = new PartnerManager();
    }
    return PartnerManager.sPartnerManager;
  }

  private static sPartnerManager: PartnerManager | null;

  private constructor() {}

  public init() {
    this.mStores = [];
    this.mCategories = [];
    this.mEffects = [];
  }

  // get partner
  private mPartner: Partner | undefined | null;
  public get partner() {
    return this.mPartner;
  }

  // set partner
  public setPartner(partner: Partner | undefined) {
    this.mPartner = partner;
    AlgoliaManager.shared().initSearchClient(this.mPartner);
    AmplifyManager.shared().setupPartnerUserPool(this.mPartner)
  }

  // update partner
  public updatePartner(updatedPartner: Partner) {
    this.setPartner(updatedPartner);
  }

  // reset partner
  public resetPartner() {
    this.setPartner(undefined);
  }

  // get stores
  private mStores: Store[];
  public get stores() {
    return this.mStores;
  }

  // set stores
  public setStores(stores: Store[]) {
    this.mStores = stores;
  }

  // reset stores
  public resetStores() {
    this.setStores([]);
  }

  // get current selected store
  private mCurSelectedStore: Store;
  public get currentSelectedStore() {
    return this.mCurSelectedStore;
  }

  // set current selected store
  public setCurrentSelectedStore(currentSelectedStore: Store) {
    this.resetCategories();
    this.resetEffects();
    this.mCurSelectedStore = currentSelectedStore;
  }

  // get categories
  private mCategories: Category[];
  public get categories() {
    return this.mCategories;
  }

  // set categories
  public setCategories(categories: Category[]) {
    this.mCategories = categories;
  }

  // reset categories
  public resetCategories() {
    this.setCategories([]);
  }

  // get effects
  private mEffects: Effect[];
  public get effects() {
    return this.mEffects;
  }

  // set effects
  public setStoreEffects(effects: Effect[]) {
    this.mEffects = effects;
  }

  // reset effects
  public resetEffects() {
    this.setStoreEffects([]);
  }

  public isJaneCredentialsAvailable(): boolean {
    let clientId = this.janeClientCredentials()[0];
    let clientSecret = this.janeClientCredentials()[1];
    if (!TextUtils.isEmpty(clientId) && !TextUtils.isEmpty(clientSecret)) {
      return true;
    }
    return false;
  }

  public janeClientCredentials(): [string, string] {
    let clientId = "";
    let clientSecret = "";
    if (this.partner) {
      clientId = this.partner.clientId;
      clientSecret = this.partner.clientSecret;
    }
    return [clientId, clientSecret];
  }

  public doLogout() {
    this.resetPartner();
    this.resetStores();
    this.resetCategories();
    this.resetEffects();
    SessionManager.shared().clearSession();
    PartnerManager.sPartnerManager = undefined;
    console.log("logout successfully.");
  }

  // check partner website is live or not
  public isWebsiteLive() {
    if (this.partner && this.partner.partnerState === PartnerState.LIVE) {
      return true;
    }
  }

  // find category by name
  public findCategory(name: string): Category | undefined {
    if (this.partner) {
      let partnerCategory = this.partner.categories.find(
        (item: Category) => item.name == name
      );
      if (partnerCategory) {
        return partnerCategory;
      }
    }
    return undefined;
  }

  // find effect by name
  public findEffect(name: string): Effect | undefined {
    if (this.partner) {
      let partnerEffect = this.partner.effects.find(
        (item: Effect) => item.name == name
      );
      if (partnerEffect) {
        return partnerEffect;
      }
    }
    return undefined;
  }

  // find store_ids associated with partner
  public storeIds(): number[] {
    return [68]; // for testing only
    const stores = this.stores;
    if (stores && stores.length > 0) {
      const selectedIds = stores.map(({ id }) => id);
      // picked first store for now
      return [selectedIds[0]];
    }
    return [];
  }
}
