import React, { useEffect } from "react";
import "./ProductCarousel.scss";
import { ProductCard } from './ProductCard';
import Carousel from 'react-material-ui-carousel';
import { Stack, Box } from '@mui/material';
import Product, { CartProduct } from '../../../../core/models/Product';
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import ProductList from '../../../../core/models/ProductList';
import Loader from '../../../common/loader/Loader';


type ProductCarouselProps = {
    itemDimension: any;
    productList: ProductList;
    isLoading: boolean;
    pOnProductClicked(product: Product): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

export const ProductCarousel: React.FC<ProductCarouselProps> = (props: ProductCarouselProps) => {

    const [items, setItems] = React.useState([]);

    // on parent width or product changes reload the UI
    useEffect(() => {
        setUpUI()
    }, [props.itemDimension.parentWidth, props.productList.products]);

    const setUpUI = () =>{
        const width = props.itemDimension.parentWidth
        if(width > 0) {
            
            const minItemWidth = props.itemDimension.width
            const sliderItems: number = props.itemDimension.itemsPerPage
            const leftRightPadding = props.itemDimension.parentLeftRightPadding
    
            let childItems = []
            for (let i = 0; i < props.productList.products.length; i += sliderItems) {
                if (i % sliderItems === 0) {
                    childItems.push(
                        <Stack
                            key={i.toString()}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                            minHeight={50}
                        >
                            {props.productList.products.slice(i, i + sliderItems).map((item, index) => {
                                return(
                                    <Box
                                        sx={{
                                            minWidth: minItemWidth,
                                            maxWidth: minItemWidth,
                                            height: "100%",
                                            pl: `${leftRightPadding}px`,
                                            pr: `${leftRightPadding}px`,
                                            backgroundColor: '#fff'
                                        }}
                                        key={index.toString()}
                                    >
                                        <ProductCard 
                                            key={index.toString()} 
                                            product={item} 
                                            width={minItemWidth} 
                                            height={minItemWidth}
                                            pOnProductClicked={props.pOnProductClicked}
                                            pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}/>
                                    </Box>
                                )
                            })}
                        </Stack>
                    );
                }
            }
            setItems(childItems)
        }
    }
     
    const onChangeCarousel = (active?: number, previous?: number) => {
        // console.log(`we active ${active}, and previous ${previous}`);
    }

    if(props.isLoading) {
        return(
            <Box sx={{ position: "relative", minHeight: "100px", width: "100%" }}>
                <Loader pshow={true} />
            </Box>
        )
    }
    
    return (
        <Carousel className={`product-carousel ${(props.isLoading)?'-hide':''}`} 
                animation="slide" 
                indicators={false} 
                autoPlay={false}
                swipe={false}
                navButtonsAlwaysVisible={true}
                changeOnFirstRender={true}
                cycleNavigation={false}
                onChange= {onChangeCarousel}
            >
                {items}
        </Carousel>
    );
};