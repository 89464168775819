import React from "react";
import AuthService from "../../../services/AuthService";
import StoreOperationsService from "../../../services/StoreOperationsService";
import { ApiError } from "../../../core/webservice/ApiError";
import { AuthResponse } from "../../../core/models/AuthResponse";
import SessionManager from "../../../core/utils/SessionManager";
import Store from "../../../core/models/Store";
import Category from "../../../core/models/Category";
import Effect from "../../../core/models/Effect";
import { Button, TextField } from "@mui/material";
import PartnerService from "../../../services/PartnerService";
import Partner, { PartnerSocialMedia } from "../../../core/models/Partner";
import {
  PartnerAppTheme,
  PartnerAppThemeType,
} from "../../../core/models/PartnerTheme";
import PartnerTheme from "../../../core/models/PartnerTheme";
import PartnerManager from "../../../core/utils/PartnerManager";

interface ApiTestingComponentProps {}
interface ApiTestingComponentState {}

export class ApiTestingComponent extends React.Component<
  ApiTestingComponentProps,
  ApiTestingComponentState
> {
  constructor(props: ApiTestingComponentProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  // API : To link Jane Account
  private generateJaneToken() {
    if (SessionManager.shared().isJaneTokenAvailable()) {
      // janeAccessToken available, no need to login again.
      console.log("token :" + SessionManager.shared().janeAccessToken);
      return;
    }

    PartnerService.generateJaneRootsToken("janeshop1.warpspd.ai")
      .then((authResponse: AuthResponse) => {
        console.log(
          "generateJaneRootsToken response :" + JSON.stringify(authResponse)
        );
      })
      .catch((error: ApiError) => {
        console.log("error code:" + error.errorCode);
        console.log("error message:" + error.message);
      });
  }

  // API : Get stores associated with partner
  private getStores() {
    StoreOperationsService.getStores()
      .then((stores: Store[]) => {
        console.log("stores :" + JSON.stringify(stores));
      })
      .catch((error: ApiError) => {
        console.log("error code:" + error.errorCode);
        console.log("error message:" + error.message);
      });
  }

  // API : Get categories associated with partner
  private getCategories() {
    StoreOperationsService.getCategories(27)
      .then((categories: Category[]) => {
        console.log("categories :" + JSON.stringify(categories));
      })
      .catch((error: ApiError) => {
        console.log("error code:" + error.errorCode);
        console.log("error message:" + error.message);
      });
  }

  // API : Get effects associated with partner
  private getEffects() {
    console.log("call getEffects :");
    StoreOperationsService.getEffects(68)
      .then((effects: Effect[]) => {
        console.log("effects :" + JSON.stringify(effects));
      })
      .catch((error: ApiError) => {
        console.log("error code:" + error.errorCode);
        console.log("error message:" + error.message);
      });
  }

  // API : Get partner details by domain_name
  private getPartnerInfo() {
    PartnerService.getPartnerInfo("janeshop1.warpspd.ai")
      .then((result: Partner) => {
        console.log("getPartnerInfo result :" + JSON.stringify(result));
      })
      .catch((error: ApiError) => {
        console.log("error code:" + error.errorCode);
        console.log("error message:" + error.message);
      });
  }

  render() {
    return (
      <div>
        <h1>This are sample APIs</h1>
        <br />
        <Button
          variant="text"
          onClick={() => {
            this.getPartnerInfo();
          }}
        >
          Get Partner details
        </Button>
        <br />
        <Button
          variant="text"
          onClick={() => {
            this.generateJaneToken();
          }}
        >
          Generate Jane Token
        </Button>
        <br />
        <Button
          variant="text"
          onClick={() => {
            this.getStores();
          }}
        >
          Get Stores
        </Button>
        <br />
        <Button
          variant="text"
          onClick={() => {
            this.getCategories();
          }}
        >
          Get Categories
        </Button>
        <br />
        <Button
          variant="text"
          onClick={() => {
            this.getEffects();
          }}
        >
          Get Effects
        </Button>
        <br />
      </div>
    );
  }
}
