import React from "react";
import "./EffectsWebMenuComponent.scss";
import BreadcrumbsComponent from '../breadcrumbs/BreadcrumbsComponent';
import FiltersComponent from '../filter/FiltersComponent';
import ProductSection from './ProductSection';
import ProductListHeader from './ProductListHeader';
import Filter from '../../../../core/models/Filter';
import FilterAttribute from '../../../../core/models/FilterAttribute';
import RangeAttribute from '../../../../core/models/RangeAttribute';
import FilterList from "../../../../core/models/FilterList";
import AppliedFilter from "../../../../core/models/AppliedFilter";
import SortingItem from "../../../../core/models/SortingItem";
import ProductSectionModel from "../../../../core/models/ProductSectionModel";
import Product, { CartProduct } from '../../../../core/models/Product';
import FilterUtils from "../../../../core/utils/FilterUtils";
import { NavigationProps } from "../../../../Navigation.types";

type EffectsWebMenuComponentProps = {
    router: NavigationProps;
    pFilters?: Filter[];
    filterList: FilterList;
    appliedFilterList: AppliedFilter[];
    pOnFilterHeaderTapped?(filter: Filter): void;
    pOnSelectFilterAttribute?(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
    pOnSelectRangeAttribute?(filter: Filter, rangeAttribute: RangeAttribute): void;
    onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
    onSelectSortingOption(sortingItem: SortingItem): void;
    pOnSeeAllTapped(productSectionModel: ProductSectionModel): void;
    pOnProductClicked(product: Product): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

const EffectsWebMenuComponent: React.FC<EffectsWebMenuComponentProps> = (props: EffectsWebMenuComponentProps) => {

    const renderProductSection = (productSectionModel: ProductSectionModel) => {
        return (
          <ProductSection
            key={productSectionModel.name}
            productSectionModel={productSectionModel}
            filterList={props.filterList}
            pOnSeeAllTapped={props.pOnSeeAllTapped}
            pOnProductClicked={props.pOnProductClicked}
            pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}
          />
        );
    }
    
    const renderAllProductsInfo = () => {
        return (
          <ProductListHeader
            filterList={props.filterList}
            appliedFilterList={
                props.filterList && props.filterList.appliedFiltersList
            }
            onRemoveAppliedFilter={props.onRemoveAppliedFilter}
            onSelectSortingOption={props.onSelectSortingOption}
          />
        );
    }
    
    const renderSections = () => {
        if (props.filterList.filters.length == 0) return null;
        if (props.filterList.productsCount <= 0) return null;
        const filterList = [...props.filterList.filters];
        const productSectionsList = FilterUtils.getProductSections(filterList);
        return productSectionsList.map((pSectionModel: ProductSectionModel) =>
          renderProductSection(pSectionModel)
        );
    }


    return(
        <div>
            <div className="breadcrum-container">
                <BreadcrumbsComponent router={props.router}/>
            </div>
            <div className="product-results-inner-container">
            <FiltersComponent
                pFilters={props.filterList.filters}
                pOnFilterHeaderTapped={props.pOnFilterHeaderTapped}
                pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
                pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
            />
            <div className="product-sections-container">
                {renderAllProductsInfo()}
                {renderSections()}
            </div>
            </div>
        </div>       
    )
}
export default EffectsWebMenuComponent





