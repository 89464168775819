import React, {useEffect, useState} from "react";
import "./WeightBoxList.scss";
import { alpha, Box, Grid } from '@mui/material';
import Product from "../../../../core/models/Product";
import Weight from '../../../../core/models/Weight';
import WeightBox from "./WeightBox";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import DimensionUtils from "../../../../core/utils/DimensionUtils";

type WeightBoxListProps = {
    variant?: string
    product: Product
    arrOfWeights?: Weight[]
    selectedWeight: Weight;
    xsItem:number
    onSelectedWeight(weight: Weight): void
    mt?: number
    mb?: number
};

const WeightBoxList: React.FC<WeightBoxListProps> = (props: WeightBoxListProps) => {

    const renderWeightBox = (weight: Weight) => {
        return(
            <WeightBox
                selectedWeight={props.selectedWeight}
                weight={weight}
                xsItem={props.xsItem}
                onSelectedWeight={props.onSelectedWeight}
            />
        )
    }

    const renderWeightBoxList = (weights: Weight[]) => {
        
        return weights.map((weight: Weight) => renderWeightBox(weight));
    };

    const { mt = 0, mb = 0} = props
    const arr = (props.arrOfWeights) ? props.arrOfWeights : props.product.weights
    const gridID = `weight-box-container${(props.variant)?props.variant:''}`
    const columns = 3//(arr.length >= 3) ? 3 : arr.length
    return(
        <Box 
            id="weight-box-main-container"
            sx={{
                mt: PixelsToViewPortUnitConverter.getvw(mt),
                mb: PixelsToViewPortUnitConverter.getvw(mb)
            }}
            alignItems={"center"}
            justifyContent={"center"}>
            <Grid container
                id={gridID}
                sx={{
                    gridTemplateColumns: `repeat(${columns}, 1fr)`
                }}>
                {renderWeightBoxList(arr)}
            </Grid>
        </Box>
        
    )
}
export default WeightBoxList





