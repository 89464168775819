import { PartnerAppThemeType } from "../core/models/PartnerTheme";
import PartnerManager from "../core/utils/PartnerManager";
import { getDefaultTheme } from "./DefaultTheme";
import { getEffectsTheme } from "./EffectsTheme";
import { getEffectsV2Theme } from "./EffectsV2Theme";

export function getTheme() {
  if (PartnerManager.shared().partner) {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.EFFECTS:
        return getEffectsTheme();
      default:
        return getEffectsV2Theme();
    }
  } else {
    return getDefaultTheme();
  }
}
