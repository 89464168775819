import FilterAttribute from "./FilterAttribute";
import PartnerManager from "../utils/PartnerManager";
import AppliedFilter from "./AppliedFilter";
import Product from "./Product";
import TextUtils from "../utils/TextUtils";
const queryString = require("query-string");
export default class ProductSectionModel {
  private mName: string;
  public get name() {
    return this.mName;
  }

  private mSlug: string;
  public get slug() {
    return this.mSlug;
  }

  private mFilterSlug: string;
  public get filterSlug() {
    return this.mFilterSlug;
  }

  private mRank: number;
  public get rank() {
    return this.mRank;
  }

  private mProductsCount: number;
  public get productsCount() {
    return this.mProductsCount;
  }

  private mSelected: boolean;
  public get isSelected() {
    return this.mSelected;
  }

  private mExcludeProductId: number;
  public get excludeProductId() {
    return this.mExcludeProductId;
  }

  private mIsPopularItems: boolean;
  public get isPopularItems() {
    return this.mIsPopularItems;
  }

  public setIsPopularItems(isPopularItems: boolean) {
    this.mIsPopularItems = isPopularItems
  }

  public constructor() {
    this.mSelected = false;
    this.mIsPopularItems = false;
  }

  public initWith(filterAttribute: FilterAttribute) {
    this.mName = filterAttribute.name;
    this.mSlug = filterAttribute.slug;
    this.mFilterSlug = filterAttribute.filterSlug;
    if (this.filterSlug === "root_types" || this.filterSlug === "kind") {
      // Partner category mapping for rank
      const category = PartnerManager.shared().findCategory(this.mSlug);
      this.mRank = category ? category.rank : undefined;
    } else {
      // Partner effect mapping for rank
      const effect = PartnerManager.shared().findEffect(this.mSlug);
      this.mRank = effect ? effect.rank : undefined;
    }
    this.mProductsCount = filterAttribute.productsCount;
  }

  public selected(isSelected: boolean) {
    this.mSelected = isSelected;
  }

  public setName(name: string) {
    this.mName = name;
  }

  public getQuery() {
    if (this.filterSlug === "root_types" || this.filterSlug === "kind") {
      return `kind:"${this.slug}" OR root_types:"${this.slug}"`;
    }
    if (this.filterSlug === "brand") {
      if (this.excludeProductId) {
        return `brand:"${this.slug}" AND NOT product_id:${this.excludeProductId}`;
      }
      return `brand:"${this.slug}"`;
    }
    return `feelings:"${this.slug}"`;
  }

  public getProductCountInfo() {
    if (!this.isSelected) return ``;
    if (this.mProductsCount === 0) {
      return `No results found`;
    } else if (this.mProductsCount === 1) {
      return `1 Result`;
    } else {
      return `${this.mProductsCount} Results`;
    }
  }

  public static getPopularProductSectionModel(
    title: string
  ): ProductSectionModel {
    const parsed = queryString.parse("?facets.root_types[0]=best_selling");
    const appdFilterList = AppliedFilter.list(parsed);
    const dummyFilterAttribute = FilterAttribute.dummyFilterAttribute(
      appdFilterList[0]
    );
    let pSection = new ProductSectionModel();
    pSection.initWith(dummyFilterAttribute);
    pSection.setName(title);
    window.screen.width <= 557
      ? pSection.selected(true)
      : pSection.selected(false);
    pSection.setIsPopularItems(true);
    return pSection;
  }

  public static getRelatedProductsSectionModel(
    product: Product,
    title: string
  ): ProductSectionModel {
    if (product.brand && !TextUtils.isEmpty(product.brand)) {
      // brand specific related products excluding product_id
      let brandName = encodeURIComponent(product.brand);
      const parsed = queryString.parse(`?facets.brand[0]=${brandName}`);
      console.log(parsed);
      const appdFilterList = AppliedFilter.list(parsed);
      console.log(JSON.stringify(appdFilterList));
      const dummyFilterAttribute = FilterAttribute.dummyFilterAttribute(
        appdFilterList[0]
      );
      let pSection = new ProductSectionModel();
      pSection.initWith(dummyFilterAttribute);
      pSection.setName(title);
      pSection.mExcludeProductId = product.id;
      return pSection;
    }
    return undefined;
  }
}
