import React, { useState } from "react";
import "./FilterMenuItem.scss";
import { RangeSlider } from "./RangeSlider";
import { FilterHeader } from "./FilterHeader";
import { CheckboxFilter } from './CheckboxFilter';
import Accordion from '@mui/material/Accordion';
import Filter, { FilterType } from "../../../../core/models/Filter";
import FilterAttribute from '../../../../core/models/FilterAttribute';
import RangeAttribute from '../../../../core/models/RangeAttribute';

type FilterMenuItemProps = {
    pFilter: Filter;
    pOnFilterHeaderTapped(filter: Filter): void;
    pOnSelectFilterAttribute(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
    pOnSelectRangeAttribute(filter: Filter, rangeAttribute: RangeAttribute): void;
};

export const FilterMenuItem: React.FC<FilterMenuItemProps> = (props: FilterMenuItemProps) => {

    const renderFilterDetails = (filter: Filter) => {
        if(filter.type !== FilterType.THC_POTENCY &&
            filter.type !== FilterType.CBD_POTENCY) {
            return(
                <CheckboxFilter 
                    pFilter={filter} 
                    pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
                />
            )
        } else {
            return(
                <RangeSlider 
                    pFilter={filter} 
                    pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
                />
            )
        }
    }

    const renderFilterItem = (filter: Filter) => {
        return(
        <Accordion key={filter.slug} expanded={filter.isSelected} disableGutters>
            <FilterHeader filter={filter} onFilterHeaderTapped={props.pOnFilterHeaderTapped}/>
            {renderFilterDetails(filter)}
        </Accordion>
        )
    }

    return (
        <div className="product-filter-item-container" key={props.pFilter.slug}>
            {renderFilterItem(props.pFilter)}
        </div>
    );
};