import SvgIcon from "@mui/material/SvgIcon";
export default function FilterMobileView(props) {
    var uProps = { ...props }
    uProps.viewBox = "0 0 31 21";
    return (
        <SvgIcon {...uProps} >
            <path d="M12.0556 21V18.375H18.9444V21H12.0556ZM5.16667 11.8125V9.1875H25.8333V11.8125H5.16667ZM0 2.625V0H31V2.625H0Z" fill={uProps.sx.fill} />
        </SvgIcon>
    );
}
