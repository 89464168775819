import Product from './Product';
export default class ProductList {

    // total items received for current page
    private mProductsCount: number;
    public get productsCount() {
        return this.mProductsCount;
    }

    // current page
    private mPage: number;
    public get page() {
      return this.mPage;
    }

    // total pages
    private mTotalPages: number;
    public get totalPages() {
      return this.mTotalPages;
    }

    // items count per page
    private mProductsPerPage: number;
    public get productsPerPage() {
      return this.mProductsPerPage;
    }

    // products
    private mProducts: Product[];
    public get products() {
      return this.mProducts;
    }

    // query
    private mQuery: string;
    public get query() {
      return this.mQuery;
    }

    // dataLoaded from API or not
    private mDataLoaded: boolean;
    public get dataLoaded() {
      return this.mDataLoaded;
    }

    public constructor() {}

    public init(itemsResult: any, previousProductList: ProductList) {
        this.mProductsCount = itemsResult.nbHits
        this.mPage = itemsResult.page
        this.mTotalPages = itemsResult.nbPages
        this.mProductsPerPage = itemsResult.hitsPerPage
        this.mQuery = itemsResult.query;

        let finalProducts: Product[] = []
        const previousProducts = (previousProductList && previousProductList.products.length > 0) ? previousProductList.products : []
        const newProducts = Product.list(itemsResult.hits)
        if(this.mPage !== 0) {
          finalProducts = finalProducts.concat(previousProducts)
        }
        finalProducts = finalProducts.concat(newProducts)
        this.mProducts = finalProducts
        this.mDataLoaded = true
    }

    public initWithDefault() {
      this.mProductsCount = 0
      this.mPage = 0
      this.mTotalPages = 0
      this.mProductsPerPage = 0
      this.mProducts = []
      this.mDataLoaded = false
  }

    public static initWithResult(result: any, previousProductList: ProductList): ProductList|undefined {
        let pList: ProductList = undefined
        pList = new ProductList()
        pList.init(result,previousProductList)
        return pList
    }

    public getProductCountInfo() {
      if(this.mProductsCount === 0) {
        return `No results found`
      } else if(this.mProductsCount === 1) {
        return `1 Result`
      } else {
        return `${this.mProductsCount} Results`
      }
    }
}