import React from "react";
import "./CustomerLoginComponent.scss";
import withRouter from "../../../withRouter";
import { NavigationProps } from "../../../Navigation.types";
import Navigation from '../../../Navigation';
import { Box } from "@mui/material";
import LoginComponent from "../../themes/effectsv2/login/LoginComponent";

interface CustomerLoginComponentProps {
  router: NavigationProps;
}

interface CustomerLoginComponentState {

}

class CustomerLoginComponent extends React.Component<
    CustomerLoginComponentProps,
    CustomerLoginComponentState
> {
  constructor(props: CustomerLoginComponentProps) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {}
  
  private handleLoginSuccess() {
    Navigation.toProfile(this.props.router);
  }

  private handleSignUpClicked() {
    console.log('handleSignUpClicked')
    Navigation.toSignUp(this.props.router);
  }

  private handleNavigateVerifyCode(userName: string, password: string) {
    Navigation.toVerifyCode(this.props.router, userName, password);
  }

  private renderLoginComponentEffectV2ThemeContainer() {
    return(
        <LoginComponent
            pShowProfileContent={true}
            anchorEl={null}
            pIsPopover={false}
            onOpen={() => {
              
            }}
            onClose={() => {
              
            }}
            onLoginSuccess={() => {this.handleLoginSuccess()}}
            onSignUpClicked={() => {this.handleSignUpClicked()}}
            onNavigateToVerifyCode={(u,p) => {this.handleNavigateVerifyCode(u,p)}}
          />
    )
  }

  render() {
    return(
        <Box className="customer-login-container" 
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
            }}>
            {this.renderLoginComponentEffectV2ThemeContainer()}
        </Box>
    )
    return null;
  }
  
}

export default withRouter(CustomerLoginComponent);