import React, { useEffect, useState } from "react";
import "./ProductImageList.scss";
import Product from '../../../../core/models/Product';
import Card from '@mui/material/Card';
import { Grid, Box, ButtonBase, alpha, Theme } from '@mui/material';
import Photo from "../../../../core/models/Photo";
import Carousel from 'react-material-ui-carousel';
import PartnerManager from '../../../../core/utils/PartnerManager';
import { PartnerAppThemeType } from '../../../../core/models/PartnerTheme';
import { useElementSize } from 'usehooks-ts'
import DimensionUtils from "../../../../core/utils/DimensionUtils";

type ProductImageListProps = {
    product: Product;
    isMobile: boolean;
};

const ProductImageList: React.FC<ProductImageListProps> = (props: ProductImageListProps) => {

    const[page, setPage] = useState(0)
    const [retryImageError, setRetryImageError] = React.useState(0);
    const [imgCRef, { width, height }] = useElementSize()

    const handlePageClick = (event: React.MouseEvent<HTMLElement>, photoIndex: number) => {
        setPage(photoIndex)
    }

    const handleImageError = (e) => {
        if(retryImageError === 0) {
            setRetryImageError(1)
            e.target.onerror = null;
            const fallbackUrl = props.product.getCardDetailFallbackImageUrl()
            e.target.src = fallbackUrl
        }
    }

    const renderProductImage = (photo: Photo, photoIndex: number) => {
        let imgUrl = ''
        if(!photo) {
            imgUrl = props.product.getProductCardImageUrl();
        } else {
            imgUrl = (photo.extraLarge) ? photo.extraLarge : ''
            if(props.isMobile) {
                imgUrl = (photo.medium) ? photo.medium : ''
            }
        }
        
        return(
            <Box className="sproduct-image-wrapper">
                <img
                    id={`product-detail-image-${props.product.id}-${photoIndex}`}
                    className="sproduct-image"
                    alt={`${props.product.name}`}
                    style={{
                        width: `${width-DimensionUtils.getVwNumber(80)}px`,
                        height: `${height-DimensionUtils.getVwNumber(80)}px`
                    }}
                    src={imgUrl}
                    key={photoIndex.toString()}
                    loading="lazy"
                    onError={handleImageError}
                />  
            </Box>
        )
    }
    //getSmallImageUrlWithHeight(463,431)

    const getChildProductImages = (photos: Photo[]) => {
        if(photos.length == 0) {
            return renderProductImage(undefined,0)
        }
        const childItems = photos.map((photo: Photo, index: number) => renderProductImage(photo,index));
        return childItems
    }

    const renderProductImageList = (photos: Photo[]) => {
        let childItems = getChildProductImages(photos)
        return(
            <Carousel 
                className="product-photo-assets-carousel" 
                animation="slide"
                sx={{
                    height: `100%`,
                    textAlign: 'center',
                    display: 'flex',
                    alignSelf: 'center',
                }}
                indicators={false} 
                autoPlay={false}
                swipe={false}
                navButtonsAlwaysVisible={false}
                changeOnFirstRender={true}
                cycleNavigation={false}
                index={page}
                >
                {childItems}
            </Carousel>
        )
    }

    const renderPaginationButton = (photo: Photo, photoIndex: number) => {
        const isSelected = (page === photoIndex)?true:false
        return(
            <ButtonBase 
                    className="pagination-btn-base" 
                    onClick={(e) => {handlePageClick(e,photoIndex)}}
                    key={photoIndex.toString()}>
                <Box 
                    className={`pagination-item ${isSelected &&'-selected'}`}
                    sx={{
                        backgroundColor: (theme) => (isSelected) ? theme.palette.primary.contrastText : alpha(theme.palette.primary.contrastText,0.13)
                    }}
                    ></Box>
            </ButtonBase>
        )
    }

    const renderPaginationButtonList = (photos: Photo[]) => {
        return photos.map((photo: Photo, index: number) => renderPaginationButton(photo,index));
    }

    const renderPaginationButtons = () => {
        if(props.product.photos.length === 1) return null
        return(
            <Box className="pagination-container">
                {renderPaginationButtonList(props.product.photos)}
            </Box>
        )
    }

    const imgContainerNormalBorderColor = (theme: Theme) => {
        switch (PartnerManager.shared().partner.theme.name) {
            case PartnerAppThemeType.CATEGORIES:
                return alpha(theme.palette.primary.contrastText,0.1);
            case PartnerAppThemeType.EFFECTS1:
                return theme.palette.secondary.contrastText;
            case PartnerAppThemeType.EFFECTS:
            case PartnerAppThemeType.EFFECTS2:
                return "#FFFFFF";
            default:
                break;
        }
    }

    const imgContainerBorderWidth = () => {
        switch (PartnerManager.shared().partner.theme.name) {
            case PartnerAppThemeType.CATEGORIES:
                return "1px";
            case PartnerAppThemeType.EFFECTS1:
                return "2px";
            case PartnerAppThemeType.EFFECTS:
            case PartnerAppThemeType.EFFECTS2:
                return "2px";
            default:
              break;
        }
    }

    return(
        <Grid className="product-photos-container" xs>
            <Box ref={imgCRef} className="product-photos-main-container" sx={{
                borderWidth: imgContainerBorderWidth(),
                borderStyle: 'solid',
                borderRadius: '0px',
                backgroundColor: (theme) => theme.palette.primary.main,
                borderColor: (theme) => imgContainerNormalBorderColor(theme)
            }}>
                {renderProductImageList(props.product.photos)}
            </Box>
            {renderPaginationButtons()}
        </Grid>
    )
}
export default ProductImageList
