import React, { useEffect, useState } from "react";
import "./WeightCarousel.scss";
import { Stack, alpha, ButtonBase } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { Box } from "@mui/system";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import Product, { CartProduct } from '../../../../core/models/Product';
import Weight from '../../../../core/models/Weight';
import StrikeLabel from '../other/StrikeLabel';
import Carousel from 'react-material-ui-carousel';
import ShoppingCartIcon from "../../../../statics/svgs/Cart";
import AddedToCartIcon from "../../../../statics/svgs/AddedToCartIcon";
import NextIconV3 from "../../../../statics/svgs/NextIconV3";
import PreviousIconV3 from "../../../../statics/svgs/PrevIconV3";

type WeightCarouselProps = {
    product: Product;
    weight: Weight;
    pSelectedPriceId: string;
    weightDimension: any;
    display: boolean;
    pOnWeightSelected(weight: Weight): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

export const WeightCarousel: React.FC<WeightCarouselProps> = (props: WeightCarouselProps) => {
    const [items, setItems] = React.useState([]);

    useEffect(()=> {
        setUpWeightItems()
    },[])

    useEffect(()=> {
        setUpWeightItems()
    },[props.weightDimension.parentWidth, props.weight, props.display])

    const handleWeightClick = (event: React.MouseEvent<HTMLElement>, weight: Weight) => {
        const cartProduct: CartProduct = {
            productId: props.product.id,
            priceId: weight.priceId,
            count: 1
        }
        props.pOnAddOrRemoveProductFromCart(cartProduct)        
        props.pOnWeightSelected(weight)
    }

    const setUpWeightItems = () =>{
        if(props.weightDimension.parentWidth > 0) {
            var arrOfWeights = props.product.getAllWeightsForProductCard()
            let itemDimension = props.weightDimension
            
            const minItemWidth = itemDimension.width
            // const minItemHeight = itemDimension.height
            const sliderItems: number = itemDimension.itemsPerPage
            const parentLeftPadding = itemDimension.parentLeftPadding
            const parentRightPadding = itemDimension.parentRightPadding
            const gap = itemDimension.gap
    
            let childItems = []
            for (let i = 0; i < arrOfWeights.length; i += sliderItems) {
                if (i % sliderItems === 0) {
                    childItems.push(
                        <Stack
                            key={i.toString()}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                            minHeight={50}
                            sx={{
                                ml: `${parentLeftPadding}px`,
                                mr: `${parentRightPadding}px`,
                                flexGrow: 1,
                            }}
                        >
                            {arrOfWeights.slice(i, i + sliderItems).map((item, index) => {
                                const isLastItem = (index === ((i + sliderItems) - 1))
                                // const isSelected = (props.weight && item.name === props.weight.name)?true:false
                                const isSelected = (props.pSelectedPriceId && item.priceId === props.pSelectedPriceId)?true:false
                                return(
                                    <ButtonBase key={index.toString()} onClick={(e) => {handleWeightClick(e,item)}}>
                                        <Box
                                            sx={{
                                                minWidth: minItemWidth,
                                                maxWidth: minItemWidth,
                                                height: '100%',
                                                mr: `${(index === 0) ? gap : 0}px`,
                                                // backgroundColor: (theme) => alpha(theme.palette.primary.contrastText,0.05),
                                                border: (isSelected)?'1px solid':'1px solid',
                                                borderColor: (isSelected) ? (theme) => alpha(theme.palette.primary.contrastText,1.0) : (theme) => alpha(theme.palette.primary.contrastText,0.1),
                                                borderRadius: 0,
                                                boxShadow: 'none',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                alignSelf: 'stretch'
                                            }}
                                            className="weight-button"
                                            key={index.toString()}
                                        >
                                            <Box>
                                                <Box className="add-header-container">
                                                    <Typography
                                                        variant="h1"
                                                        className="add-header-title"
                                                        sx={{
                                                            color: (theme) => alpha(theme.palette.primary.contrastText,1.0)
                                                        }}>
                                                            {(isSelected) ? `ADDED` : `ADD`}
                                                    </Typography>
                                                    {!isSelected && <ShoppingCartIcon viewBox="0 0 41 32" sx={{
                                                        fill: '#000',
                                                        width: PixelsToViewPortUnitConverter.getvw(18.68),
                                                        height: PixelsToViewPortUnitConverter.getvw(17)
                                                    }}/>}
                                                    {isSelected && <AddedToCartIcon sx={{
                                                        fill: '#000',
                                                        width: PixelsToViewPortUnitConverter.getvw(18.68),
                                                        height: PixelsToViewPortUnitConverter.getvw(17)
                                                    }}/>}
                                                </Box>
                                                <Typography
                                                    variant="h1"
                                                    className="weight-title"
                                                    sx={{
                                                        color: (theme) => alpha(theme.palette.primary.contrastText,0.49)
                                                    }}>
                                                        {item.name}
                                                </Typography>
                                                {item.getDiscountPrice() && 
                                                    <StrikeLabel variant="overlay-new" value={item.getDiscountPrice()} mb={0}/>
                                                }
                                                <Typography
                                                    variant="h1"
                                                    className="weight-price"
                                                    sx={{
                                                        color: (theme) => alpha(theme.palette.primary.contrastText,1.0)
                                                    }}>
                                                        {item.getSellingPrice()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ButtonBase>
                                )
                            })}
                        </Stack>
                    );
                }
            }
            setItems(childItems)
        }
    }

    if(props.weightDimension.parentWidth <= 0) return null
    if(items.length === 0) return null
    return(
        <Carousel 
            className="weight-carousel-container" 
            animation="slide"
            sx={{
                width: `${props.weightDimension.parentWidth}px`,
                height: '100%',
                pb:'1px'
            }}
            indicators={false} 
            autoPlay={false}
            swipe={false}
            navButtonsAlwaysVisible={true}
            changeOnFirstRender={true}
            cycleNavigation={false}
            navButtonsProps={{ // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                    backgroundColor: 'transparent',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    margin: '0px',
                    marginLeft: `${props.weightDimension.leftM/2}px`,
                    marginRight: `${props.weightDimension.leftM/2}px`
                }
            }} 
            NextIcon={<NextIconV3 sx={{
                fill:'none',
                stroke: '#000',
                backgroundColor: 'transparent',
                width: PixelsToViewPortUnitConverter.getvw(8),
                height: PixelsToViewPortUnitConverter.getvw(23)
            }}/>}
            PrevIcon={<PreviousIconV3 sx={{
                fill:'none',
                stroke: '#000',
                padding: '0px',
                backgroundColor: 'transparent',
                width: PixelsToViewPortUnitConverter.getvw(8),
                height: PixelsToViewPortUnitConverter.getvw(23)
            }}/>}
        >
            {items}
        </Carousel>
    )
}
