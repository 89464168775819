export default class StoreReview {
  private mId: number;
  public get id() {
    return this.mId;
  }

  private mRating: number;
  public get rating() {
    return this.mId;
  }

  private mReview: string;
  public get review() {
    return this.mReview;
  }

  private mDate: number;
  public get reviewDate() {
    return this.mDate;
  }

  public constructor(storeReviewJson: any) {
    this.mId = storeReviewJson.id;
    this.mRating = storeReviewJson.value;
    this.mReview = storeReviewJson.body;
    this.mDate = storeReviewJson.created_at * 1000;
  }
}
