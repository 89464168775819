import React, { useState } from "react";
import "./CheckboxChildrenFilter.scss";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Rating from "@mui/material/Rating";
import { Accordion, Card, CardActionArea, Stack } from "@mui/material";
import { alpha } from "@mui/system";
import AccordionSummary from "@mui/material/AccordionSummary";
import Filter, { FilterType } from "../../../../core/models/Filter";
import FilterAttribute from "../../../../core/models/FilterAttribute";
import ExpandCollapseIcon from "../../../../statics/svgs/ExpandCollapseIcon";
import CardContent from "@mui/material/CardContent";
import TickIcon from "../../../../statics/svgs/TickIcon";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";

type CheckboxChildrenFilterProps = {
  pFilter: Filter;
  pFilterAttribute: FilterAttribute;
  pOnSelectFilterAttribute(
    filter: Filter,
    filterAttribute: FilterAttribute,
    selected: boolean
  ): void;
};

export const CheckboxChildrenFilter: React.FC<CheckboxChildrenFilterProps> = (
  props: CheckboxChildrenFilterProps
) => {
  const [isOpen, setIsOpen] = useState(false);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>,filterAttribute: FilterAttribute) => {
  //     // console.log('name '+filterAttribute.name)
  //     // console.log('checked '+event.target.checked)
  //     /*if(filterAttribute.isAll && filterAttribute.isSelected && !event.target.checked) {
  //         event.target.checked = true
  //         event.preventDefault()
  //     }*/
  //     props.pOnSelectFilterAttribute(props.pFilter,filterAttribute,event.target.checked)
  // };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    filterAttribute: FilterAttribute
  ) => {
    if (filterAttribute.isAll && filterAttribute.isSelected) {
      event.preventDefault();
    }
    const selected = !filterAttribute.isSelected;
    props.pOnSelectFilterAttribute(props.pFilter, filterAttribute, selected);
  };

  const renderLabel = (filterAttribute: FilterAttribute) => {
    let labelTitle = filterAttribute.name;
    if (
      !filterAttribute.isAll &&
      (props.pFilter.type == FilterType.BRANDS ||
        props.pFilter.type == FilterType.ACTIVITIES ||
        props.pFilter.type == FilterType.FEELINGS ||
        props.pFilter.type == FilterType.CATEGORIES ||
        props.pFilter.type == FilterType.PRICE)
    ) {
      if (
        props.pFilter.type == FilterType.CATEGORIES &&
        filterAttribute.subAttributes.length > 0
      ) {
        labelTitle = `> ${filterAttribute.displayName()}`;
      } else {
        labelTitle = filterAttribute.displayName();
      }
    }

    const className = `${
      filterAttribute.isSelected
        ? "checkbox-form-control-label-selected"
        : "checkbox-form-control-label"
    }`;
    return (
      <Typography
        className={className}
        variant="h1"
        sx={{
          fontSize: {
            sm: `${PixelsToViewPortUnitConverter.getFontVw(13)} !important`,
            xs: `${PixelsToViewPortUnitConverter.getFontVw(16)} !important`,
          },
          color: (theme) =>
            filterAttribute.isSelected
              ? alpha(theme.palette.secondary.main, 1.0)
              : alpha(theme.palette.secondary.main, 1.0),
          [`&, & .MuiTypography-root`]: {
            color: (theme) =>
              filterAttribute.isSelected
                ? alpha(theme.palette.secondary.main, 1.0)
                : alpha(theme.palette.secondary.main, 1.0),
          },
        }}
      >
        {labelTitle}
      </Typography>
    );
  };

  const renderFilterAttribute = (filterAttribute: FilterAttribute) => {
    return (
      <Card
        key={filterAttribute.slug}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          marginBottom: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(45),
          },
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(45),
          },
        }}
      >
        <CardActionArea
          onClick={(e) => {
            handleChange(e, filterAttribute);
          }}
        >
          <CardContent
            sx={{
              padding: "0px",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={PixelsToViewPortUnitConverter.getvw(21)}
            >
              <TickIcon
                sx={{
                  width: {
                    sm: PixelsToViewPortUnitConverter.getvw(17),
                    xs: PixelsToViewPortUnitConverter.getvw(52),
                  },
                  height: {
                    sm: PixelsToViewPortUnitConverter.getvw(12),
                    xs: PixelsToViewPortUnitConverter.getvw(47),
                  },
                  visibility: filterAttribute.isSelected ? "visible" : "hidden",
                }}
              />
              {renderLabel(filterAttribute)}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const renderChildrens = (filterAttribute: FilterAttribute) => {
    return filterAttribute.subAttributes.map(
      (filterAttribute: FilterAttribute) =>
        renderFilterAttribute(filterAttribute)
    );
  };

  const expandMoreIcon = () => {
    const className = `expandCollapseIcon ${isOpen && "-selected"}`;
    return (
      <ExpandCollapseIcon className={className} style={{ fontSize: 20 }} />
    );
  };

  return (
    <div className="cmenu-chidren-filter-attribute-wrapper">
      <Accordion
        onChange={(e, expanded) => {
          setIsOpen(expanded);
        }}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          marginBottom: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(30),
          },
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(30),
          },
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            "&.MuiAccordionSummary-root": {
              minHeight: 0,
              // height: '22px',
              padding: "0px 0px",
              // marginBottom: '6px'
            },
            "& .MuiAccordionSummary-content": {
              margin: "0px 0px",
              height: "inherit",
              alignItems: "center",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              //   margin: "18px 0",
            },
          }}
        >
          {expandMoreIcon()}
          <Typography
            sx={{
              color: (theme) => alpha(theme.palette.secondary.main, 1.0),
              fontSize: {
                sm: `${PixelsToViewPortUnitConverter.getFontVw(13)} !important`,
                xs: `${PixelsToViewPortUnitConverter.getFontVw(15)} !important`,
              },
            }}
            className="parent-item-name"
          >
            {props.pFilterAttribute.displayName()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingTop: PixelsToViewPortUnitConverter.getvw(5),
            paddingBottom: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {renderChildrens(props.pFilterAttribute)}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
