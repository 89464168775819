import React, { useEffect } from "react";
import "./ProductCard.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { alpha, Box, Grid, Rating, Theme } from "@mui/material";
import AddToCartIcon from "../../../../statics/svgs/AddToCartIcon";
import Product, { CartProduct } from "../../../../core/models/Product";
import StarIconV2 from "../../../../statics/svgs/StarIconV2";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { ProductCardOverlay } from "./ProductCardOverlay";
import DimensionUtils from "../../../../core/utils/DimensionUtils";
import Weight from "../../../../core/models/Weight";
import { Cart } from "../../../common/cart/CartReducer";
import { light } from "@mui/material/styles/createPalette";
import { relative } from "path";
import PartnerManager from "../../../../core/utils/PartnerManager";
import { PartnerAppThemeType } from "../../../../core/models/PartnerTheme";
import StrikeLabel from "../other/StrikeLabel";
import { withMediaQuery } from "../../../../withMediaQuery";

type ProductCardProps = {
  isMobile: boolean;
  product: Product;
  width: number;
  height: number;
  imageHeight: number;
  isGridItem: boolean;
  pCart: Cart | undefined;
  pOnProductClicked(product: Product): void;
  pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

const ProductCard: React.FC<ProductCardProps> = (props: ProductCardProps) => {
  const [showHoverOverlay, setShowHoverOverlay] = React.useState(false);
  const [overlayHeight, setOverlayHeight] = React.useState(0);
  const [retryImageError, setRetryImageError] = React.useState(0);
  const { product } = props;
  const productName = product.name;
  const productRating = product.aggregateRating;
  const imgUrl = product.getProductCardImageUrl();
  const brandTitle = product.brand;

  const imgVwLeftRight = DimensionUtils.getVwNumber(showHoverOverlay ? 12 : 20);
  const imgVwTopBottom = DimensionUtils.getVwNumber(showHoverOverlay ? 12 : 32);
  let imgVwWidth = props.width - imgVwLeftRight * 2;
  let imgVwHeight = props.imageHeight - imgVwTopBottom * 2;
  imgVwHeight = showHoverOverlay ? imgVwHeight - overlayHeight : imgVwHeight;
  const imgAspectRatio = imgVwWidth / imgVwHeight;
  imgVwWidth = imgVwWidth / imgAspectRatio;

  /*useEffect(() => {
        console.log('3.  ProductCard =>'+JSON.stringify(props.pCart))
    },[props.pCart])*/

  /*useEffect(() => {
        console.log('3....ProductCard =>useEffect =>'+JSON.stringify(props.pCart))
    },[])*/

  const handleMouseOver = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setShowHoverOverlay(true);
  };

  const handleMouseOut = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setShowHoverOverlay(false);
  };

  const handleProductClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      props.pOnProductClicked(props.product);
    }
  };

  const handleImageError = (e) => {
    if (retryImageError === 0) {
      setRetryImageError(1);
      e.target.onerror = null;
      const fallbackUrl = product.getFallbackImageUrl();
      e.target.src = fallbackUrl;
    }
  };

  const handleOnAddOrRemoveProductFromCart = (product: CartProduct) => {
    sendToCart(product);
    //props.pOnAddOrRemoveProductFromCart(product)
  };

  const sendToCart = (product: CartProduct) => {
    if (product.count === 0) {
      props.pOnAddOrRemoveProductFromCart(product);
      setShowHoverOverlay(false);
      return;
    }
    const idOfImage = `product-image-${product.productId}`;
    const imageElement = document.getElementById(idOfImage) as HTMLImageElement;
    const newImageWidth = DimensionUtils.getVwNumber(160); //imgVwWidth
    const newImageHeight = DimensionUtils.getVwNumber(160); //imgVwHeight
    const clone = imageElement.cloneNode(false) as HTMLImageElement;
    clone.id = "animated-product-image";
    clone.className = "animated-product-image";
    clone.style.position = "absolute";
    clone.style.width = newImageWidth + "px"; //imageElement.style.width
    clone.style.height = newImageHeight + "px"; //imageElement.style.height
    clone.style.zIndex = "9999";
    clone.style.margin = "0px";
    clone.style.visibility = "hidden";
    clone.width = newImageWidth;
    clone.height = newImageHeight;
    const navBarHeight = DimensionUtils.getVwNumber(114) + 30; //navbar_h + extra_space
    const cartIconWidth = DimensionUtils.getVwNumber(105.34); // cart container height
    const imgWidth = newImageWidth; //Number(imageElement.style.width.replace('px',''))
    const interMargin = cartIconWidth / 2 - imgWidth / 2;
    const navBarRightPadding = DimensionUtils.getVwNumber(62) + interMargin;

    const { scrollX, scrollY } = window;
    const topPos = navBarHeight + scrollY;
    const rightPos = navBarRightPadding + scrollX;

    clone.style.right = rightPos + "px"; // right_p of image
    clone.style.top = topPos + "px"; // top_p of image

    document.body.appendChild(clone);
    clone.style.visibility = "visible";
    clone.className = "sendtocart";

    let mainTimer = undefined;
    let innerTimer = undefined;

    mainTimer = setTimeout(() => {
      clone.className = "part2";
      innerTimer = setTimeout(() => {
        clone.remove();
        if (innerTimer) {
          clearTimeout(innerTimer);
          innerTimer = undefined;
        }
        if (mainTimer) {
          clearTimeout(mainTimer);
          mainTimer = undefined;
        }
        props.pOnAddOrRemoveProductFromCart(product);
        setShowHoverOverlay(false);
      }, 400);
    }, 800);
  };

  const imgContainerNormalBorderColor = (theme: Theme) => {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.CATEGORIES:
        return alpha(theme.palette.primary.contrastText, 0.1);
      case PartnerAppThemeType.EFFECTS1:
        return theme.palette.secondary.contrastText;
      case PartnerAppThemeType.EFFECTS:
      case PartnerAppThemeType.EFFECTS2:
        return "#FFFFFF";
      default:
        break;
    }
  };

  const imgContainerBorderWidth = () => {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.CATEGORIES:
        return "1px";
      case PartnerAppThemeType.EFFECTS1:
        return "2px";
      case PartnerAppThemeType.EFFECTS:
      case PartnerAppThemeType.EFFECTS2:
        return "1px";
      default:
        break;
    }
  };

  const renderProductCard = () => {
    return (
      <Card
        className="cmenu-product-card"
        sx={{
          height: `${props.height}px`,
          background: "none",
          boxShadow: "none",
        }}
      >
        <Box id="product-card-container" className="product-card-container">
          <Box
            className="product-image-container"
            sx={{
              ml: "2px",
              mr: "2px",
              height: `${props.imageHeight}px`,
              cursor: "pointer",
              borderWidth: imgContainerBorderWidth(),
              borderStyle: "solid",
              borderRadius: "0px",
              backgroundColor: (theme) => theme.palette.primary.main,
              borderColor: (theme) =>
                showHoverOverlay
                  ? theme.palette.primary.contrastText
                  : imgContainerNormalBorderColor(theme),
            }}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
          >
            <Box
              className="product-image-area-container"
              onClick={(e) => {
                if(!props.isMobile){
                  handleProductClick(e)
                }
              }}
            >
              <CardMedia
                id={`product-image-${props.product.id}`}
                component="img"
                className={`product-image ${
                  showHoverOverlay ? "-expanded1" : ""
                }`}
                style={{
                  maxHeight: "100%",
                  backgroundColor: "transparent",
                  objectFit: "cover",
                  cursor: "pointer",
                  margin: `${imgVwTopBottom}px ${imgVwLeftRight}px`,
                  width: `${imgVwWidth}px`,
                  height: `${imgVwHeight}px`,
                }}
                sx={{
                  height: `${imgVwHeight}px`,
                  "&.-expanded1": {
                    height: `${imgVwHeight}px`,
                    transition: "height 0.3s ease",
                    transformOrigin: "bottom",
                  },
                }}
                image={imgUrl}
                alt={productName}
                loading="lazy"
                onClick={handleProductClick}
                onError={handleImageError}
              />
              {!props.isMobile && (
                <ProductCardOverlay
                  display={showHoverOverlay} //showHoverOverlay
                  product={props.product}
                  overlayHeight={props.imageHeight}
                  overlayWidth={props.width}
                  pOnProductOverlayWidthUpdate={(w, h) => {
                    setOverlayHeight(h);
                  }}
                  pCart={props.pCart}
                  pOnAddOrRemoveProductFromCart={
                    handleOnAddOrRemoveProductFromCart
                  }
                />
              )}
            </Box>
          </Box>
          <CardContent
            className={`product-card-content${
              props.isMobile ? " -mobile" : ""
            }`}
            onClick={(e) => {
              if(!props.isMobile){
                handleProductClick(e)
              }
            }}
          >
            <Box className="product-title-container">
              <Typography
                className="product-title"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
              >
                {productName}
              </Typography>
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
                className="rating-label-container"
              >
                <StarIconV2
                  sx={{
                    width: PixelsToViewPortUnitConverter.getvw(16),
                    height: PixelsToViewPortUnitConverter.getvw(16),
                    fill: (theme) => theme.palette.primary.contrastText,
                  }}
                />
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  className="rating-title-label"
                >
                  {productRating}
                </Typography>
              </Box>
            </Box>
            <Box
              className={`product-brand-info-container${
                props.isMobile ? " -mobile" : ""
              }`}
            >
              <Typography
                className="brand-title"
                sx={{
                  color: (theme) =>
                    alpha(theme.palette.primary.contrastText, 0.36),
                }}
              >
                {`${brandTitle && brandTitle.length > 0 ? brandTitle : ""}`}
              </Typography>
            </Box>
            <Box
              className={`product-price-info-container${
                props.isMobile ? " -mobile" : ""
              }`}
            >
              {product.getDiscountPrice() && (
                <StrikeLabel
                  variant="list"
                  value={product.getDiscountPrice()}
                  mr={8}
                />
              )}
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                  pr: PixelsToViewPortUnitConverter.getvw(
                    props.isMobile ? 50 : 0
                  ),
                }}
                className={
                  props.isMobile
                    ? "product-price-label-mobile"
                    : "product-price-label"
                }
              >
                {product.defaultSellingPrice}
              </Typography>
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
                className="product-weight-label"
              >
                {product.defaultWeightTitle}
              </Typography>
            </Box>
            <Box
              className={`product-title-container${
                props.isMobile ? " -mobile" : ""
              }`}
            >
              <Box
                className="rating-label-container"
                sx={{
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <StarIconV2
                  sx={{
                    width: PixelsToViewPortUnitConverter.getvw(35),
                    height: PixelsToViewPortUnitConverter.getvw(35),
                    fill: (theme) => theme.palette.primary.contrastText,
                  }}
                />
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                  className="rating-title-label"
                >
                  {productRating}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
    );
  };

  return (
    <Box
      sx={{
        boxSizing: "border-box;",
      }}
    >
      {renderProductCard()}
    </Box>
  );
};

export default withMediaQuery(ProductCard);
