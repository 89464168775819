import React, { useEffect, useState } from "react";
import "./StrikeLabel.scss";
import { alpha } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import { Box } from "@mui/system";
import StrikeIconV2 from "../../../../statics/svgs/StrikeIconV2";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { withMediaQuery } from "../../../../withMediaQuery";

type StrikeLabelProps = {
  isMobile: boolean;
  value: string;
  variant?: string;
  mr?: number;
  mb?: number;
};

const StrikeLabel: React.FC<StrikeLabelProps> = (props: StrikeLabelProps) => {
  const { mb = 0, mr = 0, variant = "" } = props;

  return (
    <Box
      className="strike-label-container"
      sx={{
        pb: PixelsToViewPortUnitConverter.getvw(mb),
        mr: (variant === 'list') ? PixelsToViewPortUnitConverter.getvw(props.isMobile ? 50 : mr) : '0px',
      }}
    >
      <Box className="strike-container">
        <StrikeIconV2
          sx={{
            stroke: "red",
            width: "100%", //`${refWidth}px`,
            // height: PixelsToViewPortUnitConverter.getvw(50),
          }}
        />
      </Box>
      <Typography
        variant="h1"
        className={`strike-label -${variant}`}
        sx={{
          color: (theme) => alpha(theme.palette.primary.contrastText, 0.35),
        }}
      >
        {props.value}
      </Typography>
    </Box>
  );
};
export default withMediaQuery(StrikeLabel);
