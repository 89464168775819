import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export default function AddedToCartIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 14 20"
    return (
        <SvgIcon {...uProps} fill="none">
            <path d="M5.5625 4.19189L7.4 6.0294L12.3 1.12939" fill="none" stroke="#73B77A" stroke-linecap="round"/>
            <path d="M10.8213 17.4529C10.0773 17.4529 9.47536 18.026 9.47536 18.7265C9.47536 19.427 10.0773 20.0001 10.8213 20.0001C11.5652 20.0001 12.1739 19.427 12.1739 18.7265C12.1739 18.026 11.5652 17.4529 10.8213 17.4529ZM4.05797 17.4529C3.31401 17.4529 2.71208 18.026 2.71208 18.7265C2.71208 19.427 3.31401 20.0001 4.05797 20.0001C4.80193 20.0001 5.41063 19.427 5.41063 18.7265C5.41063 18.026 4.80193 17.4529 4.05797 17.4529ZM4.05797 15.5426L4.80193 14.269H9.84058C10.3478 14.269 10.7942 14.0079 11.0242 13.6131L14 8.53797H2.84734L2.21159 7.2644H0V8.53797H1.35266L3.78744 13.3711L1.77198 16.8161H12.1739V15.5426H4.05797Z"/>
        </SvgIcon>
    );
}

{/* <circle cx="19.5" cy="19.5" r="19" fill="#3A2D25" stroke="black"/>
            <path d="M23 10L15 19L23 28" stroke="white"/> */}