import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import StoreReview from "../../../../core/models/StoreReview";
import DateUtils from "../../../../core/utils/DateUtils";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../../core/webservice/ApiError";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import Loader from "../../../common/loader/Loader";
import { alpha } from "@mui/material/styles";
import Product from "../../../../core/models/Product";
import MenuService from "../../../../services/MenuService";
import ProductReview from "../../../../core/models/ProductReview";
import ProductReviewList from "../../../../core/models/ProductReviewList";

interface IProductReviewsComponentProps {
  product: Product;
}

interface IProductReviewsComponentState {
  sProductReviewList: ProductReviewList;
  sIsLoadingReviews: boolean;
  sLoadingError: boolean;
  sLoadingErrorMsg: String;
}

export default class ProductReviewsComponent extends React.Component<
  IProductReviewsComponentProps,
  IProductReviewsComponentState
> {
  constructor(props: IProductReviewsComponentProps) {
    super(props);

    const defReviewList = new ProductReviewList();
    defReviewList.initWithDefault();

    this.state = {
      sProductReviewList: defReviewList,
      sIsLoadingReviews: true,
      sLoadingError: false,
      sLoadingErrorMsg: "",
    };
  }

  componentDidMount() {
    this.getProductReviewDetails(this.props.product.id);
  }

  private getProductReviewDetails(productId: number) {
    // if(this.state.sIsLoadingReviews) return;
    this.setState({ sIsLoadingReviews: true });
    MenuService.getProductReviews(productId)
      .then((reviewList: ProductReviewList) => {
        this.setState({
          sProductReviewList: reviewList,
          sIsLoadingReviews: false,
        });
      })
      .catch((apiError: ApiError) => {
        this.setState({
          sLoadingError: true,
          sLoadingErrorMsg: apiError.message,
          sIsLoadingReviews: false,
        });
      });
  }

  private renderSeeAllButton() {
    if (!this.state.sProductReviewList) return null;
    if (this.state.sProductReviewList.reviews.length === 0) return null;
    return null;
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: PixelsToViewPortUnitConverter.getvw(56),
          width: "100%",
        }}
      >
        <ButtonBase>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              border: "2px solid #FFFFFF",
              width: PixelsToViewPortUnitConverter.getFontVw(353),
              height: PixelsToViewPortUnitConverter.getFontVw(58),
            }}
          >
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 600,
                fontStyle: "normal",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
                fontHeight: PixelsToViewPortUnitConverter.getFontVw(17),
                letterSpacing: "0.2em",
                margin: "auto",
                color: (theme) => theme.palette.primary.contrastText,
                padding: "0px",
                minHeight: "0px",
                minWidth: "0px",
              }}
            >
              {`SEE ALL`}
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
    );
  }

  render() {
    return (
      <Stack
        sx={{
          position: "relative",
          marginTop: {
            sm: PixelsToViewPortUnitConverter.getvw(100),
            xs: PixelsToViewPortUnitConverter.getvw(200),
          },
          marginRight: PixelsToViewPortUnitConverter.getvw(0),
          marginLeft: PixelsToViewPortUnitConverter.getvw(0),
          marginBottom: PixelsToViewPortUnitConverter.getvw(0),
          display: "flex",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontFamily: "Bebas",
              fontWeight: 400,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(28),
              letterSpacing: "0.1em",
              textAlign: "start",
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            {`REVIEWS OF ${this.props.product.name}`}
          </Typography>
          {this.state.sProductReviewList.reviews.length > 0 &&
            PartnerManager.shared().currentSelectedStore.reviewsCount > 6 && (
              <Button
                variant="text"
                sx={{
                  display: "none",
                  fontFamily: "Barlow",
                  fontWeight: 400,
                  fontStyle: "italic",
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                  letterSpacing: "0.1em",
                  textAlign: "end",
                  marginLeft: "auto",
                  color: (theme) => theme.palette.primary.contrastText,
                  padding: "0px",
                  minHeight: "0px",
                  minWidth: "0px",
                  marginRight: PixelsToViewPortUnitConverter.getvw(24),
                }}
              >
                SEE ALL REVIEWS
              </Button>
            )}
        </Stack>
        <Stack
          direction="row"
          alignItems="end"
          sx={{
            display: {
              sm: "flex",
              xs: "block",
            },
            textAlign: {
              sm: "unset",
              xs: "left",
            },
          }}
        >
          <Rating
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(50),
              marginTop: {
                sm: PixelsToViewPortUnitConverter.getvw(20),
                xs: PixelsToViewPortUnitConverter.getvw(60),
              },
              marginBottom: {
                sm: PixelsToViewPortUnitConverter.getvw(0),
                xs: PixelsToViewPortUnitConverter.getvw(60),
              },
            }}
            defaultValue={this.props.product.aggregateRating}
            precision={0.5}
            readOnly
          />
          <Typography
            sx={{
              fontFamily: "Barlow",
              fontWeight: 500,
              fontSize: {
                sm: PixelsToViewPortUnitConverter.getFontVw(24),
                xs: PixelsToViewPortUnitConverter.getFontVw(18),
              },
              letterSpacing: "0.2em",
              color: (theme) => theme.palette.primary.contrastText,
              marginLeft: {
                sm: PixelsToViewPortUnitConverter.getvw(40),
                xs: PixelsToViewPortUnitConverter.getvw(40),
              },
            }}
          >
            {this.props.product.reviewCount === 0
              ? "No reviews"
              : `${this.props.product.reviewCount} reviews`}
          </Typography>
        </Stack>
        <Divider
          sx={{
            marginTop: {
              sm: PixelsToViewPortUnitConverter.getvw(56),
              xs: PixelsToViewPortUnitConverter.getvw(100),
            },
            marginBottom: {
              sm: PixelsToViewPortUnitConverter.getvw(0),
              xs: PixelsToViewPortUnitConverter.getvw(70),
            },
            color: (theme) => theme.palette.primary.contrastText,
          }}
        />
        <Box sx={{ position: "relative", minHeight: "100px", width: "100%" }}>
          {this.state.sIsLoadingReviews ? (
            <Loader pshow={true} />
          ) : (
            this.renderReviews()
          )}
        </Box>
        {this.renderSeeAllButton()}
      </Stack>
    );
  }

  private renderReviews(): React.ReactNode {
    return (
      <Stack
        direction="row"
        sx={{
          width: "100%",
          marginTop: PixelsToViewPortUnitConverter.getvw(64),
          display: "flex",
          flexWrap: "wrap",
          alignItems: "space-between",
          justifyContent: "space-between",
        }}
      >
        {this.state.sProductReviewList.reviews.map((productReview, index) => (
          <Stack
            sx={{
              width: {
                sm: "20.8vw",
                xs: "87vw",
              },
              height: {
                sm: "auto",
                xs: "auto",
              },
              display: "flex",
              alignItems: {
                sm: "center",
                xs: "left",
              },
            }}
          >
            <Stack direction="row" alignItems="center">
              <Rating
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                  fontSize: {
                    sm: PixelsToViewPortUnitConverter.getFontVw(34.44),
                    xs: PixelsToViewPortUnitConverter.getFontVw(32),
                  },
                }}
                defaultValue={productReview.rating}
                precision={0.5}
                readOnly
              />
              <Typography
                sx={{
                  fontFamily: "Barlow",
                  fontWeight: 500,
                  letterSpacing: "0.2em",
                  fontSize: PixelsToViewPortUnitConverter.getFontVw(18),
                  marginLeft: {
                    sm: PixelsToViewPortUnitConverter.getvw(28),
                    xs: PixelsToViewPortUnitConverter.getvw(120),
                  },
                  color: (theme) => theme.palette.primary.contrastText,
                  lineHeight: "unset",
                }}
              >
                {productReview.displayReviewDate()}
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 400,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                letterSpacing: "0.1em",
                marginRight: "auto",
                marginLeft: {
                  sm: "0",
                  xs: PixelsToViewPortUnitConverter.getvw(20),
                },
                color: (theme) =>
                  alpha(theme.palette.primary.contrastText, 0.7),
                overflow: "hidden",
                textOverflow: "unset",
                display: "-webkit-box",
                WebkitLineClamp: "unset",
                WebkitBoxOrient: "vertical",
                marginTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(20),
                  xs: PixelsToViewPortUnitConverter.getvw(60),
                },
                marginBottom: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(110),
                },
                lineClamp: "3",
                textAlign: "left",
                maxLines: "3",
              }}
            >
              {productReview.review}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  }
}
