import React, { useEffect } from 'react';
import './RangeSlider.scss';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Filter from '../../../../core/models/Filter';
import RangeAttribute from '../../../../core/models/RangeAttribute';

interface RangeSliderProps {
    pFilter: Filter;
    pOnSelectRangeAttribute(filter: Filter, rangeAttribute: RangeAttribute): void;
}

const RangeSliderComp = styled(Slider)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '100%',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginLeft:11.5,
    "&.second-thumb": {
      marginLeft:-11.5,
    },
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .slider-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

interface RangeThumbComponentProps extends React.HTMLAttributes<unknown> {}

function RangeThumbComponent(props: RangeThumbComponentProps) {
  const { children, className, ...other } = props;
  const extraClassName =
    other["data-index"] === 0 ? "first-thumb" : "second-thumb";
  return (
    <SliderThumb {...other} className={`${className} ${extraClassName}`}>
      {children}
      <span className="slider-bar" />
      <span className="slider-bar" />
      <span className="slider-bar" />
    </SliderThumb>
  );
}

export const RangeSlider: React.FC<RangeSliderProps> = (props: RangeSliderProps) => {

    const min = props.pFilter.rangeAttribute.minSelected ? ((props.pFilter.rangeAttribute.minSelected * 100)/props.pFilter.rangeAttribute.max) : 0
    const max = props.pFilter.rangeAttribute.maxSelected ? ((props.pFilter.rangeAttribute.maxSelected * 100)/props.pFilter.rangeAttribute.max) : 100
      
    const [rangeDefValue, setRangeDefValue] = React.useState<number[]>([0, 100]);
    const [rangeValue, setRangeValue] = React.useState<number[]>([min,max])
    const { pFilter, pOnSelectRangeAttribute } = props

    useEffect(() => {
      const minU = props.pFilter.rangeAttribute.minSelected ? ((props.pFilter.rangeAttribute.minSelected * 100)/props.pFilter.rangeAttribute.max) : 0
      const maxU = props.pFilter.rangeAttribute.maxSelected ? ((props.pFilter.rangeAttribute.maxSelected * 100)/props.pFilter.rangeAttribute.max) : 100
      setRangeValue([minU,maxU])
    },[props.pFilter])

    const renderMinMaxLabels = () => {
      let min = Math.round(rangeDefValue[0]/pFilter.rangeAttribute.steps)
      let max = Math.round(rangeDefValue[1]/pFilter.rangeAttribute.steps)
      if(rangeValue.length > 0) {
        min = Math.round(rangeValue[0]/pFilter.rangeAttribute.steps)
        max = Math.round(rangeValue[1]/pFilter.rangeAttribute.steps)
      }
      return(
        <div className='range-slider-header'>
            <Typography className='min-text-info'
              sx={{color:(theme) => theme.palette.secondary.main}}
            >{`Min ${min}%`}</Typography>
            <Typography 
              className='max-text-info'
              sx={{color:(theme) => theme.palette.secondary.main}}
              >{`Max ${max}%`}</Typography>
        </div>
      )
    }

    const handleChangeCommitted = (value: number | number[]) => {
      if (!Array.isArray(value)) {
        return;
      }
      const min = Math.round(value[0]/pFilter.rangeAttribute.steps)
      const max = Math.round(value[1]/pFilter.rangeAttribute.steps)
      let updatedRangeAttribute = pFilter.rangeAttribute
      updatedRangeAttribute.selected(min,max) 
      pOnSelectRangeAttribute(pFilter,updatedRangeAttribute)
    };

    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
      ) => {
        if (!Array.isArray(newValue)) {
          return;
        }
        setRangeValue(newValue);
  };

  return (
    <div className='cmenu-range-slider-wrapper'>
        {renderMinMaxLabels()}
        <div className='range-slider-ui-item'>
          <RangeSliderComp
              components={{ Thumb: RangeThumbComponent }}
              onChange={handleChange}
              onChangeCommitted={(e,value) => handleChangeCommitted(value)}
              step={pFilter.rangeAttribute.steps}
              value={rangeValue.length>0 ? rangeValue : rangeValue}
              defaultValue={rangeDefValue}
              disableSwap
          />
        </div>
    </div>
  );
}
