import styled from "@emotion/styled";
import { Button, alpha, Box } from "@mui/material";
import React from "react";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import withRouter from "../../../../withRouter";

interface IHeaderButtonProps {
  pName: string;
  pIsSelected: boolean;
  onClick(): void;
  onPointerEnter(): void;
  onPointerLeave(): void;
}

class HeaderButton extends React.Component<IHeaderButtonProps, any> {
  constructor(props: IHeaderButtonProps) {
    super(props);
  }

  render() {
    return (
      <Box
        sx={{
          position: "relative",
          marginRight: PixelsToViewPortUnitConverter.getvw(14),
          display:{
            sm:"unset",
            xs:"none"
          }
        }}
        onPointerEnter={() => {
          if(this.props.onPointerEnter) {
            this.props.onPointerEnter();
          }
        }}
        onPointerLeave={() => {
          if(this.props.onPointerLeave) {
            this.props.onPointerLeave();
          }
        }}
      >
        {this.props.pIsSelected && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              marginX: "auto",
              backgroundColor: "white",
              height: PixelsToViewPortUnitConverter.getvw(14),
              marginBottom: PixelsToViewPortUnitConverter.getvw(-4),
              marginLeft: PixelsToViewPortUnitConverter.getvw(26),
              marginRight: PixelsToViewPortUnitConverter.getvw(26),
            }}
          />
        )}
        <Button
          sx={{
            position: "relative",
            color: (theme) => theme.palette.primary.contrastText,
            lineHeight: "0px",
            padding: "0px 0px",
            fontFamily: "Barlow",
            letterSpacing: "0.16em",
            fontWeight: "700",
            maxWidth: PixelsToViewPortUnitConverter.getvw(150),
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block",

            fontSize: PixelsToViewPortUnitConverter.getFontVw(14),
            paddingLeft: PixelsToViewPortUnitConverter.getvw(20),
            paddingRight: PixelsToViewPortUnitConverter.getvw(20),
            paddingTop: PixelsToViewPortUnitConverter.getvw(12),
            paddingBottom: PixelsToViewPortUnitConverter.getvw(12),
          }}
          onClick={() => {
            this.props.onClick();
          }}
        >
          {this.props.pName}
        </Button>
      </Box>
    );
  }
}

export default withRouter(HeaderButton);
