export default class PixelsToViewPortUnitConverter {
  private static guidelineBaseWidth = 1920;
  private static guidelineBaseWidthForFont = 1920;
  private static guidelineBaseHeight = 1300;
  public static sScreenPortraitHeight = window.innerHeight;

  private constructor() {}

  public static changeBaseWidth(basewidth: number) {
    this.guidelineBaseWidth = basewidth;
  }

  public static changeFontBaseWidth(basewidth: number) {
    this.guidelineBaseWidthForFont = basewidth;
  }

  public static getBaseWidth(): number {
    return this.guidelineBaseWidth;
  }

  public static generateFourDigitRandomNumber(): number {
    return Math.floor(1000 + Math.random() * 9000);
  }

  public static getvw(target: number) {
    return `${(target / (this.guidelineBaseWidth * 0.01)) * 1}vw`; // vw
  }

  public static getvh(target: number) {
    return `${(target / (this.guidelineBaseHeight * 0.01)) * 1}vh`; // vw
  }

  public static getFontVw(target: number) {
    return `${((target + 1) / (this.guidelineBaseWidthForFont * 0.01)) * 1}vw`; // vw
  }

  public static getFontVh(target: number) {
    return `${((target + 1) / (this.guidelineBaseHeight * 0.01)) * 1}vh`; // vw
  }

  // temp function/need to replace
  public static getProductCardDimension(
    parentWidth: number,
    productCount: number
  ) {
    const baseItemWidth = 340;
    const baseItemHeight = 391;
    const itemRatio = baseItemWidth / baseItemHeight;
    // w-340, h-391 | ratio = (340/391) = 0.8695652174
    const divLeftRightPadding = 0; //(10+10)
    const parentDivWidth = parentWidth - divLeftRightPadding;
    const leftRightPadding = (window.screen.width * 12.5) / 1920; //6
    // console.log('window.screen.width :'+(window.screen.width*baseItemWidth)/1920)
    // let minItemWidth = (parentWidth*baseItemWidth)/1920
    let minItemWidth = 170; //+8+8
    const t1 = parentDivWidth / (minItemWidth + leftRightPadding * 2);
    const numberOfItemsPerPage = t1 | 0;
    const sliderItems: number =
      productCount > numberOfItemsPerPage ? numberOfItemsPerPage : productCount;

    const totalPadding = leftRightPadding * 2;
    const leftSpace =
      parentDivWidth -
      (minItemWidth * numberOfItemsPerPage +
        totalPadding * numberOfItemsPerPage);
    const singleSpace = leftSpace / numberOfItemsPerPage;
    minItemWidth = minItemWidth + singleSpace;
    const minItemHeight = minItemWidth / itemRatio;
    const result = {
      parentWidth: parentWidth,
      width: minItemWidth,
      height: minItemHeight,
      itemsPerPage: sliderItems,
      parentLeftRightPadding: leftRightPadding,
    };
    // console.log(result)
    return result;
  }
}
