import React, { useState, useEffect } from "react";
import "./CategoryWebMenuComponent.scss";
import BreadcrumbsComponent from '../breadcrumbs/BreadcrumbsComponent';
import FiltersComponent from '../filter/FiltersComponent';
import ProductSection from './ProductSection';
import ProductListHeader from './ProductListHeader';
import Filter from '../../../../core/models/Filter';
import FilterAttribute from '../../../../core/models/FilterAttribute';
import RangeAttribute from '../../../../core/models/RangeAttribute';
import FilterList from "../../../../core/models/FilterList";
import AppliedFilter from "../../../../core/models/AppliedFilter";
import SortingItem from "../../../../core/models/SortingItem";
import ProductSectionModel from "../../../../core/models/ProductSectionModel";
import Product, { CartProduct } from '../../../../core/models/Product';
import FilterUtils from "../../../../core/utils/FilterUtils";
import { Box } from "@mui/material";
import LineageComponent from "./LineageComponent";
import { Cart } from "../../../common/cart/CartReducer";
import PixelsToViewPortUnitConverter from '../../../../core/utils/PixelsToViewPortUnitConverter';
import { NavigationProps } from "../../../../Navigation.types";
import LocationBtn from "../../../common/locationBtn/LocationBtn";
import { withMediaQuery } from "../../../../withMediaQuery";

type CategoryWebMenuComponentProps = {
    router: NavigationProps;
    isMobile: boolean;
    pFilters?: Filter[];
    filterList: FilterList;
    appliedFilterList: AppliedFilter[];
    pCart: Cart | undefined;
    pIsFiltersLoading: boolean;
    pOnFilterHeaderTapped?(filter: Filter): void;
    pOnSelectFilterAttribute?(filter: Filter, filterAttribute: FilterAttribute, selected: boolean): void;
    pOnSelectRangeAttribute?(filter: Filter, rangeAttribute: RangeAttribute): void;
    onRemoveAppliedFilter(appliedFilter: AppliedFilter): void;
    onSelectSortingOption(sortingItem: SortingItem): void;
    pOnSeeAllTapped(productSectionModel: ProductSectionModel): void;
    pOnProductClicked(product: Product): void;
    pOnAddOrRemoveProductFromCart(product: CartProduct): void;
    pOnResetClicked(): void;
    onSetFilterToggleInMobileMode:any;
    filterToggleInMobileMode:boolean;
};

const CategoryWebMenuComponent: React.FC<CategoryWebMenuComponentProps> = (props: CategoryWebMenuComponentProps) => {
    
    const[isSectionExpanded, setIsSectionExpanded] = useState(false)

    useEffect(() => {
        let sIsSectionOpen = false
        if (props.filterList.filters.length > 0 && props.filterList.productsCount > 0) {
            const filterList = [...props.filterList.filters];
            const productSectionsList = FilterUtils.getProductSections(filterList);
            sIsSectionOpen = FilterUtils.isAnyProductSectionOpen(productSectionsList);
        }
        setIsSectionExpanded(sIsSectionOpen)          
    },[props.filterList.filters])

    const renderProductSection = (productSectionModel: ProductSectionModel) => {
        return (
          <ProductSection
            key={productSectionModel.name}
            productSectionModel={productSectionModel}
            filterList={props.filterList}
            pCart={props.pCart}
            pOnSeeAllTapped={props.pOnSeeAllTapped}
            pOnProductClicked={props.pOnProductClicked}
            pOnAddOrRemoveProductFromCart={props.pOnAddOrRemoveProductFromCart}
          />
        );
    }
    
    const renderAllProductsInfo = () => {
        return (
          <ProductListHeader
         
          onSetFilterToggleInMobileMode={props.onSetFilterToggleInMobileMode}
          filterToggleInMobileMode={props.filterToggleInMobileMode}

          pFilters={props.filterList.filters}
          pIsFiltersLoading={props.pIsFiltersLoading}
          pOnFilterHeaderTapped={props.pOnFilterHeaderTapped}
          pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
          pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
          pOnResetClicked={props.pOnResetClicked}
            filterList={props.filterList}
            appliedFilterList={
                props.filterList && props.filterList.appliedFiltersList
            }
            isSectionExpanded={isSectionExpanded}
            onRemoveAppliedFilter={props.onRemoveAppliedFilter}
            onSelectSortingOption={props.onSelectSortingOption}
          />
        );
    }
    
    const renderSections = () => {
        if (props.filterList.filters.length == 0) return null;
        if (props.filterList.productsCount <= 0) return null;
        const filterList = [...props.filterList.filters];
        const productSectionsList = FilterUtils.getProductSections(filterList);
        return productSectionsList.map((pSectionModel: ProductSectionModel) =>
          renderProductSection(pSectionModel)
        );
    }

    const renderLineageList = () => {
        return null // hide as discussed with abnesh
        return(
            <LineageComponent 
                pFilters={props.filterList.filters}
                isSectionExpanded={isSectionExpanded} 
                pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}/>
        )
    }

    const isMobileClass = (props.isMobile) ? '-mobile': ''
    const sectionContainerClass = (isSectionExpanded) ? `product-sections-container -open${isMobileClass}`:'product-sections-container'
    const breadcrumbClassName = (isSectionExpanded) ? 'breadcrum-container -open' : 'breadcrum-container'
    const isFilterDataLoaded = (props.filterList.filters && props.filterList.filters.length > 0) ? true : false
    return(
        <Box 
            className="categories-web-menu-container"
            sx={{
                backgroundColor: (theme) => theme.palette.primary.main
            }}>
            <FiltersComponent
                pFilters={props.filterList.filters}
                pIsFiltersLoading={props.pIsFiltersLoading}
                appliedFilterList={
                    props.filterList && props.filterList.appliedFiltersList
                }
                pOnFilterHeaderTapped={props.pOnFilterHeaderTapped}
                pOnSelectFilterAttribute={props.pOnSelectFilterAttribute}
                pOnSelectRangeAttribute={props.pOnSelectRangeAttribute}
                pOnResetClicked={props.pOnResetClicked}
            />
            <Box 
                className={sectionContainerClass}>
                {isFilterDataLoaded && <div className={breadcrumbClassName}>
                
                    <BreadcrumbsComponent
                        isSectionExpanded={isSectionExpanded} 
                        router={props.router}/>
                       
                </div>}
                <LocationBtn />
                {renderLineageList()}
                {renderAllProductsInfo()}
                {renderSections()}
            </Box>
        </Box>
    )
}

export default withMediaQuery(CategoryWebMenuComponent)





