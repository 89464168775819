import {
  Button,
  CircularProgress,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { useState } from "react";
import DimensionUtils from "../../../core/utils/DimensionUtils";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../core/webservice/ApiError";
import UserAuthService from "../../../services/UserAuthService";
import ErrorLabel from "../../themes/effectsv2/other/ErrorLabel";

interface IChangePasswordComponentProps {
  onChangePasswordCanceled(): void;
  onChangePasswordSucceed(): void;
}

const StyledPasswordTextField = styled(TextField)(({ theme }) => ({
  width: PixelsToViewPortUnitConverter.getvw(364),
  marginTop: PixelsToViewPortUnitConverter.getvw(20),
  "& .MuiInputBase-input.MuiInput-input": {
    fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
    fontFamily: "Barlow",
    fontWeight: "400",
    color: theme.palette.primary.contrastText,
    letterSpacing: "0.1em",
  },
  "& .MuiInput-underline:before": {
    borderColor: theme.palette.primary.contrastText,
    borderBottomWidth: PixelsToViewPortUnitConverter.getvw(1),
  },
  "& .MuiInput-underline:after": {
    borderColor: theme.palette.primary.contrastText,
    borderBottomWidth: PixelsToViewPortUnitConverter.getvw(2),
  },
  "& .MuiInput-underline:hover:before": {
    borderColor: theme.palette.primary.contrastText,
    borderBottomWidth: PixelsToViewPortUnitConverter.getvw(1),
  },
}));

export default function ChangePasswordComponent(
  _props: IChangePasswordComponentProps
) {
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: PixelsToViewPortUnitConverter.getvw(3),
    height: PixelsToViewPortUnitConverter.getvw(51),
    width: PixelsToViewPortUnitConverter.getvw(180),
  }));

  const SxFormHelperTextProps = {
    fontSize: PixelsToViewPortUnitConverter.getFontVw(12),
    fontFamily: "Barlow",
    fontWeight: "500",
    color: (theme) => theme.palette.primary.contrastText,
    letterSpacing: "0.1em",
  };

  const [isChanging, setIsChanging] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);

  function validateForm() {
    return (
      oldPassword.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  function handleChangeClick(event) {
    event.preventDefault();
    if (isChanging) return;
    setIsChanging(true);
    setErrorMessage(undefined);

    try {
      UserAuthService.changePassword(oldPassword, password)
        .then((_) => {
          setIsChanging(false);
          _props.onChangePasswordSucceed();
        })
        .catch((error: ApiError) => {
          setIsChanging(false);
          setErrorMessage(error.message);
        });
    } catch (error) {
      setIsChanging(false);
    }
  }

  return (
    <Stack sx={{ alignItems: "flex-start" }}>
      <StyledPasswordTextField
        helperText="Enter your current password"
        type="password"
        variant="standard"
        onChange={(event) => {
          setOldPassword(event.currentTarget.value);
        }}
        value={oldPassword}
        sx={{ width: {
          sm: PixelsToViewPortUnitConverter.getvw(364),
          xs:'100%'
        },}}
        FormHelperTextProps={{
          sx: {
            ...SxFormHelperTextProps,
          },
        }}
      />
      <StyledPasswordTextField
        helperText="Enter your new password "
        type="password"
        variant="standard"
        onChange={(event) => {
          setPassword(event.currentTarget.value);
        }}
        value={password}
        sx={{ width: {
          sm: PixelsToViewPortUnitConverter.getvw(364),
          xs:'100%'
        },}}
        FormHelperTextProps={{
          sx: {
            ...SxFormHelperTextProps,
          },
        }}
      />
      <StyledPasswordTextField
        helperText="Re-enter your new password "
        type="password"
        variant="standard"
        onChange={(event) => {
          setConfirmPassword(event.currentTarget.value);
        }}
        value={confirmPassword}
        sx={{ width: {
          sm: PixelsToViewPortUnitConverter.getvw(364),
          xs:'100%'
        },}}
        FormHelperTextProps={{
          sx: {
            ...SxFormHelperTextProps,
          },
        }}
      />

      <ErrorLabel errorMessage={errorMessage} mt={17} />

      <Stack
        direction="row"
        sx={{ marginTop: PixelsToViewPortUnitConverter.getvw(40) }}
      >
        <StyledButton sx ={{ padding: {
                  xs:"18px"                 
                  },
                  width:{
                    xs:PixelsToViewPortUnitConverter.getvw(377),
                    sm:PixelsToViewPortUnitConverter.getvw(180)
                    } 
                }}
          disabled={isChanging}
          variant="outlined"
          onClick={() => {
            _props.onChangePasswordCanceled();
          }}
        >
          cancel
        </StyledButton>
        <StyledButton
          variant="contained"
          disabled={!validateForm()}
          sx={{
            marginLeft: PixelsToViewPortUnitConverter.getvw(12),
            color: (theme) => theme.palette.secondary.contrastText,
            padding: {
              xs:"18px"                 
              },
              width:{
xs:PixelsToViewPortUnitConverter.getvw(377),
sm:PixelsToViewPortUnitConverter.getvw(180)
} 
          }}
          onClick={(event) => {
            handleChangeClick(event);
          }}
        >
          {!isChanging && "Confirm"}
          {isChanging && (
            <CircularProgress
              size={DimensionUtils.getVwNumber(20)}
              sx={{ color: (theme) => theme.palette.secondary.contrastText }}
            />
          )}
        </StyledButton>
      </Stack>
    </Stack>
  );
}
