import React from "react";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import PartnerManager from "../../../../core/utils/PartnerManager";
import IconButton from "@mui/material/IconButton/IconButton";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import TextUtils from "../../../../core/utils/TextUtils";
import DailyWorkingHours from "../../../../core/models/DaliyWorkingHours";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import Store from "../../../../core/models/Store";
import connect from "react-redux/es/components/connect";
import { RootState } from "../../../../redux/Store";
import Avatar from "@mui/material/Avatar";
import Facebook from "../../../../statics/images/facebook.png";
import Instagram from "../../../../statics/images/instagram.png";
import Pinterest from "../../../../statics/images/pinterest.png";
import Snap from "../../../../statics/images/snap.png";
import Twitter from "../../../../statics/images/twitter.png";
import Youtube from "../../../../statics/images/youtube.png";
import BottomBar from "../../../common/bottombar/BottomBar";
import withRouter from "../../../../withRouter";
import { NavigationProps } from "../../../../Navigation.types";
import AppRoutes from "../../../../routes";

interface IFooterProps {
  router: NavigationProps;
  pStoreId: number;
}
interface IFooterState {
  sStoreDetails: Store | undefined;
}
class Footer extends React.Component<IFooterProps, IFooterState> {
  constructor(props: IFooterProps) {
    super(props);
    this.state = { sStoreDetails: undefined };
  }
  componentDidMount() {
    this.fetchStoreDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pStoreId != prevProps.pStoreId) this.fetchStoreDetails();
  }

  private fetchStoreDetails() {
    StoreOperationsService.getStoreDetails(
      PartnerManager.shared().currentSelectedStore.id
    ).then((storeDetails) => {
      this.setState({
        sStoreDetails: storeDetails,
      });
    });
  }

  render() {
    let pdpValidation = window.location.pathname.split(/\/(?=.)/)[2];
    return (
      <Stack
        sx={{
          flexShrink: "0",
          backgroundColor: "#303030",
          paddingLeft: {
            sm: PixelsToViewPortUnitConverter.getvw(60),
            xs: PixelsToViewPortUnitConverter.getvw(130),
          },
          paddingBottom: {
            sm: PixelsToViewPortUnitConverter.getvw(0),
            xs: PixelsToViewPortUnitConverter.getvw(300),
          },
          paddingRight: {
            sm: PixelsToViewPortUnitConverter.getvw(60),
            xs: PixelsToViewPortUnitConverter.getvw(130),
          },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          display={{ sm: "flex", xs: "none" }}
          sx={{
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "center",
            marginTop: PixelsToViewPortUnitConverter.getvw(20),
            marginBottom: PixelsToViewPortUnitConverter.getvw(50),
          }}
        >
          <img
            style={{ width: "16vw" }}
            src={PartnerManager.shared().partner.theme.logoUrl}
          />
          <Typography
            sx={{
              fontFamily: "Bebas",
              fontWeight: 400,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(46),
              color: "#ffffff",
              letterSpacing: "0.1em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              lineClamp: "1",
              maxLines: "1",
              marginTop: PixelsToViewPortUnitConverter.getvw(20),
            }}
          >
            {PartnerManager.shared().currentSelectedStore.name}
          </Typography>
        </Box>

        <Box
          display={{ xs: "none", lg: "block" }}
          sx={{
            width: "1px",
            alignSelf: "stretch",
            backgroundColor: "rgba(255, 255, 255, 0.12)",
            marginLeft: PixelsToViewPortUnitConverter.getvw(160),
            marginRight: PixelsToViewPortUnitConverter.getvw(80),
          }}
        />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{
            flexGrow: 1,
            display: {
              sm: "flex",
              xs:
                (window.location.pathname === AppRoutes.LOGIN ||
                  window.location.pathname === AppRoutes.SIGNUP ||
                  window.location.pathname === AppRoutes.SEARCH ||
                  pdpValidation != undefined ||
                  window.location.pathname === AppRoutes.CART) &&
                "none",
            },
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: PixelsToViewPortUnitConverter.getvw(40),
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: "Bebas",
                fontWeight: 400,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(26),
                color: "#737373",
                letterSpacing: "0.1em",
                textAlign: "left",
                marginBottom: PixelsToViewPortUnitConverter.getvw(30),
                paddingTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(200),
                },
              }}
            >
              HOURS
            </Typography>
            {this.state.sStoreDetails && (
              <Stack direction="row" sx={{ width: { xs: "55vw", sm: "auto" } }}>
                {this.workingHoursTableRender(
                  this.state.sStoreDetails.storeWorkingHours
                )}
              </Stack>
            )}
          </Stack>

          <Stack>
            <Typography
              sx={{
                fontFamily: "Bebas",
                fontWeight: 400,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(26),
                color: "#737373",
                letterSpacing: "0.1em",
                textAlign: "left",
                paddingTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(100),
                },
                marginBottom: PixelsToViewPortUnitConverter.getvw(30),
              }}
            >
              LOCATION
            </Typography>
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 700,
                textAlign: "left",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                color: "#BCBCBC",
                marginBottom: {
                  xs: PixelsToViewPortUnitConverter.getvw(30),
                  sm: 0,
                },
              }}
            >
              {PartnerManager.shared().currentSelectedStore.fullAddress}
            </Typography>
          </Stack>

          <Stack>
            <Typography
              sx={{
                fontFamily: "Bebas",
                fontWeight: 400,
                fontSize: PixelsToViewPortUnitConverter.getFontVw(26),
                color: "#737373",
                letterSpacing: "0.1em",
                textAlign: "left",
                paddingTop: {
                  sm: PixelsToViewPortUnitConverter.getvw(0),
                  xs: PixelsToViewPortUnitConverter.getvw(100),
                },
                marginBottom: PixelsToViewPortUnitConverter.getvw(30),
              }}
            >
              CONTACT US
            </Typography>
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontWeight: 700,
                textAlign: "left",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                color: "#BCBCBC",
              }}
            >
              {TextUtils.formatUSPhoneNumber(
                PartnerManager.shared().currentSelectedStore.phone
              )}
            </Typography>
            <Stack
              sx={{
                marginTop: {
                  xs: PixelsToViewPortUnitConverter.getvw(40),
                },
                paddingBottom: {
                  xs: PixelsToViewPortUnitConverter.getvw(124),
                  sm: PixelsToViewPortUnitConverter.getvw(24),
                },
              }}
              direction="row"
            >
              {this.storeSocialMedialLinkButton(
                PartnerManager.shared().partner.facebookUrl,
                Facebook
              )}
              {this.storeSocialMedialLinkButton(
                PartnerManager.shared().partner.instagramUrl,
                Instagram
              )}
              {this.storeSocialMedialLinkButton(
                PartnerManager.shared().partner.pinterestUrl,
                Pinterest
              )}
              {this.storeSocialMedialLinkButton(
                PartnerManager.shared().partner.snapchatUrl,
                Snap
              )}

              {this.storeSocialMedialLinkButton(
                PartnerManager.shared().partner.twitterUrl,
                Twitter
              )}
              {this.storeSocialMedialLinkButton(
                PartnerManager.shared().partner.youtubeUrl,
                Youtube
              )}
            </Stack>
          </Stack>
        </Stack>
        <BottomBar router={this.props.router} />
      </Stack>
    );
  }

  private workingHoursTableRender(workingHours: DailyWorkingHours[]) {
    return (
      <Table>
        <TableBody>
          {workingHours.map((workingHours) => (
            <TableRow key={workingHours.day}>
              <TableCell
                sx={{
                  padding: {
                    xs: 0,
                    sm: 0,
                  },
                  borderBottom: "unset",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Barlow",
                    fontWeight: 700,
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                    textAlign: "start",
                    color: "#BCBCBC",
                    textTransform: "capitalize",
                    marginRight: PixelsToViewPortUnitConverter.getvw(26),
                  }}
                >
                  {workingHours.day.substring(0, 3)}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: {
                    xs: "5px 0",
                    sm: 0,
                  },
                  borderBottom: "unset",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Barlow",
                    fontWeight: 700,
                    fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
                    color: "#BCBCBC",
                  }}
                >
                  {workingHours.period().replace(/\s/g, "").toLowerCase()}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  private storeSocialMedialLinkButton(url: string, imageSrc: any) {
    return (
      !TextUtils.isEmpty(url) && (
        <IconButton
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            padding: "0px",
            minWidth: "0px",
            marginX: PixelsToViewPortUnitConverter.getvw(4),
          }}
        >
          <Avatar
            sx={{
              width: PixelsToViewPortUnitConverter.getFontVw(25),
              height: PixelsToViewPortUnitConverter.getFontVw(25),
            }}
            src={imageSrc}
          />
        </IconButton>
      )
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  pStoreId: state.cartProducts.cart
    ? state.cartProducts.cart.storeId
    : undefined,
});

export default withRouter(connect(mapStateToProps, null)(Footer));
