import React, { useEffect } from "react";
import "./ProductCarousel.scss";
import ProductCard from "./ProductCard";
import Carousel from "react-material-ui-carousel";
import { Stack, Box, Theme } from "@mui/material";
import Product, { CartProduct } from "../../../../core/models/Product";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import ProductList from "../../../../core/models/ProductList";
import Loader from "../../../common/loader/Loader";
import NextIconV2 from "../../../../statics/svgs/NextIconV2";
import PreviousIconV2 from "../../../../statics/svgs/PreviousIconV2";
import { height } from "@mui/system";
import DimensionUtils from "../../../../core/utils/DimensionUtils";
import { Cart } from "../../../common/cart/CartReducer";
import PartnerManager from "../../../../core/utils/PartnerManager";
import { PartnerAppThemeType } from "../../../../core/models/PartnerTheme";

type ProductCarouselProps = {
  isMobile: boolean;
  itemDimension: any;
  productList: ProductList;
  isLoading: boolean;
  pCart: Cart | undefined;
  pOnProductClicked(product: Product): void;
  pOnAddOrRemoveProductFromCart(product: CartProduct): void;
};

export const ProductCarousel: React.FC<ProductCarouselProps> = (
  props: ProductCarouselProps
) => {
  const [items, setItems] = React.useState([]);

  // on parent width or product changes reload the UI
  useEffect(() => {
    setUpUI();
  }, [
    props.itemDimension.parentWidth,
    props.productList.products,
    props.pCart,
  ]);

  /*useEffect(() => {
        // console.log('2. ProductCarousel =>'+JSON.stringify(props.pCart))
        setUpUI()
    },[props.pCart])*/

  const setUpUI = () => {
    const width = props.itemDimension.parentWidth;
    if (width > 0) {
      const minItemWidth = props.itemDimension.width;
      const minItemHeight = props.itemDimension.height;
      const sliderItems: number = props.itemDimension.itemsPerPage;
      const parentLeftPadding = props.itemDimension.parentLeftPadding;
      const parentRightPadding = props.itemDimension.parentRightPadding;
      const gap = props.itemDimension.gap;
      const imgHeight = props.itemDimension.imageHeight;

      let childItems = [];
      for (let i = 0; i < props.productList.products.length; i += sliderItems) {
        if (i % sliderItems === 0) {
          const isLastItem = i === props.productList.products.length - 1;
          childItems.push(
            <Stack
              key={i.toString()}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              minHeight={50}
              sx={{
                ml: `${parentLeftPadding}px`,
                mr: `${parentRightPadding}px`,
              }}
            >
              {props.productList.products
                .slice(i, i + sliderItems)
                .map((item, index) => {
                  return (
                    <Box
                      sx={{
                        minWidth: minItemWidth,
                        maxWidth: minItemWidth,
                        height: "100%",
                        mr: `${!isLastItem ? gap : 0}px`,
                        // pl: `${leftRightPadding}px`,
                        // pr: `${leftRightPadding}px`,
                        backgroundColor: "transparent",
                      }}
                      key={index.toString()}
                    >
                      <ProductCard
                        key={index.toString()}
                        product={item}
                        width={minItemWidth}
                        height={minItemHeight}
                        imageHeight={imgHeight}
                        isGridItem={false}
                        pCart={props.pCart}
                        pOnProductClicked={props.pOnProductClicked}
                        pOnAddOrRemoveProductFromCart={
                          props.pOnAddOrRemoveProductFromCart
                        }
                      />
                    </Box>
                  );
                })}
            </Stack>
          );
        }
      }
      setItems(childItems);
    }
  };

  const onChangeCarousel = (active?: number, previous?: number) => {
    // console.log(`we active ${active}, and previous ${previous}`);
  };

  if (props.isLoading) {
    return (
      <Box sx={{ position: "relative", minHeight: "100px", width: "100%" }}>
        <Loader pshow={true} />
      </Box>
    );
  }

  // find top padding to align vertical center as per the imageview not card.
  const getNexPrevTop = () => {
    const carouselTopPad = DimensionUtils.getVwNumber(20);
    const carouselH = carouselTopPad + props.itemDimension.height;
    let nextPrevTop =
      carouselH / 2 -
      (props.itemDimension.nextPreviousIconSize / 2 + carouselTopPad);
    const temp =
      props.itemDimension.imageHeight / 2 -
      props.itemDimension.nextPreviousIconSize / 2;
    nextPrevTop = nextPrevTop - temp;
    return nextPrevTop;
  };

  const nextPrevColors = (theme: Theme) => {
    switch (PartnerManager.shared().partner.theme.name) {
      case PartnerAppThemeType.CATEGORIES:
        return {
          fill: theme.palette.secondary.main,
          stroke: theme.palette.secondary.contrastText,
        };
      case PartnerAppThemeType.EFFECTS1:
        return {
          fill: theme.palette.primary.main,
          stroke: theme.palette.secondary.main,
        };
      case PartnerAppThemeType.EFFECTS:
      case PartnerAppThemeType.EFFECTS2:
        return {
          fill: theme.palette.secondary.main,
          stroke: theme.palette.secondary.contrastText,
        };
      default:
        return {
          fill: theme.palette.secondary.main,
          stroke: theme.palette.secondary.contrastText,
        };
        break;
    }
  };

  return (
    <Carousel
      className={`cmenu-product-carousel ${props.isLoading ? "-hide" : ""}`}
      animation="slide"
      sx={{
        height: `${props.itemDimension.height}px`,
      }}
      indicators={false}
      autoPlay={false}
      swipe={true}
      navButtonsAlwaysVisible={props.isMobile ? false : true}
      changeOnFirstRender={true}
      cycleNavigation={false}
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: "none",
          borderRadius: "50%",
          padding: "0px",
          margin: "0px",
          marginLeft: `${props.itemDimension.leftM / 2}px`,
          marginRight: `${props.itemDimension.leftM / 2}px`,
        },
      }}
      navButtonsWrapperProps={{
        // Move the buttons to the bottom. Unsetting top here to override default style.
        style: {
          top: `-${getNexPrevTop()}px`,
        },
      }}
      NextIcon={
        <NextIconV2
          sx={{
            fill: (theme) => nextPrevColors(theme).fill,
            stroke: (theme) => nextPrevColors(theme).stroke,
            width: PixelsToViewPortUnitConverter.getvw(39),
            height: PixelsToViewPortUnitConverter.getvw(39),
          }}
        />
      }
      PrevIcon={
        <PreviousIconV2
          sx={{
            fill: (theme) => nextPrevColors(theme).fill,
            stroke: (theme) => nextPrevColors(theme).stroke,
            padding: "0px",
            width: PixelsToViewPortUnitConverter.getvw(39),
            height: PixelsToViewPortUnitConverter.getvw(39),
          }}
        />
      }
      onChange={onChangeCarousel}
    >
      {items}
    </Carousel>
  );
};
