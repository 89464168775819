import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DownArrowIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 18 12"
    return (
        <SvgIcon {...uProps}>
            <path d="M1.00485 1.17603L8.77024 9.81979L16.5453 1.18476" strokeWidth="2" strokeLinecap="round"/>
        </SvgIcon>
    );
}
