import { Box, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import StoreReview from "../../../../core/models/StoreReview";
import DateUtils from "../../../../core/utils/DateUtils";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { ApiError } from "../../../../core/webservice/ApiError";
import StoreOperationsService from "../../../../services/StoreOperationsService";
import Loader from "../../../common/loader/Loader";

interface ICustomerReviewsComponentState {
  sStoreReviews: StoreReview[];
  sIsLoadingReviews: boolean;
  sLoadingError: boolean;
  sLoadingErrorMsg: String;
}

export default class CustomerReviewsComponent extends React.Component<
  any,
  ICustomerReviewsComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      sStoreReviews: [],
      sIsLoadingReviews: true,
      sLoadingError: false,
      sLoadingErrorMsg: "",
    };
  }

  componentDidMount() {
    StoreOperationsService.getStoreReviews(
      PartnerManager.shared().currentSelectedStore.id
    )
      .then((storeReviews) => {
        this.setState({
          sStoreReviews: storeReviews,
          sIsLoadingReviews: false,
        });
      })
      .catch((apiError: ApiError) => {
        this.setState({
          sLoadingError: true,
          sLoadingErrorMsg: apiError.message,
          sIsLoadingReviews: false,
        });
      });
  }

  render() {
    return (
      <Stack
        sx={{
          position: "relative",
          marginTop: PixelsToViewPortUnitConverter.getvw(37),
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Overpass",
            fontWeight: 700,
            fontSize: PixelsToViewPortUnitConverter.getFontVw(40),
            textAlign: "center",
          }}
        >
          CUSTOMER REVIEWS
        </Typography>
        <Rating
          sx={{
            color: "#FFD12E",
            marginTop: PixelsToViewPortUnitConverter.getvw(20),
          }}
          defaultValue={PartnerManager.shared().currentSelectedStore.rating}
          precision={0.5}
          readOnly
        />
        <Typography
          sx={{
            fontFamily: "Overpass",
            fontWeight: 400,
            fontStyle: "italic",
            fontSize: PixelsToViewPortUnitConverter.getFontVw(30),
            textAlign: "center",
          }}
        >
          {PartnerManager.shared().currentSelectedStore.reviewsCount} reviews
        </Typography>
        <Box sx={{ position: "relative", minHeight: "100px", width: "100%" }}>
          {this.state.sIsLoadingReviews ? (
            <Loader pshow={true} />
          ) : (
            this.renderReviews()
          )}
        </Box>
      </Stack>
    );
  }

  private renderReviews(): React.ReactNode {
    return (
      <Stack
        direction="row"
        sx={{
          width: "100%",
          marginTop: PixelsToViewPortUnitConverter.getvw(30),
          display: "flex",
          justifyContent: "center",
        }}
        key={"reviews-stack"}
      >
        {this.state.sStoreReviews.map((storeReview, index) => (
          <Stack
            sx={{
              width: "18vw",
              height: "13vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), rgba(255, 255, 255, 0.54)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              borderRadius: "30px",
              marginX: PixelsToViewPortUnitConverter.getvw(32),
            }}
            key={index.toString()}
          >
            <Rating
              sx={{
                color: "#FFD12E",
                marginTop: PixelsToViewPortUnitConverter.getvw(36),
                height: PixelsToViewPortUnitConverter.getvw(26),
              }}
              defaultValue={storeReview.rating}
              precision={0.5}
              readOnly
            />
            <Typography
              sx={{
                fontFamily: "Overpass",
                fontWeight: 400,
                fontStyle: "italic",
                fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
                textAlign: "center",
                marginTop: PixelsToViewPortUnitConverter.getvw(10),
              }}
            >
              {DateUtils.timeStampToDisplayDateFormat(storeReview.reviewDate)}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Overpass",
                fontWeight: 400,
                fontSize: PixelsToViewPortUnitConverter.getvw(22),
                textAlign: "center",
                marginY: "auto",
                marginX: PixelsToViewPortUnitConverter.getvw(10),
              }}
            >
              {storeReview.review}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  }
}
