import Partner from "../core/models/Partner";
import WebServiceUtils from "../core/webservice/WebServiceUtils";
import PartnerManager from "../core/utils/PartnerManager";
import SessionManager from "../core/utils/SessionManager";
import { AuthResponse } from "../core/models/AuthResponse";
import TextUtils from "../core/utils/TextUtils";

export default class PartnerService {
  private static partnerApiUrl =
    process.env.REACT_APP_PARTNER_BACKEND_BASE_URL + "/partner";
  private static config = { headers: { "Content-Type": "application/json" } };

  /**
   * Get Partner Info.
   * @returns {Partner} - partner details object
   */
  public static async getPartnerInfo(domainName: string) {
    PartnerManager.shared().resetPartner();
    const response = await WebServiceUtils.post(
      this.getPartnerInfoBody(domainName),
      PartnerService.config,
      PartnerService.partnerApiUrl
    );

    if (response.success && response.data && response.data.data) {
      try {
        if (
          response.data.data.partnerInfoByDomain &&
          !TextUtils.isEmpty(response.data.data.partnerInfoByDomain)
        ) {
          const partner = new Partner(response.data.data.partnerInfoByDomain);
          PartnerManager.shared().setPartner(partner);
          return Promise.resolve(partner);
        } else {
          return Promise.reject(null);
        }
      } catch (error) {
        return Promise.reject(null);
      }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  private static getPartnerInfoQueryParams(): any {
    return `id,clientId,clientSecret,domainName,algoliaAppId,algoliaAppKey,instagramUrl,fbUrl,youTubUrl,twitterUrl,snapchatUrl,pinterestUrl,state,theme{id,name,logoUrl,faviconUrl,backgroundColor,pageHighlightColor,buttonColor,buttonTextColor},categories{id,name,rank},effects{id,name,rank},appSettings{appName},userPoolInfo{poolId,clientName,clientId}`;
  }

  private static getPartnerInfoBody(domainName: string): any {
    return `{
          "query": "query ($domainName:String!){ partnerInfoByDomain(domainName:$domainName){${this.getPartnerInfoQueryParams()}}}",
          "variables": {
              "domainName": "${domainName}"
          }
      }`.replace(/\s+/g, " ");
  }

  /**
   * Generate Jane Roots Token.
   * @returns {Partner} - partner details object
   */
  public static async generateJaneRootsToken(domainName: string) {
    const response = await WebServiceUtils.post(
      this.generateJaneRootsTokenBody(domainName),
      PartnerService.config,
      PartnerService.partnerApiUrl
    );

    if (
      response.success &&
      response.data &&
      response.data.data &&
      response.data.data.getJaneAccessToken
    ) {
      try {
        if (response.data) {
          let access_token: string = response.data.data.getJaneAccessToken;
          let authResponse: AuthResponse = {
            access_token: access_token,
            token_type: "Bearer",
            created_at: 123,
          };
          SessionManager.shared().saveJaneAccessToken(
            authResponse.access_token
          );
          return Promise.resolve(authResponse);
        } else {
          return Promise.resolve(null);
        }
      } catch (error) {
        return Promise.resolve(null);
      }
    }
    return Promise.resolve(null);
  }

  private static generateJaneRootsTokenBody(domainName: string): any {
    return `{
        "query": "mutation ($domainName:String!){ getJaneAccessToken(domainName:$domainName)}",
        "variables": {
            "domainName": "${domainName}"
        }
    }`.replace(/\s+/g, " ");
  }
}
