import SvgIcon from "@mui/material/SvgIcon";
export default function CartIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M40.7436 7.41069L36.0914 22.0629C35.7436 23.1063 34.8306 23.802 33.7436 23.802H15.7871C14.7436 23.802 13.7436 23.1498 13.3958 22.1933L6.52622 3.80199H2.5697C1.61318 3.80199 0.830566 3.01938 0.830566 2.06286C0.830566 1.10634 1.61318 0.32373 2.5697 0.32373H7.74361C8.48274 0.32373 9.13491 0.801991 9.39578 1.49764L16.4827 20.3237H33.0045L36.7001 8.5846H16.2219C15.2653 8.5846 14.4827 7.80199 14.4827 6.84547C14.4827 5.88895 15.2653 5.10634 16.2219 5.10634H39.0914C39.6567 5.10634 40.1784 5.41069 40.4827 5.84547C40.8306 6.28025 40.9175 6.88895 40.7436 7.41069ZM16.6567 26.1063C15.9175 26.1063 15.1784 26.4107 14.6567 26.9324C14.1349 27.4542 13.8306 28.1933 13.8306 28.9324C13.8306 29.6716 14.1349 30.4107 14.6567 30.9324C15.1784 31.4542 15.9175 31.7585 16.6567 31.7585C17.3958 31.7585 18.1349 31.4542 18.6567 30.9324C19.1784 30.4107 19.4827 29.6716 19.4827 28.9324C19.4827 28.1933 19.1784 27.4542 18.6567 26.9324C18.1349 26.4107 17.3958 26.1063 16.6567 26.1063ZM32.2653 26.1063C31.5262 26.1063 30.7871 26.4107 30.2653 26.9324C29.7436 27.4542 29.4393 28.1933 29.4393 28.9324C29.4393 29.6716 29.7436 30.4107 30.2653 30.9324C30.7871 31.4542 31.5262 31.7585 32.2653 31.7585C33.0045 31.7585 33.7436 31.4542 34.2654 30.9324C34.7871 30.4107 35.0914 29.6716 35.0914 28.9324C35.0914 28.1933 34.7871 27.4542 34.2654 26.9324C33.7436 26.4107 33.0045 26.1063 32.2653 26.1063Z"
      />
    </SvgIcon>
  );
}
