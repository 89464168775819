import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "../../../../statics/svgs/CartIcon";
import Badge from "@mui/material/Badge";
import InputBase from "@mui/material/InputBase";
import Button, { ButtonProps } from "@mui/material/Button";
import SearchIcon from "../../../../statics/svgs/SearchIcon";
import PartnerManager from "../../../../core/utils/PartnerManager";
import PixelsToViewPortUnitConverter from "../../../../core/utils/PixelsToViewPortUnitConverter";
import { alpha } from "@mui/material/styles";
import Popover from "@mui/material/Popover/Popover";
import EffectsHeaderMenu from "../home/EffectsHeaderMenu";
import ProductList from "../../../../core/models/ProductList";
import TextUtils from "../../../../core/utils/TextUtils";
import MenuService from "../../../../services/MenuService";
import { ApiError } from "../../../../core/webservice/ApiError";
import List from "@mui/material/List/List";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import Popper from "@mui/material/Popper/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Card } from "@mui/material";
import withRouter from "../../../../withRouter";
import Navigation from "../../../../Navigation";
import AppRoutes from "../../../../routes";

interface HeaderButtonProps extends ButtonProps {
  isSelected?: boolean;
}

enum eHeaderButton {
  SHOP,
  DEALS,
  LOCATION,
}

const HeaderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "success",
})<HeaderButtonProps>(({ isSelected, theme }) => ({
  ...{
    color: theme.palette.primary.contrastText,
    marginLeft: PixelsToViewPortUnitConverter.getvw(120),
    lineHeight: "0px",
    padding: "0px 0px",
    paddingLeft: PixelsToViewPortUnitConverter.getvw(30),
    paddingRight: PixelsToViewPortUnitConverter.getvw(30),
    paddingTop: PixelsToViewPortUnitConverter.getvw(22),
    paddingBottom: PixelsToViewPortUnitConverter.getvw(18),
    "&:hover": {
      backgroundColor: isSelected
        ? alpha(theme.palette.primary.contrastText, 0.4)
        : "none",
    },
    background: isSelected
      ? alpha(theme.palette.primary.contrastText, 0.4)
      : "none",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginLeft: "auto",
  marginRight: "auto",
  width: "auto",
  display: "flex",
  borderBottom: "1px solid",
  BorderColor: theme.palette.primary.contrastText,
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: PixelsToViewPortUnitConverter.getvw(30),
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    transition: theme.transitions.create("width"),
    width: "20ch",
    "&:focus": {
      width: "32ch",
    },
  },
}));

interface IHeaderProps {
  router: any;
  pShowTransperentHeader: boolean;
}

interface IHeaderStates {
  sHeaderSelectedButton: eHeaderButton | undefined;
  sShowShopMenu: boolean;
  sSearchResults: ProductList | undefined;
  sSearchValue: string | undefined;
  sSearchSuggestions: boolean;
}

const queryString = require("query-string");

class Header extends React.Component<IHeaderProps, IHeaderStates> {
  mShopButton = React.createRef<HTMLButtonElement>();
  mSearch = React.createRef<HTMLDivElement>();
  constructor(props: IHeaderProps) {
    super(props);
    const query = queryString.parse(this.props.router.location.search)["query"];
    this.state = {
      sHeaderSelectedButton: this.props.router.location.pathname.includes(
        "products"
      )
        ? eHeaderButton.SHOP
        : undefined,
      sShowShopMenu: false,
      sSearchResults: undefined,
      sSearchValue: query,
      sSearchSuggestions: false,
    };
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <AppBar
        sx={
          this.props.pShowTransperentHeader
            ? {
                backgroundColor: "transparent",
                boxShadow: "unset",
              }
            : {}
        }
      >
        <Toolbar
          style={{
            paddingLeft: PixelsToViewPortUnitConverter.getvw(50),
            paddingRight: PixelsToViewPortUnitConverter.getvw(50),
            paddingTop: PixelsToViewPortUnitConverter.getvw(20),
            paddingBottom: PixelsToViewPortUnitConverter.getvw(20),
          }}
        >
          <IconButton
            onClick={() => {
              this.setState({ sHeaderSelectedButton: undefined });
              this.props.router.navigate({
                pathname: AppRoutes.ROOT,
              });
            }}
            sx={{
              padding: "0px",
              marginRight: PixelsToViewPortUnitConverter.getvw(80),
            }}
          >
            <img
              style={{
                height: PixelsToViewPortUnitConverter.getvw(74),
                width: PixelsToViewPortUnitConverter.getvw(74),
              }}
              src={PartnerManager.shared().partner.theme.logoUrl}
            />
          </IconButton>
          <HeaderButton
            ref={this.mShopButton}
            isSelected={this.state.sHeaderSelectedButton == eHeaderButton.SHOP}
            onMouseOver={() => {
              this.setState((state) => ({
                sShowShopMenu: !state.sShowShopMenu,
              }));
            }}
          >
            SHOP
          </HeaderButton>
          <Popover
            disableRestoreFocus={true}
            open={this.state.sShowShopMenu}
            anchorEl={this.mShopButton.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: PixelsToViewPortUnitConverter.getvw(
                this.props.pShowTransperentHeader ? 18 : 48
              ),
            }}
            onClick={(e) => {
              if (
                this.mShopButton.current.getBoundingClientRect().left <
                  e.clientX &&
                this.mShopButton.current.getBoundingClientRect().right >
                  e.clientX &&
                this.mShopButton.current.getBoundingClientRect().top <
                  e.clientY &&
                this.mShopButton.current.getBoundingClientRect().bottom >
                  e.clientY
              ) {
              } else this.setState({ sShowShopMenu: false });
            }}
          >
            <EffectsHeaderMenu />
          </Popover>
          <HeaderButton
            onClick={() => {
              this.setState({ sHeaderSelectedButton: eHeaderButton.DEALS });
            }}
            isSelected={this.state.sHeaderSelectedButton == eHeaderButton.DEALS}
          >
            DEALS
          </HeaderButton>
          <HeaderButton
            onClick={() => {
              this.setState({ sHeaderSelectedButton: eHeaderButton.LOCATION });
            }}
            isSelected={
              this.state.sHeaderSelectedButton == eHeaderButton.LOCATION
            }
          >
            LOCATION
          </HeaderButton>
          {!this.props.pShowTransperentHeader && (
            <Search ref={this.mSearch} sx={{ marginLeft: "auto" }}>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={this.state.sSearchValue}
                onChange={(e) => {
                  this.setState({
                    sSearchResults: undefined,
                    sSearchValue: e.currentTarget.value,
                    sSearchSuggestions: true,
                  });
                  if (!TextUtils.isEmpty(e.currentTarget.value)) {
                    MenuService.getProducts("", e.currentTarget.value)
                      .then((results) => {
                        if (results.query == this.state.sSearchValue) {
                          this.setState({ sSearchResults: results });
                        }
                      })
                      .catch((apiError: ApiError) => {});
                  } else {
                    this.setState({ sSearchResults: undefined });
                  }
                }}
                onClick={(e) => {
                  setTimeout(() => {
                    this.setState({ sSearchSuggestions: true });
                  }, 200);

                  e.stopPropagation();
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const parsed = queryString.parse(
                      this.props.router.location.search
                    );
                    parsed["query"] = this.state.sSearchValue;
                    Navigation.toShopAllWithSearchQuery(
                      this.props.router,
                      `${queryString.stringify(parsed)}`
                    );
                  }
                }}
              />
              <SearchIconWrapper>
                <IconButton
                  focusRipple={true}
                  onClick={() => {
                    const parsed = queryString.parse(
                      this.props.router.location.search
                    );
                    parsed["query"] = this.state.sSearchValue;
                    Navigation.toShopAllWithSearchQuery(
                      this.props.router,
                      `${queryString.stringify(parsed)}`
                    );
                  }}
                >
                  <SearchIcon color="secondary" viewBox="0 0 31 31" />
                </IconButton>
              </SearchIconWrapper>
            </Search>
          )}
          <ClickAwayListener
            onClickAway={() => {
              this.setState({ sSearchSuggestions: false });
            }}
          >
            <Popper
              open={
                !this.props.pShowTransperentHeader &&
                this.mSearch.current &&
                this.state.sSearchSuggestions &&
                this.state.sSearchResults &&
                this.state.sSearchResults.products != undefined &&
                this.state.sSearchResults.products.length > 0
              }
              anchorEl={this.mSearch.current}
              placement="bottom-start"
              sx={{
                zIndex: "1200",
              }}
            >
              {this.state.sSearchResults && this.state.sSearchResults.products && (
                <Card
                  sx={{
                    width: this.mSearch.current
                      ? this.mSearch.current.clientWidth
                      : "auto",
                    maxHeight: "60vh",
                    overflowY: "auto",
                    backgroundColor: "white",
                    marginTop: PixelsToViewPortUnitConverter.getvw(36),
                  }}
                >
                  <List>
                    {this.state.sSearchResults.products.map((product) => (
                      <ListItemButton
                        sx={{
                          width: "100%",
                          paddingX: PixelsToViewPortUnitConverter.getFontVw(24),
                          fontFamily: "Overpass",
                          fontSize: PixelsToViewPortUnitConverter.getFontVw(24),
                          fontWeight: "400",
                          color: "rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        {product.name}
                      </ListItemButton>
                    ))}
                  </List>
                </Card>
              )}
            </Popper>
          </ClickAwayListener>
          <Badge
            color="secondary"
            showZero
            badgeContent={0}
            sx={{
              marginRight: PixelsToViewPortUnitConverter.getvw(20),
              marginLeft: "auto",
            }}
          >
            <IconButton
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                width: PixelsToViewPortUnitConverter.getvw(40),
                padding: "0px",
              }}
            >
              <ShoppingCartIcon viewBox="0 0 41 32" />
            </IconButton>
          </Badge>
        </Toolbar>
      </AppBar>
    );
  }
}
export default withRouter(Header);
