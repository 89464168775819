
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TickIcon(props: SvgIconProps) {
    var uProps = {...props}
    uProps.viewBox = "0 0 19 15"
    return (
        <SvgIcon {...uProps}>
            <path d="M1 7L5.1129 13L18 1" fill='none' stroke="black" strokeWidth="2"/>
        </SvgIcon>
    );
}