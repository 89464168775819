import TextUtils from "../utils/TextUtils";

export default class User {
  private mId: string;
  public get id() {
    return this.mId;
  }

  private mFirstName: string;
  public get firstName() {
    return this.mFirstName;
  }
  public set firstName(setFirstName: string) {
    this.mFirstName = setFirstName;
  }

  private mLastName: string;
  public get lastName() {
    return this.mLastName;
  }
  public set lastName(setLastName: string) {
    this.mLastName = setLastName;
  }

  private mPhone: string;
  public get phone() {
    return this.mPhone;
  }
  public set phone(setPhone: string) {
    this.mPhone = setPhone;
  }

  private mEmail: string;
  public get email() {
    return this.mEmail;
  }

  public parseResponse(userJson: any) {
    this.mId = userJson.id;
    this.mFirstName = userJson.first_name;
    this.mLastName = userJson.last_name;
    this.mPhone = TextUtils.formatUSPhoneNumber(userJson.phone);
    this.mEmail = userJson.email;
    return this;
  }
}
