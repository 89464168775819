import { InputBase, List, ListItemButton, Button } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import React from "react";
import ProductList from "../../../core/models/ProductList";
import PixelsToViewPortUnitConverter from "../../../core/utils/PixelsToViewPortUnitConverter";
import TextUtils from "../../../core/utils/TextUtils";
import { ApiError } from "../../../core/webservice/ApiError";
import MenuService from "../../../services/MenuService";
import SearchSuggestionItem from "../../themes/effectsv2/header/SearchSuggestionItem";
import { alpha } from "@mui/material/styles";
import withRouter from "../../../withRouter";
import Navigation from "../../../Navigation";
import PartnerManager from "../../../core/utils/PartnerManager";

interface ISearchComponentState {
  sSearchResults: ProductList | undefined;
  sSearchValue: string | undefined;
  sSearchSuggestions: boolean;
}

const queryString = require("query-string");

class SearchComponent extends React.Component<any, ISearchComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      sSearchResults: undefined,
      sSearchValue: undefined,
      sSearchSuggestions: true,
    };
  }

  render() {
    return (
      <Stack
        sx={{
          display: {
            sm: "block",
            xs: "block",
          },
          // position: "relative",
          // width: "100%",
          // marginTop:{
          //   sm:  PixelsToViewPortUnitConverter.getvw(50),
          //   xs: PixelsToViewPortUnitConverter.getvw(130),
          // },
          zIndex: {
            sm: "0",
            xs: "1000",
          },
          top: {
            sm: "unset",
            xs: "0",
          },
          position: "relative",
          width: {
            sm: "100%",
            xs: "100vw",
          },
          height: {
            sm: "auto",
            xs: "calc(100vh - 165px)",
          },
          overflowY: {
            sm: "unset",
            xs: "auto",
          },
          backgroundColor: {
            sm: "unset",
            xs: PartnerManager.shared().partner.theme.backgroundColor,
          },
          paddingTop: {
            sm: "0",
            xs: PixelsToViewPortUnitConverter.getFontVh(50),
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            paddingRight: PixelsToViewPortUnitConverter.getvw(100),
            paddingLeft: PixelsToViewPortUnitConverter.getvw(100),
            paddingBottom: PixelsToViewPortUnitConverter.getvw(35),

            marginTop: PixelsToViewPortUnitConverter.getvw(150),

            borderStyle: "solid",
            borderWidth: 0,
            borderBottomWidth: PixelsToViewPortUnitConverter.getvw(1),
            borderBottomColor: (theme) =>
              alpha(theme.palette.primary.contrastText, 0.14),
          }}
        >
          <InputBase
            sx={{
              fontFamily: "Barlow",
              fontWeight: "500",
              flex: 1,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(20),
            }}
            autoFocus
            type="search"
            value={this.state.sSearchValue}
            placeholder="Search strain, brand, effect, etc"
            inputProps={{ enterKeyHint: "search", "aria-label": "search" }}
            onChange={(e) => {
              this.setState({
                sSearchResults: undefined,
                sSearchValue: e.currentTarget.value,
                sSearchSuggestions: true,
              });
              if (!TextUtils.isEmpty(e.currentTarget.value)) {
                MenuService.getProducts("", e.currentTarget.value)
                  .then((results) => {
                    if (results.query == this.state.sSearchValue) {
                      this.setState({ sSearchResults: results });
                    }
                  })
                  .catch((apiError: ApiError) => {});
              } else {
                this.setState({ sSearchResults: undefined });
              }
            }}
            onClick={() => this.setState({ sSearchSuggestions: true })}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                const parsed = queryString.parse(
                  this.props.router.location.search
                );
                parsed["query"] = this.state.sSearchValue;
                Navigation.toShopAllWithSearchQuery(
                  this.props.router,
                  `${queryString.stringify(parsed)}`
                );
              }
            }}
          />
          <Button
            variant="text"
            sx={{
              opacity: !TextUtils.isEmpty(this.state.sSearchValue) ? 1 : 0,
              fontSize: PixelsToViewPortUnitConverter.getFontVw(16),
              fontFamily: "Barlow",
              fontWeight: 500,
              minWidth: "0px",
              minHeight: "0px",
              padding: "0px",
              marginBottom: PixelsToViewPortUnitConverter.getvw(4),
              paddingRight: PixelsToViewPortUnitConverter.getvw(8),
              paddingLeft: PixelsToViewPortUnitConverter.getvw(4),
              color: (theme) => theme.palette.primary.contrastText,
            }}
            onClick={() => {
              this.setState({
                sSearchResults: undefined,
                sSearchSuggestions: false,
                sSearchValue: "",
              });
            }}
          >
            Clear
          </Button>
        </Box>
        {this.state.sSearchSuggestions &&
          this.state.sSearchResults &&
          this.state.sSearchResults.products.length > 0 && (
            <Box
              sx={{
                overflowY: "auto",
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              <List>
                {this.state.sSearchResults.products.map((product) => (
                  <ListItemButton
                    sx={{
                      overflowX: "hidden",
                    }}
                    onClick={() => {
                      this.setState({
                        sSearchSuggestions: false,
                      });
                      Navigation.toProductDetails(this.props.router, product);
                    }}
                  >
                    <SearchSuggestionItem pProduct={product} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          )}
      </Stack>
    );
  }
}
export default withRouter(SearchComponent);
